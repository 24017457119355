import React,{useState} from 'react';
import* as version from '../../Constants/version'
import * as Web from '../../Constants/Web'



export const clearCache=()=>{
/*  const [msg,setMsg]=useState('')
  const [currentVersion,setCurrentVersion]=useState(version.version)
  const [newVersion, setNewVersion]=useState()*/

  var msg=''
  var currentVersion=version.version
  var newVersion=''
  const data = {
        id:'1'
      }
      fetch(Web.wurl+'/Api/Get_Version.php')
      .then((response)=>response.json())
      .then((responseJson)=>
      {
        newVersion=responseJson[0].version
        if (currentVersion==newVersion) {
          ///go
        }else {
          if('caches' in window){
    caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
    }
        }

      })



  return(msg)


}
