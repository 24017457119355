import React from 'react'
import {VictoryChart, VictoryBar, VictoryGroup} from 'victory';
import Title from '../../Titles';
import Chart from 'react-google-charts';
const NewStudents = (props) =>{
const {newStudents}=props
const d=[
          ['Galaxy', 'Distance', 'Brightness'],
          ['Canis Major Dwarf', 8000, 23.3],
          ['Sagittarius Dwarf', 24000, 4.5],
          ['Ursa Major II Dwarf', 30000, 14.3],
          ['Lg. Magellanic Cloud', 50000, 0.9],
          ['Bootes I', 60000, 13.1]
        ]
return(<React.Fragment>
  <Title title={"Alumnos Nuevos"}/>
  <Chart
    width={'1000px'}
    height={'300px'}
    chartType="Bar"
    loader={<div>Loading Chart</div>}
    data={newStudents}
    options={{
      // Material design options
      chart: {
        title: 'Alumnos Nuevos'
      },
    }}
    // For tests
    rootProps={{ 'data-testid': '2' }}
  />
</React.Fragment>
)

}
export default NewStudents;
