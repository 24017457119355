import { connect } from 'react-redux';
import { fetchGetPost, fetchAddPost, fetchUpdatePost, fetchDeletePost } from '../../reducers/postsReducer';
import React, { Component } from 'react';
import '../../Styles/App.css';
import PlacesIcon from '../../Media/Places.png'
class ShowPlaces extends Component {

  constructor(props){
          super(props);
          this.state={
                show:false,
            searchTerm: '',
                Name:'',
                States:'',
                State:'',
                City:'',
                Phone:'',
                Address:'',
                Zip:'',
                Email:'',
                url:'Create/Create_Place.php',
            };
      }


      componentWillMount() {
          this.props.fetchGetPost('Get_Places.php');
          fetch("http://localhost/conversationhackers/Api/Get_States.php")
          .then((response)=>response.json())
          .then((responseJson)=>
          {
          this.setState({States:responseJson});
          const vare=JSON.stringify(this.state.States)
       })
      }

  render(){
    return(
      <div class="center">
   <div class="card-place">
    <div class="additional">
      <div class="user-card">
        <div class="level center">
          Level 13
        </div>
        <div class="points center">
          5,312 Points
        </div>
      </div>
      <div class="more-info">
        <h1>Jane Doe</h1>
        <div class="coords">
          <span>Group Name</span>
          <span>Joined January 2019</span>
        </div>
        <div class="coords">
          <span>Position/Role</span>
          <span>City, Country</span>
        </div>
        <div class="stats">
          <div>
            <div class="title">Awards</div>
            <i class="fa fa-trophy"></i>
            <div class="value">2</div>
          </div>
          <div>
            <div class="title">Matches</div>
            <i class="fa fa-gamepad"></i>
            <div class="value">27</div>
          </div>
          <div>
            <div class="title">Pals</div>
            <i class="fa fa-group"></i>
            <div class="value">123</div>
          </div>
          <div>
            <div class="title">Coffee</div>
            <i class="fa fa-coffee"></i>
            <div class="value infinity">∞</div>
          </div>
        </div>
      </div>
    </div>
    <div class="general">
      <h1>Jane Doe</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a volutpat mauris, at molestie lacus. Nam vestibulum sodales odio ut pulvinar.</p>
      <span class="more">Mouse over the card for more info</span>
    </div>
  </div>


</div>



			)
		}





}

export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        fetchGetPost, fetchAddPost, fetchUpdatePost, fetchDeletePost
    }
)(ShowPlaces);
