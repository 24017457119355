import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal'
import { Edit } from '../../reducers/postsReducer';
import Card from 'react-bootstrap/Card'
import Images from '../../Constants/images';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Gray_Template from '../MainComponents/Gray_Template'
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card_video from './post'
import * as Web from '../../Constants/Web'
import SearchInput, {createFilter} from 'react-search-input';
const KEYS_TO_FILTERS = ['title'];

class App extends Component {

  constructor(props){
          super(props);
          this.state={
              Datos:[],
              url:[],
              url:Web.wurl+'/',
              url3:Web.wurl+'/inglesfacil/',
              videos:[],
              data:'',
              url_video:'',
              audio_url:'',
              image_url_thumbnail:'',
              title:'',
              des:'',
              topics:[],

          searchTerm: '',

            };
      }


      componentDidMount(){
        const student={
          id:'1'
        }

        var url = this.state.url+'Api/Get_VideosStudents.php';
        fetch(url, {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(student), // data can be `string` or {object}!
          header: {'Content-Type': 'application/json'}
        }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(response => {
          this.setState({videos:response.reverse()})} );

        }

     searchUpdated (term) {
      this.setState({searchTerm: term})
  }



  render(){
    var row;
    const student=this.state.videos
		const filteredInfo = student.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
	        if (student.length === 0 ) {
	        	return(<div>vacio</div>
	            )} else{
		return(
				<div>
	        <SearchInput placeholder="Buscar" className="search-input form-group my-input text-center"  onChange={this.searchUpdated.bind(this)} />

				  {filteredInfo.map((item, i) => {
            if(i% 2){
                return <li xs={6} key={item} className="card-marginleft" >
                  <Card_video   data={item} Id_video_conversation={item.Id_video_conversation}  image={item.image_url_thumbnail} title={item.title} />
                </li>;
            }
            else {
              //0
                return <li xs={6} key={item} className="card-marginright" >
                  <Card_video data={item} Id_video_conversation={item.Id_video_conversation}   image={item.image_url_thumbnail} title={item.title} />
                </li>;
            }

	                    })
	                }


				</div>

			)}
		}





}

export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({

              edit: state.edit.edit,
    }),
    //mapeo de funciones
    {
        Edit
        }
)(App);
