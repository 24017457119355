import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import {Link} from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import '../../Styles/Cardvideo.css';
import * as LOGIN from '../../Constants/Menu'
import {Redirect} from 'react-router-dom';
import YouTube from 'react-youtube';

//php version 7.3.2

class CardVideoHome extends Component {

  constructor(props){
          super(props);
          this.state={
              video:this.props.data.youtubevideo.replace('https://www.youtube.com/watch?v=',''),
              url:'',
              des:'',
              title:'',
              height:'390',
              width:'640',
              cols:'150'
            };
      }

     componentDidMount() {
         window.addEventListener("resize", this.resize.bind(this));
          this.resize();

      }

     resize() {
        //this.setState({hideNav: window.innerWidth <= 858});
  if(window.innerWidth<=800){
    this.setState({
      height:'200',
      width:'300',
      cols:'30'
    })
  }else {
    this.setState({
      height:'390',
      width:'640',
      cols:'100'
    })
  }
}



  render() {

    const opts = {
      height: this.state.height,
      width: this.state.width,
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0
      }
    };
    return (
      <Container>
      <Row>
      <Col sm  className="text-center">


        <div className="blog-body">
<hr/>

                  <hr/>
        <YouTube
                videoId={this.state.video}
                opts={opts}
                onReady={this._onReady}
              />
          <div className="blog-title">
            <h2>{this.props.title}</h2>
          </div>
          <hr/>
            <hr/>


        </div>


      </Col>
      </Row>
      </Container>

    );
  }
}

export default CardVideoHome;
