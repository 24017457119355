import React, { Component } from 'react';
import '../../Styles/App.css';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import {NavLink,Redirect,withRouter,Link} from 'react-router-dom'
import Home from './Home';
import * as AdminMenu from '../../Constants/Menu'
import logo from '../../Media/Conversation_Hackers_Logo.png'
import home from '../../Media/Home.png'






class Menu extends Component {

  constructor(props){
          super(props);
          this.state={
              Home:'1',
              redirectToReferrer: false,
              Sucursales:'',
              Membership:'',
              Settings:'',
              Coaches:'',
              Reportes:'',
              one:'Active_Menu',
              two:'',
              three:'',
              four:'',
              five:''
            };
            this.Home=this.Home.bind(this);
      }



      Home(){this.setState({Home:'1',Sucursales:'',Membership:'',Settings:'',Reportes:'',one:'Active_Menu',two:'',three:'',four:'',five:''})}
      logOut(){
      localStorage.removeItem('userData');

       this.setState({
         redirectToReferrer:true
       })
       alert('Saliste')
     }




  render() {
    if (this.state.redirectToReferrer) {
     return (<Redirect to={'/'}/>)
   }

   if(localStorage.getItem('userData')){
     let myItem = localStorage.getItem('userData')
     const info=JSON.parse(myItem)
     if(info.type=='MASTER'){
      // return (<Redirect to={'/admin'}/>)
     }
     if(info.type=='Student'){
       return (<Redirect to={'/Profile'}/>)
     }
     if(info.type=='Coach'){
      /// return (<Redirect to={'/Coach'}/>)
     }
     if(info.type=='Admin'){
       return (<Redirect to={'/admins'}/>)
     }

   }else {
     return (<Redirect to={'/login'}/>)
   }
    return (
    <div>
    <Navbar collapseOnSelect expand="lg" className="Menu navbar-blue"  >
    <Navbar.Brand ><label className="CH_Text2 white-text">Hola, Coach</label>   <img src={logo} className="Logo_Img_Sm"/></Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
        <Nav.Link href="#" onClick={this.Home} className={this.state.one}><Link to={'/Coach'}><label className="CH_Text"><img src={home} className="CH_SM_Icono"/>{AdminMenu.HOME}</label></Link></Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link eventKey={2} href="#" onClick={this.logOut} className="white-text">
            {AdminMenu.LOGOUT}
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>


<Home/>


        </div>
    );
  }
}

export default Menu;
