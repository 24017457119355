import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Helpers from '../../lib/Helper'
import Helper from '../../lib/Helper'
import firebase from 'firebase'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import GrayTemplate from '../MainComponents/Gray_Template'
import Zoom from 'react-reveal/Fade';
import Desc from './Desc'
var labels = [
"Usa",
"Mexico",
"Mexico2",
"Guatemala",
"Honduras",
"Salvador",
"Colombia",
"Peru",
"Bolivia",
"Argentina",
"Chile",
];
var country=''
class LoadAudio extends Component {
  constructor(props){
    super(props)
    this.state={
      uid:'8B57J61q4bM51u9oF7tKln340Fm2',
      show:false,
      country:'',
      audio:'',
      Loader:false,
      audios:null
    }
    this.saveaudio=this.saveaudio.bind(this)

  }

  saveaudio(event){
    if(event){
    //  alert('si')
    this.setState({Loader:true})
       var fet=event.target.name
      const file = event.target.files[0]
      const storageRef = firebase.storage().ref("countries/"+fet+".mp3")
      const task = storageRef.put(file)
   task.on('state_changed', (snapshot) => {
        let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      }, (error) => {
        alert("hubo un error, vuelve a intentarlo.");
      },() =>{
    task.snapshot.ref.getDownloadURL().then((downloadURL)=> {
          const url={
            Url:downloadURL
          }
          Helper.AddAudio(this.state.uid,fet,(url)).then(()=>{/*informacion de archivo*/
            alert('Correcto')
            this.setState({Loader:false})
          }).catch(function(error){

            this.setState({Loader:false})
          let MS=error.message
          alert(MS)
          });
        })
              });
    }else {
    //  alert('no')
    }

  }
  render() {
    return (
      <Zoom left cascade>
      {this.state.Loader==true?<h3>CARGANDO...</h3>:
      <div className="black-text">

          {(() => {
            const options = [];

            for (let i = 0; i <= 9; i++) {
              options.push(
                <Row>
                <Col sm={3}><span > {labels[i]} </span> </Col>
                <Col sm={6}>
                <input type='file' accept=".mp3,audio/*" name={labels[i]} onChange={this.saveaudio}/>
                </Col>
                </Row>
              );
            }

            return options;
          })()}

      </div>}
</Zoom>
    );
  }
}

export default LoadAudio;
