import React, { Component } from 'react';
import Add from '../MainComponents/Add'
import Badge from 'react-bootstrap/Badge'
import ShowPlaces from './Tables/Show_Places'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Gray_Template from '../MainComponents/Gray_Template'



class Sucursales extends Component {

  constructor(props){
          super(props);
          this.state={
              Name:''
            };

                }






  render() {
    return (
    <Container  >
    <h1><Badge variant="secondary">Sucursales</Badge></h1>
    <hr/>
      <ShowPlaces/>
    </Container>
    );
  }
}
export default Sucursales;
