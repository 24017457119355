import React,{useEffect, useState} from 'react'
import * as URL from '../../../../Constants/services'
import Table from 'react-bootstrap/Table'
import classes from './oldClasses.module.css'


const OldClassHistory = (props) =>{
  const [classes, setClasses]=useState([])
  const {student_id}=props

  useEffect(()=>{
    var url = URL.api+"/oldclasses";
    const inf={
      id:student_id
    }
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(inf), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => {
      setClasses(response)
   })
  },[student_id])
if (!classes) {
  return<p>no hay clases</p>
}

const list = classes.map((item,i)=>{
  return(<tr>
        <td>{i}</td>
        <td>{item.Id_OldClass}</td>
        <td>{item.Num_Day_Old}</td>
        <td>{item.Month_Old}</td>
        <td>{item.Year_Old}</td>
        <td>{item.Time_Old}</td>
        <td>{item.TimeEnd_Old}</td>
        <td>{item.Coach}</td>
        </tr>)
})

  return(<div>
    <hr/>
    <h3>{"Historial de clases"}</h3>
    <Table striped bordered hover>
      <thead>
        <tr>
        <th></th>
          <th>id clase</th>
          <th>día</th>
          <th>mes</th>
          <th>año</th>
          <th>incio</th>
          <th>fin</th>
          <th>coach</th>
        </tr>
      </thead>
      <tbody>
      {list}
      </tbody>
    </Table>
  </div>)
}

export default OldClassHistory;
