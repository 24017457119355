import React from 'react';
import Functions from '../Functions/Functions'
const HourCheckerNotInternational = function(data){

///var timeDiff= await Time_Zone.Time_Zone(idStudent,data.Time_Zone,data.hourFrom,data.hourTo)/*returns time difference*/

var items
var initial=data.quantityAgenda;//how many students in class
var final=data.quantityAgendaNew;//already agendados
         items={
          _id            :data.Id_Agenda,
           name          : data.infoAgenda,
           startDateTime : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourFrom, data.minutesFrom),
           endDateTime   : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourTo, data.minutesTo),
           classes       : data.colorAgenda,
           coaches:data.id_coachAgenda,
           classroom:data.id_classroomAgenda,
           quantity:data.quantityAgenda,
           level:data.levelAgenda,
           backgroundColor:data.colorAgenda,
           hourFrom:data.hourFrom,
           minutesFrom:data.minutesFrom,
           hourTo:data.hourTo,
           minutesTo:data.minutesTo,
           hexa:data.hexa,
           quantityAgendaNew:data.quantityAgendaNew
         }

return (items)
}

export default HourCheckerNotInternational;
