import React, { Component } from 'react';
import '../../../Styles/App.css';
import Stat from '../../../Media/States.png'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import NumberFormat from 'react-number-format';
import dateimg from '../../../Media/Calendar.png'
import { connect } from 'react-redux';
import { UpdateActive,fetchGetPost,} from '../../../reducers/postsReducer';
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import States from '../Forms/Create_State'
import SearchInput, {createFilter} from 'react-search-input';
import ListGroup from 'react-bootstrap/ListGroup'
import Table from 'react-bootstrap/Table'
import Total from './TotalPrice'
import Add from '../../../Media/Add.png';
import DeletePayment from './DeletePayment'
import * as Web from '../../../Constants/Web'
const KEYS_TO_FILTERS = ['Name','Last_Name','Second_Last_Name','membership_payment','id_student_payment'];
var allpay=0;
class ShowStates extends Component {



  constructor(props){
          super(props);
          this.state={
              url:Web.wurl+'/',
              datos:[],
            searchTerm: '',
            Id:'',
            edit:false,
            Payments:[],
            name:'',
            price:'',
            year:'',
            day:'',
            month:'',
            daylet:'',
            daynum:'',
            hour:'',
            member:'',
            date:'',
            cate:''
            };
            this.onChange=this.onChange.bind(this);

      }

      today(){
        allpay=0
        this.setState({Payments:[]})
        var newDate = new Date()
        var date = newDate.getDate();
        var month = newDate.getMonth() + 1;
        var year = newDate.getFullYear();
        var numday= newDate.getDate();
        this.setState({
        day:numday,
        month:month,
        year:year
        })

        this.setState({
          date:numday+"/"+month+"/"+year
        })
              const data={
                id:this.props.id,
                day:numday,
                month:month,
                year:year
              }

        var url = this.state.url+'Api/Get_Payments.php';
        fetch(url, {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(data), // data can be `string` or {object}!
          header: {'Content-Type': 'application/json'}
        }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(response => {this.setState({Payments:response.reverse()})} );
      }
    async  componentDidMount(){
      if(window.location.href=='http://localhost:3000/conversationplace'|| window.location.href=="https://www.conversationhackers.com/conversationplace"){
        this.setState({
          cate:'Admin'
        })
      }
      allpay=0
      var newDate = new Date()
      var date = newDate.getDate();
      var month = newDate.getMonth() + 1;
      var year = newDate.getFullYear();
      var numday= newDate.getDate();
      this.setState({
      day:numday,
      month:month,
      year:year
      })
      this.setState({
        date:numday+"/"+month+"/"+year
      })
            const data={
              id:this.props.id,
              day:numday,
              month:month,
              year:year
            }

      var url = this.state.url+'Api/Get_Payments.php';
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        header: {'Content-Type': 'application/json'}
      }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {this.setState({Payments:response.reverse()})} );
}

     onChange(e){
       this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre

     }
     update(){
         allpay=0

         this.setState({Payments:[]})
      this.props.fetchGetPost('Get_States.php');
     }


     searchUpdated (term) {
      this.setState({searchTerm: term})
  }


  handleClose() {
  this.setState({ show: false,edit:false });
  }

  Show(e){
  //dato.Name,
  //dato.Last_Name
  //dato.Second_Last_Name
  //dato.price
  ///dato.remaining
  //dato.year
  //dato.month
  //dato.day--letra
  //num day---numero
  //dato.hour
  //dato.membership_payment
  this.setState({ edit:true,
  name:e[0]+' '+e[1]+' '+e[2],
  price:e[3],
  year:e[4],
  month:e[5],
  daylet:e[6],
  daynum:e[7],
  hour:e[8],
  member:e[9]

})
  }
  handleShow() {
  this.setState({ show: true });
  }


  payment(){
    return(
      <div >
      <span >{this.state.name}</span><br/>
      <span >{this.state.member}  ${this.state.price}</span><br/>
      <span >{this.state.year} {this.state.month} {this.state.daynum}</span><br/>
      <span >{this.state.daylet} {this.state.hour}</span>
      <hr/>
      </div>
    )

  }
  onChange(e){
    this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
  }
  find(){
      allpay=0

      this.setState({Payments:[]})
if(this.state.year&&this.state.month&&this.state.day){
  //alert('todos')
  this.setState({
    date:this.state.day+"/"+this.state.month+"/"+this.state.year
  })
  const data={
    id:this.props.id,
    year:this.state.year,
    month:this.state.month,
    day:this.state.day
  }
var url = this.state.url+'Api/Get_Payments.php';
fetch(url, {
method: 'POST', // or 'PUT'
body: JSON.stringify(data), // data can be `string` or {object}!
header: {'Content-Type': 'application/json'}
}).then(res => res.json())
.catch(error => console.error('Error:', error))
.then(response => {  this.setState({Payments:response.reverse()})} );
}else {
  if(this.state.year&&this.state.month){
    //alert('anio y mes')
    const data={
      id:this.props.id,
      year:this.state.year,
      month:this.state.month,
    }
  var url = this.state.url+'Api/Get_Paymentsym.php';
  fetch(url, {
  method: 'POST', // or 'PUT'
  body: JSON.stringify(data), // data can be `string` or {object}!
  header: {'Content-Type': 'application/json'}
  }).then(res => res.json())
  .catch(error => console.error('Error:', error))
  .then(response => {  this.setState({Payments:response.reverse()})} );


  }else {
    if(this.state.day&&this.state.year){
     alert('selecciona el mes')
    }else {
      if(this.state.year){
        //alert('year')
        const data={
          id:this.props.id,
          year:this.state.year
        }
      var url = this.state.url+'Api/Get_Paymentsy.php';
      fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
      }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {  this.setState({Payments:response.reverse()})} );
      }

    }


  }

}
  }
look(){
  return(
    <div>
    {this.state.cate=='Admin'?null:
    <Form>
<Row>
<Col>
  <Form.Control name="year" placeholder="Año(campo obligatorio)" value={this.state.year} onChange={this.onChange}/>
</Col>
<Col>
  <Form.Control name="month" placeholder="Mes" value={this.state.month} onChange={this.onChange.bind(this)} />
</Col>
<Col>
  <Form.Control name="day" placeholder="Día"  value={this.state.day}onChange={this.onChange.bind(this)} />
</Col>
{this.state.year?
<ButtonToolbar>
  <Button onClick={this.find.bind(this)} variant="primary">Buscar</Button>
</ButtonToolbar>:null}
</Row>

</Form>}
    </div>
  )
}
  render(){
    const post = this.state.Payments;
		const filteredInfo = post.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
	        if (post.length === 0 ) {
	        	return(
	            <div>
              {this.look()}
              <div className="center2">
              <h2 ><img className="CH_SM_Icono" src={dateimg}/>{this.state.date}</h2>
	            <h5>Aún no se han hecho pagos en este día ;)</h5>
               <p onClick={this.today.bind(this)} className="pointer hoover-black">ver hoy</p>
	            </div>
              <label>Puedes realizar busquedas con los siguientes datos:</label><br/>
              <label>1.-Presiona HOY para ver el día actual</label><br/>
              <label>2.-Ingresa el AÑO y presiona buscar para ver aun año completo</label><br/>
              <label>3.Ingresa el AÑO y Mes para ver un mes completo</label><br/>
              <label>4.Ingresa el AÑO,Mes y DÍA para ver un día completo</label>
              <label>IMPORTANTE. NO ES NECESARIO AGREGAR 0 EN MES O DIA (7) (07)</label>


                </div>
	            )} else{
		return(
				<div>
        <div className="center2">
        <h2 ><img className="CH_SM_Icono" src={dateimg}/>{this.state.date}</h2>
        <p onClick={this.today.bind(this)} className="pointer hoover-black">ver hoy</p>

        </div>
        <br/>
        {/*------------------edit---------------------*/}
        <Modal show={this.state.edit} onHide={this.handleClose.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Pago</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
          {this.payment()}
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose.bind(this)}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
        {/*--------------------------------------------------------*/}
      {this.look()}<br/>
      <label>Puedes realizar busquedas con los siguientes datos:</label><br/>
      <label>1.-Presiona HOY para ver el día actual</label><br/>
      <label>2.-Ingresa el AÑO y presiona buscar para ver aun año completo</label><br/>
      <label>3.Ingresa el AÑO y Mes para ver un mes completo</label><br/>
      <label>4.Ingresa el AÑO,MES y DÍA para ver un día completo</label>
      <label>IMPORTANTE. NO ES NECESARIO AGREGAR 0 EN MES O DIA (7) (07)</label>
      <br/>

        <Total
          isMaster={this.props.Master}
          month={this.state.month}
          year={this.state.year}
          day={this.state.day}
          idPlace={this.props.id}/>

          <SearchInput placeholder="Nombre apellido,Membresía,id" className="search-input form-group my-input text-center"  onChange={this.searchUpdated.bind(this)} />

          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>
                <th className="table-width">Año</th>
              <th className="table-width">Mes</th>
              <th className="table-width">Día</th>
              <th className="table-width">Nombre</th>
              <th className="table-width">Monto</th>
              <th className="table-width">Membresía</th>
              {this.props.Master?
              <th className="table-width">Eliminar</th>:null}
              </tr>
            </thead>
            <tbody>
				  {filteredInfo.map((dato, key) => {
	  return(
      <tr key={key}>
      <td className="pointer" onClick={() => this.Show([dato.Name,dato.Last_Name,dato.Second_Last_Name,dato.price,dato.year,dato.month,dato.day,dato.num_day,dato.hour,dato.membership_payment])}><label className="ch-dark-text">{dato.year}</label></td>
      <td className="pointer" onClick={() => this.Show([dato.Name,dato.Last_Name,dato.Second_Last_Name,dato.price,dato.year,dato.month,dato.day,dato.num_day,dato.hour,dato.membership_payment])}><label className="ch-dark-text">
      {dato.month=="1"?<p>Enero</p>:null}
      {dato.month=="2"?<p>Febrero</p>:null}
      {dato.month=="3"?<p>Marzo</p>:null}
      {dato.month=="4"?<p>Abril</p>:null}
      {dato.month=="5"?<p>Mayo</p>:null}
      {dato.month=="6"?<p>Junio</p>:null}
      {dato.month=="7"?<p>Julio</p>:null}
      {dato.month=="8"?<p>Agosto</p>:null}
      {dato.month=="9"?<p>Septiembre</p>:null}
      {dato.month=="10"?<p>Octubre</p>:null}
      {dato.month=="11"?<p>Noviembre</p>:null}
      {dato.month=="12"?<p>Diciembre</p>:null}
      </label></td>
      <td className="pointer" onClick={() => this.Show([dato.Name,dato.Last_Name,dato.Second_Last_Name,dato.price,dato.year,dato.month,dato.day,dato.num_day,dato.hour,dato.membership_payment])}><label className="ch-dark-text">{dato.num_day}</label></td>
      <td className="pointer" onClick={() => this.Show([dato.Name,dato.Last_Name,dato.Second_Last_Name,dato.price,dato.year,dato.month,dato.day,dato.num_day,dato.hour,dato.membership_payment])}><label className="ch-dark-text">{dato.Name} {dato.Last_Name} {dato.Second_Last_Name}</label></td>
      <td className="pointer" onClick={() => this.Show([dato.Name,dato.Last_Name,dato.Second_Last_Name,dato.price,dato.year,dato.month,dato.day,dato.num_day,dato.hour,dato.membership_payment])}><label className="ch-dark-text"><NumberFormat value={dato.price} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </label></td>
      <td className="pointer" onClick={() => this.Show([dato.Name,dato.Last_Name,dato.Second_Last_Name,dato.price,dato.year,dato.month,dato.day,dato.num_day,dato.hour,dato.membership_payment])}><label className="ch-dark-text">{dato.membership_payment} </label></td>
      {this.props.Master?<td><DeletePayment idPayment={dato.Id_Payment} update={this.find.bind(this)}/></td>:null}
        </tr>

	                        )
	                    })
	                }</tbody>
                </Table>





				</div>

			)}
		}





}export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        UpdateActive,fetchGetPost,
    }
)(ShowStates);
