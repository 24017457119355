import React, { Component } from 'react';
import firebase from 'firebase'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Helpers from '../../lib/Helper'
import Modal from 'react-bootstrap/Modal'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import ModalDialog from 'react-bootstrap/ModalDialog'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Ruleta from  './Ruleta'
import ButtonFreeClass from './ButtonFreeClass'
import Images from '../../Constants/images'
	const todos = [];
class Home extends Component {

  constructor(props){
          super(props);
          this.state={
                uid:'8B57J61q4bM51u9oF7tKln340Fm2',
              setShow:false,
							ended:false,
							talk:false,
            };
      }


			componentWillMount(){
		this.setState({setShow:this.props.setShow})
			}
  render() {
    return (
<div>
        <Modal
          show={this.props.setShow}
          onHide={() => this.props.closeBlack()}
					size="xl"
        backdrop="static"
        ><Modal.Header closeButton>
  </Modal.Header>
				{this.props.video==true?
					<div className="text-center">
					<video controlslist="nodownload" preload="none" className="video-free" height="240" autoPlay   controls src={this.props.videoUrl} />


					</div>:null
				}


          </Modal>
					</div>
    );
  }
}

export default Home;
