import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import {Link} from 'react-router-dom'
import { connect } from 'react-redux';
import { fetchGetPlaces, fetchAddPost, fetchUpdatePost, fetchDeletePost } from '../../reducers/postsReducer';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import '../../Styles/App.css';
import * as LOGIN from '../../Constants/Menu'
import {Redirect} from 'react-router-dom';
import * as Web from '../../Constants/Web'
import EditVideo from './editVideo'
import YouTube from 'react-youtube';
 import Loader from 'react-loader-spinner'
import Card_video from './Card_video'

//php version 7.3.2

class Video extends Component {

  constructor(props){
          super(props);
          this.state={
              video:'',
              urlvideo:'',
              des:'',
              title:'',
              redirectToReferrer: false,
              url:Web.wurl+'/',
              date:new Date().toLocaleString(),
              edit:''
            };
            this.onChange=this.onChange.bind(this)
      }



      onChange(e){
        this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre

      }

      _onReady(event) {
          // access to player in all event handlers via event.target
          event.target.pauseVideo();
        }

        create(){
          return(
            <Form>
  <Form.Group controlId="formBasicEmail">
    <Form.Label>youtube URL</Form.Label>
    <Form.Control onChange={this.onChange}  value={this.state.video} name="video" type="text" placeholder="URL" />
    <Form.Text className="text-muted">
  https://www.youtube.com/watch?v=******ARZzla-SC5o**** copia los numeros a partir de el simbolo igual
    </Form.Text>
  </Form.Group>

  <Form.Group >
    <Form.Label>URL de pagina externa</Form.Label>
    <Form.Control onChange={this.onChange} value={this.state.urlvideo} name="urlvideo" type="text" placeholder="https://google.com" />
    <Form.Text className="text-muted">
      Aquí se ingresará la pagina externa
    </Form.Text>
  </Form.Group>
  <Form.Group >
    <Form.Label>título</Form.Label>
    <Form.Control onChange={this.onChange} value={this.state.title} name="title" type="text" placeholder="verbos" />
    <Form.Text className="text-muted">
      título del video
    </Form.Text>
  </Form.Group>
  <Form.Group >
    <Form.Label>Descripción</Form.Label>
    <Form.Control onChange={this.onChange} value={this.state.des} name="des" as="textarea" rows="3" placeholder="Este video muestra como usar..." />
    <Form.Text className="text-muted">
      Descripción no mayor a 300 letras.
    </Form.Text>
  </Form.Group>
</Form>
          )

        }
        savevideo(){
          this.setState({loader:'1'})
          let newDate = new Date()
          let date = newDate.getDate();
          let month = newDate.getMonth() + 1;
          let year = newDate.getFullYear();
          let numday= newDate.getDate();
          var day = newDate.getDay();
          var hour = newDate.getHours();
          var minutes = newDate.getMinutes();
          var hours=hour+':'+minutes
          const data = {
            date:numday+"/"+month+"/"+year+" a las"+" "+hours,
            video:this.state.video,
            url:this.state.urlvideo,
            des:this.state.des,
            title:this.state.title
              }
          this.props.fetchAddPost(data,'Create/Create_Video.php');
          alert('correcto');
          this.setState({
            video:'',
            urlvideo:'',
            des:'',
            title:'',
            loader:''})
        }


edit(){
  this.setState({edit:'1'})
}
closeEdit(){
  this.setState({edit:''})
}

  render() {
    if (this.state.redirectToReferrer) {
     return (<Redirect to={'/conversationplace'}/>)
   }

   if(localStorage.getItem('userData')){
     let myItem = localStorage.getItem('userData')
     const info=JSON.parse(myItem)
     if(info.type=='MASTER'){
       //return (<Redirect to={'/admin'}/>)
     }
     if(info.type=='Student'){
       return (<Redirect to={'/Profile'}/>)
     }
     if(info.type=='Coach'){
       return (<Redirect to={'/Coach'}/>)
     }
     if(info.type=='Admin'){
      //return (<Redirect to={'/conversationplace'}/>)
     }

   }
   const opts = {
     height: '390',
     width: '640',
     playerVars: { // https://developers.google.com/youtube/player_parameters
       autoplay: 0
     }
   };
    return (
      <div>
      {this.state.loader?
        <div className="center2">
        <Loader
             type="Puff"
             color="#00BFFF"
             height="100"
             width="100"
          />
        </div>:
        <div>
        {this.state.edit?<div>
          <br/>
          <a className="btn btn-danger mt-btn" onClick={this.closeEdit.bind(this)} rel="noopener noreferrer" target="_blank" >Cancelar</a>
          <EditVideo/>
          </div>:
      <div>
      <br/>
      <a className="btn btn-info mt-btn" onClick={this.edit.bind(this)} rel="noopener noreferrer" target="_blank" >Editar videos</a>
      <hr/>
{this.create()}
{this.state.video&&this.state.urlvideo&&this.state.des?
<Button onClick={this.savevideo.bind(this)}variant="primary" type="submit">
  Guardar
</Button>:null}
<hr/>

{this.state.video?
      <Card_video video={this.state.video} title={this.state.title} desc={this.state.des} url={this.state.urlvideo} date={this.state.date}/>:null}
      </div>}
    </div>}
      </div>
    );
  }
}

export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        placesall: state.posts.places,
    }),
    //mapeo de funciones
    {
        fetchGetPlaces, fetchAddPost, fetchUpdatePost, fetchDeletePost
    }
)(Video);
