import React,{useState} from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {CirclePicker} from 'react-color';
import Button from 'react-bootstrap/Button'
import VirtualClass from './virtualClass'
import {createDay} from './Actions/createDay'
import Add from './addFiles'
import classes from './createday.module.css'
const EditDay=(props)=>{
  const [coach,setCoach]=useState();
  const [level,setLevel]=useState();
  const [classroom,setClassroom]=useState();
  const [limit,setLimit]=useState();
  const [info,setInfo]=useState();
  const [filesJson,setFiles]=useState();
  const bool=false;
  const [isActive,setActive]=useState(false);
  const [clasevirtual,setLink]=useState('')

  const {coaches,
        classrooms,
        nums,
        backgroundColor,
        handleChangeComplete,
        openDisponibilitie,
        dateFrom,
        dateTo,
        day,
        idAgenda,
        id,
        timezone,
        backgroundhexa
      }=props;

      const handleCreateDay=()=>{
        createDay(id,
          idAgenda,
          dateFrom,
          dateTo,
          coach,
          level,
          limit,
          day,
          classroom,
          info,
        timezone,
        backgroundColor,
      backgroundhexa,
    clasevirtual,
    isActive,
    filesJson)
      }


const files=(e)=>{
    setFiles(e)
}
  return(<Form.Row>
    <Form.Group as={Col} controlId="formGridEmail">
      <Form.Label>Coach</Form.Label>
    {coaches?    <Form.Control as="select" onChange={(e)=>setCoach(e.target.value)} name="coach">

        <option>...</option>
        {coaches.map(item => (
          <option value={item.Id}  key={item}>{item.Coach_Name} {item.Last_Name} {item.Second_Lastname}</option>
        ))}
      </Form.Control>:<p>No tienes coaches registrados</p>}
      <Form.Label>Nivel</Form.Label>
      <Form.Control as="select" onChange={(e)=>setLevel(e.target.value)} name="level">
      <option></option>
      <option value={-10}  >[-10]-Kids extra básico </option>
      <option value={-5}  >[-5]-Teens extra básico </option>
      <option value={0}  >[0]-Básico </option>
      <option value={49}  >[49]-Básico intermedio  </option>
      <option value={50}  >[50]-Avanzado intermedio  </option>
      <option value={100}  >[100]-Avanzado </option>
      {/*activelevels.map(item => (
        <option value={item.Id_Level}  key={item}>[{item.Level_Priority}]-{item.Name}   </option>
      ))*/}

    </Form.Control>

      <Form.Label>Salon</Form.Label>
      <Form.Control as="select" onChange={(e)=>setClassroom(e.target.value)} name="classroom">

      <option></option>
      {classrooms.map(item => (
        <option value={item.Id_Classroom}  key={item}>{item.Name}</option>
      ))}
    </Form.Control>


    <Form.Label>Limite</Form.Label>
    <Form.Control as="select" onChange={(e)=>setLimit(e.target.value)} name="quantity">

    <option>Choose...</option>
    {nums.map(item => (
      <option value={item}  key={item}>{item}</option>
    ))}
    </Form.Control>


    <Form.Label>info</Form.Label>
    <Form.Control onChange={(e)=>setInfo(e.target.value)} name="info" as="textarea" rows="3" />

    <Form.Label>Color</Form.Label>

    <CirclePicker
        color={ backgroundColor }
        onChangeComplete={ handleChangeComplete }
    /><br/><br/>

    <Col className={classes.link}>
    <Form.Group controlId="formBasicCheckbox" onChange={()=>setActive(!isActive)} value={isActive}>
      <Form.Check type="checkbox" label="Activar enlace a clase virtual" />
    </Form.Group>

    {isActive?<input type={"text"} onChange={(e)=>setLink(e.target.value)} value={clasevirtual}/>:null}
    </Col>
    <Add day={dateTo} callbackFiles={files}/>
    <Col className={classes.buttonContainer}>
    <Button variant="success" type="submit" onClick={handleCreateDay} className={classes.button}>
      Guardar
    </Button>
    <Button variant="info" type="submit" onClick={openDisponibilitie} className={classes.button}>
      Ver disponibilidad
    </Button>

    </Col>
  </Form.Group>



    </Form.Row>)
}

export default EditDay;
