import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import ListGroup from 'react-bootstrap/ListGroup'
import Row from 'react-bootstrap/Row'
import {NavLink,Redirect,withRouter,Link} from 'react-router-dom'
import * as Left from '../../../Constants/Dictionary/ES'
import Col from 'react-bootstrap/Col'
import logo from '../../../Media/Conversation_Hackers_Logo.png'
import student from  '../../../Media/Student.png'
import teacher from '../../../Media/Teacher.png'
import day from '../../../Media/Gift.png'
import calendar from '../../../Media/Calendar.png'
import report from '../../../Media/Report.png'


class  leftSide extends Component {

    constructor(props){
            super(props);
            this.state={
              redirectToReferrer:false
            }}

salir(){
  localStorage.removeItem('userData');

   alert("saliste");
   this.setState({
     redirectToReferrer:true
   })
}
  render() {
    if (this.state.redirectToReferrer) {
     return (<Redirect to={'/'}/>)
   }
const placeData = this.props.PlaceData;
const StudentCounter=this.props.StudentCounter
const CoachCounter=this.props.CounterCoaches
const BirthdCounter=this.props.BirthdayCounter
    return (
  <Col xs={12} md={4} className="columnleft">
  <div className="center-ima">
  <img src={logo} className="Logo_Img"/><br/>
    </div>
  {
      placeData.map(post =>
        <ListGroup variant="flush" >
      <ListGroup.Item className={this.props.si} onClick={this.props.six}>
          <span className="ch-black-text">{post.Name}</span><br/>
          </ListGroup.Item>
          </ListGroup>
      )}
      <br/>
      <button className="btn btn-success" type="submit" onClick={this.props.update}>{Left.Update}</button><br/>

      		<hr/>
  <ListGroup variant="flush" >
<ListGroup.Item className={this.props.on}  onClick={this.props.one}><img src={student} className="CH_SM_Icono" />{Left.Students} <span className="ch-black-text">{StudentCounter[0]}</span></ListGroup.Item>
<ListGroup.Item className={this.props.tw} onClick={this.props.two}><img src={teacher} className="CH_SM_Icono"/>{Left.Coach} <span className="ch-black-text">{CoachCounter[0]}</span></ListGroup.Item>
<ListGroup.Item className={this.props.th} onClick={this.props.three}><img src={day} className="CH_SM_Icono"/>{Left.Birthday} <span className="ch-black-text">{BirthdCounter[0]}</span></ListGroup.Item>
<ListGroup.Item className={this.props.fo} onClick={this.props.four}><img src={calendar} className="CH_SM_Icono"/>{Left.Agenda}</ListGroup.Item>
<ListGroup.Item className={this.props.foourone} onClick={this.props.fourone}><img src={calendar} className="CH_SM_Icono"/>{Left.GeneralAgenda}</ListGroup.Item>
<ListGroup.Item className={this.props.fiv} onClick={this.props.five}><img src={report} className="CH_SM_Icono"/>{Left.Reports}</ListGroup.Item>
<ListGroup.Item className="pointer hoover" onClick={this.salir.bind(this)}>Salir</ListGroup.Item>

</ListGroup>
  </Col>

    );
  }
}

export default leftSide;
