import React, { Component } from 'react';
import '../../../Styles/App.css';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
 import Loader from 'react-loader-spinner'
import { connect } from 'react-redux';
import { UpdateActive,fetchGetPost,} from '../../../reducers/postsReducer';
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import Col from 'react-bootstrap/Col'
import * as Web from '../../../Constants/Web'

const numbers = [1, 2, 3, 4, 5];


class CreateLevels extends Component {

  constructor(props){
          super(props);
          this.state={
            url:Web.wurl+'/',
              Name:'',
              Info:'',
              topay:'',
              paying:'',
              desc:'',
              calculating:'',
              loader:'',
              remaining:'',
              msg:''

            };
            this.onChange=this.onChange.bind(this);
            this.debt=this.debt.bind(this)
            this.paystudent=this.paystudent.bind(this)
      }



componentWillMount(){
  //alert(this.props.id_pay)
  this.setState({
    topay:this.props.Price,
remaining:this.props.remaining
  })
}
onChange(e){
  this.setState({[e.target.name]:e.target.value,calculating:''})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
}
debt(){
  var debt =this.state.topay-this.state.paying
  this.setState({
    debt:debt,
    calculating:'1'
  })
}



paystudent(){
  this.setState({loader:'1'})
  var paying=parseInt(this.state.paying);
  var remaining=parseInt(this.props.remaining)

  var newvalue=remaining-paying

  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let numday= newDate.getDate();
  var day = newDate.getDay();
  var hour = newDate.getHours();
  var minutes = newDate.getMinutes();
  var hours=hour+':'+minutes
  var sessions=parseInt(this.props.sessions, 10);
  var addsessions=parseInt(this.props.addsessions, 10);
  var newsession=sessions+addsessions
  if(day=='0'){
    day="Domingo"
  }
  if(day=='1'){
    day="Lunes"
  }
  if(day=='2'){
    day="Martes"
  }
  if(day=='3'){
  day="Miercoles"
  }
  if(day=='4'){
    day="Jueves"
  }
  if(day=='5'){
    day="Viernes"
  }
  if(day=='6'){
    day="Sabado"
  }
  const datapayment={
    year:year,
    month:month,
    day:day,
    numday:numday,
    hour:hours,
    id:this.props.id,///id student
    price:this.state.paying,//////need to paying
    payed:this.state.paying,////gaved
    remaining:'0',///debt
    id_place_payment:this.props.place,///idPlace
    membership:'Pago de Adeudo'//name desc
  }
  const data={
    idpayment:this.props.id_pay,
    pay:newvalue
  }
  var fst = this.state.url+'Api/Put/Payment.php';
  fetch(fst, {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(datapayment), // data can be `string` or {object}!
    header: {'Content-Type': 'application/json'}
  }).then(res =>{

    var fst = this.state.url+'Api/Update/Update_Payment.php';
    fetch(fst, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => { this.setState({remaining:newvalue,loader:'',msg:'1'}); return this.props.update})


  })





}

  render() {
      const post = this.props.posts;
    return (
    <div>
    {this.state.loader?<div className="center2">
    <Loader
         type="Puff"
         color="#00BFFF"
         height="100"
         width="100"
      />
    </div>:

    <Form>
    <h3>Debe:${this.state.remaining}</h3>
{this.state.msg?<p>Correcto</p>:
  <div>
    <Row>
      <Col>
       <Form.Label>Ingresa $</Form.Label>
        <Form.Control  placeholder="Cobro" name="paying" onChange={this.onChange}/>
      </Col>
    {/* <Col>
       <Form.Label>Desc</Form.Label>
        <Form.Control  placeholder="cobro inicial" name="desc" onChange={this.onChange}/>
      </Col>*/}
    </Row>
    <br/><br/>
    <Row>
      <Col>
        <Button variant="primary"  className="cv-button" onClick={this.paystudent}>
          Cobrar
        </Button>
      </Col>
    </Row>
    </div>}
  </Form>}
        </div>
    );
  }
}
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        UpdateActive,fetchGetPost,
    }
)(CreateLevels);
