import React, { Component } from 'react';
import Badge from 'react-bootstrap/Badge'
import SettingCard from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Gray_Template from '../MainComponents/Gray_Template'
import Media from './Settings/Media';
import Admins from './Settings/Admins';
import Classroom from './Settings/Classrooms'
import LevelsTable from './Settings/Levels'
import Advanced from './Settings/Advanced';
import * as SETTINGS from '../../Constants/Menu'
import * as DATA from '../../Constants/Dictionary/ES'
import IMAGES  from '../../Constants/images'
import Routes from '../../Constants/Routes'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {NavLink,Redirect,withRouter,Link} from 'react-router-dom'

class Settings extends Component {


  constructor(props){
          super(props);
          this.state={
              gallery:'',
              Admins:'',
              levels:'',
              design:'',
              stat:'',
              one:'1',
              two:'',
              three:''
            };
      }
      media(){ this.setState({gallery:'1',Admins:'',levels:'',stat:'',design:'',classrooms:'' })}
      admins(){ this.setState({gallery:'',Admins:'1',levels:'',stat:'',design:'',classrooms:'' })}
      levels(){ this.setState({gallery:'',Admins:'',levels:'1',stat:'',design:'',classrooms:'' })}
      stat(){ this.setState({gallery:'',Admins:'',levels:'',stat:'1',design:'',classrooms:'' })}
      design(){ this.setState({gallery:'',Admins:'',levels:'',stat:'',design:'1',classrooms:'' })}
      classrooms(){ this.setState({gallery:'',Admins:'',levels:'',stat:'',design:'',classrooms:'1'})}



close(){
  this.setState({
    gallery:'',
    Admins:'',
    levels:'',
    stat:'',
    design:'',
    classrooms:''
  })

}


  render() {
    return (
      <div className="center-text">
      <Container className="mainbox container2 " >
      <div className="center-text">
      <h1 ><Badge variant="secondary">{DATA.Settings}</Badge></h1>
<span className="ch-black-text">{DATA.SettingInfo}</span>
  <hr/>
      </div>
      <Row >
{this.state.gallery||this.state.levels||this.state.stat||this.state.Admins||this.state.design||this.state.classrooms?null:
          <Col >
          <div class="cards-list">
  <Link  class="SettingCard-new 1" onClick={this.media.bind(this)}>
  <div > <img  className="img-50 margintop4" src={IMAGES.Settings.Gallery}/> </div>
  <div class="center-text title-black">
    <p>{DATA.Gallery}</p>
  </div>
  </Link>
    <Link  class="SettingCard-new 2"  onClick={this.levels.bind(this)}>

    <img className="img-50 margintop4" src={IMAGES.Settings.Levels} />

  <div class="card_title title-black">
    <p>{DATA.Levels}</p>
  </div>
  </Link>

  <Link  class="SettingCard-new 3"  onClick={this.stat.bind(this)}>

    <img className="img-50 margintop4" src={IMAGES.Settings.States} />

  <div class="card_title title-black">
    <p>{DATA.States}</p>
  </div>
  </Link >

  <Link class="SettingCard-new 4"  onClick={this.admins.bind(this)}>

    <img className="img-50 margintop4" src={IMAGES.Settings.Admin} />

  <div class="card_title title-black">
    <p>{DATA.Admins}</p>
  </div>
    </Link >





  <Link class="SettingCard-new 5"  onClick={this.classrooms.bind(this)}>

    <img className="img-50 margintop4" src={IMAGES.Settings.Classroomicon} />

  <div class="card_title title-black">
    <p>{DATA.Classrooms}</p>
  </div>
  </Link>


   <Link to={Routes.Settings.HomePage} class="SettingCard-new 6" >

      <img className="img-50 margintop4" src={IMAGES.Settings.Home} />

    <div class="card_title title-black">
      <p>{DATA.HomePage}</p>
    </div>
    </Link>

    <Link to={Routes.Settings.OnlineSettings} class="SettingCard-new 6" >

       <img className="img-50 margintop4" src={IMAGES.Settings.Home} />

     <div class="card_title title-black">
       <p>{DATA.OnlineSettings}</p>
     </div>
     </Link>
     <Link to={Routes.Settings.questions} class="SettingCard-new 6" >

        <img className="img-50 margintop4" src={IMAGES.Settings.Home} />
      <div class="card_title title-black">
        <p>{DATA.Questions}</p>
      </div>
      </Link>
</div>
  </Col>}


        </Row>
        {/*<Card className='card-root'>
      <CardContent>
        <Typography className='card-title' color="textSecondary" gutterBottom>
          Word of the Day
        </Typography>
        <Typography variant="h5" component="h2">
          basd
        </Typography>
        <Typography className='card-pos' color="textSecondary">
          adjective
        </Typography>
        <Typography variant="body2" component="p">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>*/}
        {this.state.gallery?<Media close={this.close.bind(this)}/>:null}
        {this.state.Admins?<Admins close={this.close.bind(this)}/>:null}
        {this.state.levels?<LevelsTable close={this.close.bind(this)}/>:null}
        {this.state.stat?<Advanced close={this.close.bind(this)}/>:null}
        {this.state.classrooms?<Classroom close={this.close.bind(this)}/>:null}
        </Container>
        </div>
    );
  }
}

export default Settings;
