import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchGetPost,fetchGetPost2, fetchAddPost, fetchUpdatePost, fetchDeletePost } from '../../../reducers/postsReducer';
import '../../../Styles/App.css';
import { QRCode } from "react-qr-svg";
import PaymentStudent from '../Data/PaymentStudent'
import PaymentStudent2 from '../Data/PaymentStudent2'
import ClockNow from '../../Agenda/Clock/clock_'
import Flag from 'react-world-flags'
import Inactive from '../Data/Inactive'
import Standby from '../Data/Standby'
import AgendaStudent from '../../Agenda/AgendaBigStudentAdminView'
import Dropdown from 'react-bootstrap/Dropdown'
import SearchInput, {createFilter} from 'react-search-input';
import Table from 'react-bootstrap/Table'
import Memberships from '../../../Media/Memberships.png'
import Alert from 'react-bootstrap/Alert'
import Levels from '../../../Media/Levels.png'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import * as Web from '../../../Constants/Web'
import classes from './showStudents.module.css'
import EditStudent from '../Forms/Edit_StudentAdmin'
import * as StudentForm from '../../../Constants/Forms'
const KEYS_TO_FILTERS = ['Name','Name_Membership','Level_Name','id_Student','Last_Name','Second_Last_Name'];
class ShowStudents extends Component {

  constructor(props){
          super(props);
          this.state={
            url:Web.wurl+'/',
            searchTerm: '',
            show:false,
            idPlace:this.props.id,
            place:'',
            id:'',
            NameStudent:'',
            LastName:'',
            LastName2:'',
            Day_Birthdate:'',
            Month_Birthdate:'',
            Year_Birthdate:'',
            CellPhone:'',
            Phone:'',
            Email:'',
            Password:'',
            PasswordVerification:'',
            Street:'',
            Col:'',
            Number:'',
            InsideNumber:'',
            Zip:'',
            City:'',
            State:'',
            Picture:'',
            Level:'',
            Membership:'',
            Expiration_Day:'',
            Expiration_Month:'',
            Expiration_Year:'',
            pin:'',
            main:'1',
            payment:false,
            Payments:[],
            payment2:false,
            inactive:false,
            standby:false,
          //  url:'conversationhackers.com/',
            Price:'',
            Id_Membership:'',
            quantity:'',
            mempayment:'',
            sesions:'',
            addsessions:'',
            comment:'',
            showCountry:false,
            data:[],
            };
            this.countries=this.countries.bind(this)
            this.handleShow = (e) => {
      this.setState({
        id:e.Id_Student,
        NameStudent:e.Name,
        LastName:e.Last_Name,
        LastName2:e.Second_Last_Name,
        Level:e.Level_Name,
        Membership:e.Name_Membership,
        show: true,
        agenda:false,
        data:e

       });
    };

    this.handleHide = () => {
      this.setState({agenda:false ,show: false,payment:false,payment2:false,inactive:false,standby:false,
      pin:'', });
    };
      }
      countries(){
        this.setState({
          showCountry:!this.state.showCountry
        })
      }
     searchUpdated (term) {
      this.setState({searchTerm: term})
  }
  async  componentDidMount(){
          const data={
            id:this.props.id
          }
    var url = this.state.url+'Api/Get_Remaining.php';
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => {this.setState({Payments:response.reverse()})} );
}
update(){
  alert('actualizado')
}

main(){
  this.setState({
    main:'1',
    pin:''
  })
}
pin(){
  this.setState({
    pin:'1',
    main:''
  })
}

edit(){

  const data = { "Id":this.state.id, "type":"Student" };
  return(
<div>
<Nav variant="tabs" defaultActiveKey="/home">
  <Nav.Item>
    <Nav.Link onClick={this.main.bind(this)}>{StudentForm.qr}</Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link onClick={this.pin.bind(this)}>{StudentForm.PersonalInformation}</Nav.Link>
  </Nav.Item>
  <Nav.Item>
  </Nav.Item>
</Nav>

{this.state.main?
  <Container>
  <Row><br/>
    <Col><QRCode
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="Q"
                style={{ width: 256 }}
                value={JSON.stringify(data)}
            /></Col>
    <Col>
    <Alert variant="success">
  <Alert.Heading>{this.state.NameStudent} {this.state.LastName}</Alert.Heading>
  <p><img src={Memberships} className="CH_MD_Icono"/>{this.state.Membership}
  </p>
  <p><img src={Levels} className="CH_MD_Icono"/>{this.state.Level}
  </p>
  <hr />
</Alert>


    </Col>
  </Row>



  </Container>:null}
{this.state.pin?<EditStudent
  data={this.state.data}
/>:null}


</div>


  )
}

inactive(e){
  //// ID 0
  //Name1
  //LastName2
  //second last name3

  this.setState({
    inactive:true,
    id:e[0],
    NameStudent:e[1],
    LastName:e[2],
    LastName2:e[3],
  })
}
standby(e){
  //// ID 0
  //Name1
  //LastName2
  //second last name3

  this.setState({
    standby:true,
    id:e[0],
    NameStudent:e[1],
    LastName:e[2],
    LastName2:e[3],
  })
}
agendar(e){
  //// ID 0
  //Name1
  //LastName2
  //second last name3
//placee
  this.setState({
    agenda:true,
    id:e[0],
    NameStudent:e[1],
    LastName:e[2],
    LastName2:e[3],
    Place:e[4]
  })
}
pay(e){
  //alert(JSON.stringify(e))
  //// ID 0
  //Name1
  //LastName2
  //second last name3
  //Id_Membership4
  //membership price5
  this.setState({
    payment:true,
    id:e[0],
    NameStudent:e[1],
    LastName:e[2],
    LastName2:e[3],
    Id_Membership:e[4],
    Price:e[5],
    place:e[6],//idplace
    mempayment:e[7],//nombre membresia
    sessions:e[8],///sesions students
    addsessions:e[9]/////add if PaymentStudent
  })

}
pay2(e){
  //alert(JSON.stringify(e))
  //// ID 0
  //Name1
  //LastName2
  //second last name3
  //Id_Membership4
  //membership price5
  this.setState({
    payment2:true,
    id:e[0],
    NameStudent:e[1],
    LastName:e[2],
    LastName2:e[3],
    Id_Membership:e[4],
    Price:e[5],
    place:e[6],//idplace
    mempayment:e[7],//nombre membresia
    sessions:e[8],///sesions students
    addsessions:e[9]/////add if PaymentStudent
  })

}

closeModal(){
  this.setState({agenda:false ,show: false,payment:false,payment2:false,inactive:false,standby:false,
  pin:''});
}
  render(){
    const Payments=this.state.Payments
    const info =this.props.posts
		const filteredInfo = info.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
	        if (info.length === 0 ) {
	        	return(
	            <div>
	            <h1>{StudentForm.Empty}</h1>
	            </div>
	            )} else{
		return(
				<div>
        <Col className="margintop4">
        <Button onClick={this.countries}>región</Button>
        </Col>
          <Modal
            size="lg"
            show={this.state.show}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header >
              <Modal.Title id="example-modal-sizes-title-lg">
                {this.state.NameStudent} {this.state.LastName} {this.state.LastName2}
              </Modal.Title>
              <Button variant="danger" onClick={this.closeModal.bind(this)}>Cerrar</Button>
            </Modal.Header>
            <Modal.Body>
            {this.edit()}


            </Modal.Body>
          </Modal>


{/*PaymentStudent*/}

          <Modal
            size="lg"
            show={this.state.payment}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header >
            <Modal.Title id="example-modal-sizes-title-lg">
            {StudentForm.Pay}      {this.state.NameStudent} {this.state.LastName} {this.state.LastName2}
              </Modal.Title>
              <Button variant="danger" onClick={this.closeModal.bind(this)}>Cerrar</Button>
            </Modal.Header>
            <Modal.Body>
            <PaymentStudent id={this.state.id} Price={this.state.Price} IdMembership={this.state.Id_Membership} place={this.state.place} membershipname={this.state.mempayment} sessions={this.state.sessions} addsessions={this.state.addsessions} />

            </Modal.Body>
          </Modal>

          {/*PaymentStudent2*/}

                    <Modal
                      size="lg"
                      show={this.state.payment2}
                      aria-labelledby="example-modal-sizes-title-lg"
                    >
                      <Modal.Header >
                      <Modal.Title id="example-modal-sizes-title-lg">
                      {StudentForm.Pay}      {this.state.NameStudent} {this.state.LastName} {this.state.LastName2}
                        </Modal.Title>
                        <Button variant="danger" onClick={this.closeModal.bind(this)}>Cerrar</Button>

                      </Modal.Header>
                      <Modal.Body>
                      <PaymentStudent2 id={this.state.id} Price={this.state.Price} IdMembership={this.state.Id_Membership} place={this.state.place} membershipname={this.state.mempayment} sessions={this.state.sessions} addsessions={this.state.addsessions} />

                      </Modal.Body>
                    </Modal>
{/*inactive*/}

<Modal
  size="lg"
  show={this.state.inactive}
  aria-labelledby="example-modal-sizes-title-lg"
>
  <Modal.Header >
  <Modal.Title id="example-modal-sizes-title-lg">
  {StudentForm.Inactive}     {this.state.NameStudent} {this.state.LastName} {this.state.LastName2}
    </Modal.Title>
    <Button variant="danger" onClick={this.closeModal.bind(this)}>Cerrar</Button>

  </Modal.Header>
  <Modal.Body>

<Inactive id={this.state.id} idPlace={this.props.id}/>
  </Modal.Body>
</Modal>

{/*standby*/}

<Modal
  size="lg"
  show={this.state.standby}
  aria-labelledby="example-modal-sizes-title-lg"
>
  <Modal.Header >
  <Modal.Title id="example-modal-sizes-title-lg">
  {StudentForm.standby}     {this.state.NameStudent} {this.state.LastName} {this.state.LastName2}
    </Modal.Title>
    <Button variant="danger" onClick={this.closeModal.bind(this)}>Cerrar</Button>

  </Modal.Header>
  <Modal.Body>


<Standby id={this.state.id} idPlace={this.props.id}/>
  </Modal.Body>
</Modal>



{/*agenda*/}

<Modal
className={classes.fullModal}
  show={this.state.agenda}
  aria-labelledby="example-modal-sizes-title-lg"
>
  <Modal.Header >
  <Modal.Title id="example-modal-sizes-title-lg">
  {this.state.NameStudent} {this.state.LastName} {this.state.LastName2}
    </Modal.Title>
    <Button variant="danger" onClick={this.closeModal.bind(this)}>Cerrar</Button>

  </Modal.Header>
  <Modal.Body>

<AgendaStudent idStudent={this.state.id} idPlace={this.props.id}/>
  </Modal.Body>
</Modal>
	        <SearchInput placeholder="Buscar"  className={classes.searchBar} onChange={this.searchUpdated.bind(this)} />

          <div className={classes.table}>
          <Table striped bordered hover size="sm" >
            <thead>
              <tr>
                <th>{StudentForm.NumberStudent}</th>
                <th>{StudentForm.NameStudent}</th>
                <th><img src={Memberships} className="CH_SM_Icono"/></th>
                <th><img src={Levels} className="CH_SM_Icono"/></th>
                <th>{StudentForm.Remain}</th>
                <th>{StudentForm.Options}</th>
              {this.state.showCountry==true? <th>{StudentForm.Country}</th>:null}
              {this.state.showCountry==true? <th>{StudentForm.Time}</th>:null}
              </tr>
            </thead>
            <tbody  >

				  {filteredInfo.map((dato, key) => {
	  return(
	      <tr key={key}>
        <td className="pointer" onClick={()=>this.handleShow(dato)}>{dato.id_Student}</td>
       <td className="pointer" onClick={()=>this.handleShow(dato)}>{dato.Name} {dato.Last_Name} {dato.Second_Last_Name}</td>
       <td className="pointer" onClick={()=>this.handleShow(dato)}>{dato.Name_Membership}</td>

       <td className="pointer" onClick={()=>this.handleShow(dato)}>{dato.Level_Name}</td>

       <td className="pointer" onClick={()=>this.handleShow(dato)}>

{this.state.Payments.map((data,key) => {
            var myJSON = JSON.stringify(data);
            if(data.id_student_payment==dato.id_Student){
              return(
                <div className="cv-red-remaining">
                <label>${data.remaining}</label>
                </div>
              )
            }
          })}
</td>

<td>

       <Dropdown>
  <Dropdown.Toggle variant="success" id="dropdown-basic">
  {StudentForm.Options}
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item  onClick={()=>this.pay([dato.id_Student,dato.Name,dato.Last_Name,dato.Second_Last_Name,dato.Membership,dato.Price,dato.Place,dato.Name_Membership,dato.Sessions,dato.Quantity_Membership_Session])} ><label className="sm-text hoover">Renovar <br/>Membresía</label></Dropdown.Item>
    <hr/>
    <Dropdown.Item  onClick={()=>this.pay2([dato.id_Student,dato.Name,dato.Last_Name,dato.Second_Last_Name,dato.Membership,dato.Price,dato.Place,dato.Name_Membership,dato.Sessions,dato.Quantity_Membership_Session])} ><label className="sm-text hoover">Inscripción / <br/>Otros Pagos</label></Dropdown.Item>
<hr/>
    <Dropdown.Item onClick={()=>this.inactive([dato.id_Student,dato.Name,dato.Last_Name,dato.Second_Last_Name])}><label className="sm-text hoover">Inactivo</label></Dropdown.Item>
<hr/>
    <Dropdown.Item onClick={()=>this.standby([dato.id_Student,dato.Name,dato.Last_Name,dato.Second_Last_Name])}><label className="sm-text hoover">Standby</label></Dropdown.Item>
<hr/>
    <Dropdown.Item onClick={()=>this.agendar([dato.id_Student,dato.Name,dato.Last_Name,dato.Second_Last_Name,dato.Place])}><label className="sm-text hoover">Agendar</label></Dropdown.Item>
  </Dropdown.Menu>
</Dropdown></td>

{this.state.showCountry==true?<td>{dato.Country_Code?<Flag code={dato.Country_Code} height="16" />:null}</td>:null}
{this.state.showCountry==true?<td>{dato.diff&&dato.signDiff?
<ClockNow small={true}hourDiff={dato.diff} signDiff={dato.signDiff}/>:null}

</td>:null}
     </tr>
	                        )
	                    })
	                }
                  </tbody>
                </Table>
          </div>

				</div>

			)}
		}





}
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        fetchGetPost,fetchGetPost2, fetchAddPost, fetchUpdatePost, fetchDeletePost
    }
)(ShowStudents);
