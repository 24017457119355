
import * as URL from '../../../../Constants/services';

export const cancelClass=(e)=>{

 const data={
 idClass:e,
 }

  var url = URL.api+"/removeStudentClass";
  fetch(url, {
    method: 'POST', // or 'PUT'
    mode: 'cors',
    body: JSON.stringify(data), // data can be `string` or {object}!
    header: {'Content-Type': 'application/json'}
  }).then(res => res.json())
  .catch(error => console.error('Error:', error))
  .then(response => {
    alert(JSON.stringify(response))
  })

  return(
    'hola'
  )
}
