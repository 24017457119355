////////////CREATE Student///////

export const CreateStudent="Agregar Estudiante"
export const downloadList="Descargar lista completa de estudiantes"
export const PersonalInformation="Información Personal"
  export const qr="QR"
export const NameStudent="Nombre"
export const LastName="Apellido paterno"
export const LastName2="Apellido materno"
export const Birthdate="Fecha de nacimiento"
export const Day_Birthdate="Día"
export const Month_Birthdate="Mes"
export const Year_Birthdate="Año"
export const CellPhone="Celular"
export const Phone="Telefono"
export const Email="Correo"
export const Password="Contraseña"
export const PasswordVerification="Verificar contraseña"
export const Address="Dirección"
export const Street="Calle"
export const Col="Colonia"
export const Number="Número"
export const InsideNumber="Número interior"
export const Zip="Código postal"
export const City="Ciudad"
export const State="Estado"
export const Education="Escolar"
export const Picture="Imagen"
export const Level="Nivel"
export const Membership="Membresía"
export const StatusMembership="Estatus membresía expiracion"
export const Expiration_Day="Día"
export const Expiration_Month="Mes"
export const Expiration_Year="Año"
export const NumberStudent="Matrícula"
export const Country="País"
export const Time="Hora"



/////addplaces
export const placeName="Nombre"
export const placeAddress="Dirección"
export const placeCity="Ciudad"
export const placeState="Estado"
export const placeZip="Código Postal"
export const placePhone="Teléfono"
export const placeEmail="Email"





/////actions
export const Cancel="Cancelar"
export const Empty="Vacío"
export const EmptyMemberships="Primero debes agregar membresías"
export const EmptyLevels="Primero debes agregar niveles"
export const Pay="Pagar"
export const Inactive="Inactivo"
export const Options="Opciones"
export const Remain="Debe"
export const standby="Standby"

/////School Name
export const School="Conversation Hackers"
