import React, { useState } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CardPost from '../../blog/cardPost'
import Modal from '../../MainComponents/Modal'
import ModalHome from '../ModalFree'
import Images from '../../../Constants/images'
import Videos from '../../../Constants/videos'
import Form from 'react-bootstrap/Form'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import * as Web from '../../../Constants/Web'


var labels = [
"	Anguila	+1 264",
"Argentina	+54",
"Aruba	+297",
"Bahamas	+1",
"Barbados	+1",
"Belice	+501",
"Bermudas	+1",
"Bolivia	+591",
"Brasil	+55",
"Canadá	+1",
"Chile	+56",
"Colombia	+57",
"Costa Rica	+506",
"Cuba	+53",
"Curazao	+599",
"Dominica	+1",
"Ecuador	+593",
"El Salvador +503",
"Estados Unidos	+1",
"Groenlandia	+299",
"Guadalupe	+590",
"Guatemala	+502",
"Guyana	+592",
"Haití	+509",
"Honduras	+504",
"Islas Caimán	+1-345",
"Islas Malvinas	+500",
"Islas Vírgenes Británicas	+1 284",
"Jamaica	+1",
"México	+52",
"Montserrat	+1 664",
"Nicaragua	+505",
"Panamá	+507",
"Paraguay	+595",
"Perú +51",
"Puerto Rico	+1",
"República Dominicana	+1",
"San Bartolomé	+590",
"San Cristóbal y Nevis	+1",
"San Martín	+1 599",
"San Pedro y Miquelón	+508",
"San Vicente y las Granadinas	+1",
"Santa Lucía	+1",
"Surinam	+597",
"Trinidad y Tobago	+1",
"Uruguay	+598",
"Venezuela	+58"
];
var currentdate = new Date();
var datetime =  currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/"
                + currentdate.getFullYear() + " @ "
                + currentdate.getHours() + ":"
                + currentdate.getMinutes() + ":"
                + currentdate.getSeconds();
function Head(){
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [change, setChange]=useState(false)
  const [show, setShow]=useState(false)
  const [second,setSeconds]=useState(10000)
  const openBlack=()=>{

      setChange(true)
setShow(true)
  }
  const closeBlack=()=>{
setShow(false)
  }
  /*  const [show,setModal]=useState(false)
const handleShow = () => setShow(true);
  const handleShow = () => {
    setModal(true)
  };*/
  const Save=()=>{
      const data = {
            Name:name,
            Email:email,
            Phone:country+' '+phone,
            Date:datetime,
          }
          var url = Web.wurl+'/Api/Create/Create_WorldStudent.php';
          fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            header: {'Content-Type': 'application/json'}
          }).then(res => res.json())
          .catch(error => console.error('Error:', error))
          .then(response => {
            setChange(true)
            setShow(true)
          });

}
const body=()=><img src={Images.FreeClass.Popup1} className="img-96"/>
    return (
      <Row >
      {change==true?<ModalHome video={true} onOpen={openBlack} closeBlack={closeBlack} setShow={show} talk={Images.FreeClass.talk}  videoUrl={Videos.HomePage.Webinar}/>:
        <Modal title={"Estas a un click de cambiar tu vida.."}  image={Images.FreeClass.Popup1} body={body}>
    <Form>
<Form.Group controlId="formBasicEmail" onChange={e=>setName(e.target.value)}>
  <Form.Control type="text" placeholder="Nombre" />
</Form.Group>
<Form.Group>
  <Form.Control type="email" placeholder="Correo" onChange={e=>setEmail(e.target.value)}/>
</Form.Group>
<Row>
    <Col>
    <Form.Label>Pais</Form.Label>
            <Form.Control  as="select" onChange={e => setCountry(e.target.value)}>
                      <option ></option>
                      {(() => {
                        const options = [];

                        for (let i = 0; i <= 47; i++) {
                          options.push(
                            <option value={labels[i]} >{labels[i]}</option>
                          );
                        }

                        return options;
                      })()}
            </Form.Control>

    </Col >
    <Col >
    <Form.Label>whatsapp</Form.Label>

      <Form.Control type="tel" placeholder="whatsapp" onChange={e => setPhone(e.target.value)}/>
    </Col>

  </Row>
</Form>


{name&&email&&country&&phone?<img onClick={Save} src={Images.FreeClass.freeWeek} className="center-img pointer"/>:<img src={Images.FreeClass.freeWeek} className="center-img pointer"/>}
    </Modal>}
          <Col ><img className="img-75" src={Images.FreeClass.Head}/></Col>
        <Col sm={7}  >
        <div  onClick={openBlack}>
        <CardPost  SocialBar={false} videoCustomButtonCustomeFreeClass={Images.FreeClass.thumbn} centerimg={Images.Blog.Click}/>
        </div>
        </Col>
      </Row>
    );
}
export default Head;
