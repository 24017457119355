import React, { Component } from 'react';
import '../../../Styles/App.css';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import moment from 'moment';
import Helmet from 'react-helmet';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { connect } from 'react-redux';
import { UpdateActive,fetchGetPost,fetchAddPost} from '../../../reducers/postsReducer';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Badge from 'react-bootstrap/Badge'

const numbers = [1, 2, 3, 4, 5];


class CreateLevels extends Component {

  constructor(props){
          super(props);
          this.state={
              Name:'',
              Info:'',

            };
   this.handleToChange = this.handleToChange.bind(this);
   this.onChange=this.onChange.bind(this);
   this.state = {
     from: undefined,
     to: undefined,
   };
      }

      onChange(e){
        this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
      }
      save(){
        var from =this.state.from
        var yearfrom =from.getFullYear();
        var monthfrom=from.getMonth()+1;
        var dayfrom=from.getDate();
        var to =this.state.to
        var yearto =to.getFullYear();
        var monthto=to.getMonth()+1;
        var dayto=to.getDate();

        /*const data={
          Id_Student:this.props.id,
          from:this.state.from,
          to:this.state.to,
          info:this.state.Info
        }*/

        const data={
          Id_Student:this.props.id,
          fromyear:yearfrom,
          frommonth:monthfrom,
          fromday:dayfrom,
          toyear:yearto,
          tomonth:monthto,
          today:dayto,
          info:this.state.Info
        }


        const inactive={
          Id:this.props.id,
          Active:'0',
          Table:'students',
          IdTable:'id_Student'
        }
        this.props.fetchAddPost(data,'Create/Create_Inactive.php');
        this.props.UpdateActive(inactive,'Update/Update_Active.php');
                alert('Correcto')
                const update={
                  id:this.props.idPlace
                }
        this.props.fetchGetPost('Get_Students.php',update);

      }
      showFromMonth() {
          const { from, to } = this.state;
          if (!from) {
            return;
          }
          if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
          }
        }
        handleFromChange(from) {
          // Change the from date and focus the "to" input field
          this.setState({ from });
        }
        handleToChange(to) {
          this.setState({ to }, this.showFromMonth);
          //alert(to)
        }
        render() {

          const CounterStudents=this.props.counterstudents
          const { from, to } = this.state;
          const modifiers = { start: from, end: to };
          return (
            <div className="InputFromTo">

            <h1>Fecha</h1>
              <DayPickerInput
                value={from}
                placeholder="From"
                format="ll"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  selectedDays: [from, { from, to }],
                  disabledDays: { after: to },
                  toMonth: to,
                  modifiers,
                  numberOfMonths: 2,
                  onDayClick: () => this.to.getInput().focus(),
                }}
                onDayChange={this.handleFromChange.bind(this)}
              />{' '}
              —{' '}
              <span className="InputFromTo-to">
                <DayPickerInput
                  ref={el => (this.to = el)}
                  value={to}
                  placeholder="To"
                  format="LL"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { before: from },
                    modifiers,
                    month: from,
                    fromMonth: from,
                    numberOfMonths: 2,
                  }}
                  onDayChange={this.handleToChange.bind(this)}
                />
              </span>
              <Helmet>
                <style>{`
        .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
          background-color: #f0f8ff !important;
          color: #4a90e2;
        }
        .InputFromTo .DayPicker-Day {
          border-radius: 0 !important;
        }
        .InputFromTo .DayPicker-Day--start {
          border-top-left-radius: 50% !important;
          border-bottom-left-radius: 50% !important;
        }
        .InputFromTo .DayPicker-Day--end {
          border-top-right-radius: 50% !important;
          border-bottom-right-radius: 50% !important;
        }
        .InputFromTo .DayPickerInput-Overlay {
          width: 550px;
        }
        .InputFromTo-to .DayPickerInput-Overlay {
          margin-left: -198px;
        }
      `}</style>
              </Helmet>
              <br/>
              <Form>
              <h1>Razon</h1>
              <Row>
                <Col>
                  <Form.Control placeholder="Razon" onChange={this.onChange.bind(this)} name="Info"/>
                </Col>
              </Row>
            </Form>
            <br/>
            {this.state.from&&this.state.to&&this.state.Info?
  <Button variant="success" onClick={this.save.bind(this)}>Guardar</Button>:null}
            </div>
          );
        }
      }
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
        counterstudents:state.counterstudents.counterstudents,
    }),
    //mapeo de funciones
    {
        UpdateActive,fetchGetPost,fetchAddPost
    }
)(CreateLevels);
