import React from 'react';
import * as Web from '../../../../Constants/Web'


export const updateFilesCoach=(id,clasevirtual,filesJson)=>{





const data={
  id:id,
  clasevirtual:clasevirtual,
  files:JSON.stringify(filesJson)
}

  var url = Web.wurl+'/'+'Api/Update/Update_ClassCoach.php';
  //var url = 'https://conversation-test.000webhostapp.com/Api/Create/Create_State.php';

fetch(url, {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(data), // data can be `string` or {object}!
    header: {'Content-Type': 'application/json'}
  }).then(res => res.text())
  .catch(error => console.error('Error:', error))
  .then(response => {

    alert(JSON.stringify(response))
  });


}
