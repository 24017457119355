import React, { Component } from 'react';
import '../../../Styles/App.css';
import Leve from '../../../Media/Levels.png'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Levels from '../Forms/Create_Questions'
import { connect } from 'react-redux';
import { UpdateActive,fetchGetPost,} from '../../../reducers/postsReducer';
import SearchInput, {createFilter} from 'react-search-input';
import Table from 'react-bootstrap/Table'
import Add from '../../../Media/Add.png';
const KEYS_TO_FILTERS = ['Name'];
var priority=[]
class ShowLevels extends Component {

  constructor(props){
          super(props);
          this.state={
            searchTerm: '',
            edit:false,
            Id:'',
            Name:'',
            Info:'',
            Priority:'',
            done:false
            };
            this.onChange=this.onChange.bind(this);

      }


     async  componentDidMount(){
        this.props.fetchGetPost('Get_Questions.php');
      }

      update(){
       this.props.fetchGetPost('Get_Questions.php');
      }
      onChange(e){
        this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre

      }
Edit(){
  return(
    <div>
    {this.state.done==true?<label className="green-text">CORRECTO</label>:
    <Form>
    <Form.Row>
    <Form.Group as={Col} controlId="formGridEmail">
      <Form.Label>Pregunta`</Form.Label>
      <Form.Control onChange={this.onChange} name="Name" value={this.state.Name} placeholder="Nombre" />

    <Form.Label>Respuesta</Form.Label>
    <Form.Control onChange={this.onChange} name="Info" value={this.state.Info} as="textarea" rows="3" />

    <Form.Label>Prioridad</Form.Label>
{this.state.Priority?
    <Form.Control as="select" onChange={this.onChange} value={this.state.Priority} name="Priority">
    {this.state.Priority==-10?  <option>Kids extra básico (SELECCIONADO)</option>:null}
    {this.state.Priority==-5?  <option>Teens extra básico (SELECCIONADO)</option>:null}
    {this.state.Priority==0?  <option>Básico (SELECCIONADO)</option>:null}
    {this.state.Priority==49?  <option>Básico intermedio  (SELECCIONADO)</option>:null}
    {this.state.Priority==50?  <option>Avanzado intermedio  (SELECCIONADO)</option>:null}
    {this.state.Priority==100?  <option>Avanzado (SELECCIONADO)</option>:null}
    <option value={-10}  >[-10]-Kids extra básico </option>
    <option value={-5}  >[-5]-Teens extra básico </option>
    <option value={0}  >[0]-Básico </option>
    <option value={49}  >[49]-Básico intermedio  </option>
    <option value={50}  >[50]-Avanzado intermedio  </option>
    <option value={100}  >[100]-Avanzado </option>
    </Form.Control >:null}
    </Form.Group>

    </Form.Row>
    </Form>}
        </div>
  )

}

     searchUpdated (term) {
      this.setState({searchTerm: term})
  }
states(){
  return(
<Levels update={this.update.bind(this)}/>
  )
}

  handleClose() {
  this.setState({ show: false,edit:false,done:false });
  }
  editShow(e){
    //dato.Active,dato.Id_Membership,dato.Name,dato.Price,dato.Info
    this.setState({ edit:true,
  Id:e[0],
  Name:e[1],
  Info:e[2],
Priority:e[3] });
  }

  handleShow() {
  this.setState({ show: true });
  }

  saveEditing(){
    //alert('hola')
    const data = {
          Id:this.state.Id,
          Name: this.state.Name,
          Info:this.state.Info,
        }
        this.props.UpdateActive(data,'Update/Update_Question.php');
        this.setState({
          done:true
        })
        this.update()
  }

  active(e){
    const active=e[0];
    {this.update()}
    if(e[0]==1){
      {this.update()}
      const data = {
          Id: e[1],
          Active:'0',
          Table:'levels',
          IdTable:'Id_Level'
        }
        this.props.UpdateActive(data,'Update/Update_Active.php');
      //alert("desactivado")
      {this.update()}
    }

    {this.update()}
    if(e[0]==0){
      {this.update()}
      const data = {
            Id: e[1],
            Active:'1',
            Table:'levels',
            IdTable:'Id_Level'
          }
          this.props.UpdateActive(data,'Update/Update_Active.php');
      //alert("activado")
      {this.update()}
    }
{this.update()}
  }


  render(){

     this.props.fetchGetPost('Get_Questions.php');
    const {children}=this.props
		const filteredInfo = this.props.posts.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
	        if (this.props.posts.length === 0 ) {
	        	return(
	            <div>
              <Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered>
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">Preguntas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
                {this.states()}
                </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose.bind(this)}>
                    Cancelar
                  </Button>
                </Modal.Footer>
              </Modal>
              <img src={Add} className="CH_SM_Icono" onClick={this.handleShow.bind(this)}/><br/>

	            <h1>Empty</h1>


	            </div>
	            )} else{
		return(
				<div><br/>
        {/*------------------edit---------------------*/}
        <Modal show={this.state.edit} onHide={this.handleClose.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Editar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
          {this.Edit()}
          </div>
          </Modal.Body>
          <Modal.Footer>
          {this.state.Name&&this.state.Info?<Button className="btn btn-success" onClick={this.saveEditing.bind(this)}>Guardar</Button>:null}

            <Button variant="secondary" onClick={this.handleClose.bind(this)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/*--------------------------------------------------------*/}
        <Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Preguntas</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
          {this.states()}
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose.bind(this)}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
	        <SearchInput placeholder="Buscar" className="search-input form-group my-input text-center"  onChange={this.searchUpdated.bind(this)} />
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>
                <th className="center-ico" >
                <img src={Add} className="CH_SM_Icono" onClick={this.handleShow.bind(this)}/><br/>
                  </th>
                <th className="table-width">Nombre</th>
              <th className="table-width">Respuesta</th>
              </tr>
            </thead>
            <tbody>
				  {filteredInfo.map((dato, key) => {
	  return(
      <tr key={key}>
      <td  className="pointer" onClick={() => this.editShow([dato.Id_question,dato.question,dato.answer])} className="CH_SM_Icono pointer"><img src={Leve} className="CH_SM_Icono"/></td>
      <td className="pointer" onClick={() => this.editShow([dato.Id_question,dato.question,dato.answer])}><label className="ch-dark-text pointer">{dato.question}</label></td>
        <td className="pointer" onClick={() => this.editShow([dato.Id_question,dato.question,dato.answer])}>
        <label className="ch-dark-text pointer">{dato.answer}
      </label></td>

    </tr>

	                        )
	                    })
	                }</tbody>
                </Table>





				</div>

			)}
		}





}
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        UpdateActive,fetchGetPost,
    }
)(ShowLevels);
