import React, { Component } from 'react';
import * as Web from '../../Constants/Web'
import Container from 'react-bootstrap/Container'
import StudentsOnThisClass from './components/Student/studentsOnThisClass';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux';
import {fetchGetPost2,GetClassroom,
} from '../../reducers/postsReducer';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {Redirect} from 'react-router-dom';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import GrayTemplate from '../MainComponents/Gray_Template_divide'
import CoachActions from './components/coachEdit'
import Colors from '../../Constants/Colors'
import ReactAgenda from './src/reactAgenda';
require('moment/locale/es-us.js'); // this is important for traduction purpose

var ready;
var now = new Date();
var coaches='';
var classroomname;



class AgendaSelect extends Component {

  constructor(props){
          super(props);
          this.state={
            studentsAgendas:[],
              items:[],
              quantityAgendaNew:'',
              loader:'',
              url:Web.wurl+'/',
              show:false,
              cellHeight:25,
              rowsPerHour:1,
              numberOfDays:7,
              Time:'',
              Coach:'',
              Id:'',
              Info:'',
              Level:'',
              Color:'',
              Day:'',
              DayAgenda:'',
              Classroom:'',
              scheduled:'',
              counter:'',
              full:'',
              student:'',
              agendaselected:'',
              loader:'1',
              classroomname:'',
              files:'',
              dateTo:'',
              link:'',
              isActive:'',
              _id:''
            };
      }



      componentDidMount(){
          this.props.GetClassroom('Get_Classrooms.php');
        var items=[];
        let myItem = localStorage.getItem('userData')
        const info=JSON.parse(myItem)
          const getagenda={
            id:info.Place
          }
          var url = this.state.url+'Api/Get_AgendasNamePlace.php';
          fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(getagenda), // data can be `string` or {object}!
            header: {'Content-Type': 'application/json'}
          }).then(res => res.json())
          .catch(error => console.error('Error:', error))
          .then(response => {
            response.forEach((data) => {
                        var myJSON = JSON.stringify(data);
                        //alert(data.Id_Agenda_Names)
                        this.setState({
                          agendaselected:data.Id_Agenda_Names
                        })
                      })
                      const data={
                        id:info.Place,
                        agendaid:this.state.agendaselected,
                        idCoach:info.Id
                      }
                      var url = this.state.url+'Api/Get_AgendaCoach.php';
                      fetch(url, {
                        method: 'POST', // or 'PUT'
                        body: JSON.stringify(data), // data can be `string` or {object}!
                        header: {'Content-Type': 'application/json'}
                      }).then(res => res.json())
                      .catch(error => console.error('Error:', error))
                      .then(response => {
                        response.forEach((data) => {
                                      items.push(
                                        {
                                         _id            :data.Id_Agenda,
                                          name          : data.infoAgenda,
                                          startDateTime : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourFrom, data.minutesFrom),
                                          endDateTime   : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourTo, data.minutesTo),
                                          classes       : data.colorAgenda,
                                          coaches:data.id_coachAgenda,
                                          classroom:data.id_classroomAgenda,
                                          quantity:data.quantityAgenda,
                                          level:data.levelAgenda,
                                          backgroundColor:data.colorAgenda,
                                          hourFrom:data.hourFrom,
                                          minutesFrom:data.minutesFrom,
                                          hourTo:data.hourTo,
                                          minutesTo:data.minutesTo,
                                          hexa:data.hexa,
                                          quantityAgendaNew:data.quantityAgendaNew,
                                         files:data.files,
                                         clase_virtual:data.clase_virtual,
                                         isActiveCV:data.isActiveCV

                                        }

                                        /*{
                                        _id            :data.Id_Agenda,
                                         name          : data.infoAgenda,
                                         startDateTime : new Date(data.year_agenda, data.month_agenda, data.day_agenda, data.hourFrom, data.minutesFrom),
                                           endDateTime   : new Date(data.year_agenda, data.month_agenda, data.day_agenda, data.hourTo, data.minutesTo),
                                           classes       : 'color-2'
                                      }*/);

                             })
                             this.setState({
                               loader:'',
                               items:items
                             })

                       });



           });

      }
      updateAll(){
          this.props.GetClassroom('Get_Classrooms.php');
        var items=[];
        let myItem = localStorage.getItem('userData')
        const info=JSON.parse(myItem)
          const getagenda={
            id:info.Place
          }
          var url = this.state.url+'Api/Get_AgendasNamePlace.php';
          fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(getagenda), // data can be `string` or {object}!
            header: {'Content-Type': 'application/json'}
          }).then(res => res.json())
          .catch(error => console.error('Error:', error))
          .then(response => {
            response.forEach((data) => {
                        var myJSON = JSON.stringify(data);
                        //alert(data.Id_Agenda_Names)
                        this.setState({
                          agendaselected:data.Id_Agenda_Names
                        })
                      })
                      const data={
                        id:info.Place,
                        agendaid:this.state.agendaselected,
                        idCoach:info.Id
                      }
                      var url = this.state.url+'Api/Get_AgendaCoach.php';
                      fetch(url, {
                        method: 'POST', // or 'PUT'
                        body: JSON.stringify(data), // data can be `string` or {object}!
                        header: {'Content-Type': 'application/json'}
                      }).then(res => res.json())
                      .catch(error => console.error('Error:', error))
                      .then(response => {
                        response.forEach((data) => {
                                      items.push(
                                        {
                                         _id            :data.Id_Agenda,
                                          name          : data.infoAgenda,
                                          startDateTime : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourFrom, data.minutesFrom),
                                          endDateTime   : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourTo, data.minutesTo),
                                          classes       : data.colorAgenda,
                                          coaches:data.id_coachAgenda,
                                          classroom:data.id_classroomAgenda,
                                          quantity:data.quantityAgenda,
                                          level:data.levelAgenda,
                                          backgroundColor:data.colorAgenda,
                                          hourFrom:data.hourFrom,
                                          minutesFrom:data.minutesFrom,
                                          hourTo:data.hourTo,
                                          minutesTo:data.minutesTo,
                                          hexa:data.hexa,
                                          quantityAgendaNew:data.quantityAgendaNew,
                                         files:data.files,
                                         clase_virtual:data.clase_virtual,
                                         isActiveCV:data.isActiveCV

                                        }

                                        /*{
                                        _id            :data.Id_Agenda,
                                         name          : data.infoAgenda,
                                         startDateTime : new Date(data.year_agenda, data.month_agenda, data.day_agenda, data.hourFrom, data.minutesFrom),
                                           endDateTime   : new Date(data.year_agenda, data.month_agenda, data.day_agenda, data.hourTo, data.minutesTo),
                                           classes       : 'color-2'
                                      }*/);

                             })
                             this.setState({
                               loader:'',
                               items:items
                             })

                       });



           });

      }

      handleClose() {
    this.setState({ show: false,studentsAgendas:[] });
    this.updateAll()
  }

  handleShow(e) {
    this.setState({
      files:e.files,
      dateTo:e.endDateTime,
      link:e.clase_virtual,
      isActive:e.isActiveCV,
      _id :e._id
    })
    const classrooms = this.props.classroom;
    classrooms.forEach((data) => {
                var myJSON = JSON.stringify(data);
                if(data.Id_Classroom==e.classroom){
                  //alert(data.Coach_Name)
                  classroomname=data.Name
                  }});
this.setState({
  show: true})
  const coachesplaces={
    id:e._id
  }

  var url = this.state.url+'Api/Get_Agenda_StudentSelected2.php';
  fetch(url, {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(coachesplaces), // data can be `string` or {object}!
    header: {'Content-Type': 'application/json'}
  }).then(res => res.json())
  .catch(error => console.error('Error:', error))
  .then(response => {
this.setState({studentsAgendas:response})

   } );
  }

  render() {
    if(localStorage.getItem('userData')){
      let myItem = localStorage.getItem('userData')
      const info=JSON.parse(myItem)
      if(info.type=='MASTER'){
        return (<Redirect to={'/admin'}/>)
      }
      if(info.type=='Student'){
        return (<Redirect to={'/Profile'}/>)
      }
      if(info.type=='Coach'){
        //return (<Redirect to={'/Coach'}/>)
      }
      if(info.type=='Admin'){
        return (<Redirect to={'/admins'}/>)
      }

    }
    return (
      <GrayTemplate>
      {this.state.loader?
        <div className="center2">
        <Loader
             type="Puff"
             color="#00BFFF"
             height="100"
             width="100"
          />
          <h1>Cargando...</h1>
        </div>:
    <Row>
    <Col className="Front_divide" >
    <Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered  >
      <Modal.Header closeButton  >
        <Modal.Title id="contained-modal-title-vcenter" >
        <h2>Salón {classroomname}</h2></Modal.Title>
      </Modal.Header>
      <Modal.Body >
      <CoachActions
          id={this.state._id }
          isActiveCV={this.state.isActive}
          link={this.state.link}
          filesToShow={this.state.files}
           dateTo={this.state.dateTo} />
      <StudentsOnThisClass idClass={this.state._id}/>
      </Modal.Body>
      <Modal.Footer >
        <Button variant="primary" onClick={this.handleClose.bind(this)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  <hr/>
  {this.state.items?
  <ReactAgenda
      minDate={new Date(now.getFullYear(), now.getMonth()-2, now.getDate()-5)}
      maxDate={new Date(now.getFullYear(), now.getMonth()+2)}
      disablePrevButton={false}
      startDate={this.state.startDate}
      cellHeight={this.state.cellHeight}
      locale={this.state.locale}
      items={this.state.items}
      numberOfDays={this.state.numberOfDays}
      rowsPerHour={this.state.rowsPerHour}
      itemColors={Colors.Agenda}
      autoScale={false}
      fixedHeader={false}
      onItemEdit={this.handleShow.bind(this)}/>:null}

   </Col>
  </Row>}
</GrayTemplate>

    );
  }
}
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        classroom: state.classroom.classroom
    }),
    //mapeo de funciones
    {
        GetClassroom
    }
)(AgendaSelect);
