import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Spinner from 'react-bootstrap/Spinner'
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import classes from './card.module.css'
import { useCreditCard } from '../../../../MT/hooks/StripePayments/useCreditCard';
import ShowMonths from '../ModalInstallments'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import './card.module.css';
const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#87bbfd',
      },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};
const CheckoutForm = () => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ paymentId, setPaymentId ] = useState();
  const talons = useCreditCard({ paymentId, setIsLoading });
  const { data,installments, submit, intentId, planIsAvailable, confirmPayment , paymentError,  paymentDoneCorrect} = talons;
  const stripe = useStripe();
  const elements = useElements();



  useEffect(()=>{
    if (planIsAvailable == false  && intentId) {
      console.log('hacer pago....');
      confirmPayment(null);
    }
  },[planIsAvailable,intentId]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)
    });
    submit(paymentMethod.id);
  };

  if (paymentError) {
    return(
      <Alert variant="danger">
        <Alert.Heading>Card error</Alert.Heading>
        <p>
        Mensaje
        </p>
        <hr />
        <p className="mb-0">
        Instrucciones
        </p>
    </Alert>
  )
  }
  if (paymentDoneCorrect) {
    return(
      <Alert variant="success">
        <Alert.Heading>Card completo</Alert.Heading>
        <p>
        Mensaje
        </p>
        <hr />
        <p className="mb-0">
        Instrucciones
        </p>
    </Alert>
  )
  }

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <ShowMonths confirmPayment={confirmPayment} installments={installments} planIsAvailable={planIsAvailable}/>

      { isLoading ?  <div className={classes.spinner}><Spinner animation="border" /></div> :
      <div className={classes.spinner}>
      <Button variant="light" type="submit" disabled={!stripe || !elements}>
        Pagar
      </Button>
    </div>}
    </form>
  );
};

const stripePromise = loadStripe('pk_test_51JkDtSJdpV2aKYNEj3YB3awHzgi7ow0CkkIlT3DZ6Sk0nRK5BE1bRMtuA7RSyKcJDVCqwYOcYONlvsCXwCKBkyVA00eokHgF5i');

const CardPayment = () => (
  <Elements stripe={stripePromise}>
  <div className={classes.root}>
    <CheckoutForm />
  </div>
  </Elements>
);

export default CardPayment;
