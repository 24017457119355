import React, {useEffect, useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {VictoryChart, VictoryBar} from 'victory';
import * as URL from '../../../../Constants/services';
import classes from './perClub.module.css';
import List from '../List';
import TableContainer from '../TableContainer';
import Title from '../Titles';
import SearchBarInput from '../SearchBarInput';
const NewStudents = (props) =>{
  const {startDate, endDate, place, show}= props;
  const [data, setData] = useState();
  const [array, setArray] = useState([]);
  const [searchTerm,setSearchTerm] = useState('')





  useEffect(()=>{
    /*SELECT * FROM `students` WHERE created BETWEEN '2021-10-00' AND '2021-10-31'
*/
      var url = URL.api+"/getOldClubsNamesStudents";
      const inf={
        from:startDate,
        to:endDate,
        place:place
      }
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(inf), // data can be `string` or {object}!
        header: {'Content-Type': 'application/json'}
      }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {
        setData(response)
        response.map((item)=>{
          setArray(array=>[...array,[item.id]])
        })

      })
  },[startDate,endDate])


  if (!startDate || !endDate || !show || !data) {
    return'';
  }
  const list =data.filter((val)=>{
          if(searchTerm == ""){
            return val
          }
          if (!searchTerm) {
            return val;
          }
          else if(val.name.toLowerCase().includes(searchTerm.toLowerCase())){
            return val;
          }
        }).map((val,key)=>{
          return <Tr>
          <Td>{val.id}</Td>
          <Td> {val.name}</Td>
          <Td>{val.lastName+" "+val.secondLastName}</Td>
          <Td>{val.created}</Td>
          <Td>{val.place}</Td>
          </Tr>
        })


        const headersNewStudents = [
          { label: "id", key: "id" },
          { label: "nombre", key: "name" },
          { label: "apellido", key: "lastName" },
          { label: "apellido", key: "secondLastName" },
          { label: "fecha de ingreso", key: "created" },
          { label: "sucursal", key: "place" }
        ];
  return(
    <React.Fragment>
    <Title title={"Nuevos estudiantes "+array.length} />
    <TableContainer>
    <SearchBarInput linkToExport={""} fileName={"nuevos_estudiantes"} data={ data}headers={headersNewStudents} setSearchTerm={setSearchTerm}/>
    <Table>
    <Thead>
      <Tr>
        <Th>ID</Th>
        <Th>Nombre</Th>
        <Th>Apellido</Th>
        <Th>Ingreso</Th>
        <Th>Sucursal</Th>
      </Tr>
    </Thead>
    <Tbody>
      {list}
    </Tbody>
  </Table>



    </TableContainer>
    </React.Fragment>
  )
}
export default NewStudents;
