import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Helpers from '../../lib/Helper'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import About from '../../Media/carousel/about.jpg'
import Helper from '../../lib/Helper'
import firebase from 'firebase';
import Images from '../../Constants/images'
class People extends Component {
  constructor(props){
    super(props)
    this.state={
      uid:'8B57J61q4bM51u9oF7tKln340Fm2',
      show:false,
      card1image:'',
      card1imageurl:'',
      card1text:'',
      card2image:'',
      card2imageurl:'',
      card2text:'',
      uploading:false,
      uploadvalue:'',

    }
    this.show=this.show.bind(this);
    this.texts=this.texts.bind(this);
    this.upload=this.upload.bind(this);
    this.cancel=this.cancel.bind(this);
    this.selectedpicture=this.selectedpicture.bind(this);
  }
  componentWillMount(){
    for (var i = 1; i < 3; i++) {
      if (this.props.data['card'+i].Url=='null') {
        //alert('nulo')
         this.setState({
           ['card'+i+'imageurl']:About,
           ['card'+i+'text']:'no hay texto'
         })

        //alert('nonulo')
      }else {
        //alert('no nulo')
        this.setState({
          ['card'+i+'imageurl']:this.props.data['card'+i].Url,
          ['card'+i+'text']:this.props.data['card'+i].title
        })
      }

    }
  }
  show(){
    this.setState({
      show:!this.state.show
    })
  }
  texts(event){
    this.setState({
      [event.target.name]:event.target.value
    })
  }
  selectedpicture(event){
    this.setState({
      [event.target.name]:event.target.files[0],/*guarda el archivo en un estado para despues subirlo cuando se le de click*/
      [event.target.name+'url']: URL.createObjectURL(event.target.files[0])
    })
  }
  cancel(){
    for (var i = 1; i < 3; i++) {
      if (this.props.data['card'+i].Url=='null') {
        //alert('nulo')
         this.setState({
           ['card'+i+'imageurl']:About,
           ['card'+i+'text']:'no hay texto'
         })

        //alert('nonulo')
      }else {
        //alert('no nulo')
        this.setState({
          ['card'+i+'imageurl']:this.props.data['card'+i].Url,
          ['card'+i+'text']:this.props.data['card'+i].title
        })
      }

    }
  }
  render() {
    return (
    	<div >
      {this.props.edit==true?<Button onClick={this.show}>Editar</Button>:null}
      <Modal show={this.state.show} onHide={this.show}>
        <Modal.Header closeButton>
          <Modal.Title>Testimonios</Modal.Title>
        </Modal.Header>
        {this.state.uploading==true?
          <p>Subiendo..</p>:
        <Col>
        {(() => {
          const options = [];

          for (let i = 1; i <= 2; i++) {
            options.push(
              <Row>
                  <Col className="overflow-hidden ">
                      <Row className="margin-left-null">
                      <img className="img-96" src={this.state['card'+i+'imageurl']}/>
                      </Row>

                      <Row className="margin-left-null overflow-hidden">
                          {this.state['card'+i+'imageurl']&&this.state['card'+i+'text']?<Col className="overflow-hidden margintop6"><a onClick={()=>this.upload(i)}>Subir</a></Col>:null}
                          <Col className="overflow-hidden margintop6 "><input  type="file" onChange={this.selectedpicture} name={'card'+i+'image'} label="Cargar Archivo" s={3} /></Col>
                      </Row>

                  </Col>

                  <Col><textarea rows="5" cols="15" type='text' value={this.state['card'+i+'text']} name={'card'+i+'text'} onChange={this.texts}/>
                  </Col>
              </Row>
            );
          }

          return options;
        })()}



        </Col>}
        <Modal.Footer>
          <Button variant="danger" onClick={this.cancelText}>
            Cancelar
          </Button>
          <Button variant="secondary" onClick={this.prede}>
            Prede
          </Button>
          <Button variant="primary" onClick={this.saveText}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      <Container className="margin-bottom5">
              <Row >
                  <Col md="3">
                  <div className="container-imgb ">
                  <img src={Images.People.T1} className="images-ornament" />
                  <div className="centered-imgb black-text  ">
                  <img src={this.state.card1imageurl} alt="cv_testimonio" className="images-knowledge"/>
                  </div>
                  </div>
                  </Col>

                  <Col md="3">
                  <div className="container-imgb">
                    <img src={Images.People.T3} alt="cv_testimonio" className="image-ballon"/>
                    {/*<div className="bottom-left-imgb black-text">Bottom Left</div>*/}
                    {/*<div className="top-left-imgb black-text">Top Left</div>*/}
                    {/*<div className="top-right-imgb black-text">Top Right</div>*/}
                    {/*<div className="bottom-right-imgb black-text">Bottom Right</div>*/}
                    <div className="centered-imgb text_home_people">{this.state.card1text}</div>
                    </div>
                    </Col>




                    <Col md="3" >
                    <div className="container-imgb ">
                      <img src={Images.People.T2} alt="cv_testimonios" className="image-ballon "/>
                      {/*<div className="bottom-left-imgb black-text">Bottom Left</div>*/}
                      {/*<div className="top-left-imgb black-text">Top Left</div>*/}
                      {/*<div className="top-right-imgb black-text">Top Right</div>*/}
                      {/*<div className="bottom-right-imgb black-text">Bottom Right</div>*/}
                      <div className="centered-imgb text_home_people">{this.state.card2text}</div>
                      </div>
                      </Col>
                      <Col md="3">
                      <div className="container-imgb ">
                      <img src={Images.People.T1} className="images-ornament" />
                        <div className="centered-imgb black-text ">
                          <img src={this.state.card2imageurl} alt="cv_testimonios" className="images-knowledge "/>
                        </div>
                      </div>
                      </Col>

              </Row>
              <hr/>
              <hr/>
      </Container>
    		</div>
    );
  }
  upload(card){
    this.setState({
      uploading:true})
    const file = this.state['card'+card+'image']
    const storageRef = firebase.storage().ref("Gallery/Home/Testimonios"+card+".jpg")
    const task = storageRef.put(file)

    task.on('state_changed', (snapshot) => {
      let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      this.setState({
        uploadvalue: percentage
      })
    }, (error) => {
      alert("hubo un error, vuelve a intentarlo.")
      this.setState({
        uploadvalue:'',
        uploading:false

      })
    }, () =>{
      task.snapshot.ref.getDownloadURL().then((downloadURL)=> {
        const gallery={
          Url:downloadURL,
          title:this.state['card'+card+'text']
        }
        Helper.AddPictureHomeCardTest(this.state.uid,card,(gallery)).then(()=>{/*informacion de archivo*/
        }).catch(function(error){
        let MS=error.message
        alert(MS)
        });
        this.setState({
          uploadvalue:'',
          uploading:false
        })
  })
        });
  }
}

export default People;
