import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Modal from 'react-bootstrap/Modal'
import {Link} from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import '../../Styles/Cardvideo.css';
import Videos from '../../Constants/videos'
import ModalFree from '../Homepage/Modal'
import * as LOGIN from '../../Constants/Menu'
import Images from '../../Constants/images';
import {Redirect} from 'react-router-dom';
import YouTube from 'react-youtube';
import Social from './social'

//php version 7.3.2

class CardPost extends Component {

  constructor(props){
          super(props);
          this.state={
              video:'',
              url:'',
              des:'',
              title:'',
              height:'390',
              width:'640',
              cols:'150',
              show:false,
              custom:false,
              url_video:'',
              audio_url:'',
              image_url_thumbnail:'',
            };

            this.OpenPost=this.OpenPost.bind(this);
            this.closePost=this.closePost.bind(this);
      }

      componentDidMount() {

          window.addEventListener("resize", this.resize.bind(this));
          this.resize();
      }

      resize() {
        //this.setState({hideNav: window.innerWidth <= 858});
  if(window.innerWidth<=800){
    this.setState({
      height:'200',
      width:'300',
      cols:'30'
    })
  }else {
    this.setState({
      height:'390',
      width:'640',
      cols:'100'
    })
  }
      }
      closePost(){
        this.setState({show:!this.state.show})
      }
      OpenPost(event){
        this.setState({show:true,data:event,
          url_video:event.url_video,
          title:event.title_video,
          des:event.desc_video,
          image_url_thumbnail:event.image_url_thumbnail,
          audio_url:event.audio_url
        })

      }
      OpenPostOnlyVideo(event){
        this.setState({show:true,
          url_video:event,
        })

      }
      OpenVideoFreeClass(){
        this.setState({
          show:true,
          custom:true
        })

      }

  render() {

    const opts = {
      height: this.state.height,
      width: this.state.width,
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 1
      }
    };
    return (
      <Container className="pointer center-text blog-card">
      <Modal centered show={this.state.show} onHide={this.closePost} className="transparent-blog-modal">
        <Modal.Header >
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {this.state.custom==true?
          <ModalFree video={true} videoUrl={Videos.HomePage.FreeClass}/>:null}
        {this.state.url_video?
          <div className="container-imgb">
          <YouTube
          className=" img-post"
                  videoId={this.state.url_video.replace('https://www.youtube.com/watch?v=','')}
                  opts={opts}
                  onReady={this._onReady}
                />
                <p className="text-initial">{this.state.des}</p>
         </div>
        :null}



        {this.props.localVideo?
            <div className="container-imgb text-center" >
            <video preload="none" src={this.props.localVideo} muted controls autoPlay className="video-prices"/>
            </div>
        :null}

        {/*blog view with podcast*/}
              {this.state.audio_url?
                <div className="container-imgb text-center" >
                  <img className=" img-post" src={Images.Logos.Main}/>

                  <div className="">  <audio  src={this.state.audio_url} controls/></div>
                  <p className="text-initial">{this.state.des}</p>
                  </div>
              :null}

              {/*blog view with only text an image*/}
                    {this.state.image_url_thumbnail?

                        <div className="container-imgb text-center" onClick={()=>this.OpenPost(this.props.data)}>
                      <img className=" img-post" src={this.state.image_url_thumbnail} />
                      <p className="text-initial">{this.state.des}</p>
                      </div>
                      :null}
        </Modal.Body>
      </Modal>
          <h4 className="black-text">{this.props.title}</h4>
{/*blog view with  video*/}
      {this.props.video?
        <Link to={'/inglesfacil/'+this.props.Id_video_conversation}>
          <div className="container-imgb text-center" onClick={()=>this.OpenPost(this.props.data)}>
          <img className=" img-post" src={"https://img.youtube.com/vi/"+this.props.video.replace('https://www.youtube.com/watch?v=','')+"/maxresdefault.jpg"} />
          <div className="centered-img-blog gray-text">{this.props.centerimg?<img className="img-post" src={this.props.centerimg}/>:<img className=" img-50" src={Images.Blog.Play}/>}</div>
        </div>
        </Link>
      :null}
      {this.props.videoFreeClass?
          <div className="container-imgb text-center" onClick={()=>this.OpenPostOnlyVideo(this.props.videoFreeClass)}>
          <img className=" img-post" src={"https://img.youtube.com/vi/"+this.props.videoFreeClass.replace('https://www.youtube.com/watch?v=','')+"/maxresdefault.jpg"} />
          <div className="centered-img-blog gray-text">{this.props.centerimg?<img className="img-post" src={this.props.centerimg}/>:<img className=" img-50" src={Images.Blog.Play}/>}</div>
        </div>
      :null}




      {this.props.videoCustomButton?
          <div className="container-imgb text-center"  onClick={()=>this.OpenVideoFreeClass()} >
          <img className=" img-post" src={"https://img.youtube.com/vi/"+this.props.videoCustomButton.replace('https://www.youtube.com/watch?v=','')+"/maxresdefault.jpg"} />
          <div className="centered-img-blog gray-text">{this.props.centerimg?<img className="img-post" src={this.props.centerimg}/>:<img className=" img-50" src={Images.Blog.Play}/>}</div>
        </div>
      :null}
      {this.props.videoCustomButtonCustome?
          <div className="container-imgb text-center"   >
          <img className=" img-post" src={"https://img.youtube.com/vi/"+this.props.videoCustomButtonCustome.replace('https://www.youtube.com/watch?v=','')+"/maxresdefault.jpg"} />
          <div className="centered-img-blog gray-text">{this.props.centerimg?<img className="img-post" src={this.props.centerimg}/>:<img className=" img-50" src={Images.Blog.Play}/>}</div>
        </div>
      :null}

      {this.props.videoCustomButtonCustomeFreeClass?
          <div className="container-imgb text-center"   >
          <img className=" img-post" src={this.props.videoCustomButtonCustomeFreeClass} />
          <div className="centered-img-blog gray-text">{this.props.centerimg?<img className="img-post" src={this.props.centerimg}/>:<img className=" img-50" src={Images.Blog.Play}/>}</div>
        </div>
      :null}


        {/*blog view when is loading the video*/}


{/*blog view with podcast*/}
      {this.props.audio?

          <Link to={'/inglesfacil/'+this.props.Id_video_conversation}>
        <div className="container-imgb text-center" onClick={()=>this.OpenPost(this.props.data)}>
          <img className=" img-post" src={Images.Logos.Main}/>
          <div className="centered-img-blog gray-text"><img className=" img-50" src={Images.Blog.Podcast}/></div>
        {/*<div className="">  <audio  src={this.props.audio} controls/></div>*/}
        </div>
        </Link>
      :null}
      {/*blog view when is loading the audio*/}
      {this.props.audioPodcast?
        <div className="container-imgb ">
        <img src={this.props.ImagePodcast}className="img-100" />

          <div className="">  <audio  src={this.props.audioPodcast} controls/></div>
          <p className="text-initial">{this.props.desc}</p>
          </div>
      :null}

{/*blog view with only text an image*/}
      {this.props.image?

          <Link to={'/inglesfacil/'+this.props.Id_video_conversation}>
          <div className="container-imgb text-center" onClick={()=>this.OpenPost(this.props.data)}>
        <img className=" img-post" src={this.props.image} />
        <div className="centered-img-blog gray-text"><img className=" img-50" src={Images.Blog.Text}/></div>
        <p className="text-initial">{this.props.desc}</p>
        </div>
        </Link>
        :null}


        {this.props.SocialBar==false?null:
        <Social Id_video_conversation={this.props.Id_video_conversation}/>}
        {this.props.postview==true?<p className="black-text">{this.props.desc}</p>:null}
    {/*
share div*/}



  </Container>


    );
  }
}

export default CardPost;
