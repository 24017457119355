import React, { Component } from 'react';
import '../../Styles/App.css';
import Menu from './Menu'
import Badge from 'react-bootstrap/Badge'
import Container from 'react-bootstrap/Container'
import Add from '../MainComponents/Add'
import Gray_Template from '../MainComponents/Gray_Template'
import ShowMemberships from './Tables/Show_Membership'
import * as Memberships from '../../Constants/Menu'

class Membership extends Component {



  render() {
    return (

      <div className="center-text">
      <Container className="mainbox container2 " >
          <h1 ><Badge variant="secondary">{Memberships.MEMBERSHIPS}</Badge></h1>
            <span className="ch-black-text">Aquí podrás crear tus membresías</span>
          <hr/>
      <ShowMemberships/>
</Container>
</div>
    );
  }
}

export default Membership;
