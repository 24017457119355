import React, { Component } from 'react';
import firebase from 'firebase'
import '../../Styles/Home.css';
import '../../Styles/App.css';
import Carousel from 'react-bootstrap/Carousel'
import Dimensiones from '../../Media/carousel/carouselDimensiones.jpg'
import Container from 'react-bootstrap/Container'
import Banner from '../Admin/Settings/bannerHome'
import Helpers from '../../lib/Helper'
	const todos = [];
class Caro extends Component {

  constructor(props){
          super(props);
          this.state={
                uid:'8B57J61q4bM51u9oF7tKln340Fm2',
								images:[]
            };

      }



			componentWillMount(){
			  const extr= firebase.database().ref().child("Gallery/Home/Picture")
					 extr.on('value', (snapshot) => {

					 			snapshot.forEach((data) => {
					 				todos.push({
					 						Url:data.val().Url,
					 				});

					 this.setState({
					 images:todos
					 })
					 })
					 });
			}


  render() {
    return (
      <div >
					{/*-------------------CLASE CAROUSEL PARA MODIFICAR-----------------------------*/}


					<Carousel>
			    {this.state.images.map(pic => (
			      <Carousel.Item>
			        <img
			          className="d-block w-100"
			          src={pic.Url}
			        />
			        <Carousel.Caption>
			        </Carousel.Caption>
			      </Carousel.Item>

					))}
			</Carousel>
			{this.props.edit==true?<Banner/>:null}
</div>
    );
  }
}

export default Caro;
