import React, { Component } from 'react';
import firebase from 'firebase'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Helpers from '../../lib/Helper'
import Modal from 'react-bootstrap/Modal'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import ModalDialog from 'react-bootstrap/ModalDialog'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Ruleta from  './Ruleta'
import ButtonFreeClass from './ButtonFreeClass'
import Images from '../../Constants/images'
	const todos = [];
class Home extends Component {

  constructor(props){
          super(props);
          this.state={
                uid:'8B57J61q4bM51u9oF7tKln340Fm2',
              setShow:false,
							ended:false,
							talk:false
            };
            this.setShow=this.setShow.bind(this)
      }

      setShow(e){
        this.setState({setShow:e})
      }

			componentWillMount(){

				if(this.props.time){
					/*alert('tiene')*/
					setTimeout(
		    function() {
		        this.setShow(true);
		    }
		    .bind(this),
		    this.props.time
		)
				}else {
					/*alert('no')*/

			        this.setShow(true);
				}
      /*  ;*/
			  const extr= firebase.database().ref().child("Gallery/Home/Picture")
					 extr.on('value', (snapshot) => {

					 			snapshot.forEach((data) => {
					 				todos.push({
					 						Url:data.val().Url,
					 				});

					 this.setState({
					 banner:todos
					 })
					 })
					 });
			}
			ended(){
				this.setState({
					ended:true
				})
				setTimeout(
				function() {
				this.setState({talk:true})

				}
				.bind(this),
				5000
				);
				setTimeout(
				function() {
				this.setState({setShow:false})

				}
				.bind(this),
				15000
				);
			}
  render() {
    return (

        <Modal
          show={this.state.setShow}
					className="modal-ruleta"
          onHide={() => this.setShow(false)}
        >
				{this.props.ruleta==true?
				<Ruleta social={this.props.data} close={()=>this.setShow(false)}/>:null}
				{this.props.video==true?
					<div className="text-center">
					<p className="red-text">SI CIERRAS ESTE VIDEO NO LOGRARÁS TENER TU MES GRÁTIS</p>
					<video preload="none" controlslist="nodownload" className="video-free" height="240" autoPlay muted  controls src={this.props.videoUrl} onEnded={this.ended.bind(this)}/>

					{this.state.ended==true?<div>
					<ButtonFreeClass image={Images.FreeClass.freeMonth} url={'https://pay.hotmart.com/E41634392F?checkoutMode=2'}/>
						</div>:<ButtonFreeClass image={Images.FreeClass.changeLife}/>}
						{this.state.talk==true?<Row>
							<Col xs={6}><img src={Images.FreeClass.talk} className="img-100"/></Col>
							</Row>:null}
							{this.props.talk?<img src={this.props.talk} className="img-100-abosulte"/>:null}
					</div>:null
				}

          </Modal>
    );
  }
}

export default Home;
