import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Route, Link,NavLink,Switch,Redirect,BrowserRouter} from 'react-router-dom'
import Reports from '../../Admin/Reports'
import Home from './placeData';
import classes from './admin.module.css';
import Version from '../../ui/Version'
class Index extends Component {
  render() {
		if(localStorage.getItem('userData')){
			let myItem = localStorage.getItem('userData')
			const info=JSON.parse(myItem)
			if(info.type=='MASTER'){
				return (<Redirect to={'/admin'}/>)
			}
			if(info.type=='Student'){
				return (<Redirect to={'/Profile'}/>)
			}
			if(info.type=='Coach'){
				return (<Redirect to={'/Coach'}/>)
			}
			if(info.type=='admin'){
			 //return (<Redirect to={'/conversationplace'}/>)
			}

		}else {
			return (<Redirect to={'/'}/>)
		}
    if(!localStorage.getItem('userData')){
      return (<Redirect to={'/'}/>)
    }
    return (
    <div className={classes.root}>
      <Version/>
      <div className={classes.menuContainer}>
      <NavLink className={classes.menuLink}  activeClassName={classes.menuLinkActive} to={'/conversationplace/home'}>Home</NavLink>
      <NavLink  className={classes.menuLink} activeClassName={classes.menuLinkActive} to={'/conversationplace/reports'}>Reportes</NavLink>
      </div>
      <Switch>
        <Route exact path="/conversationplace/home"  component={Home}/>
        <Route exact path="/conversationplace/reports" component={Reports}/>
      </Switch>
    </div>
)}}

export default Index;
