import React from 'react';
import {UpdateActive} from '../../../../reducers/postsReducer';
import { connect } from 'react-redux';
import Toast from '../../../ui/Toast/toast';
import * as Web from '../../../../Constants/Web'
import { useToasts } from 'react-toast-notifications'

export const updateDay=(id,
  idAgenda,
  dateFrom,
  dateTo,
  coach,
  level,
  limit,
  day,
  classroom,
  info,
timezone,
backgroundColor,
backgroundhexa,clasevirtual,isActive,
filesJson,
id_agendaGeneral,
hourFrom, minutesFrom, hourTo,minutesTo)=>{


        if(idAgenda){
            if(limit&&coach&&level&&classroom&&backgroundColor&&info&&timezone){
              var from =new Date(dateFrom);
              var to =new Date(dateTo);
              var coachSN =coach.replace(/\D/g,'');
              var levelSN=level.replace(/\D/g,'');
              var daynumber = from.getDay();
              let month = from.getMonth() + 1;
              let year = from.getFullYear();
              var daymonth = from.getDate();
              var hour = from.getHours();
              var hourMinutes = from.getMinutes();
              var hour_end = to.getHours();
              var hour_endMinutes = to.getMinutes();



              const data={
                color:backgroundColor,
                hexa:backgroundhexa,
                Level:levelSN,
                Id_Coach:coachSN,
                Id_Agenda:id_agendaGeneral,
                Id_Classroom:classroom,
                info:info,
                Day:day,
                quantity:limit,
                Id_Agenda_Name:idAgenda,
                /*date*/
                hourFrom:hourFrom,
                minutesFrom:minutesFrom,
                hourTo:hourTo,
                minutesTo:minutesTo,
                hexa:backgroundhexa,
                Time_Zone:timezone,
                clase_virtual:clasevirtual,
                isActive:isActive,
                /*end date*/
                /*place number*/

                Id_Place:id,
                files:JSON.stringify(filesJson)
                /*end place number*/
              }
              //UpdateActive(data,'Create/Create_Day.php');
              var url = Web.wurl+'/'+'Api/Update/Update_AgendaBig.php';
              //var url = 'https://conversation-test.000webhostapp.com/Api/Create/Create_State.php';

            fetch(url, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                header: {'Content-Type': 'application/json'}
              }).then(res => res.text())
              .catch(error => console.error('Error:', error))
              .then(response => {

                alert(JSON.stringify(response))
              });
              //alert(hourMinutes)
              //this.update();
              //this.handleClose();
            }else {
              alert("llena todos los datos")
            }
          }else {
            alert('selecciona una agenda')
          }


}
