import React, { Component } from 'react';
import { QRCode } from "react-qr-svg";
import { connect } from 'react-redux';
import Badge from 'react-bootstrap/Badge'
import CHlogo from '../../Media/Conversation_Hackers_Logo.png'
import { Edit } from '../../reducers/postsReducer';
import Agenda from '../Agenda/Agenda'
import Gray_Template from '../MainComponents/Gray_Template'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import '../../Styles/App.css';
import Menu from './Menu'


class App extends Component {

  constructor(props){
          super(props);
          this.state={
              Datos:[],
              url:[]
            };
      }
      componentWillMount(){
        let myItem = localStorage.getItem('userData')
        const info=JSON.parse(myItem)
        //alert(info.Id)
        //alert(JSON.stringify(info))

        const student={
          id:info.Id
        }
        this.props.Edit('Get_Student.php',student);

        this.setState({
          url:this.state.id
        })
        }

  render() {
    const student=this.props.edit
    let myItem = localStorage.getItem('userData')
    const info=JSON.parse(myItem)
    const data = { "Id":info.Id, "type":"Student" };
    return (
      <Gray_Template>
      <div className={'profile-info'}>
      {student.map(item => (

            <Container key={item}>
          <Row>
            <Col sm={8}>
            <h1>
            Información
            </h1>

            </Col>
          </Row>
          <Row>
            <Col sm>
            <img src={CHlogo} className="Logo_Img_BG CH_Responsive center-image-R" />
            <br/><br/>
            <QRCode
            className="center-image-R"
                            bgColor="#f0f0f0"
                            fgColor="#000000"
                            level="Q"
                            style={{ width: 256 }}
                            value={JSON.stringify(data)}
                        /><br/><br/><br/><br/><br/><label>Este es tu código único para ingresar a tu clase</label>


            </Col>
            <Col sm >
            <img src={CHlogo} className="Logo_Img_BG CH_NR center-image-NR" />
            <br/>
            <h3>
            Nombre: <Badge variant="secondary">{item.Name} {item.Last_Name} {item.Second_Last_Name}</Badge>
            </h3><br/>
            <h3>
            Correo: <Badge variant="secondary">{item.Email}</Badge>
            </h3><br/>
            <h3>
            Membresía: <Badge variant="secondary">{item.Name_Membership}</Badge>
            </h3><br/>
            </Col>
            <Col sm>
            <div className="center-text">
            <h1 className="sesions-text">Sesiones</h1>
            <span className="sesions-text">{item.Sessions}</span><br/>
            <label>Tu membresía expira</label><br/>
            <Badge variant="secondary">{item.Expiration_Date_Membership_Day}/{item.Expiration_Date_Membership_Month}/{item.Expiration_Date_Membership_Year}</Badge>
            </div>
            </Col>
          </Row>
        </Container>


          ))}

</div>
  </Gray_Template>
    );
  }
}

export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({

              edit: state.edit.edit,
    }),
    //mapeo de funciones
    {
        Edit
        }
)(App);
