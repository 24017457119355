import React, { useState, useEffect } from 'react';
import * as URL from '../../../Constants/services'
import axios from 'axios';



export const useCreditCard = ( { setIsLoading} ) =>{
  const [data, setData] = useState();
  const [installments, setInstallments] = useState();
  const [planIsAvailable, setPlanIsAvailable] =useState(null);
  const [intentId, setIntentId] = useState();
  const [ confirmPaymentStatus, setConfirmPaymentStatus ] = useState(null);
  const [paymentDoneCorrect, setPaymentDoneCorrect ] =useState(false);
  const [paymentError, setPaymentError] = useState(false);

  const submit = (paymentId)=>{
    setIsLoading(true);
    if (paymentId) {
      const stripeData = {
        payment_id:paymentId,
        amount:320000,
        customer:"cus_LMNxrcrMojS8S4",
        typePayment:'card'
      }

      var url = URL.api+"/getPaymentIntentStripe";

          axios.post(url, stripeData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then(({data}) => {
        setInstallments(data.available_plans);
        setIntentId(data.intent_id);
        setIsLoading(false);
        if (data.available_plans.length===0) {
            console.log('false');
            setPlanIsAvailable(false);
        }
        else {
        setPlanIsAvailable(true);
        console.log('true');
        }
      })
    }
  }
  const confirmPayment = (selected_plan) =>{
    setIsLoading(true);
    const dataConfirmPayment = {
      selected_plan:selected_plan,
      payment_intent_id:intentId
    }

    //var url = URL.api+"/confirmPaymentIntentStripe";
    var url ="https://conversationhackers.com/service/models/payments/curlConfirmPaymentIntentStripe.php";
        axios.post(url, dataConfirmPayment, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
    .then(({data}) => {
      setIsLoading(false);
      if (data.status == "succeeded") {
        setPaymentDoneCorrect(true);
        console.log('complete');
      }else {
        setPaymentError(true)
        console.log('error');
      }
    })
  }

  return{
    data,
    installments,
    submit,
    paymentError,
    paymentDoneCorrect,
    planIsAvailable,
    intentId,
    confirmPayment
  }
}
