import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchGetPlaces} from '../../reducers/postsReducer';
import Button from 'react-bootstrap/Button'
import Fade from '../MainComponents/fade'
import Modal from 'react-bootstrap/Modal'
import Helpers from '../../lib/Helper'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from './Navbar';
import Images from '../../Constants/images'
import WhiteTemplate from '../MainComponents/White_Template'
import Form from 'react-bootstrap/Form'
import Desc from './Desc'
import GoogleMapReact from 'google-map-react';
import Scholarship from './Scholarship'
import Card_Audio from '../blog/cardPost'
import Zoom from 'react-reveal/Fade';
import * as Web from '../../Constants/Web'
import AudioCountry from './audioCountry'
var center= {
  lat: 3.5708914,
  lng: -78.2158566
}
var zoom= 3.46
class Places extends Component {
  constructor(props){
    super(props)
    this.state={
      uid:'8B57J61q4bM51u9oF7tKln340Fm2',
      url2:Web.wurl+'/',
      show:false,
      title:'',
      Navbar:null,
      Modal:false,
      image:'',
      imageFront:'',
      audio_url:'',
      openEdit:false,
      audios:'',
      editpu:false
    }


  }
  componentDidMount(){
    this.props.fetchGetPlaces('Get_Places.php');
    if(localStorage.getItem('userData')){
      let myItem = localStorage.getItem('userData')
      const info=JSON.parse(myItem)
      if(info.type=='MASTER'){
        //return (<Redirect to={'/admin'}/>)
        this.setState({editpu:true})
      }
      if(info.type=='admin'){
        this.setState({editpu:true})
      }

    }
    Helpers.GetHomepageInfo(this.state.uid,(des)=>{

 this.setState({
  Navbar:des.Navbar
 })
 })


  }
  openEdit(){
    this.setState({
      openEdit:!this.state.openEdit,
    })
  }
  Modal(e){
    Helpers.GetAudios(this.state.uid,e[0],(des)=>{
  this.setState({
  audio_url:des.Url,
  })
  })
    this.setState({title:e[0],image:e[1],imageFront:e[2]})
    this.setShow()
  }
setShow(){
  this.setState({Modal:!this.state.Modal})
}
  render() {
      const posts = this.props.placesall;
    const title=this.state.title

    return (
      <div>
      {this.state.Navbar?
            <Navbar edit={false} data={this.state.Navbar} />:null}


      <Container className="white-modal">
      <Modal
        show={this.state.Modal}
        className="transparent-blog-modal"
        onHide={() => this.setShow()}
      >
      <Modal.Header closeButton>
          <Modal.Title>
          <Row> <img src={this.state.image} className="img-50"/></Row></Modal.Title>
        </Modal.Header>
        <Modal.Body >
        <Card_Audio
          audioPodcast={this.state.audio_url}
          src={this.state.image}
          ImagePodcast={this.state.imageFront}
          SocialBar={false}
           />

        </Modal.Body>
      </Modal>

      <Modal
        show={this.state.openEdit}
        onHide={() => this.openEdit()}
      >
        <Modal.Body >
        <AudioCountry/>
        </Modal.Body>
      </Modal>



      <Row className="margin-top-200" >

        <Col >

            <div className="container-imgb overflow-scroll">
        <img src={Images.Countries.Maps} alt="cv_testimonio" />
        {/*-------------------------------usa*/}
        <div className="top-left-imgb  usa-icon pointer" onClick={()=>this.Modal(['Usa',Images.Countries.Us,Images.PeopleWorld.default])}>  <img
            src={Images.Countries.Us}
            className="img-5px"/></div>
        {/************************************************************/}
        {/*-------------------------------Mexico*/}
        <div className="top-left-imgb  mexico-icon pointer" onClick={()=>this.Modal(['Mexico',Images.Countries.Mx,Images.PeopleWorld.Mexico])}>  <img
            src={Images.Countries.Mx}
            className="img-5px"/></div>

            <div className="top-left-imgb  mexico-icon2 pointer" onClick={()=>this.Modal(['Mexico2',Images.Countries.Mx2,Images.PeopleWorld.Mexico2])}>  <img
                src={Images.Countries.Mx}
                className="img-5px"/></div>
    {/************************************************************/}

    {/*-------------------------------Guatemala*/}
    <div className="top-left-imgb  gu-icon pointer" onClick={()=>this.Modal(['Guatemala',Images.Countries.Gu,Images.PeopleWorld.Guatemala])}>  <img
        src={Images.Countries.Gu}
        className="img-5px"/></div>
{/************************************************************/}

    {/*-------------------------------Honduras*/}
    <div className="top-left-imgb  hon-icon pointer" onClick={()=>this.Modal(['Honduras',Images.Countries.Hon,Images.PeopleWorld.default])}>  <img
        src={Images.Countries.Hon}
        className="img-5px"/></div>
{/************************************************************/}
{/*-------------------------------el salvador*/}
<div className="top-left-imgb  salv-icon pointer" onClick={()=>this.Modal(['Salvador',Images.Countries.Hon,Images.PeopleWorld.default])}>  <img
    src={Images.Countries.Salv}
    className="img-5px"/></div>
{/************************************************************/}

    {/*-------------------------------COLOMBIA*/}
    <div className="top-left-imgb  col-icon pointer" onClick={()=>this.Modal(['Colombia',Images.Countries.Col,Images.PeopleWorld.Colombia])}>  <img
        src={Images.Countries.Col}
        className="img-5px"/></div>
{/************************************************************/}
{/*-------------------------------PERU*/}
<div className="top-left-imgb  peru-icon pointer" onClick={()=>this.Modal(['Peru',Images.Countries.Per,Images.PeopleWorld.default])}>  <img
    src={Images.Countries.Per}
    className="img-5px"/></div>
{/************************************************************/}

{/*-------------------------------BOLIVIA*/}
<div className="top-left-imgb  bol-icon pointer" onClick={()=>this.Modal(['Bolivia',Images.Countries.Boli,Images.PeopleWorld.default])}>  <img
    src={Images.Countries.Boli}
    className="img-5px"/></div>
{/************************************************************/}

{/*-------------------------------ARGENTINA*/}
<div className="top-left-imgb  arg-icon pointer" onClick={()=>this.Modal(['Argentina',Images.Countries.Arg,Images.PeopleWorld.Argentina])}>  <img
    src={Images.Countries.Arg}
    className="img-5px"/></div>
{/************************************************************/}
{/*-------------------------------CHILE*/}
<div className="top-left-imgb  chile-icon pointer" onClick={()=>this.Modal(['Chile',Images.Countries.Ch,Images.PeopleWorld.Chile])}>  <img
    src={Images.Countries.Ch}
    className="img-5px"/></div>
{/************************************************************/}
        <div className="bottom-left-imgb black-text">
        </div>

        {/*<div className="top-right-imgb black-text">Top Right</div>*/}
        {/*<div className="bottom-right-imgb black-text">Bottom Right</div>*/}
        <div className="centered-imgb gray-text"> </div>

            </div>
          </Col>
          <img src={Images.Countries.Around} className="img-100-places"/>
          <img src={Images.Countries.Pres} className="img-50-places"/>
      </Row>





        <Row>
              <Col xl={4}><div className="card-place text-center pointer" onClick={this.onpressMx.bind(this)}>
              <img src={Images.Countries.PresMX} className="img-50 pointer" /></div></Col>
              <Col xl={4}><div className="card-place text-center pointer" onClick={this.onpressMxInfo.bind(this)}>
              {this.state.showMX==true?<img src={Images.Countries.PresMorelos}  className="img-50 pointer"/>:null}
              </div></Col>
              <Col xl={4}>{this.state.showMxInfo==true?
                posts.map((dato, key) => {
                  if (dato.Id_Place==21) {
                    return(

                      <div className="card-place text-center">

                       <div >
                        <img src={Images.Logos.Main} className="CH_bxl_Icono"/>
                        <h2> {dato.Name}</h2>
                        <p>{dato.Address}</p>
                        <p>{dato.Phone}</p>
                       </div>
                      </div>

                                          )
                  }

      	                    })


                :null}</Col>
        </Row>

        <Row>
              <Col  xl={4}><div className="card-place text-center">
              <img src={Images.Countries.Prescol} className="img-50 pointer" onClick={this.onpressCol.bind(this)}/></div></Col>
              <Col xl={4} ><div className="card-place text-center">
              {this.state.showCol==true?<img src={Images.Countries.PresBogota} onClick={this.onpressColInfo.bind(this)} className="img-50 pointer"/>:null}</div></Col>
              <Col xl={4}>{this.state.showColInfo==true?
                posts.map((dato, key) => {
                  if (dato.Id_Place==22) {
                    return(

                      <div className="card-place text-center">

                       <div >
                        <img src={Images.Logos.Main} className="CH_bxl_Icono"/>
                        <h2> {dato.Name}</h2>
                        <p>{dato.Address}</p>
                        <p>{dato.Phone}</p>
                       </div>
                      </div>

                                          )
                  }

                            })

                :null}</Col>
        </Row>





{this.state.editpu==true?
  <Button onClick={this.openEdit.bind(this)} >editar</Button>:null}
</Container>
</div>
    );
  }
  onpressMx(){
    this.setState({
      showMX:!this.state.showMX
    })
  }
  onpressMxInfo(){
    this.setState({
      showMxInfo:!this.state.showMxInfo
    })
  }onpressCol(){
    this.setState({
      showCol:!this.state.showCol
    })
  }onpressColInfo(){
    this.setState({
      showColInfo:!this.state.showColInfo
    })
  }
}

export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        placesall: state.posts.places,
    }),
    //mapeo de funciones
    {
        fetchGetPlaces
    }
)(Places);
