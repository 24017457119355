/////SETTINGS///
export const MEDIA="Galería";
export const ADMINS="Administradores";
export const ADVANCED="Avanzado";
export const STATES="Estados"
export const LEVELS="niveles"
export const CLASSROOMS="Salones"

//LOGIN PAGE//
export const STUDENT="Alumno"
export const TEACHER="Coach"
export const ADMIN="Administrador"
export const MASTER="Master"
export const EMAIL="Correo"
export const PASSWORD="contraseña"
export const LOGIN="Inciar"
///ADMIN MASTER///
export const HOME="Inicio"
export const PLACES="Sucursales"
export const MEMBERSHIPS="Membresías"
export const QUESTIONS="Preguntas"
export const ADDMEMBERSHIPS="Agregar Membresías"
export const NOTES="Reportes"
export const CONFIG="Configuración"
export const NOTIFICATIONS="notificaciones"
export const LOGOUT="Salir"
///STUDENTS///
////////////CREATE FORMS///////
export const addStateName="agregar estado"
export const addStateLink="addState"
export const addStateModal="Create_State"

///////sucursal////////////
export const addPlace="Agregar Sucursal"
