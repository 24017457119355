import React from 'react'
import classes from './version.module.css'

const Version = ()=>{
  return(
      <div className={classes.root}>
          <span className={classes.version}>v. {'3.8.3.1'}
            {' '}Última actualización 21/12/2021
          </span>
      </div>)
}
export default Version
