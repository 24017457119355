import React, { useState, useEffect} from 'react';
import { useOxxo } from '../../../../MT/hooks/StripePayments/useOxxo';
import Spinner from 'react-bootstrap/Spinner'
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import classes from './oxxo.module.css';
import * as URL from '../../../../Constants/services'
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';

const CheckoutForm = () => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [paymentDone, setPaymentDone] = useState(false);
  const talons = useOxxo();
  const { createPaymentIntent, clientSecret, isDisabled, name, email,
  setName,
  setEmail } = talons;
  const stripe = useStripe();
  const elements = useElements();


  const confirmOxxoPayment =  () =>{
    const names=name;
    const emails=email;
    if (paymentDone || !names || !emails) {
      console.log('noooooo');
      return null;
    }
    setIsLoading(true);

    const stripeData = {
      amount:250000,
      typePayment:'oxxo'
    }

    var url = URL.api+"/getPaymentIntentStripe";

        axios.post(url, stripeData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
    .then(({data}) => {
      const key=data.split('{"client_secret":')[1]
      const key2=key.replace("}","")
      const keySecret=key2.replace(/['"]+/g, '');
        console.log('tiene');
        stripe.confirmOxxoPayment(
          keySecret,{
            payment_method:{
              billing_details:{
                name:names,
                email:emails
              }
            }
          }
        ).then((e)=>{
          setPaymentDone(true);
          //setIsLoading(false)
        })


    })

  }


if (paymentDone) {
  return(
    <Alert variant="success">
      <Alert.Heading>Oxxo completo</Alert.Heading>
      <p>
      Mensaje
      </p>
      <hr />
      <p className="mb-0">
      Instrucciones
      </p>
  </Alert>
)
}
  if (isLoading) {
    return<div className={classes.spinner}><Spinner animation="border" /></div>
  }
  return (
    <div>
      <Form className={classes.form}>
      <Form.Group className="mb-3" controlId="formBasicEmail" >
        <Form.Label>Nombre</Form.Label>
        <Form.Control type="text" placeholder="Nombre" value={name} onChange={(e)=>setName(e.target.value)} />
      </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Correo</Form.Label>
          <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>
        <div className={classes.spinner}>
        <Button variant="light" size="sm" onClick={()=>confirmOxxoPayment()} disabled={isDisabled}>
          Generar
        </Button>
        </div>
      </Form>
    </div>
  );
};

const stripePromise = loadStripe('pk_test_51JkDtSJdpV2aKYNEj3YB3awHzgi7ow0CkkIlT3DZ6Sk0nRK5BE1bRMtuA7RSyKcJDVCqwYOcYONlvsCXwCKBkyVA00eokHgF5i');

const OxxoPayment = () => (
  <Elements stripe={stripePromise}>
  <div >
    <CheckoutForm />
  </div>
  </Elements>
);

export default OxxoPayment;
