import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Modal from 'react-bootstrap/Modal'
import {Link} from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import '../../Styles/Cardvideo.css';
import Videos from '../../Constants/videos'
import ModalFree from '../Homepage/Modal'
import * as LOGIN from '../../Constants/Menu'
import Images from '../../Constants/images';
import {Redirect} from 'react-router-dom';
import YouTube from 'react-youtube';
import Social from './social'

//php version 7.3.2

class CardPost extends Component {

  constructor(props){
          super(props);
          this.state={
              video:'',
              url:'',
              des:'',
              title:'',
              height:'390',
              width:'640',
              cols:'150',
              show:false,
              custom:false,
              url_video:'',
              audio_url:'',
              image_url_thumbnail:'',
            };

            this.OpenPost=this.OpenPost.bind(this);
            this.closePost=this.closePost.bind(this);
      }

      componentDidMount() {

          window.addEventListener("resize", this.resize.bind(this));
          this.resize();
      }

      resize() {
        //this.setState({hideNav: window.innerWidth <= 858});
  if(window.innerWidth<=800){
    this.setState({
      height:'200',
      width:'300',
      cols:'30'
    })
  }else {
    this.setState({
      height:'390',
      width:'640',
      cols:'100'
    })
  }
      }
      closePost(){
        this.setState({show:!this.state.show})
      }
      OpenPost(event){
        this.setState({show:true,data:event,
          url_video:event.url_video,
          title:event.title_video,
          des:event.desc_video,
          image_url_thumbnail:event.image_url_thumbnail,
          audio_url:event.audio_url
        })

      }
      OpenPostOnlyVideo(event){
        this.setState({show:true,
          url_video:event,
        })

      }
      OpenVideoFreeClass(){
        this.setState({
          show:true,
          custom:true
        })

      }

  render() {

    const opts = {
      height: this.state.height,
      width: this.state.width,
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 1
      }
    };
    return (
      <Container className="pointer center-text blog-card">

          <p className="blogTitle">{this.props.title}</p>
      {this.props.image?

          <Link to={'/inglesfacil/'+this.props.Id_video_conversation}>
          <div className="container-imgb text-center" onClick={()=>this.OpenPost(this.props.data)}>
        <img className=" img-post" src={this.props.image} />
        <div className="centered-img-blog gray-text"><img className=" img-50" src={Images.Blog.Text}/></div>
        <p className="text-initial">{this.props.desc}</p>
        </div>
        </Link>
        :null}


        {this.props.SocialBar==false?null:
        <Social Id_video_conversation={this.props.Id_video_conversation}/>}
        {this.props.postview==true?<p className="black-text">{this.props.desc}</p>:null}
    {/*
share div*/}



  </Container>


    );
  }
}

export default CardPost;
