import React,{useState,useEffect} from 'react'

import * as Web from '../../../../Constants/Web'
import NumberFormat from 'react-number-format';
const Total=(props)=>{
const {isMaster, data,idPlace, month,year,day}=props
const [totalReport,setTotal]=useState(0)

useEffect(()=>{
  const data={
    id:idPlace,
    year:year,
    month:month,
    day:day
  }
var url = Web.wurl+'/Api/Get_PaymentsTotal.php';
fetch(url, {
method: 'POST', // or 'PUT'
body: JSON.stringify(data), // data can be `string` or {object}!
header: {'Content-Type': 'application/json'}
}).then(res => res.json())
.catch(error => console.error('Error:', error))
.then(response => {
  setTotal(response.totalSum)
  });
},[data])
  return(<>
    {isMaster=="active"?
    <NumberFormat value={totalReport} displayType={'text'} thousandSeparator={true} prefix={'$'} />
    :null}
    </>)
}
export default Total
