import React,{useState,useEffect} from 'react'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import classes from './showSelected.module.css'
import * as Web from '../../../../Constants/Web'
import { cancelClass} from './cancelClass'
import {CancelClass} from '../Actions/'

const Selected=(props)=>{
const {id}=props;
const [isLoading, setIsLoading]=useState(true)
const [data, setData]=useState([])


const update = ()=>{
  setIsLoading(true)
  const data={
    id:id
  }
  var url = Web.wurl+'/Api/conversationHackers/Get_classes_selected.php';
  fetch(url, {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(data), // data can be `string` or {object}!
    header: {'Content-Type': 'application/json'}
  }).then(res => res.json())
  .catch(error => console.error('Error:', error))
  .then(response => {
    setData(response)
    setIsLoading(false)
  })
}
useEffect(()=>{
  update()
},[id])

if (isLoading) {
  return<p>Cargando...</p>
}
if (!data) {
  return <p>Cargando...</p>
}

const list=data.map((item)=>{
  //anio mes dia hora minutos segundos
  var from = new Date(item.year_agenda, item.month_agenda-1, item.day_agenda, item.hourFrom, item.minutesFrom, 0);
  var to = new Date(item.year_agenda, item.month_agenda-1, item.day_agenda, item.hourTo, item.minutesTo, 0);

  return(
          <tr  value={item}  key={item}>
            <td>Hora:{from.toLocaleTimeString()} {"-"} {to.toLocaleTimeString()}

            <br/>
            Salón:{item.Name_Classroom}<br/>
            Día:{item.Day} {item.day_agenda}/{item.month_agenda}/{item.year_agenda}<br/>
            Clase: {item.infoAgenda}
            </td>

            <td className={classes.td}>
            <CancelClass id_student={id} id_class={item.Id_Week_Agenda} update={()=>update()}/>
            </td>

          </tr>)


})
return(
<Table striped bordered hover>
  <thead>
    <tr>
      <th>Clase</th>
      <th>Acciones</th>
    </tr>
  </thead>
  <tbody className={classes.tableBody}>
  {list}
  </tbody>
</Table>)
}


export default Selected;
