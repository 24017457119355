import React,{ useEffect, useState} from 'react'
import * as URL from '../../../../Constants/services';



const StudentsOnThisClass = (props) =>{
 const {idClass}=props;
 const [students, setStudents]=useState([])


   useEffect(()=>{
     const data={
       idClass:idClass
     }
     if (!idClass) {

     }else {

     var url = URL.api+"/studentsOnThisClass";
     fetch(url, {
       method: 'POST', // or 'PUT'
       body: JSON.stringify(data), // data can be `string` or {object}!
       header: {'Content-Type': 'application/json'}
     }).then(res => res.json())
     .catch(error => console.error('Error:', error))
     .then(response => {
       setStudents(response);
     })
   }
   },[idClass])

  if (!students) {
    return;
  }

const list = students.map((item)=>{
  return(<div  value={item}  key={item}>
    <p className="CH_Text_list">{item.name} {item.lastName} {item.secondLastName}</p>
  </div>)
})

  return(
    <div>
      {list}
    </div>)

}
export default StudentsOnThisClass;
