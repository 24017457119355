import React, { Component } from 'react';
import COACH from '../Agenda/AgendaCoach'
import '../../Styles/App.css';
import place from '../../Media/Place.png'
import student from '../../Media/Student.png'
import coaches from '../../Media/Teacher.png'
import levels from '../../Media/Levels.png'
import * as Web from '../../Constants/Web'
import memberships from '../../Media/Membership.png'
import logo from '../../Media/Conversation_Hackers_Logo.png'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Gray_Template from '../MainComponents/Gray_Template'

class Home extends Component {

  constructor(props){
          super(props);
          this.state={
              url:Web.wurl+'/conversationhackers/',
            };
      }



  render() {
    //const CounterStudents=this.props.counterstudents
    //const CounterCoach=this.props.countercoaches
		//const Activelevels=this.props.activelevels
    //const Places=this.props.counterbirthdays

    return (
      <div>
      <COACH/>
          </div>
    );
  }
}

export default Home;
