import firebase from 'firebase';

class Helper {

    static AddBanner(userId,fet,obj) {
        let AddBanner = "Gallery/Banners/"+fet
        return firebase.database().ref(AddBanner).set(obj)
    }

    static AddPicture(userId,fet,obj) {
        let AddBanner = "Gallery/Pictures/"+fet
        return firebase.database().ref(AddBanner).set(obj)
    }


    static AddPictureHome(userId,fet,obj) {
        let AddHome = "Gallery/Home/Picture/"+fet
        return firebase.database().ref(AddHome).set(obj)
    }

    static AddPictureHomeCard(userId,card,obj) {
        let AddHome = "info/Homepage/cards/"+'card'+card
        return firebase.database().ref(AddHome).set(obj)
    }
    static AddPictureHomeCardTest(userId,card,obj) {
        let AddHome = "info/Homepage/testimonio/"+'card'+card
        return firebase.database().ref(AddHome).set(obj)
    }
    static AddPictureHomeFooter(userId,obj) {
        let AddHome = "info/Homepage/footer/"
        return firebase.database().ref(AddHome).set(obj)
    }
    static AddAudio(userId,country,obj) {
        let AddHome = "audios/Homepage/countries/"+country
        return firebase.database().ref(AddHome).set(obj)
    }
    static AddBlogAudio(userId,obj) {
        let AddPodcast = "blog/audio/"
        return firebase.database().ref(AddPodcast).push(obj)
    }
    static AddBlogImage(userId,obj) {
        let AddPodcast = "blog/image/"
        return firebase.database().ref(AddPodcast).push(obj)
    }
    static AddHomepagetitle(userId,title) {
        let AddInfo = "info/Homepage/title"
        return firebase.database().ref(AddInfo).set(title).then((response)=>{
          alert('Correcto')
        })
    }
    static AddHomepagesocial(userId,social) {
        let AddInfo = "info/Homepage/social"
        return firebase.database().ref(AddInfo).set(social).then((response)=>{
          alert('Correcto')
        })
    }
    static AddPictureLogo(userId,fet,obj) {
        let AddHome ='info/Homepage/Navbar'
        return firebase.database().ref(AddHome).update(obj)
    }



  static GetBanners(userId,callback){
    let Archivos = "Gallery/Banners"
    firebase.database().ref(Archivos).on('value', (snapshot)=> {
  let datos=[];
  if(snapshot.val()){
  datos = snapshot.val()
}
callback(datos)
  //callback(Nombre)
  })

}
static GetAudios(userId,country,callback){
  let Archivos = "audios/Homepage/countries/"+country
  firebase.database().ref(Archivos).on('value', (snapshot)=> {
let datos=[];
if(snapshot.val()){
datos = snapshot.val()
}
callback(datos)
//callback(Nombre)
})

}
static GetBlogAudios(userId,callback){
  let Archivos = "blog/audio/"
  firebase.database().ref(Archivos).on('value', (snapshot)=> {
let datos=[];
if(snapshot.val()){
datos = snapshot.val()
}
callback(datos)
//callback(Nombre)
})

}
static GetBannersHome(userId,callback){
  let banners = "Gallery/Home/Picture"
  let des=[]
  firebase.database().ref(banners).on('value', (snapshot)=> {
  snapshot.forEach(function(childSnapshot){
        des.push(childSnapshot.val())
        });
callback(des)
//callback(Nombre)
})

}
static AddHomepageInfo(userId,obj) {
    let AddInfo = "info/Homepage/Navbar"
    return firebase.database().ref(AddInfo).set(obj).then((response)=>{
      alert('Correcto')
    })
}
static UpdateHomepageInfo(userId,obj) {
    let AddInfo = "info/Homepage/Navbar"
    return firebase.database().ref(AddInfo).update(obj).then((response)=>{
      alert('Correcto')
    })
}
static GetHomepageInfo(userId,callback){
  let Archivos = "info/Homepage"
  firebase.database().ref(Archivos).on('value', (snapshot)=> {
let datos=[];
if(snapshot.val()){
datos = snapshot.val()
}
callback(datos)
//callback(Nombre)
})

}
static HomeCarousel(userId, callback){///llamar el nombre de usuario
    let userNamePath =  "Gallery/Home/Picture"
    let des=[];
    firebase.database().ref(userNamePath).once('value', function(snapshot){
      snapshot.forEach(function(childSnapshot){
          //key= childSnapshot.key,
        //  temp=childSnapshot.val().Descripcion
            des.push(childSnapshot.val())
            });
            callback(des)
            //alert(des.Descripcion)
    });
  }









}

export default Helper;
