import React, {useEffect, useState } from 'react';
import {VictoryChart, VictoryBar} from 'victory';
import * as URL from '../../../../Constants/services';
import classes from './selectedOnThisTime.module.css';
import List from '../List';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import SearchBarInput from '../SearchBarInput';
import TableContainer from '../TableContainer';
import Title from '../Titles';

const SelectedOnThisTime = (props) =>{
  const {startDate, endDate, place}= props;
  const [data, setData] = useState();
  const [dataNames, setDataNames]=useState([]);
  const [dataDiscounts, setDataDiscounts]=useState([]);
  const [array, setArray] = useState([]);
  const [arrayNames, setArrayNames] = useState([]);
  const [notFound, setNotFound]=useState([]);
  const [total, setTotal]= useState();
  const [searchTermNewStudents,setSearchTermNewStudent] = useState('');
  const [searchTermNamesPeriod, setSearchTermNamesPeriod] = useState('');
  const [searchTermDiscountData,setSearchTermDiscountData] = useState('');



  useEffect(()=>{
    /*SELECT * FROM `students` WHERE created BETWEEN '2021-10-00' AND '2021-10-31'
*/
      var url = URL.api+"/oldClassesByDate";//clases descontadas con fechas
      const inf={
        from:startDate,
        to:endDate,
        place:place
      }
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(inf), // data can be `string` or {object}!
        header: {'Content-Type': 'application/json'}
      }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {
        setDataDiscounts(response)
        response.map((item)=>{
          setArray(array=>[...array,[item.id,item.name, item.lastName+" "+item.secondLastName , item.date]])
        })
      })
      var urlCount = URL.api+"/oldClassesByDateCountStudents";
      const infCount={
        from:startDate,
        to:endDate,
        place:place
      }
      fetch(urlCount, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(infCount), // data can be `string` or {object}!
        header: {'Content-Type': 'application/json'}
      }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {
        setTotal(response)
      })
      var urlCountNames = URL.api+"/oldClassesByDateCountStudentsNames";
      const infCountNames={
        from:startDate,
        to:endDate,
        place:place
      }
      fetch(urlCountNames, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(infCountNames), // data can be `string` or {object}!
        header: {'Content-Type': 'application/json'}
      }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {
        setDataNames(response)
        response.map((item)=>{
          setArrayNames(array=>[...array,[item.id]])
        })
      })

      var urlNotFound = URL.api+"/oldClassesNotFoundStudents";
      fetch(urlNotFound, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(infCountNames), // data can be `string` or {object}!
        header: {'Content-Type': 'application/json'}
      }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {
        setNotFound(response)
        console.log(response.length);
        /*response.map((item)=>{
          setNotFound(array=>[...array,[item.id,item.name, item.lastName+" "+item.secondLastName, item.email]])
        })*/
      })







  },[startDate,endDate])
  if (!startDate || !endDate || !dataNames || !notFound || !dataDiscounts || !total ) {
    return'';
  }

  const list =notFound.filter((val)=>{
          if(searchTermNewStudents == ""){
            return val
          }
          if (!searchTermNewStudents) {
            return val;
          }
          else if(val.name.toLowerCase().includes(searchTermNewStudents.toLowerCase())){
            return val;
          }
        }).map((item,i)=>{
    return(<tr>
          <td>{item.id}</td>
          <td> {item.name}</td>
          <td>{item.lastName+" "+item.secondLastName}</td>
          <td>{item.email}</td>
          <td>{item.phone}</td>
          </tr>)
  })
  const listPeriodNames =dataNames.filter((val)=>{
          if(searchTermNamesPeriod == ""){
            return val
          }
          if (!searchTermNamesPeriod) {
            return val;
          }
          else if(val.name.toLowerCase().includes(searchTermNamesPeriod.toLowerCase())){
            return val;
          }
        }).map((item,i)=>{
    return(<tr>
          <td>{item.id}</td>
          <td> {item.name}</td>
          <td>{item.lastName+" "+item.secondLastName}</td>
          <td>{item.date}</td>
          <td>{item.oldSessions}</td>
          <td>{item.newSessions}</td>
          </tr>)
  })
  const listPeriodDiscountClass =dataDiscounts.filter((val)=>{
          if(searchTermDiscountData == ""){
            return val
          }
          if (!searchTermDiscountData) {
            return val;
          }
          else if(val.name.toLowerCase().includes(searchTermDiscountData.toLowerCase())){
            return val;
          }
        }).map((item,i)=>{
    return(<tr>
          <td>{item.id}</td>
          <td> {item.name}</td>
          <td>{item.lastName+" "+item.secondLastName}</td>
          <td>{item.email}</td>
          <td>{item.date}</td>
          <td>{item.oldSessions}</td>
          <td>{item.newSessions}</td>
          </tr>)
  })

  const headersDiscountData = [
    { label: "id", key: "id" },
    { label: "name", key: "name" },
    { label: "apellido", key: "lastName" },
    { label: "apellido", key: "secondLastName" },
    { label: "fecha", key: "date" },
    { label: "oldSessions", key: "oldSessions" },
    { label: "newSessions", key: "newSessions" }
  ];
  const headersAlreadyOnAgenda= [
    { label: "id", key: "id" },
    { label: "name", key: "name" },
    { label: "apellido", key: "lastName" },
    { label: "apellido", key: "secondLastName" },
    { label: "fecha", key: "date" },
    { label: "oldSessions", key: "oldSessions" },
    { label: "newSessions", key: "newSessions" }
  ];

  const headersNotOnAgenda= [
    { label: "id", key: "id" },
    { label: "name", key: "name" },
    { label: "apellido", key: "lastName" },
    { label: "apellido", key: "secondLastName" },
    { label: "correo", key: "email" },
    { label: "Telf", key: "phone" }
  ];



  return(
    <React.Fragment>

    <h1>Historial</h1>
    <TableContainer>
      <Title title={"Clubs descontados en este periodo:"+ array.length} />
    <SearchBarInput linkToExport={""} fileName={"Clubs_descontados"} data={dataDiscounts} headers={headersDiscountData} setSearchTerm={setSearchTermDiscountData}/>

    <Table>
    <Thead>
      <Tr>
        <Th>ID</Th>
        <Th>Nombre</Th>
        <Th>Apellido</Th>
        <Th>Fecha</Th>
        <Th>Antes(sesiones)</Th>
        <Th>Despues(sesiones)</Th>
      </Tr>
    </Thead>
    <Tbody>
      {listPeriodDiscountClass}
    </Tbody>
    </Table>
</TableContainer>


<TableContainer>
    <Title title={"Alumnos agendados en este periodo:"+total.length} />

        <SearchBarInput linkToExport={""} fileName={"Alumnos_agendados_en_este_periodo"} data={dataNames}headers={headersAlreadyOnAgenda}setSearchTerm={setSearchTermNamesPeriod}/>
        <Table>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Nombre</Th>
            <Th>Apellido</Th>
            <Th>Fecha</Th>
            <Th>Antes(sesiones)</Th>
            <Th>Despues(sesiones)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {listPeriodNames}
        </Tbody>
      </Table>
      </TableContainer>

      <TableContainer>
    <Title title={"Alumnos no agendados en este periodo:" +notFound.length} />

    <SearchBarInput linkToExport={""} fileName={"alumnos_no_agendados_en_este_periodo"} data={notFound}headers={headersNotOnAgenda} setSearchTerm={setSearchTermNewStudent}/>
    <Table>
    <Thead>
      <Tr>
        <Th>ID</Th>
        <Th>Nombre</Th>
        <Th>Apellido</Th>
        <Th>Correo</Th>
        <Th>Telf</Th>
      </Tr>
    </Thead>
    <Tbody>
      {list}
    </Tbody>
  </Table>
  </TableContainer>
    </React.Fragment>
  )
}
export default SelectedOnThisTime;
