import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../Styles/App.css';

class Gray_Template extends Component {



  render(){
    const {children}=this.props;
		return(
        <Container className="width_div">

    {children}


</Container>

			)
    }






}

export default Gray_Template;
