import React from "react";
import ReactDOM from "react-dom";
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Helpers from '../../../lib/Helper'
import firebase from 'firebase'
import classes from './addFiles.module.css'
import {createDay} from './Actions/createDay'
class IncorporationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      //shareholders: [{ name: "" }],
      //shareholders: [{ name: "" },{title:""},{desc:""},{imageUrl:""},{audiourl:""},{videoUrl:""}]
      shareholders: [],
      thumbnail:'',
      title:'',
      loader:false

    };
    this.upload=this.upload.bind(this);
    this.handleAddVideo=this.handleAddVideo.bind(this)
  }


  handleAddVideo = (e) => {
    alert(e)
    this.setState({
      shareholders: this.state.shareholders.concat([{ fileUrl: e }])
    });
  };
  componentDidMount(){
    if (this.props.filesToShow) {
      const files= JSON.parse(this.props.filesToShow)
      //const files=this.props.filesToShow
      this.setState({
        shareholders: files
      });
    }
  }

componentDidUpdate(){
  this.props.callbackFiles(this.state.shareholders)
}


  handleChangefile = idx => evt => {
    const newShareholders = this.state.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, fileUrl:  evt.target.value?evt.target.value:' ' };
    });
    this.setState({ shareholders: newShareholders });
  };

  handleSubmit = evt => {
    const { name, shareholders } = this.state;
    if (this.state.thumbnail&&this.state.title) {

      this.props.callback(shareholders, this.state.thumbnail, this.state.title);
    }else {
      alert("llena todos los datos")
    }

  //  alert(`Incorporated: ${name} with ${shareholders.length} shareholders`);
  };



  handleRemoveShareholder = idx => () => {

    try {
      this.setState({
        shareholders: this.state.shareholders.filter((s, sidx) => idx !== sidx)
      });
      //this.props.callbackFiles(this.state.shareholders)
      //this.props.deleteFile(this.state.shareholders,idx)
    } catch (e) {
      alert(e)
    }


  };


  upload(event){
      const  fechaFile =  new Date();
      var segundosFile = fechaFile.getSeconds();
      var minutosFile = fechaFile.getMinutes();
      var horasFile = fechaFile.getHours();
      var diaFile = fechaFile.getDate();



  const  fecha = new Date(this.props.day);
    var segundos = fecha.getSeconds();
    var minutos = fecha.getMinutes();
    var horas = fecha.getHours();
    var anio = fecha.getFullYear();
    var mes = fecha.getMonth()+1;
    var dia = fecha.getDate();
    this.setState({loader:true})
      const file =event.target.files[0]
      const storageRef = firebase.storage().ref("files/"+anio+"/"+"/"+mes+"/"+dia+"/"+diaFile+horasFile+minutosFile+segundosFile+file.name)
      const task = storageRef.put(file)
   task.on('state_changed', (snapshot) => {
        let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      }, (error) => {
        alert("hubo un error, vuelve a intentarlo.");
        this.setState({loader:false})
      },() =>{
    task.snapshot.ref.getDownloadURL().then((downloadURL)=> {

          this.setState({
            shareholders: this.state.shareholders.concat([{ fileUrl:downloadURL }])
          });
          this.props.callbackFiles(this.state.shareholders)
          this.setState({loader:false})
        })
              });
  }


  render() {
    return (
      <Row  className={classes.child}>
      <h3>Archivos</h3>
            {this.state.shareholders.map((shareholder, idx) => (
              <div className="shareholder">
              {shareholder.fileUrl?<a target="_blank" href={shareholder.fileUrl}>{'Archivo:'+(idx+1)}</a>
              :null}
              {/*  <input
                  type="text"
                  placeholder={`Shareholder #${idx + 1} name`}
                  value={shareholder.fileUrl}
                  onChange={this.handleChangefile(idx)}
                />*/}

                <Button
                onClick={this.handleRemoveShareholder(idx)}
                variant="outline-danger">
                  ELIMINAR
                </Button>
              </div>
            ))}

            {this.state.loader?<h5>Cargando...</h5>:null}
            <input type="file" onChange={this.upload.bind(this)}/>


           </Row>
    );
  }
}
export default  IncorporationForm ;
