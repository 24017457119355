import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CardPost from '../../blog/cardPost'
import Images from '../../../Constants/images'
import Scholarship from '../Scholarship'
import Videos from '../../../Constants/videos'
import ButtonFreeClass from '../ButtonFreeClass'

function Footer(){
  return (
    <div className="text-left">
<Row >
      <Col xs={6}>
      <video className="video-free"   preload="none"  poster={Videos.HomePage.Testimonio2Poster}controls src={Videos.HomePage.Testimonio2} />  </Col>
      <Col xs={6}>
{/*<CardPost SocialBar={false} videoFreeClass={"https://www.youtube.com/watch?v=2_48HLwe0no"} centerimg={Images.Blog.Click}/>
*/}
      <video className="video-free"  preload="none"   poster={Videos.HomePage.Testimonio1Poster}controls src={Videos.HomePage.Testimonio1} /></Col>
    </Row>
    <Row>
    <Col xl={4} >
    <img src={Images.FreeClass.Leftside} className="img-100"/>
    </Col>
    <Col xl={4} >
    <Scholarship hiddenButton={true}/>
    </Col>
    <Col xl={4} >
    <img src={Images.FreeClass.Rightside} className="img-100"/>
    <img src={Images.FreeClass.Rightside2} className="img-100"/>
    </Col>

    <Row>
    <Col xl={2}></Col>
    <Col xl={8}>
    <img src={Images.FreeClass.Lograr} className="img-100"/>

    <video className="video-free"   preload="none" poster={Videos.HomePage.LograrasPoster} controls src={Videos.HomePage.Lograras} />

    </Col>
      <Col xl={2}></Col>
    </Row>


</Row>



    </div>
  );
}

export default Footer;
