import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Helpers from '../../lib/Helper'
class CardsAbout extends Component {
  constructor(props){
    super(props)
    this.state={
      uid:'8B57J61q4bM51u9oF7tKln340Fm2',
      show:false,
      title:this.props.data
    }
    this.openText=this.openText.bind(this)
    this.saveText=this.saveText.bind(this)
    this.title=this.title.bind(this)
    this.cancelText=this.cancelText.bind(this)
    this.prede=this.prede.bind(this)
  }

  openText(){
    this.setState({show:!this.state.show})
  }
  title(e){
    this.setState({
      title:e.target.value
    })
  }
  cancelText(){
    if(this.props.data){
      this.setState({
        title:this.props.data.title
      })
    }else {
      this.setState({
        title:'"Innovamos la forma de aprender Inglés"'
      })

    }
  }
  prede(){
    this.setState({
      title:'"Innovamos la forma de aprender Inglés"'
    })
    const data={
      title:'"Innovamos la forma de aprender Inglés"'
    }
    Helpers.AddHomepagetitle(this.state.uid,data)
  }
  saveText(){
    const data={
      title:this.state.title
    }
    Helpers.AddHomepagetitle(this.state.uid,data)
  }
  render() {
    return (
    	<div >
      <Modal show={this.state.show} onHide={this.openText}>
        <Modal.Header closeButton>
          <Modal.Title>título</Modal.Title>
        </Modal.Header>
        <input type='text' value={this.state.title} onChange={this.title}/>
        <Modal.Footer>
          <Button variant="danger" onClick={this.cancelText}>
            Cancelar
          </Button>
          <Button variant="secondary" onClick={this.prede}>
            Prede
          </Button>
          <Button variant="primary" onClick={this.saveText}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    			<div className="row" id="Menu">
    				<div className="col navMenu">
            {this.props.edit==true?	<h2 className="text-center pointer" onClick={this.openText}>
            {this.state.title?<span className="blueText">{this.state.title}</span>:<span className="blueText">"Innovamos la forma de aprender Inglés"</span>}</h2>:
            <h2 className="text-center pointer">
            {this.state.title?<span className="blueText">{this.state.title}</span>:<span className="blueText">"Innovamos la forma de aprender Inglés"</span>}</h2>
         }
    	     		 </div>
    			</div>

    		</div>
    );
  }
}

export default CardsAbout;
