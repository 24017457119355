
import { connect } from 'react-redux';
import { fetchGetPost, fetchAddPost, fetchUpdatePost, fetchDeletePost } from '../../../reducers/postsReducer';
import React, { Component } from 'react';
import '../../../Styles/App.css';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Form'
 import Loader from 'react-loader-spinner'
import Col from 'react-bootstrap/Col'
import * as Web from '../../../Constants/Web'
import * as Student from '../../../Constants/Forms'
var Day=[];
var Month=[]
var years=[]

class App extends Component {

  constructor(props){
          super(props);
          this.state={
          url:Web.wurl+'/',
            Membership:[],
            levels:[],
              quantity:'',
              time:[],
              Loader:true,
              NameStudent:'',
              LastName:'',
              LastName2:'',
              Day_Birthdate:'',
              Month_Birthdate:'',
              Year_Birthdate:'',
              CellPhone:'',
              Phone:'',
              Email:'',
              Password:'',
              PasswordVerification:'',
              Street:'',
              Col:'',
              Number:'',
              InsideNumber:'',
              Zip:'',
              City:'',
              CountryName:'',
              CountryCode:'',
              CountryTimeZone:'',
              State:'',
              Picture:'',
              Level:'',
              MembershipSelected:'',
              Expiration_Day:'',
              Expiration_Month:'',
              Expiration_Year:'',
              img:'',
              passwordmsg:'',
              length:'',
              quantity:'',
              event:[]
            };
            this.quantity=this.quantity.bind(this)
            this.handleChangeValue=this.handleChangeValue.bind(this);
            this.Country=this.Country.bind(this)
      }
componentDidMount(){
  for (var i = 1960; i < 2050; i++) {
  years.push(i)
  }
  for (var i = 0; i <=12; i++) {
  Month.push(i)
  }
  for (var i = 0; i <=31; i++) {
  Day.push(i)
  }
  fetch(this.state.url+"Api/Get_ActiveMemberships.php")
  .then((response)=>response.json())
  .then((responseJson)=>
  {
  this.setState({Membership:responseJson});
  fetch(this.state.url+"Api/Get_ActiveLevels.php")
  .then((response)=>response.json())
  .then((responseJson)=>
  {
  this.setState({Levels:responseJson});
  fetch(this.state.url+"Api/Get_AllTimezones.php")
  .then((response)=>response.json())
  .then((responseJson)=>
  {
  this.setState({time:responseJson,Loader:false});
  })
  })
})





}

quantity(e){
  ///to save qunatity

  this.setState({
    quantity:e[0]
  })

}
Country(e){
  this.setState({
    CountryName:e.Country,
    CountryCode:e.Country_Code
  })

}
handleChangeValue(e){
  this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
}
button(){
  return(
  <div>
  {this.state.passwordmsg?<label className="ch-wrong-text">las claves no concuerdan</label>:null}
  <br/>{this.state.emailmsg?<label className="ch-wrong-text">este correo ya existe</label>:null}
  <br/>{this.state.length?<label className="ch-wrong-text">la contrasena debe ser mayo a 8 caracteres</label>:null}
  <br/><Button className="btn btn-success"  onClick={this.save.bind(this)}>
    Guardar
  </Button>
    </div>
  )
}


form(membership,levels,time){
  return(
    <div>
    <Form  className="student-form">
    <p className="ch-black-bgtext">{Student.PersonalInformation}</p>
    <hr/>
    <p className="ch-black-text">{Student.Email}</p>
    <hr/>
    <Form.Row>
    <Form.Group as={Col} controlId="formGridEmail">
      <Form.Label>{Student.Email}</Form.Label>
      <Form.Control onBlur={this.handleChangeValue} value={this.state.event.Email} name="Email" type="email" placeholder={Student.Email} />
    </Form.Group>

    <Form.Group as={Col} controlId="formGridPassword">
      <Form.Label>{Student.Password}</Form.Label>
      <Form.Control onBlur={this.handleChangeValue} value={this.state.event.Password} name="Password" type="password" placeholder={Student.Password} />
    </Form.Group>
    <Form.Group as={Col} controlId="formGridPassword">
      <Form.Label>{Student.PasswordVerification}</Form.Label>
      <Form.Control onBlur={this.handleChangeValue} value={this.state.event.PasswordVerification} name="PasswordVerification" type="password" placeholder={Student.PasswordVerification} />
    </Form.Group>
    </Form.Row>


    <Form.Row>
    <Form.Group as={Col} controlId="formGridCity">
      <Form.Label>{Student.NameStudent}</Form.Label>
      <Form.Control  onBlur={this.handleChangeValue} value={this.state.event.NameStudent} name="NameStudent" placeholder={Student.NameStudent}/>
    </Form.Group>

    <Form.Group as={Col} controlId="formGridCity">
      <Form.Label>{Student.LastName}</Form.Label>
      <Form.Control  onBlur={this.handleChangeValue} value={this.state.event.LastName} name="LastName" placeholder={Student.LastName}/>
    </Form.Group>

    <Form.Group as={Col} controlId="formGridCity">
      <Form.Label>{Student.LastName2}</Form.Label>
      <Form.Control  onBlur={this.handleChangeValue} value={this.state.event.LastName2} name="LastName2" placeholder={Student.LastName2}/>
    </Form.Group>
    </Form.Row>
    <p className="ch-black-text">{Student.Birthdate}</p>
    <hr/>
    <Form.Row>
    <Form.Group as={Col} controlId="formGridCity">
    <Form.Label>{Student.Day_Birthdate}</Form.Label>
    <Form.Control as="select" onChange={this.handleChangeValue} value={this.state.event.Day_Birthdate} name="Day_Birthdate">
    {Day.map(item => (
          <option value={item}  key={item}>{item}</option>
        ))}
    </Form.Control >

    </Form.Group>

    <Form.Group as={Col} controlId="formGridCity">
    <Form.Label>{Student.Month_Birthdate}</Form.Label>
    <Form.Control as="select" onChange={this.handleChangeValue} value={this.state.event.Month_Birthdate} name="Month_Birthdate">
    {Month.map(item => (
          <option value={item} key={item}>{item}</option>
        ))}
    </Form.Control >
    </Form.Group>
    <Form.Group as={Col} controlId="formGridCity">
    <Form.Label>{Student.Year_Birthdate}</Form.Label>
    <Form.Control as="select" onChange={this.handleChangeValue} value={this.state.event.Year_Birthdate} name="Year_Birthdate">
    {years.map(item => (
          <option value={item} key={item}>{item}</option>
        ))}
    </Form.Control >
    </Form.Group>

    </Form.Row>
    <p className="ch-black-text">Mobile</p>
    <hr/>
    <Form.Row>
    <Form.Group as={Col} controlId="formGridCity">
    <Form.Label>{Student.CellPhone}</Form.Label>
    <Form.Control  onBlur={this.handleChangeValue} value={this.state.event.CellPhone} name="CellPhone" placeholder={Student.CellPhone}/>
    </Form.Group>

    <Form.Group as={Col} controlId="formGridCity">
    <Form.Label>{Student.Phone}</Form.Label>
    <Form.Control  onBlur={this.handleChangeValue}value={this.state.event.Phone} name="Phone" placeholder={Student.Phone}/>
    </Form.Group>
    </Form.Row>

    <p className="ch-black-text">{Student.Address}</p>
    <hr/>
    <Form.Row>
    <Form.Group as={Col} controlId="formGridCity">
      <Form.Label>{Student.Street}</Form.Label>
      <Form.Control onBlur={this.handleChangeValue} value={this.state.event.Street} name="Street" placeholder={Student.Street}/>
    </Form.Group>

    <Form.Group as={Col} controlId="formGridCity">
      <Form.Label>{Student.Col}</Form.Label>
      <Form.Control  onBlur={this.handleChangeValue} value={this.state.event.Col} name="Col" placeholder={Student.Col}/>
    </Form.Group>
    <Form.Group as={Col} controlId="formGridCity">
      <Form.Label>{Student.Number}</Form.Label>
      <Form.Control  onBlur={this.handleChangeValue}  value={this.state.event.Number}name="Number" placeholder={Student.Number}/>
    </Form.Group>
    <Form.Group as={Col} controlId="formGridCity">
      <Form.Label>{Student.InsideNumber}</Form.Label>
      <Form.Control  onBlur={this.handleChangeValue} value={this.state.event.InsideNumber} name="InsideNumber" placeholder={Student.InsideNumber}/>
    </Form.Group>


    </Form.Row>

    {/*---------------------------------country-----------------------------------------------------*/}
    <Form.Row>
    <Form.Group as={Col} controlId="formGridZip" >
    <Form.Label>{Student.City}</Form.Label>
    <Form.Control onBlur={this.handleChangeValue} value={this.state.event.City} name="City" placeholder={Student.City}/>
    </Form.Group>


    <Form.Group as={Col} controlId="formGridState">
    <Form.Label>{Student.State}</Form.Label>
    <Form.Control  onBlur={this.handleChangeValue} value={this.state.event.State} name="State" placeholder={Student.State}/>
    </Form.Group>

    <Form.Group as={Col} controlId="formGridZip" >
    <Form.Label>{Student.Zip}</Form.Label>
    <Form.Control onBlur={this.handleChangeValue} value={this.state.event.Zip} name="Zip" placeholder={Student.Zip}/>
    </Form.Group>
    </Form.Row>
    <Form.Row>
    <Form.Group as={Col} controlId="formGridCity">
    <Form.Label>{Student.Country}</Form.Label>
    <Form.Control as="select" onChange={this.handleChangeValue} >
    {time.map(item => (
        <option value={item} onClick={() => this.Country(item)} key={item}>{item.Country}</option>
      ))}
    </Form.Control >

    </Form.Group>
    </Form.Row>

    {/*--------------------------------------------------------------------------------------*/}

    <p className="ch-black-text">{Student.Education}</p>
    <hr/>
    <Form.Row>
            {membership?
              <Form.Group as={Col} controlId="formGridZip" >
                <Form.Label>{Student.Membership}</Form.Label>
                <Form.Control as="select" onBlur={this.handleChangeValue} value={this.state.event.MembershipSelected} name="MembershipSelected" placeholder="Membership">
                <option ></option>
                {membership.map(item => (
                      <option value={item.Id_Membership} onClick={() => this.quantity([item.quantity])}  key={item}> {item.Name} </option>
                    ))}
                </Form.Control >
                    </Form.Group>:<label className="ch-wrong-text">{Student.EmptyMemberships}</label>}


                    {levels?
                    <Form.Group as={Col} controlId="formGridZip" >
                      <Form.Label>{Student.Level}</Form.Label>
                      <Form.Control as="select" onBlur={this.handleChangeValue}  value={this.state.event.Level} name="Level" placeholder={Student.Level}>
                      <option ></option>
                      {levels.map(item => (
                            <option value={item.Id_Level}  key={item}>{item.Name}</option>
                          ))}
                      </Form.Control >
                          </Form.Group>:<label className="ch-wrong-text">{Student.EmptyLevels}</label>}

    </Form.Row>

    <p className="ch-black-text">{Student.StatusMembership}</p>
    <hr/>
    <Form.Row>
    <Form.Group as={Col} controlId="formGridCity">
    <Form.Label>{Student.Expiration_Day}</Form.Label>
    <Form.Control as="select" onChange={this.handleChangeValue} value={this.state.event.Expiration_Day}name="Expiration_Day">
    {Day.map(item => (
        <option value={item}  key={item}>{item}</option>
      ))}
    </Form.Control >

    </Form.Group>

    <Form.Group as={Col} controlId="formGridCity">
    <Form.Label>{Student.Expiration_Month}</Form.Label>
    <Form.Control as="select" onChange={this.handleChangeValue} value={this.state.event.Expiration_Month} name="Expiration_Month">
    {Month.map(item => (
        <option value={item} key={item}>{item}</option>
      ))}
    </Form.Control >

    </Form.Group>
    <Form.Group as={Col} controlId="formGridCity">
    <Form.Label>{Student.Expiration_Year} {this.state.Expiration_Year}</Form.Label>
    <Form.Control as="select" onChange={this.handleChangeValue} value={this.state.event.Expiration_Year} name="Expiration_Year">
    {years.map(item => (
          <option value={item} key={item}>{item}</option>
        ))}
    </Form.Control >
    </Form.Group>

    </Form.Row>

    </Form>

    </div>
  )
}
  render() {

    const membership = this.state.Membership;
    const levels =this.state.Levels
    const time =this.state.time

    return (
    <div>
    {this.state.Loader==true?<p>Cargando...</p>:
      <div>
      {this.form(membership,levels,time)}
      {this.button()}
      </div>
    }

        </div>
    );
  }
  save(){

  	if(this.state.Email&&this.state.Membership&&this.state.Level){
  this.setState({Loader:true})

    if(this.state.Password==this.state.PasswordVerification){
      //alert("bien")
      this.setState({
        passwordmsg:'',
      })

  if(this.state.Password.length>=8){
    //alert('correcto')
    this.setState({
      length:''
    })
  const data={
    Name:this.state.NameStudent,
    Last_Name:this.state.LastName,
    Second_Last_Name:this.state.LastName2,
    Day_Birthdate:this.state.Day_Birthdate,
    Month_Birthdate:this.state.Month_Birthdate,
    Year_Birthdate:this.state.Year_Birthdate,
    CellPhone:this.state.CellPhone,
    Phone:this.state.Phone,
    Email:this.state.Email,
    Password:this.state.Password,
    Street:this.state.Street,
    Col:this.state.Col,
    Number:this.state.Number,
    Inside_Number:this.state.InsideNumber,
    Zip:this.state.Zip,
    City:this.state.City,
    State:this.state.State,
    Picture:this.state.Picture,
    Level:this.state.Level,
    Membership:this.state.MembershipSelected,
    Place:this.props.id,
    Expiration_Date_Membership_Day:this.state.Expiration_Day,
    Expiration_Date_Membership_Month:this.state.Expiration_Month,
    Expiration_Date_Membership_Year:this.state.Expiration_Year,
  	Quantity:this.state.quantity,
    Country:this.state.CountryName,
    Country_Code:this.state.CountryCode

  }
  var addStudent = this.state.url+'Api/Create/Create_Studenttz.php';
  fetch(addStudent, {
  	method: 'POST', // or 'PUT'
  	body: JSON.stringify(data), // data can be `string` or {object}!
  	header: {'Content-Type': 'application/json'}
  }).then(res => {
    alert('Correcto')
  		this.setState({Loader:false,Add:''});

  })

  }else {

  	  alert('las contrasenas debe ser mayor a 8 ')
  	this.setState({
      length:'1',
        Password:'',
        PasswordVerification:'',
    })

  }


    }else {
  	  alert('las contrasenas no concuerdan')
      this.setState({
        Password:'',
        PasswordVerification:'',
        passwordmsg:'1',
  			Loader:false
      })
    }
  }else {
  	alert('llena todos los datos')
  }
  }
}

export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        fetchGetPost, fetchAddPost, fetchUpdatePost, fetchDeletePost
    }
)(App);
