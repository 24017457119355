import React, { Component } from 'react';
import '../../../Styles/App.css';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal'
import { connect } from 'react-redux';
import { UpdateActive,fetchGetPost,} from '../../../reducers/postsReducer';
import Button from 'react-bootstrap/Button'
 import Loader from 'react-loader-spinner'

 import check from '../../../Media/check.png'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import Col from 'react-bootstrap/Col'
import * as Web from '../../../Constants/Web'

const numbers = [1, 2, 3, 4, 5];


class CreateLevels extends Component {

  constructor(props){
          super(props);
          this.state={
            url:Web.wurl+'/',
              Name:'',
              Info:'',
              topay:'',
              paying:'',
              debt:'',
              calculating:'',
              loader:'',
              paymentsuc:false,
              sessions:'',

            };
            this.onChange=this.onChange.bind(this);
            this.debt=this.debt.bind(this)
            this.paystudent=this.paystudent.bind(this)
      }



componentWillMount(){
  //alert(this.props.id)
  this.setState({
    topay:this.props.Price

  })
}
onChange(e){
  this.setState({[e.target.name]:e.target.value,calculating:''})//el nombre seleccionaso lo guardara en el estado con el mismo nombre


}
debt(){
  var debt =this.state.topay-this.state.paying
  this.setState({
    debt:debt,
    calculating:'1'
  })
}



paystudent(){

    this.setState({loader:'1'})
  var paying=parseInt(this.state.paying);
  var topay=parseInt(this.state.topay)
  if(paying>topay){
  alert('no puedes ingresar más de lo solicitado')
  }
  else {


  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let numday= newDate.getDate();
  var day = newDate.getDay();
  var hour = newDate.getHours();
  var minutes = newDate.getMinutes();
  var hours=hour+':'+minutes
  var sessions=parseInt(this.props.sessions, 10);
  var addsessions=parseInt(this.props.addsessions, 10);
  var newsession=sessions+addsessions
  if(day=='0'){
    day="Domingo"
  }
  if(day=='1'){
    day="Lunes"
  }
  if(day=='2'){
    day="Martes"
  }
  if(day=='3'){
  day="Miercoles"
  }
  if(day=='4'){
    day="Jueves"
  }
  if(day=='5'){
    day="Viernes"
  }
  if(day=='6'){
    day="Sabado"
  }
  const datapayment={
    year:year,
    month:month,
    day:day,
    numday:numday,
    hour:hours,
    id:this.props.id,///id student
    price:this.state.paying,//////need to paying
    payed:this.state.paying,////gaved
    remaining:this.state.debt,///debt
    id_place_payment:this.props.place,///idPlace
    membership:this.props.membershipname//name membership
  }

  var fst = this.state.url+'Api/Put/Payment.php';
  fetch(fst, {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(datapayment), // data can be `string` or {object}!
    header: {'Content-Type': 'application/json'}
  }).catch(res => {
    alert('Hubo un error, intentas más tarde');
      this.setState({loader:''})
  }).then(res => {
    const datasessions={
      Id_Student:this.props.id,
      Sessions:newsession
    }
    var sessionupdate = this.state.url+'Api/Update/Update_Session.php';
    fetch(sessionupdate, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(datasessions), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).catch(res => {
      alert('Hubo un error, intentas más tarde');
        this.setState({loader:''})

    })
    .then(res => {
        this.setState({loader:'',paymentsuc:true,sessions:newsession})

        const data = {
              id: this.props.place
            }
      this.props.fetchGetPost('Get_Students.php',data);

    })


  })


}
}
closeModal(){
  this.setState({
    paymentsuc:false
  })
}
  render() {
      const post = this.props.posts;
    return (
    <div>
    <Modal
      size="sm"
      show={this.state.paymentsuc}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header >
      <Modal.Title id="example-modal-sizes-title-sm">
      Ahora cuenta con: {this.state.sessions}
        </Modal.Title>
        <Button variant="danger" onClick={this.closeModal.bind(this)}>Cerrar</Button>

      </Modal.Header>
      <Modal.Body>

            <img src={check} className='img-center2'/>
    <p>tu pago se realizó correctamente</p>
      </Modal.Body>
    </Modal>
    {this.state.loader?<div className="center2">
    <Loader
         type="Puff"
         color="#00BFFF"
         height="100"
         width="100"
      />
    </div>:
    <Form>
    <Row>
      <Col>
       <Form.Label>Ingresa $</Form.Label>
        <Form.Control  placeholder="Cobro" name="paying" onChange={this.onChange}/>
      </Col>
      <Col>
       <Form.Label>Cobra $</Form.Label>
        <Form.Control placeholder="Pago" value={this.props.Price}/>
      </Col>
    </Row>
    <br/><br/>
    <Row>
      <Col>
       <Form.Label>Restante$</Form.Label>
        <Form.Control placeholder="Pago"   value={this.state.debt}/>
{this.state.calculating?
        <Button variant="primary"  className="cv-button" onClick={this.paystudent}>
          Cobrar
        </Button>:<Button className="cv-button" variant="primary"  onClick={this.debt}>
          Calcular
        </Button>}
      </Col>
    </Row>
  </Form>}
        </div>
    );
  }
}
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        UpdateActive,fetchGetPost,
    }
)(CreateLevels);
