import { connect } from 'react-redux';
import { fetchGetPost,fetchGetPost2,fetchGetCoaches,fetchGetBirthdays,CounterStudents,CounterCouches,CounterBirthday,fetchAddPost, fetchUpdatePost, fetchDeletePost,Update,Edit } from '../../../reducers/postsReducer';
import React, { Component } from 'react';
import '../../../Styles/App.css';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import * as Web from '../../../Constants/Web'
import * as Student from '../../../Constants/Forms'
import { QRCode } from "react-qr-svg";
import Loader from 'react-loader-spinner'
import Clock from '../../Agenda/Clock'
var month = new Date().getMonth() + 1;
const Day=[null,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
const Month=[null,1,2,3,4,5,6,7,8,9,10,11,12]

 var items=[];
class App extends Component {

  constructor(props){
          super(props);
          this.state={
            url2:Web.wurl+'/',
            Memberships:[],
            levels:[],
            idStudent:'',
            idPlace:'',
            NameStudent:'',
            LastName:'',
            LastName2:'',
            Day_Birthdate:'',
            Month_Birthdate:'',
            Year_Birthdate:'',
            CellPhone:'',
            Phone:'',
            Email:'',
            Password:'',
            PasswordVerification:'',
            Street:'',
            Col:'',
            Number:'',
            InsideNumber:'',
            Zip:'',
            City:'',
            CountryName:'',
            CountryCode:'',
            CountryTimeZone:'',
            State:'',
            Picture:'',
            Level:'',
            Membership:'',
            Expiration_Day:'',
            Expiration_Month:'',
            Expiration_Year:'',
            img:'',
            Qr:'',
            passwordmsg:'',
            length:'',
            quantity:'',
            url:'Update/Update_Studenttz.php',
            comment:'',
            event:[],
            time:[],
            Loader:true,
            items:[],
            cate:'',
            editCountryState:false,


            };
            this.editCountry=this.editCountry.bind(this);

      }

      editCountry(){
        this.setState({
          editCountryState:!this.state.editCountryState
        })
      }
      Country(e){
        this.setState({
          CountryName:e.Country,
          CountryCode:e.Country_Code,
          CountryTimeZone:e.Time_zone
        })

      }
componentDidMount(){
  if(window.location.href=='http://localhost:3000/conversationplace'|| window.location.href=="https://www.conversationhackers.com/conversationplace"){
    this.setState({
      cate:'Admin'
    })
  }
  fetch(this.state.url2+"Api/Get_AllTimezones.php")
  .then((response)=>response.json())
  .then((responseJson)=>
  {
  this.setState({time:responseJson});
  const data = {
        id: this.props.data.id_Student
      }
      this.props.Edit('Get_Student.php',data);
      fetch(this.state.url2+"Api/Get_ActiveMemberships.php")
      .then((response)=>response.json())
      .then((responseJson)=>
      {
      this.setState({Memberships:responseJson});
      fetch(this.state.url2+"Api/Get_ActiveLevels.php")
      .then((response)=>response.json())
      .then((responseJson)=>
      {
      this.setState({Levels:responseJson});
      const data={
        id:this.props.data.id_Student
      }
      var url = this.state.url2+'Api/Get_Student.php';
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        header: {'Content-Type': 'application/json'}
      }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {
        this.com(response)
        this.setState({items:response,Loader:false})} );
      })
    })
  })







}
update(){
  const data = {
        id: this.state.idPlace,
        month:month
      }
this.props.fetchGetPost('Get_Students.php',data);
this.props.fetchGetPost2('Get_Place.php',data);
this.props.CounterStudents('CounterStudents.php',data);
this.props.CounterCouches('CounterCouches.php',data);
this.props.CounterBirthday('CounterBirthdays.php',data);
this.props.fetchGetCoaches('Get_PlaceCoaches.php',data);
this.props.fetchGetBirthdays('Get_PlaceBirthdays.php',data);
alert('Correcto')
this.setState({
  Loader:false
})


}

save(){
  this.setState({Loader:true})
  if(this.state.Password==this.state.PasswordVerification){
  //alert("bien")
    this.setState({
      passwordmsg:'',
    })

if(this.state.Password.length>=8){
  //alert('correcto')
  this.setState({
    length:''
  })
const data={
  Id:this.state.idStudent,
  Name:this.state.NameStudent,
  Last_Name:this.state.LastName,
  Second_Last_Name:this.state.LastName2,
  Day_Birthdate:this.state.Day_Birthdate,
  Month_Birthdate:this.state.Month_Birthdate,
  Year_Birthdate:this.state.Year_Birthdate,
  CellPhone:this.state.CellPhone,
  Phone:this.state.Phone,
  Email:this.state.Email,
  Password:this.state.Password,
  Street:this.state.Street,
  Col:this.state.Col,
  Number:this.state.Number,
  Inside_Number:this.state.InsideNumber,
  Zip:this.state.Zip,
  City:this.state.City,
  State:this.state.State,
  Level:this.state.Level,
  Membership:this.state.Membership,
  Place:this.state.idPlace,
  Expiration_Date_Membership_Day:this.state.Expiration_Day,
  Expiration_Date_Membership_Month:this.state.Expiration_Month,
  Expiration_Date_Membership_Year:this.state.Expiration_Year,
  Quantity:this.state.quantity,
  comment:this.state.comment,
  Time_zone:this.state.CountryTimeZone,
  Country:this.state.CountryName,
  Country_Code:this.state.CountryCode
}
//alert(JSON.stringify(data))
this.props.Update(data,this.state.url,);



}else {
  this.setState({
    length:'1',
      Password:'',
      PasswordVerification:''
  })

}


  }else {
    //alert('las contrasenas no concuerdan')
    this.setState({
      Password:'',
      PasswordVerification:'',
      passwordmsg:'1'
    })
  }

  this.update()
}
onChange(e){
  this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre

}
com(student){
  student.forEach((item, i) => {
    this.setState({
      idStudent:item.Id_Student,
      idPlace:item.Place,
      NameStudent:item.Name,
      LastName:item.Last_Name,
      LastName2:item.Second_Last_Name,
      Day_Birthdate:item.Day_Birthdate,
      Month_Birthdate:item.Month_Birthdate,
      Year_Birthdate:item.Year_Birthdate,
      CellPhone:item.CellPhone,
      Phone:item.Phone,
      Email:item.Email,
      Password:item.Password,
      PasswordVerification:item.Password,
      Street:item.Street,
      Col:item.Col,
      Number:item.Number,
      InsideNumber:item.Inside_Number,
      Zip:item.Zip,
      City:item.City,
      CountryName:item.Country,
      CountryCode:item.Country_Code,
      CountryTimeZone:item.Time_zone,
      State:item.State,
      Level:item.Level,
      Level_Name:item.Level_Name,
      Membership:item.Membership,
      Name_Membership:item.Name_Membership,
      Expiration_Day:item.Expiration_Date_Membership_Day,
      Expiration_Month:item.Expiration_Date_Membership_Month,
      Expiration_Year:item.Expiration_Date_Membership_Year,
      quantity:item.Sessions,
      comment:item.Comment_student
    })

  });
}
form(student,membership,levels,info,time,nums){

  return(
    <Form  className="student-form">
    <p className="ch-black-bgtext">{Student.PersonalInformation}</p>
    <hr/>
    <p className="ch-black-text">{Student.Email}</p>
    <hr/>
  <Form.Row>
    <Form.Group as={Col} controlId="formGridEmail">
      <Form.Label>{Student.Email}</Form.Label>

          <Form.Control value={this.state.Email} onChange={this.onChange.bind(this)} name="Email" type="email" placeholder={Student.Email} />

      </Form.Group>
    <Form.Group as={Col} controlId="formGridPassword">
      <Form.Label>{Student.Password}</Form.Label>
      <Form.Control value={this.state.Password} onChange={this.onChange.bind(this)}  name="Password" type="password" placeholder={Student.Password} />
    </Form.Group>
    <Form.Group as={Col} controlId="formGridPassword">
      <Form.Label>{Student.PasswordVerification}</Form.Label>
      <Form.Control onChange={this.onChange.bind(this)} value={this.state.PasswordVerification} name="PasswordVerification" type="password" placeholder={Student.PasswordVerification} />
    </Form.Group>
  </Form.Row>


  <Form.Row>
    <Form.Group as={Col} controlId="formGridCity">
      <Form.Label>{Student.NameStudent}</Form.Label>
      <Form.Control value={this.state.NameStudent} onChange={this.onChange.bind(this)} name="NameStudent" placeholder={Student.NameStudent}/>
    </Form.Group>

    <Form.Group as={Col} controlId="formGridCity">
      <Form.Label>{Student.LastName}</Form.Label>
      <Form.Control  onChange={this.onChange.bind(this)} value={this.state.LastName} name="LastName" placeholder={Student.LastName}/>
    </Form.Group>

    <Form.Group as={Col} controlId="formGridCity">
      <Form.Label>{Student.LastName2}</Form.Label>
      <Form.Control value={this.state.LastName2} onChange={this.onChange.bind(this)} name="LastName2" placeholder={Student.LastName2}/>
    </Form.Group>
</Form.Row>
<p className="ch-black-text">{Student.Birthdate}</p>
<hr/>
<Form.Row>
  <Form.Group as={Col} controlId="formGridCity">
    <Form.Label>{Student.Day_Birthdate}</Form.Label>
    <Form.Control as="select" onChange={this.onChange.bind(this)} name="Day_Birthdate">
    <option value={this.state.Day_Birthdate} >{this.state.Day_Birthdate}</option>
    {Day.map(item => (
          <option value={item}  key={item}>{item}</option>
        ))}
    </Form.Control >

  </Form.Group>

  <Form.Group as={Col} controlId="formGridCity">
    <Form.Label>{Student.Month_Birthdate}</Form.Label>
    <Form.Control as="select" onChange={this.onChange.bind(this)} name="Month_Birthdate">
    <option value={this.state.Month_Birthdate} >{this.state.Month_Birthdate}</option>
    {Month.map(item => (
          <option value={item} key={item}>{item}</option>
        ))}
    </Form.Control >

  </Form.Group>
  <Form.Group as={Col} controlId="formGridCity">
    <Form.Label>{Student.Year_Birthdate}</Form.Label>
    <Form.Control  onChange={this.onChange.bind(this)} value={this.state.Year_Birthdate} name="Year_Birthdate" placeholder={Student.Year_Birthdate}/>
  </Form.Group>
</Form.Row>
<p className="ch-black-text">Mobile</p>
<hr/>
<Form.Row>
<Form.Group as={Col} controlId="formGridCity">
  <Form.Label>{Student.CellPhone}</Form.Label>
  <Form.Control value={this.state.CellPhone} onChange={this.onChange.bind(this)} name="CellPhone" placeholder={Student.CellPhone}/>
</Form.Group>

<Form.Group as={Col} controlId="formGridCity">
  <Form.Label>{Student.Phone}</Form.Label>
  <Form.Control value={this.state.Phone} onChange={this.onChange.bind(this)} name="Phone" placeholder={Student.Phone}/>
</Form.Group>
</Form.Row>

<p className="ch-black-text">{Student.Address}</p>
<hr/>
<Form.Row>
    <Form.Group as={Col} controlId="formGridCity">
      <Form.Label>{Student.Street}</Form.Label>
      <Form.Control value={this.state.Street} onChange={this.onChange.bind(this)} name="Street" placeholder={Student.Street}/>
    </Form.Group>

    <Form.Group as={Col} controlId="formGridCity">
      <Form.Label>{Student.Col}</Form.Label>
      <Form.Control value={this.state.Col} onChange={this.onChange.bind(this)} name="Col" placeholder={Student.Col}/>
    </Form.Group>
    <Form.Group as={Col} controlId="formGridCity">
      <Form.Label>{Student.Number}</Form.Label>
      <Form.Control value={this.state.Number} onChange={this.onChange.bind(this)} name="Number" placeholder={Student.Number}/>
    </Form.Group>
    <Form.Group as={Col} controlId="formGridCity">
      <Form.Label>{Student.InsideNumber}</Form.Label>
      <Form.Control value={this.state.InsideNumber}  onChange={this.onChange.bind(this)} name="InsideNumber" placeholder={Student.InsideNumber}/>
    </Form.Group>


</Form.Row>

<Form.Row>
<Form.Group as={Col} controlId="formGridZip" >
  <Form.Label>{Student.City}</Form.Label>
  <Form.Control value={this.state.City} onChange={this.onChange.bind(this)} name="City" placeholder={Student.City}/>
</Form.Group>


<Form.Group as={Col} controlId="formGridState">
  <Form.Label>{Student.State}</Form.Label>
  <Form.Control value={this.state.State} onChange={this.onChange.bind(this)} name="State" placeholder={Student.State}/>
</Form.Group>

<Form.Group as={Col} controlId="formGridZip" >
  <Form.Label>{Student.Zip}</Form.Label>
  <Form.Control value={this.state.Zip} onChange={this.onChange.bind(this)} name="Zip" placeholder={Student.Zip}/>
</Form.Group>
</Form.Row>
<Form.Row>

<Form.Label>{Student.Country}</Form.Label>
<Form.Control as="select" onChange={this.handleChangeValue} >
    <option >{this.state.CountryName}</option>
</Form.Control >
<p onClick={this.editCountry}>Editar</p>
{this.state.editCountryState || !this.state.CountryName?
<Clock ask={1} notModal={true} studentId={this.state.idStudent}/>:null}
{/*<Form.Group as={Col} controlId="formGridCity">
<Form.Label>{Student.Country}</Form.Label>
<Form.Control as="select" onChange={this.handleChangeValue} >
    <option >{this.state.CountryName}----{this.state.CountryTimeZone}</option>
{time.map(item => (
    <option value={item} onClick={() => this.Country(item)} key={item}>{item.Country}----{item.Time_zone}</option>
  ))}
</Form.Control >

</Form.Group>*/}

</Form.Row>



<p className="ch-black-text">{Student.Education}</p>
<hr/>
<Form.Row>
              {membership?
              <Form.Group as={Col} controlId="formGridZip" >
                <Form.Label>{Student.Membership}</Form.Label>
                <Form.Control as="select" onChange={this.onChange.bind(this)} name="Membership" placeholder="Membership">


                      <option value={this.state.Membership}  >(asignada){this.state.Name_Membership}</option>

                {membership.map(item => (
                      <option value={item.Id_Membership}   key={item}>{item.Name}</option>
                    ))}
                </Form.Control >
              <Form.Label>Sesiones Restantes</Form.Label>
              <Form.Control as="select" onChange={this.onChange.bind(this)} name="quantity">
              <option value={this.state.quantity} >{this.state.quantity}</option>
              </Form.Control>
                    </Form.Group>:<label className="ch-wrong-text">Primero debes agreagr tipos de membresias</label>}



                    {levels?
                    <Form.Group as={Col} controlId="formGridZip" >
                      <Form.Label>{Student.Level}</Form.Label>
                      <Form.Control as="select" onChange={this.onChange.bind(this)} name="Level" placeholder={Student.Level}>

                        <option value={this.state.Level}  >(asignada){this.state.Level_Name}</option>

                      {levels.map(item => (
                            <option value={item.Id_Level}  key={item}>{item.Name}</option>
                          ))}
                      </Form.Control >
                          </Form.Group>:<label className="ch-wrong-text">Primero debes agregar niveles</label>}

</Form.Row>

<p className="ch-black-text">{Student.StatusMembership}</p>
<hr/>
<Form.Row>
<Form.Group as={Col} controlId="formGridCity">
  <Form.Label>{Student.Expiration_Day}</Form.Label>
  <Form.Control as="select" onChange={this.onChange.bind(this)} name="Expiration_Day">
      <option value={this.state.Expiration_Day}  >{this.state.Expiration_Day}</option>
  {Day.map(item => (
        <option value={item}  key={item}>{item}</option>
      ))}
  </Form.Control >

</Form.Group>

<Form.Group as={Col} controlId="formGridCity">
  <Form.Label>{Student.Expiration_Month}</Form.Label>
  <Form.Control as="select" onChange={this.onChange.bind(this)} name="Expiration_Month">
  <option value={this.state.Expiration_Month} >{this.state.Expiration_Month}</option>
  {Month.map(item => (
        <option value={item} key={item}>{item}</option>
      ))}
  </Form.Control >

</Form.Group>

<Form.Group as={Col} controlId="formGridCity">
  <Form.Label>{Student.Expiration_Year}</Form.Label>
  <Form.Control value={this.state.Expiration_Year} onChange={this.onChange.bind(this)} name="Expiration_Year" placeholder={Student.Expiration_Year}/>
</Form.Group>
</Form.Row>
<Form.Row>
<Form.Group  as={Col} >
    <Form.Label>Comentario</Form.Label>
    <Form.Control value={this.state.comment} onChange={this.onChange.bind(this)} name="comment" as="textarea" rows="8" />
  </Form.Group>
</Form.Row>
</Form>
  )

}
  render() {
    ///alert(window.location.href)
    const nums=[];
    for (var i = 0; i < 1000; i++) {
      nums[i]=i
    }
    const membership = this.state.Memberships;
    const levels =this.state.Levels
    const student=this.props.edit
    const info =this.props.posts
    const time =this.state.time


    return (
    <div>
    {this.state.Loader==true?
      <div className="center2">
      <Loader
           type="Puff"
           color="#00BFFF"
           height="100"
           width="100"
        />
      </div>:
    <div>
    {this.state.Qr?
      <div className="Center" >
      <div className="Center2">
            </div></div>:null}
            {this.form(student,membership,levels,info,time,nums)}
{this.state.passwordmsg?<label className="ch-wrong-text">las claves no concuerdan</label>:null}
<br/>{this.state.emailmsg?<label className="ch-wrong-text">este correo ya existe</label>:null}
<br/>{this.state.length?<label className="ch-wrong-text">la contrasena debe ser mayo a 8 caracteres</label>:null}
<br/><Button variant="primary" type="submit" onClick={this.save.bind(this)}>
  Guardar
</Button>
</div>}
        </div>
    );
  }
}

export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
            edit: state.edit.edit,
            posts: state.posts.posts,
            place:state.datas.dato,
            placecoaches:state.placecoaches.placecoaches,
    				placebirthdays:state.placebirthdays.placebirthdays,
            counterstudents:state.counterstudents.counterstudents,
            countercoaches:state.countercoaches.countercoaches,
            counterbirthdays:state.counterbirthdays.counterbirthdays
    }),
    //mapeo de funciones
    {
        fetchGetPost,fetchGetPost2,fetchGetCoaches,fetchGetBirthdays,CounterStudents,CounterCouches,CounterBirthday, fetchAddPost, fetchUpdatePost, fetchDeletePost,Update,Edit
    }
)(App);
