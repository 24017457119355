import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import {Link} from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import '../../Styles/Cardvideo.css';
import * as LOGIN from '../../Constants/Menu'
import {Redirect} from 'react-router-dom';
import YouTube from 'react-youtube';

//php version 7.3.2

class LoginAdmin extends Component {

  constructor(props){
          super(props);
          this.state={
              video:'',
              url:'',
              des:'',
              title:'',
              height:'390',
              width:'640',
              cols:'150'
            };
      }

      componentDidMount() {
          window.addEventListener("resize", this.resize.bind(this));
          this.resize();
      }

      resize() {
        //this.setState({hideNav: window.innerWidth <= 858});
  if(window.innerWidth<=800){
    this.setState({
      height:'200',
      width:'300',
      cols:'30'
    })
  }else {
    this.setState({
      height:'390',
      width:'640',
      cols:'100'
    })
  }
      }



  render() {

    const opts = {
      height: this.state.height,
      width: this.state.width,
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0
      }
    };
    return (
      <Container>
      <Row>
      <Col sm >


        <div className="blog-body">
<hr/>
                <div className="blog-footer">
                  <ul>
                    <li className="published-date">{this.props.date}</li>
                  </ul>
                  <hr/>
                </div>
        <YouTube
                videoId={this.props.video}
                opts={opts}
                onReady={this._onReady}
              />
          <div className="blog-title">
            <h2>{this.props.title}</h2>
          </div>
          <hr/>
          <div className="blog-summary">
            <p rows="7" cols={this.state.cols} >{this.props.desc}</p>
          </div>
          <div className="blog-tags">
            <ul>
              <li><a className="pointer" href={this.props.url}>Click Aquí para más</a></li>
            </ul>
          </div>
        </div>


      </Col>
      </Row>
      </Container>

    );
  }
}

export default LoginAdmin;
