import React from 'react';
import classes from './searchBar.module.css';
import { CSVLink } from "react-csv";

const SearchBarInput = (props) =>{
 const {setSearchTerm,linkToExport,headers,data,fileName}=props;

 if (!headers) {
     return<p>cargando headers...</p>;
 }
 /*const headers = [
   { label: "First Name", key: "firstName" },
   { label: "Last Name", key: "lastName" },
   { label: "Email", key: "email" },
   { label: "Age", key: "age" }
 ];

 const data = [
   { firstName: "Warren", lastName: "Morrow", email: "sokyt@mailinator.com", age: "36" },
   { firstName: "Gwendolyn", lastName: "Galloway", email: "weciz@mailinator.com", age: "76" },
   { firstName: "Astra", lastName: "Wyatt", email: "quvyn@mailinator.com", age: "57" },
   { firstName: "Jasmine", lastName: "Wong", email: "toxazoc@mailinator.com", age: "42" },
   { firstName: "Brooke", lastName: "Mcconnell", email: "vyry@mailinator.com", age: "56" },
   { firstName: "Christen", lastName: "Haney", email: "pagevolal@mailinator.com", age: "23" },
   { firstName: "Tate", lastName: "Vega", email: "dycubo@mailinator.com", age: "87" },
   { firstName: "Amber", lastName: "Brady", email: "vyconixy@mailinator.com", age: "78" },
   { firstName: "Philip", lastName: "Whitfield", email: "velyfi@mailinator.com", age: "22" },
   { firstName: "Kitra", lastName: "Hammond", email: "fiwiloqu@mailinator.com", age: "35" },
   { firstName: "Charity", lastName: "Mathews", email: "fubigonero@mailinator.com", age: "63" }
 ];
*/
 const csvReport = {
   data: data,
   headers: headers,
   filename: fileName+'.csv'
 };

return(
<div className={classes.searchBarContainer}>
  <input type="text" className={classes.input} placeholder="Buscar..." onChange={(e)=>setSearchTerm(e.target.value)} />
        <CSVLink {...csvReport}> <img src="https://img1.freepng.es/20180319/ehw/kisspng-microsoft-excel-logo-microsoft-word-microsoft-offi-excel-png-office-xlsx-icon-5ab06a09a50152.6415810315215109216759.jpg" className={classes.icon}/>Export to CSV</CSVLink>
</div>)

}

export default SearchBarInput;
