import React, { Component } from 'react';
import Images from '../../Constants/images';
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import '../../Styles/App.css';
import * as Web from '../../Constants/Web'

class SOCIAL extends Component {

  constructor(props){
          super(props);
          this.state={
              url:[],
              url:Web.wurl+'/',
              url3:Web.wurl+'/inglesfacil/',
              videos:[],


            };
      }
  render() {


   const topics=this.state.topics
    return (
      <Row >
      <Col ><img className=" img-50" src={Images.Social.SHARE} /></Col>
      <Col ><a href={"https://api.whatsapp.com/send?text="+this.state.url3+this.props.Id_video_conversation}><img className=" img-50 pointer" src={Images.Social.WHATSAPPBLOG} /></a></Col>
      <Col ><a href={"https://facebook.com/sharer/sharer.php?u="+this.state.url3+this.props.Id_video_conversation}><img className=" img-50 pointer" src={Images.Social.FACEBBOKBLOG} /></a></Col>
      <Col ><a href={"https://twitter.com/share?url="+this.state.url3+this.props.Id_video_conversation}><img className=" img-50 pointer" src={Images.Social.TWITERBLOG} /></a></Col>
      <Col ><a href={"mailto:name@mail.com?subject=hey%20look&body="+this.state.url3+this.props.Id_video_conversation}><img className=" img-50 pointer" src={Images.Social.EMAILBLOG} /></a></Col>
      </Row>
    );
  }
}

export default SOCIAL
