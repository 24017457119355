import React, { Component } from 'react';
import '../../../Styles/App.css';
import Stat from '../../../Media/States.png'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import dateimg from '../../../Media/Calendar.png'
import Row from 'react-bootstrap/Row'
import { connect } from 'react-redux';
import { UpdateActive,fetchGetPost,} from '../../../reducers/postsReducer';
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import States from '../Forms/Create_State'
import SearchInput, {createFilter} from 'react-search-input';
import ListGroup from 'react-bootstrap/ListGroup'
import Table from 'react-bootstrap/Table'
import Add from '../../../Media/Add.png';
import * as Web from '../../../Constants/Web'
const KEYS_TO_FILTERS = ['Name','Last_Name','Second_Last_Name','id_Student','Day_Old','Coach'];
class ShowStates extends Component {


  constructor(props){
          super(props);
          this.state={
              url:Web.wurl+'/',
              datos:[],
            searchTerm: '',
            Id:'',
            edit:false,
            history:[],
            name:'',
            price:'',
            year:'',
            day:'',
            month:'',
            daylet:'',
            daynum:'',
            hour:'',
            member:''
            };
            this.onChange=this.onChange.bind(this);

      }


    async  componentDidMount(){

      var newDate = new Date()
      var date = newDate.getDate();
      var month = newDate.getMonth() + 1;
      var year = newDate.getFullYear();
      var numday= newDate.getDate();
      this.setState({
        date:numday+"/"+month+"/"+year
      })

            const data={
              day:numday,
              month:month,
              year:year,
              id:this.props.id
            }
            //alert(JSON.stringify(data))
      var url = this.state.url+'Api/Get_History.php';
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        header: {'Content-Type': 'application/json'}
      }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {this.setState({history:response})} );
}

     onChange(e){
       this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre

     }



     searchUpdated (term) {
      this.setState({searchTerm: term})
  }


  handleClose() {
  this.setState({ show: false,edit:false });
  }

  handleShow() {
  this.setState({ show: true });
  }


  onChange(e){
    this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
  }
  find(){
if(this.state.year&&this.state.month&&this.state.day){
  //alert('todos')
  const data={
    id:this.props.id,
    year:this.state.year,
    month:this.state.month,
    day:this.state.day
  }
var url = this.state.url+'Api/Get_HistoryAll.php';
fetch(url, {
method: 'POST', // or 'PUT'
body: JSON.stringify(data), // data can be `string` or {object}!
header: {'Content-Type': 'application/json'}
}).then(res => res.json())
.catch(error => console.error('Error:', error))
.then(response => {  this.setState({history:response})} );
}else {
  if(this.state.year&&this.state.month){
    //alert('anio y mes')
    const data={
      id:this.props.id,
      year:this.state.year,
      month:this.state.month,
    }
  var url = this.state.url+'Api/Get_Historyym.php';
  fetch(url, {
  method: 'POST', // or 'PUT'
  body: JSON.stringify(data), // data can be `string` or {object}!
  header: {'Content-Type': 'application/json'}
  }).then(res => res.json())
  .catch(error => console.error('Error:', error))
  .then(response => {  this.setState({history:response})} );


  }else {
    if(this.state.day&&this.state.year){
     alert('selecciona el mes')
    }else {
      if(this.state.year){
        //alert('year')
        const data={
          id:this.props.id,
          year:this.state.year
        }
      var url = this.state.url+'Api/Get_Historyy.php';
      fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
      }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {  this.setState({history:response})} );
      }

    }


  }

}
  }
look(){
  return(
    <div>
    <Form>
<Row>
<Col>
  <Form.Control name="year" placeholder="Año(campo obligatorio)" onChange={this.onChange}/>
</Col>
<Col>
  <Form.Control name="month" placeholder="Mes" onChange={this.onChange.bind(this)} />
</Col>
<Col>
  <Form.Control name="day" placeholder="Día" onChange={this.onChange.bind(this)} />
</Col>
{this.state.year?
<ButtonToolbar>
  <Button onClick={this.find.bind(this)} variant="primary">Buscar</Button>
</ButtonToolbar>:null}
</Row>

</Form>
    </div>
  )
}
  render(){
    const post = this.state.history;
		const filteredInfo = post.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
	        if (post.length === 0 ) {
	        	return(
	            <div>
              <div className="center2">
              <h2 ><img className="CH_SM_Icono" src={dateimg}/>{this.state.date}</h2>
              <h5>No hay datos</h5>
              <p onClick={this.componentDidMount.bind(this)} className="pointer hoover-black">ver Hoy</p>
              </div>
              <label>Puedes realizar busquedas con los siguientes datos:</label><br/>
              <label>1.-Presiona HOY para ver el día actual</label><br/>
              <label>2.-Ingresa el AÑO y presiona buscar para ver aun año completo</label><br/>
              <label>3.Ingresa el AÑO y Mes para ver un mes completo</label><br/>
              <label>4.Ingresa el AÑO,MES y DÍA para ver un día completo</label>
              <label>IMPORTANTE. NO ES NECESARIO AGREGAR 0 EN MES O DIA (7) (07)</label>
              {this.look()}
	            <h1>No hay datos</h1>
	            </div>
	            )} else{
		return(
				<div>
        <div className="center2">
        <h2 ><img className="CH_SM_Icono" src={dateimg}/>{this.state.date}</h2>
        <h5>Clases</h5>
        <p onClick={this.componentDidMount.bind(this)} className="pointer hoover-black">ver Hoy</p>

        </div>
        <label>Puedes realizar busquedas con los siguientes datos:</label><br/>
        <label>1.-Presiona HOY para ver el día actual</label><br/>
        <label>2.-Ingresa el AÑO y presiona buscar para ver aun año completo</label><br/>
        <label>3.Ingresa el AÑO y Mes para ver un mes completo</label><br/>
        <label>4.Ingresa el AÑO,MES y DÍA para ver un día completo</label>
        <label>IMPORTANTE. NO ES NECESARIO AGREGAR 0 EN MES O DIA (7) (07)</label>
        {this.look()}<br/>
	        <SearchInput placeholder="Nombre apellido,Coach,id,día" className="search-input form-group my-input text-center"  onChange={this.searchUpdated.bind(this)} />
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>
                <th className="table-width">Nombre</th>
              <th className="table-width">Fecha</th>
              <th className="table-width">Hora</th>
              <th className="table-width">Día</th>
              <th className="table-width">Coach</th>
              </tr>
            </thead>
            <tbody>
				  {filteredInfo.map((dato, key) => {
	  return(
      <tr key={key}>
      <td ><label className="ch-dark-text">{dato.Name} {dato.Last_Name} {dato.Second_Last_Name}</label></td>
      <td ><label className="ch-dark-text">{dato.Year_Old}/{dato.Month_Old}/{dato.Num_Day_Old}</label></td>
      <td ><label className="ch-dark-text">{dato.Time_Old}:{dato.TimeEnd_Old}</label></td>
      <td ><label className="ch-dark-text">{dato.Day_Old}</label></td>
      <td ><label className="ch-dark-text">{dato.Coach} </label></td>
        </tr>

	                        )
	                    })
	                }</tbody>
                </Table>





				</div>

			)}
		}





}export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        UpdateActive,fetchGetPost,
    }
)(ShowStates);
