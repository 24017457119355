import React, { Component } from 'react';
import firebase from 'firebase'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import About from '../../Media/carousel/about.jpg'
import Modal from 'react-bootstrap/Modal'
import logo1 from '../../Media/1.png'
import logo2 from '../../Media/2.png'
import logo3 from '../../Media/3.png'
import logo4 from '../../Media/4.png'
import logo5 from '../../Media/5.png'
import logo6 from '../../Media/6.png'
import Fade from '../MainComponents/fade'
import Helper from '../../lib/Helper'


class CardsAbout extends Component {
  constructor(props){
    super(props)
    this.state={
      uid:'8B57J61q4bM51u9oF7tKln340Fm2',
      show:false,
      card1image:'',
      card1imageurl:'',
      card1text:'',
      card2image:'',
      card2imageurl:'',
      card2text:'',
      card3image:'',
      card3imageurl:'',
      card3text:'',
      card4image:'',
      card4imageurl:'',
      card4text:'',
      card5image:'',
      card5imageurl:'',
      card5text:'',
      card6image:'',
      card6imageurl:'',
      card6text:'',
      uploading:false,
      uploadvalue:'',
    }
    this.openModal=this.openModal.bind(this);
    this.selectedpicture=this.selectedpicture.bind(this);
    this.texts=this.texts.bind(this);
    this.upload=this.upload.bind(this);
    this.cancel=this.cancel.bind(this);
    this.pred=this.pred.bind(this);
  }

  componentWillMount(){
    for (var i = 1; i <7; i++) {
      if (this.props.toShow) {
        /*alert("some cards")*/
        if (this.props.toShow[i]==true) {
          if (this.props.data['card'+i].Url=='null') {
            //alert('nulo')
             this.setState({
               ['card'+i+'imageurl']:About,
               ['card'+i+'text']:'no hay texto'
             })

            //alert('nonulo')
          }else {
            //alert('no nulo')
            this.setState({
              ['card'+i+'imageurl']:this.props.data['card'+i].Url,
              ['card'+i+'text']:this.props.data['card'+i].title
            })
          }
        }

      }else {
      /*  alert('all cards')*/
        if (this.props.data['card'+i].Url=='null') {
          //alert('nulo')
           this.setState({
             ['card'+i+'imageurl']:About,
             ['card'+i+'text']:'no hay texto'
           })

          //alert('nonulo')
        }else {
          //alert('no nulo')
          this.setState({
            ['card'+i+'imageurl']:this.props.data['card'+i].Url,
            ['card'+i+'text']:this.props.data['card'+i].title
          })
        }
      }


    }
  }
openModal(){
  this.setState({
    show:!this.state.show
  })
}

selectedpicture(event){
  this.setState({
    [event.target.name]:event.target.files[0],/*guarda el archivo en un estado para despues subirlo cuando se le de click*/
    [event.target.name+'url']: URL.createObjectURL(event.target.files[0])
  })
}
texts(event){
  this.setState({
    [event.target.name]:event.target.value
  })
}
cancel(){
  for (var i = 1; i < 7; i++) {
    if (this.props.data['card'+i].Url=='null') {
      //alert('nulo')
       this.setState({
         ['card'+i+'imageurl']:About,
         ['card'+i+'text']:'no hay texto'
       })

      //alert('nonulo')
    }else {
      //alert('no nulo')
      this.setState({
        ['card'+i+'imageurl']:this.props.data['card'+i].Url,
        ['card'+i+'text']:this.props.data['card'+i].title
      })
    }

  }
}
  render() {
    return (
    	<div >
      <Modal show={this.state.show} onHide={this.openModal}>
        <Modal.Header closeButton>
          <Modal.Title>Cards</Modal.Title>
        </Modal.Header>
        {this.state.uploading==true?
          <div><p>Subiendo..</p>
          <ProgressBar>
          <ProgressBar striped variant="success" now={this.state.uploadvalue} key={1} />
          </ProgressBar>
          </div>:
        <Col>
        {(() => {
          const options = [];

          for (let i = 1; i <= 6; i++) {
            options.push(
              <Row>
                  <Col className="overflow-hidden ">
                      <Row className="margin-left-null">
                      <img className="img-96" src={this.state['card'+i+'imageurl']}/>
                      </Row>

                      <Row className="margin-left-null overflow-hidden">
                          {this.state['card'+i+'imageurl']&&this.state['card'+i+'text']?<Col className="overflow-hidden margintop6"><a onClick={()=>this.upload(i)}>Subir</a></Col>:null}
                          <Col className="overflow-hidden margintop6 ">
                          <input  type="file" onChange={this.selectedpicture} name={'card'+i+'image'} label="Cargar Archivo" s={3} /></Col>
                      </Row>

                  </Col>

                  <Col><textarea rows="5" cols="15" type='text' value={this.state['card'+i+'text']} name={'card'+i+'text'} onChange={this.texts}/>
                  </Col>
              </Row>
            );
          }

          return options;
        })()}



        </Col>}

        <Modal.Footer>
          <Button variant="danger" onClick={this.cancel}>
            Cancelar
          </Button>
          <Button variant="secondary" onClick={this.pred}>
            Prede
          </Button>
        </Modal.Footer>
      </Modal>






{this.props.edit==true?
<Button  onClick={this.openModal}>editar</Button>:null}
    			<div className="row ">
          {/*----------------------*/}
          {(() => {
            const options = [];

            for (let i = 1; i <= 6; i++) {
              if (this.props.toShow) {
                //algunos
                if (this.props.toShow[i]==true){
                  options.push(

                    <div className="col-md-4" data-aos="slide-up">
                    <Fade>
                    <div className="card-place text-center">
                     <div className="additional-home text-center">
                     {/*<div class="more-info text-center">*/}
                       <div class="text-center">
                         <div class="coords">
                           <h6 className="cards-home">{this.state['card'+i+'text']}</h6>
                         </div>
                         <div class="coords">
                           <span></span>
                         </div>
                         <div class="stats">

                         </div>
                       </div>
                     </div>
                     <div >
                     <img className="img-96 border-radius-6px" src={this.state['card'+i+'imageurl']}/>
                     </div>
                    </div>
                    </Fade>
                    </div>
                  );

                }

              }else {
                //todos
                options.push(

                  <div className="col-md-4" data-aos="slide-up">
                  <Fade>
                  <div className="card-place text-center">
                   <div className="additional-home text-center">
                   {/*<div class="more-info text-center">*/}
                     <div class="text-center">
                       <h3 className="CH-Text-white"></h3>
                       <div class="coords">
                         <h6 className="cards-home">{this.state['card'+i+'text']}</h6>
                       </div>
                       <div class="coords">
                         <span></span>
                       </div>
                       <div class="stats">

                       </div>
                     </div>
                   </div>
                   <div >
                   <img className="img-96 border-radius-6px" src={this.state['card'+i+'imageurl']}/>
                   </div>
                  </div>
                  </Fade>
                  </div>
                );
              }

            }

            return options;
          })()}

        {/*-------------------------------------*/}


    			</div>
    		</div>
    );
  }
  pred(){
    this.setState({
      uploading:true
    })
    for (var i = 1; i < 7; i++) {
      const gallery={
        Url:'null',
        title:'null'
      }
      Helper.AddPictureHomeCard(this.state.uid,i,(gallery)).then(()=>{/*informacion de archivo*/
      }).catch(function(error){
      let MS=error.message
      alert(MS)
      });
    }
    this.setState({uploading:false})
    window.location.reload(true);
  }
  upload(card){
    this.setState({
      uploading:true})
    const file = this.state['card'+card+'image']
    const storageRef = firebase.storage().ref("Gallery/Home/Cards"+card+".jpg")
    const task = storageRef.put(file)

    task.on('state_changed', (snapshot) => {
      let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      this.setState({
        uploadvalue: percentage
      })
    }, (error) => {
      alert("hubo un error, vuelve a intentarlo.")
      this.setState({
        uploadvalue:'',
        uploading:false

      })
    }, () =>{
      task.snapshot.ref.getDownloadURL().then((downloadURL)=> {
        const gallery={
          Url:downloadURL,
          title:this.state['card'+card+'text']
        }
        Helper.AddPictureHomeCard(this.state.uid,card,(gallery)).then(()=>{/*informacion de archivo*/
        }).catch(function(error){
        let MS=error.message
        alert(MS)
        });
        this.setState({
          uploadvalue:'',
          uploading:false
        })
  })
        });
  }
}

export default CardsAbout;
