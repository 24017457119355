import React, { Component } from 'react';
import '../../../Styles/App.css';
import Add from '../../../Media/Add.png'
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal'
import { UpdateActive,fetchGetPost, fetchAddPost, fetchUpdatePost, fetchDeletePost } from '../../../reducers/postsReducer';
import SearchInput, {createFilter} from 'react-search-input';
import Table from 'react-bootstrap/Table'
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Memberships from '../../../Media/Memberships.png'
const KEYS_TO_FILTERS = ['Name','Price','Info','Active'];
class ShowMembership extends Component {

  constructor(props){
          super(props);
          this.state={
            searchTerm: '',
                show:false,
                edit:false,
                Name:'',
                Price:'',
                Info:'',
                id:'',
                url:'Create/Create_Membership.php',
                quantity:''
            };
            this.Save=this.Save.bind(this);
            this.onChange=this.onChange.bind(this);
            this.update=this.update.bind(this);
            this.Edit=this.Edit.bind(this);

      }

      handleClose() {
        this.setState({
          Name:'',
          Price:'',
          Info:''
        })
      this.setState({ show: false,edit:false });
      }

      handleShow() {
      this.setState({ show: true });
      }
      editShow(e){
        //dato.Active,dato.Id_Membership,dato.Name,dato.Price,dato.Info,quantity
        this.setState({ edit:true,
      Id:e[1],
      Name:e[2],
      Price:e[3],
      Info:e[4],
      quantity:e[5]
     });
      }
      componentWillMount() {
          this.props.fetchGetPost('Get_Membership.php');
      }

      onChange(e){
        this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre

      }
     searchUpdated (term) {
      this.setState({searchTerm: term})
  }
  update(){
    this.props.fetchGetPost('Get_Membership.php');
  }
Edit(){
  const nums=[];
  for (var i = 0; i < 501; i++) {
    nums[i]=i
  }
  return(
    <div><Form>
  <Form.Row>
    <Form.Group as={Col} controlId="formGridEmail">
      <Form.Label>Nombre</Form.Label>
      <Form.Control onChange={this.onChange} name="Name" value={this.state.Name} placeholder="Nombre" />
    </Form.Group>

  </Form.Row>
<Form.Row>
  <Form.Group as={Col}  controlId="formGridAddress1">
    <Form.Label>Precio $</Form.Label>
    <Form.Control onChange={this.onChange} name="Price" value={this.state.Price} placeholder="$600" />
  </Form.Group>
    <Form.Group as={Col}  controlId="formGridCity">
      <Form.Label>Info</Form.Label>
      <Form.Control  onChange={this.onChange} name="Info" value={this.state.Info} placeholder="Esta membresia es para..."/>
    </Form.Group>

    <Form.Group as={Col}  controlId="formGridCity">
  <Form.Label>Sesiones</Form.Label>
  <Form.Control as="select" onChange={this.onChange} name="quantity">
  <option value={this.state.quantity} >{this.state.quantity}</option>
  {nums.map(item => (
    <option value={item}  key={item}>{item}</option>
  ))}
  </Form.Control>
  </Form.Group>



    </Form.Row>
</Form></div>)
}
saveEditing(){
  //alert('hola')
  const data = {
        //Table:'Membership',
        Id:this.state.Id,
        Name: this.state.Name,
        Price:this.state.Price,
        Info:this.state.Info,
        quantity:this.state.quantity
      }
      this.props.UpdateActive(data,'Update/Update_Membership.php');
      alert('Correcto')
      this.update()
}
  Save(event){
   event.preventDefault();
   const data = {
         Name: this.state.Name,
         Price:this.state.Price,
         Info:this.state.Info,
         quantity:this.state.quantity
       }
this.props.fetchAddPost(data,this.state.url);
alert('Correcto')
this.update()
}

active(e){
  {this.update()}
  if(e[0]==1){
    {this.update()}
    const data = {
        Id: e[1],
        Active:'0',
        Table:'membership',
        IdTable:'Id_Membership'
      }
      this.props.UpdateActive(data,'Update/Update_Active.php');
    //alert("desactivado")
    {this.update()}
  }
  {this.update()}
  if(e[0]==0){
    {this.update()}
    const data = {
          Id: e[1],
          Active:'1',
          Table:'membership',
          IdTable:'Id_Membership'
        }
        this.props.UpdateActive(data,'Update/Update_Active.php');
    //alert("activado")
    {this.update()}
  }
{this.update()}
{this.update()}

}

AddMembership(){
  const nums=[];
  for (var i = 0; i < 501; i++) {
    nums[i]=i
  }
  return(
    <div><Form onSubmit={this.Save.bind(this)}>
  <Form.Row>
    <Form.Group as={Col} controlId="formGridEmail">
      <Form.Label>Nombre</Form.Label>
      <Form.Control onChange={this.onChange} name="Name" value={this.state.Name} placeholder="Nombre" />
    </Form.Group>

  </Form.Row>
<Form.Row>
  <Form.Group as={Col}  controlId="formGridAddress1">
    <Form.Label>Precio $</Form.Label>
    <Form.Control onChange={this.onChange} name="Price" value={this.state.Price} placeholder="$600" />
  </Form.Group>
    <Form.Group as={Col}  controlId="formGridCity">
      <Form.Label>Info</Form.Label>
      <Form.Control  onChange={this.onChange} name="Info" value={this.state.Info} placeholder="Esta membresia es para..."/>
      </Form.Group>

      <Form.Group as={Col}  controlId="formGridCity">
    <Form.Label>Sesiones</Form.Label>
    <Form.Control as="select" onChange={this.onChange} name="quantity">
    <option>Choose...</option>
    {nums.map(item => (
      <option value={item}  key={item}>{item}</option>
    ))}
    </Form.Control>
    </Form.Group>

    </Form.Row>
    {this.state.Name&&this.state.quantity&&this.state.Price&&this.state.Info?<button className="btn btn-success" type="submit">Agregars</button>:null}

</Form></div>
  )
}


  render(){
    const post = this.props.posts;
    const {children}=this.props
		const filteredInfo = this.props.posts.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
	        if (this.props.posts.length === 0 ) {
	        	return(
	            <div>
	            <h1>Empty</h1>
	            </div>
	            )} else{
		return(
				<div>
        {/*------------------edit---------------------*/}
        <Modal show={this.state.edit} onHide={this.handleClose.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
          {this.Edit()}
          </div>
          </Modal.Body>
          <Modal.Footer>
          {this.state.Name&&this.state.Price&&this.state.Info&&this.state.quantity?<Button className="btn btn-success" onClick={this.saveEditing.bind(this)}>Guardar</Button>:null}

            <Button variant="secondary" onClick={this.handleClose.bind(this)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/*--------------------------------------------------------*/}
        <Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
          {this.AddMembership()}
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose.bind(this)}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
	        <SearchInput placeholder="Buscar" className="input-cv search-input form-group my-input text-center"  onChange={this.searchUpdated.bind(this)} />

          {/*<div className="CV_Square_Gray">
          <a onClick={this.handleShow.bind(this)} href="javascript:;" className="CV_Button">
          <img src={Add} className="CV_Icons"/><br/>
          <p>name</p>
            </a>
              </div>*/}

              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr>
                    <th className="center-ico" ><a  onClick={this.handleShow.bind(this)} href="javascript:;" className="CV_Button">
                    <img src={Add} className="CH_SM_Icono"/><br/>
                      </a></th>
                    <th className="table-width">Nombre</th>
                    <th className="table-width">Precio</th>
                    <th className="table-width">Sesiones</th>
                    <th className="table-width">Act/Des</th>
                  </tr>
                </thead>
                <tbody>



				  {filteredInfo.map((dato, key) => {
	  return(
      <tr key={key}>
      <td className="CH_SM_Icono"><img src={Memberships} onClick={() => this.editShow([dato.Active,dato.Id_Membership,dato.Name,dato.Price,dato.Info])} className="CH_SM_Icono"/></td>
      <td className="pointer"onClick={() => this.editShow([dato.Active,dato.Id_Membership,dato.Name,dato.Price,dato.Info,dato.Quantity])}><label className="ch-dark-text pointer" >{dato.Name}</label></td>
     <td className="pointer" onClick={() => this.editShow([dato.Active,dato.Id_Membership,dato.Name,dato.Price,dato.Info,dato.Quantity])}><label className="ch-dark-text pointer" >${dato.Price}</label></td>
    <td className="pointer"onClick={() => this.editShow([dato.Active,dato.Id_Membership,dato.Name,dato.Price,dato.Info,dato.Quantity])}><label className="ch-dark-text pointer" >{dato.Quantity}</label></td>
     <td>
     {dato.Active==1?
 <Button variant="success" onClick={() => this.active([dato.Active,dato.Id_Membership])}>Active</Button>:null}
 {dato.Active==0?
<Button variant="danger" onClick={() => this.active([dato.Active,dato.Id_Membership])}>Desactivado</Button>:null}
</td>
    </tr>
	                        )
	                    })
	                }

                  </tbody>
                </Table>


				</div>

			)}
		}





}

export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        UpdateActive,fetchGetPost, fetchAddPost, fetchUpdatePost, fetchDeletePost
    }
)(ShowMembership);
