const HomePage={
  FreeClass:"http://conversationhackers.com/media/videos/video.mp4",
  Lograras:'http://conversationhackers.com/media/videos/lograras.mp4',
  LograrasPoster:'http://conversationhackers.com/media/thumbs/perderas.png',
  Webinar:'http://conversationhackers.com/media/videos/webinar.mp4',
  WebinarPoster:'http://conversationhackers.com/media/thumbs/webinar.png',
  Webinarmobile:'http://conversationhackers.com/media/videos/WEBINARMOBILE.mp4',
  Testimonio1:'http://conversationhackers.com/media/videos/testimonio1.mp4',
  Testimonio1Poster:'http://conversationhackers.com/media/thumbs/2.png',
  Testimonio2Poster:'http://conversationhackers.com/media/thumbs/1.png',
  Testimonio2:'http://conversationhackers.com/media/videos/testimonio2.mp4'
  }
const prices={
    general:'http://conversationhackers.com/media/videos/1.mp4',
        person1:'http://conversationhackers.com/media/videos/person1.mp4',
        person2:'http://conversationhackers.com/media/videos/person2.mp4',
        person3:'http://conversationhackers.com/media/videos/person3.mp4'
}
export default{HomePage,prices}
