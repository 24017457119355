import React, { Component } from 'react';
import '../../../Styles/App.css';
import Stat from '../../../Media/States.png'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import PaymentStudent3 from '../Data/PaymentStudent3'
import dateimg from '../../../Media/Calendar.png'
import { connect } from 'react-redux';
import { UpdateActive,fetchGetPost,} from '../../../reducers/postsReducer';
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import States from '../Forms/Create_State'
import SearchInput, {createFilter} from 'react-search-input';
import ListGroup from 'react-bootstrap/ListGroup'
import Table from 'react-bootstrap/Table'
import Add from '../../../Media/Add.png';
import * as Web from '../../../Constants/Web'
const KEYS_TO_FILTERS = ['Name','Last_Name','Second_Last_Name','membership_payment','id_student_payment','num_day','year','month'];
class ShowStates extends Component {



  constructor(props){
          super(props);
          this.state={
              url:Web.wurl+'/',
              datos:[],
            searchTerm: '',
            Id:'',
            edit:false,
            Payments:[],
            name:'',
            price:'',
            year:'',
            day:'',
            month:'',
            daylet:'',
            daynum:'',
            hour:'',
            member:'',
            date:'',
            remaining:'',
            idpayment:'',
            place:''
            };
            this.onChange=this.onChange.bind(this);

      }


    async  componentDidMount(){
            const data={
              id:this.props.id
            }
      var url = this.state.url+'Api/Get_Remaining.php';
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        header: {'Content-Type': 'application/json'}
      }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {this.setState({Payments:response.reverse()})} );
}
update(){
  alert('hola')
  this.setState({
    show: false,edit:false,
  })
        const data={
          id:this.props.id
        }

  var url = this.state.url+'Api/Get_Remaining.php';
  fetch(url, {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(data), // data can be `string` or {object}!
    header: {'Content-Type': 'application/json'}
  }).then(res => res.json())
  .catch(error => console.error('Error:', error))
  .then(response => {this.setState({Payments:response.reverse()})} );
}


     searchUpdated (term) {
      this.setState({searchTerm: term})
  }


  handleClose() {
  this.setState({ show: false,edit:false });
  }

  handleShow() {
  this.setState({ show: true });
  }


  payment(){
    return(
      <div >
      <span >{this.state.name}</span><br/>
      <span >{this.state.member}  ${this.state.price}</span><br/>
      <span >{this.state.year} {this.state.month} {this.state.daynum}</span><br/>
      <span >{this.state.daylet} {this.state.hour}</span>
      <hr/>
      {<PaymentStudent3 id={this.state.Id} update={this.componentDidMount()} remaining={this.state.remaining} id_pay={this.state.idpayment} place={this.state.place}/>}
      </div>
    )

  }
  onChange(e){
    this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
  }

    Show(e){
    //dato.Name,
    //dato.Last_Name
    //dato.Second_Last_Name
    //dato.price
    ///dato.remaining
    //dato.year
    //dato.month
    //dato.day--letra
    //num day---numero
    //dato.hour
    //dato.membership_payment
    this.setState({ edit:true,
    name:e[0]+' '+e[1]+' '+e[2],
    price:e[3],
    year:e[4],
    month:e[5],
    daylet:e[6],
    daynum:e[7],
    hour:e[8],
    member:e[9],
    Id:e[10],
    remaining:e[11],
    idpayment:e[12],
    place:e[13]

  })
    }
  render(){
    const post = this.state.Payments;
		const filteredInfo = post.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
	        if (post.length === 0 ) {
	        	return(
	            <div>
              <div className="center2">
	            <h5>no hay adeudos :D</h5>
	            </div>
                </div>
	            )} else{
		return(
				<div>
        <br/>
        {/*------------------edit---------------------*/}
        <Modal show={this.state.edit} onHide={this.handleClose.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Pago</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
          {this.payment()}
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose.bind(this)}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
        {/*--------------------------------------------------------*/}
  <br/>
  <label>Puedes realizar busquedas con los siguientes datos:</label><br/>
  <label>1.-Ingresa datos personales del alumno o fechas</label><br/>
  <label>Para realizar cobros</label><br/>
  <label>Click sobre el alumno e ingrese la cantidad</label><br/>
	        <SearchInput placeholder="Nombre apellido,Membresía,id" className="search-input form-group my-input text-center"  onChange={this.searchUpdated.bind(this)} />
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>
                <th className="table-width">Año</th>
              <th className="table-width">Mes</th>
              <th className="table-width">Día</th>
              <th className="table-width">Nombre</th>
              <th className="table-width">A pagár</th>
              <th className="table-width">Membresía</th>
              </tr>
            </thead>
            <tbody>
				  {filteredInfo.map((dato, key) => {
	  return(
      <tr key={key}>
      <td className="pointer" onClick={() => this.Show([dato.Name,dato.Last_Name,dato.Second_Last_Name,dato.price,dato.year,dato.month,dato.day,dato.num_day,dato.hour,dato.membership_payment,dato.id_student_payment,dato.remaining,dato.Id_Payment,dato.id_place_payment])}><label className="ch-dark-text pointer">{dato.year}</label></td>
      <td className="pointer" onClick={() => this.Show([dato.Name,dato.Last_Name,dato.Second_Last_Name,dato.price,dato.year,dato.month,dato.day,dato.num_day,dato.hour,dato.membership_payment,dato.id_student_payment,dato.remaining,dato.Id_Payment,dato.id_place_payment])}><label className="ch-dark-text pointer">
      {dato.month=="1"?<p>Enero</p>:null}
      {dato.month=="2"?<p>Febrero</p>:null}
      {dato.month=="3"?<p>Marzo</p>:null}
      {dato.month=="4"?<p>Abril</p>:null}
      {dato.month=="5"?<p>Mayo</p>:null}
      {dato.month=="6"?<p>Junio</p>:null}
      {dato.month=="7"?<p>Julio</p>:null}
      {dato.month=="8"?<p>Agosto</p>:null}
      {dato.month=="9"?<p>Septiembre</p>:null}
      {dato.month=="10"?<p>Octubre</p>:null}
      {dato.month=="11"?<p>Noviembre</p>:null}
      {dato.month=="12"?<p>Diciembre</p>:null}
      </label></td>
      <td className="pointer" onClick={() => this.Show([dato.Name,dato.Last_Name,dato.Second_Last_Name,dato.price,dato.year,dato.month,dato.day,dato.num_day,dato.hour,dato.membership_payment,dato.id_student_payment,dato.remaining,dato.Id_Payment,dato.id_place_payment])}><label className="ch-dark-text pointer">{dato.num_day}</label></td>
      <td className="pointer" onClick={() => this.Show([dato.Name,dato.Last_Name,dato.Second_Last_Name,dato.price,dato.year,dato.month,dato.day,dato.num_day,dato.hour,dato.membership_payment,dato.id_student_payment,dato.remaining,dato.Id_Payment,dato.id_place_payment])}><label className="ch-dark-text pointer">{dato.Name} {dato.Last_Name} {dato.Second_Last_Name}</label></td>
      <td className="pointer" onClick={() => this.Show([dato.Name,dato.Last_Name,dato.Second_Last_Name,dato.price,dato.year,dato.month,dato.day,dato.num_day,dato.hour,dato.membership_payment,dato.id_student_payment,dato.remaining,dato.Id_Payment,dato.id_place_payment])}><label className="ch-dark-text pointer">${dato.remaining} </label></td>
      <td className="pointer" onClick={() => this.Show([dato.Name,dato.Last_Name,dato.Second_Last_Name,dato.price,dato.year,dato.month,dato.day,dato.num_day,dato.hour,dato.membership_payment,dato.id_student_payment,dato.remaining,dato.Id_Payment,dato.id_place_payment])}><label className="ch-dark-text pointer">{dato.membership_payment} </label></td>
        </tr>

	                        )
	                    })
	                }</tbody>
                </Table>





				</div>

			)}
		}





}export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        UpdateActive,fetchGetPost,
    }
)(ShowStates);
