import React,{useEffect, useState} from 'react';
import * as URL from '../../../Constants/services';

export const usePlaces = () =>{
 const [places, setPlaces]=useState([]);

    useEffect(()=>{
      var url = URL.api+"/getPlaces";
      const inf={
      }
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(inf), // data can be `string` or {object}!
        header: {'Content-Type': 'application/json'}
      }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {
        setPlaces(response)
      })
    },[setPlaces])

  return {places};
}
