import React, { Component } from 'react';
import '../../../Styles/App.css';
import ClassroomIcon from '../../../Media/Classroom.png'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Classroom from '../Forms/Create_Classroom'
import { connect } from 'react-redux';
import { UpdateActive,GetClassroom} from '../../../reducers/postsReducer';
import SearchInput, {createFilter} from 'react-search-input';
import Table from 'react-bootstrap/Table'
import Add from '../../../Media/Add.png';
const KEYS_TO_FILTERS = ['Name'];
class ShowClassrooms extends Component {

  constructor(props){
          super(props);
          this.state={
            searchTerm: '',
            edit:false,
            Id:'',
            Name:'',
            Info:'',
            };
            this.onChange=this.onChange.bind(this);

      }


     async  componentDidMount(){
        this.props.GetClassroom('Get_Classrooms.php');
      }

      update(){
       this.props.GetClassroom('Get_Classrooms.php');
      }
      onChange(e){
        this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre

      }
Edit(){
  return(
    <div>
    <Form>
    <Form.Row>
    <Form.Group as={Col} controlId="formGridEmail">
      <Form.Label>Nombre</Form.Label>
      <Form.Control onChange={this.onChange} name="Name" value={this.state.Name} placeholder="Nombre" />

    </Form.Group>

    </Form.Row>
    </Form>
        </div>
  )

}

     searchUpdated (term) {
      this.setState({searchTerm: term})
  }
states(){
  return(
<Classroom update={this.update.bind(this)}/>
  )
}

  handleClose() {
  this.setState({ show: false,edit:false });
  }
  editShow(e){
    //dato.Active,dato.Id_Membership,dato.Name,dato.Price,dato.Info
    this.setState({ edit:true,
  Id:e[0],
  Name:e[1]
 });
  }

  handleShow() {
  this.setState({ show: true });
  }

  saveEditing(){
    //alert('hola')
    const data = {
          Id:this.state.Id,
          Name: this.state.Name
        }
        this.props.UpdateActive(data,'Update/Update_Classrooms.php');
        alert('Correcto')
        this.update()
  }

  active(e){
    {this.update()}
    if(e[0]===1){
      {this.update()}
      const data = {
          Id: e[1],
          Active:'0',
          Table:'classroom',
          IdTable:'Id_Level'
        }
        this.props.UpdateActive(data,'Update/Update_Classrooms.php');
      //alert("desactivado")
      {this.update()}
    }
    {this.update()}
    if(e[0]===0){
      {this.update()}
      const data = {
            Id: e[1],
            Active:'1',
            Table:'classroom',
            IdTable:'Id_Level'
          }
          this.props.UpdateActive(data,'Update/Update_Classrooms.php');
      //alert("activado")
      {this.update()}
    }
{this.update()}
  }


  render(){
    const classrooms = this.props.classroom;
    //alert(JSON.stringify(classrooms))
    const {children}=this.props
		const filteredInfo = classrooms.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
	        if (classrooms.length === 0 ) {
	        	return(
	            <div>
              <Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered>
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
                {this.states()}
                </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose.bind(this)}>
                    Cancelar
                  </Button>
                </Modal.Footer>
              </Modal>
              <img src={Add} className="CH_SM_Icono" onClick={this.handleShow.bind(this)}/><br/>

	            <h1>Empty</h1>


	            </div>
	            )} else{
		return(
				<div><br/>
        {/*------------------edit---------------------*/}
        <Modal show={this.state.edit} onHide={this.handleClose.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
          {this.Edit()}
          </div>
          </Modal.Body>
          <Modal.Footer>
          {this.state.Name?<Button className="btn btn-success" onClick={this.saveEditing.bind(this)}>Guardar</Button>:null}

            <Button variant="secondary" onClick={this.handleClose.bind(this)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/*--------------------------------------------------------*/}
        <Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
          {this.states()}
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose.bind(this)}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
	        <SearchInput placeholder="Buscar" className="search-input form-group my-input text-center"  onChange={this.searchUpdated.bind(this)} />
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>
                <th className="center-ico" >
                <img src={Add} className="CH_SM_Icono" onClick={this.handleShow.bind(this)}/><br/>
                  </th>
                <th className="table-width">Nombre</th>
              </tr>
            </thead>
            <tbody>
				  {filteredInfo.map((dato, key) => {
	  return(
      <tr key={key}>
      <td  onClick={() => this.editShow([dato.Id_Classroom,dato.Name])} className="CH_SM_Icono"><img src={ClassroomIcon} className="CH_SM_Icono"/></td>
      <td onClick={() => this.editShow([dato.Id_Classroom,dato.Name])}><label className="ch-dark-text">{dato.Name}</label></td>

    </tr>

	                        )
	                    })
	                }</tbody>
                </Table>





				</div>

			)}
		}





}
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        classroom: state.classroom.classroom,
    }),
    //mapeo de funciones
    {
        UpdateActive,GetClassroom,
    }
)(ShowClassrooms);
