import React, { Component } from 'react';
import '../../../Styles/App.css';
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import * as Settings from '../../../Constants/Menu'
import Add from '../../MainComponents/Add'
import ShowLevels from '../Tables/Show_Levels'
import * as Create from '../../../Constants/Menu'
import TopLabel from './../../MainComponents/Close'
class Advanced extends Component {



  render() {
    return (
    <Container>
  <TopLabel label={Settings.LEVELS}press={this.props.close} />
    <ShowLevels>
    <Add
    name={Create.addStateName}
    link={Create.addStateLink}
    modal={Create.addStateModal}
    />
    </ShowLevels>
    </Container>

    );
  }
}

export default Advanced;
