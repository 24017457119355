import React,{useEffect, useState} from 'react';
import Editor from '@react-page/editor';
import { plugins } from './viewFiles/plugins';
import web from './Web'

const Edit=(props)=>{
  const [data, setData]=useState()
  const[id]=useState(props.id)


useEffect(()=>{
  var url = web+'blog/Get_post.php';
  const student={
    id:id
  }

  fetch(url, {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(student), // data can be `string` or {object}!
    header: {'Content-Type': 'application/json'}
}).then(res => res.json())
  .catch(error => console.error('Error:', error))
  .then(response => {
    setData(response)
      //alert(JSON.stringify(response))
    })


},props)

const update=()=>{
  setData('')
  var url = web+'blog/Get_post.php';
  const student={
    id:id
  }

  fetch(url, {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(student), // data can be `string` or {object}!
    header: {'Content-Type': 'application/json'}
}).then(res => res.json())
  .catch(error => console.error('Error:', error))
  .then(response => {
    setData(response)
      //alert(JSON.stringify(response))
    })
}


if (data) {
  return<Editor plugins={plugins} value={data} readOnly={true} />
}else {
  return<p>cargando...</p>
}


}

export default Edit;
