import React, { Component, useEffect } from 'react';
import firebase from 'firebase'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Helpers from '../../lib/Helper'
import Modal from 'react-bootstrap/Modal'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import ModalDialog from 'react-bootstrap/ModalDialog'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Ruleta from  './Ruleta'
import ButtonFreeClass from './ButtonFreeClass'
import Images from '../../Constants/images'
import videos from '../../Constants/videos'
import {isMobile,isAndroid,isIOS} from 'react-device-detect';
import ReactPlayer from './freeClass/react-player'
import classes from './modalFree.css'

	const todos = [];
class Home extends Component {

  constructor(props){
          super(props);
          this.state={
                uid:'8B57J61q4bM51u9oF7tKln340Fm2',
              setShow:false,
							ended:false,
							talk:false,
							playing:true,
							start:false,
							duration:'',
							muted:true,

							seconds:parseInt(this.props.seconds+'000')
            };
            this.setShow=this.setShow.bind(this)
						this.getTImeMobile=this.getTImeMobile.bind(this);
						this.onStart=this.onStart.bind(this);
						this.pause=this.pause.bind(this);
						this.mute=this.mute.bind(this);
      }

      setShow(e){
        this.setState({setShow:e})
      }
			setShowTalk(e){
				this.setState({talk:e})
			}
			setSh(){
				this.props.closeBlack()
				this.setState({setShow:false})
				setTimeout(
					function() {
					this.setState({talk:true})

					}
					.bind(this),
					5000
				);
			}



			componentWillMount(){

				if(this.props.talkTime){
					setTimeout(
		    function() {
		        this.setState({talk:true})
		    }
		    .bind(this),
		    this.props.talkTime
		)
				}

				if(this.props.time){
					/*alert('tiene')*/
					setTimeout(
		    function() {
		        this.setShow(true);
		    }
		    .bind(this),
		    this.props.time
		)
				}else {
					/*alert('no')*/

			        this.setShow(true);
				}
      /*  ;*/
			  const extr= firebase.database().ref().child("Gallery/Home/Picture")
					 extr.on('value', (snapshot) => {

					 			snapshot.forEach((data) => {
					 				todos.push({
					 						Url:data.val().Url,
					 				});

					 this.setState({
					 banner:todos
					 })
					 })
					 });
			}
			cerrar(){
				this.props.closeBlack()
			}
			video(){
				if (this.state.playing){
					this.setState({
						playing:!this.state.playing
					})
					this.refs.videohackers.pause();
				}else {
					this.setState({
						playing:!this.state.playing
					})
					this.refs.videohackers.play();

				}

			}

			getTime(){


				if (this.refs.videohackers){

					if(this.state.duration>=1800){
						this.setState({
							ended:true
						})
					}else {
						this.setState({
							ended:false
						})
					}
				this.setState({
					duration:Math.floor(this.refs.videohackers.currentTime)
				})
			}
			}


			getTImeMobile(e){
				this.setState({duration:e.playedSeconds})
				if(this.state.duration>=1800){
					this.setState({
						ended:true
					})
				}
			}


			pause(){
				this.setState({
					playing:!this.state.playing
				})
			}

onStart(){
	/*setTimeout(
		function() {
		this.setState({muted:false,playing:true})

		}
		.bind(this),
		50
	);*/
}

mute(){
	this.setState({muted:false})
}
  render() {


		let show;
		if (isMobile) {
			//show=<video playsInline  preload poster={videos.HomePage.WebinarPoster}  controlslist="nodownload" ref={"videohackers"} onProgress = {this.getTImeMobile} className="video-free" height="240" autoPlay  onClick={this.video.bind(this)}  src={videos.HomePage.Webinarmobile} onEnded={this.cerrar.bind(this)}/>

			if (isAndroid) {
				show=<ReactPlayer url={videos.HomePage.Webinarmobile}
				onClick={this.pause}
				 playing={this.state.playing}
				 muted={false}
				progressInterval={this.getTImeMobile}
				className="video-free"
				width="400px"
				height="360px"
				playsinline
				 controls={false}
				 onProgress={this.getTImeMobile.bind(this)}
				 onEnded={this.setSh.bind(this)}/>
			}
			if (isIOS) {
				show=<ReactPlayer url={videos.HomePage.Webinarmobile}
				onClick={this.pause}

				 playing={this.state.playing}
				 muted={this.state.muted}
				 onStart={this.onStart}
				progressInterval={this.getTImeMobile}
				className="video-free"
				width="400px"
				height="360px"
				playsinline
				 controls={false}
				 onProgress={this.getTImeMobile.bind(this)}
				 onEnded={this.setSh.bind(this)}/>

			}
			}else {
				show=<ReactPlayer url={this.props.videoUrl}
				onClick={this.pause}
				 playing={this.state.playing}
				 muted={false}
				progressInterval={this.getTImeMobile}
				className="video-free"
				width="980"
				height="360px"
				playsinline
				 controls={false}
				 onProgress={this.getTImeMobile.bind(this)}
				 onEnded={this.setSh.bind(this)}/>
			//show=<video playsInline controls   preload="none" poster={videos.HomePage.WebinarPoster} controlslist="nodownload" ref={"videohackers"}  onProgress = {this.getTime.bind(this)}  autoPlay  onClick={this.video.bind(this)}  src={this.props.videoUrl} onEnded={this.cerrar.bind(this)}/>

		}
///<video controls autoPlay={true} src={this.props.videoUrl} ref={this.handleVideoMounted.bind(this)}/>
    return (
<div>
        <Modal
          show={this.props.setShow}
					className="modal-video"
          onHide={() => this.props.closeBlack()}
					size="xl"
        backdrop="static"
        >
				{this.props.ruleta==true?
				<Ruleta  close={()=>this.setShow(false)}/>:null}
				{this.props.video==true?
					<div className="text-center">
					<p className="red-text">SI CIERRAS ESTE VIDEO NO LOGRARÁS TENER TU MES GRÁTIS</p>

					{isIOS?<div className="soundContainer" >{this.state.muted?<img src={Images.FreeClass.sound} onClick={this.mute} width="100px"/>:null}</div>:null}


					<div className="video-center">{show}</div>

{/* onClick={this.setSh.bind(this)}*/}
					{this.state.ended==true?<div >
					<ButtonFreeClass image={Images.FreeClass.freeMonth}   url={'https://pay.hotmart.com/E41634392F?checkoutMode=2'} />
						</div>:<ButtonFreeClass image={Images.FreeClass.changeLife} />}

					</div>:null
				}


          </Modal>
					{this.state.talk==true?
					<div class="icon-bar-talk sticky-container-left" >
						<Container>
						<Row data-mt={'talk'}>
						<Col xs={2}><div className="effect"><img src={Images.FreeClass.talk} className="img-100-absolute"/></div></Col>
						<Col md={{ span: 4, offset: 4 }}></Col>
						</Row>
						</Container></div>:null}
					</div>
    );
  }
}

export default Home;
