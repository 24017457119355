import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route,BrowserRouter } from 'react-router-dom'
import Login from './Components/Login/Main_Login'
import Lower from './Components/Login/Lower_Login'
import LowerPlace from './Components/AdminLow/Data'
import LoginAdmin from './Components/Login/Login_Admin'
import ProfileStudent from './Components/Student/Menu'
import Couch from './Components/Coach/MenuCoach'
import Admin from './Components/Admin/index.js'
import Home from './Components/Home'
import Method from './Components/Homepage/Method'
import Prices from './Components/Homepage/Prices'
import Place from './Components/Homepage/Places'
import Easy from './Components/Homepage/Easy'
import EasyView from './Components/Homepage/EasyView'
import Free from './Components/Homepage/Free'
import StripePayments from './Components/Student/StripePayments';
import * as serviceWorker from './serviceWorker';
import store from './reducers/store';
import firebase from 'firebase'
import ClearCache from 'react-clear-cache';
var firebaseConfig = {
   apiKey: "AIzaSyA6hNPGzMtedqVdmdsX5NgXsIk27b3yU5g",
   authDomain: "conversation-hackers.firebaseapp.com",
   databaseURL: "https://conversation-hackers.firebaseio.com",
   projectId: "conversation-hackers",
   storageBucket: "conversation-hackers.appspot.com",
   messagingSenderId: "702093406884",
   appId: "1:702093406884:web:931b16010717f64d"
 };

 firebase.initializeApp(firebaseConfig);
/*
import '@fortawesome/fontawesome-free/css/all.min.css'; import
'bootstrap-css-only/css/bootstrap.min.css'; import
'mdbreact/dist/css/mdb.css';*/
const routing=(
  <BrowserRouter>
      <div>
      <ClearCache>
        {({ isLatestVersion, emptyCacheStorage }) => (
          <div>
            {!isLatestVersion && (
              <p>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  emptyCacheStorage();
                }}
              >
                presiona para actualizar a nueva versión
              </a>
              </p>
            )}
          </div>
        )}
      </ClearCache>
        <Route path="/" component={Home} exact/>
        <Route path="/clase-gratis" component={Free}/>
        <Route path="/inglesfacil" component={Easy} exact/>
        <Route path="/inglesfacil/:id" exact render={props=><EasyView id={props.match.params.id}/>}/>
        <Route path="/metodo" component={Method} />
        <Route path="/precios" component={Prices} />
        <Route path="/sucursal" component={Place} />
        <Route path="/KaikuaHine0711" component={LoginAdmin} />
        <Route path="/login" component={Login} />
        <Route path="/admin" component={Admin} />
        <Route path="/Coach" component={Couch} />
        <Route path="/conversationadmin" exact component={Lower} />
        <Route path="/conversationplace"  component={LowerPlace}/>
        <Route path="/profile" component={ProfileStudent} />
        <Route path="/paymentsStripe" component={StripePayments} />
      </div>
  </BrowserRouter>
)
ReactDOM.render(<Provider store={store}>{routing}</Provider>,document.getElementById('root'))


//ReactDOM.render(
  //  <Provider store={store}>
  //      <App />
  //  </Provider>,
  //  document.getElementById('root'));

///ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
