import React, { Component } from 'react';
import '../../../Styles/App.css';
import { connect } from 'react-redux';
import { fetchGetPost,fetchAddPost} from '../../../reducers/postsReducer';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

const numbers = [1, 2, 3, 4, 5];


class CreateState extends Component {

  constructor(props){
          super(props);
          this.state={
              Name:'',
              url:'Create/Create_State.php'

            };
            this.Save=this.Save.bind(this);
            this.onChange=this.onChange.bind(this);
            this.Successfully=this.Successfully.bind(this)
      }


      update(){
       this.props.fetchGetPost('Get_States.php');
      }

onChange(e){
  this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre

}

    async Save(){
      const data = {
            Name: this.state.Name
          }
          this.props.fetchAddPost(data,this.state.url);
          this.update()

}

Successfully(){
  alert("se agrego correctamente")
  this.setState({
    Name:''
  })


}



  render() {
      const post = this.props.posts;
      const posts = post.reverse();
    return (
    <div>
    <Form>
  <Form.Row>
    <Form.Group as={Col} controlId="formGridEmail">
      <Form.Label>Nombre</Form.Label>
      <Form.Control onChange={this.onChange} name="Name" value={this.state.Name} placeholder="Nombre" />
    </Form.Group>

    </Form.Row>
  <Button variant="primary" type="submit" onClick={this.Save}>
    Guardar
  </Button>
</Form>
        </div>
    );
  }
}

export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        fetchGetPost,fetchAddPost
    }
)(CreateState);
