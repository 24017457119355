import React, { Component } from 'react';
import '../../../Styles/App.css';
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import * as Settings from '../../../Constants/Menu'
import Add from '../../MainComponents/Add'
import ShowClassrooms from '../Tables/Show_Classrooms'
import * as Create from '../../../Constants/Menu'
import TopLabel from './../../MainComponents/Close'
class Advanced extends Component {



  render() {
    return (
    <Container>
  <TopLabel label={Settings.CLASSROOMS}press={this.props.close} />
    <ShowClassrooms>

    </ShowClassrooms>
    </Container>

    );
  }
}

export default Advanced;
