import React, { Component } from 'react';
import '../../Styles/App.css';
import Menu from './Menu'

class App extends Component {

  constructor(props){
          super(props);
          this.state={
              db:[]
            };
      }







  render() {
    return (
    <div>
Coaches
        </div>
    );
  }
}

export default App;
