import React, { Component } from 'react';
import firebase from 'firebase'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Images from '../../Constants/images'
import Helper from '../../lib/Helper'
class Socialbars extends Component {

  constructor(props){
          super(props);
          this.state={
                uid:'8B57J61q4bM51u9oF7tKln340Fm2',
                show:false,
                facebook:'',
                instagram:'',
                youtube:'',
                youtubevideo:'',
                tiktok:'',
                freelesson:'',
                shake:true
            };
            this.show=this.show.bind(this);
            this.save=this.save.bind(this);
            this.get=this.get.bind(this);
            this.open=this.open.bind(this);
      }
    
      setShow(e){
        this.setState({shake:e})
        this.shake();
      }
      shake(){
        setTimeout(
    function() {
        this.setShow(!this.state.shake);
    }
    .bind(this),
    2000
);
      }
      componentWillMount(){
        this.shake();
        this.setState({
          facebook:this.props.data.facebook,
          youtube:this.props.data.youtube,
          youtubevideo:this.props.data.youtubevideo,
          instagram:this.props.data.instagram,
          tiktok:this.props.data.tiktok,
          freelesson:this.props.data.free
        })
      }
open(event){
  window.open(event);
}
get(event){
  this.setState({
    [event.target.name]:event.target.value
  })
}
show(){
  this.setState({
    show:!this.state.show
  })
}
save(){
  if(this.state.facebook&&this.state.instagram&&this.state.youtube&&this.state.youtubevideo&&this.state.tiktok&&this.state.freelesson){
  //  alert('si')
  const social={
      facebook:this.state.facebook,
      instagram:this.state.instagram,
      youtube:this.state.youtube,
      youtubevideo:this.state.youtubevideo,
      tiktok:this.state.tiktok,
      free:this.state.freelesson
    }
    Helper.AddHomepagesocial(this.state.uid,(social)).then(()=>{
    }).catch(function(error){
    let MS=error.message
    alert(MS)})
  }else {
    alert('llena todos los cuadros')
  }
/*  const social={
    faceebook:this.state.faceebook,
    instagram:this.state.instagram,
    youtube:this.state.youtube,
    tiktok:this.state.tiktok
  }
  Helper.AddHomepagesocial(this.state.uid,(social)).then(()=>{
  }).catch(function(error){
  let MS=error.message
  alert(MS)
});*/
}
  render() {
    return (
      <div>

      <div class="icon-bar sticky-container-left" >
      {this.state.shake==true?
      <a href={this.state.freelesson}

      className="left-bar-icon shake-slow  shake-constant shake-constant--hover border-radius-50 shaking">

      <i>
<img src={Images.Social.WHATSAPP} className="whatsapp" />
  </i></a>:<a href={this.state.freelesson} class="left-bar-icon  border-radius-50">
  <i>
<img src={Images.Social.WHATSAPP} className="whatsapp" />
</i>
</a>}
</div>
				<div class="sticky-container">
        <Modal show={this.state.show} onHide={this.show}>
        <Modal.Header closeButton>
        <Modal.Title>Barra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
          <Form.Group controlId="formGroupEmail">
                <Row xs={1} md={2}>
                  <Col  xs={1}><img src={Images.Social.FACEBBOK} className="socialIcon" /></Col>
                  <Col><Form.Control type="text" value={this.state.facebook} name="facebook" onChange={this.get}  /></Col>

                </Row>
          </Form.Group>
          <Form.Group controlId="formGroupPassword">
          <Row xs={1} md={2}>
            <Col  xs={1}><img src={Images.Social.INSTAGRAM} className="socialIcon " /></Col>
            <Col><Form.Control type="text" value={this.state.instagram} name="instagram" onChange={this.get} /></Col>
          </Row>
          </Form.Group>
          <Form.Group controlId="formGroupPassword">
          <Row xs={1} md={2}>
            <Col  xs={1}><img src={Images.Social.YOUTUBE} className="socialIcon " /></Col>
            <Col><Form.Control type="text" value={this.state.youtube} name="youtube" onChange={this.get}/></Col>
          </Row>
          </Form.Group>
          <Form.Group controlId="formGroupPassword">
          <Row xs={1} md={2}>
            <Col  xs={1}><img src={Images.Social.TIKTOK} className="socialIcon " /></Col>
            <Col><Form.Control type="url" value={this.state.tiktok} name="tiktok"  onChange={this.get}/></Col>
          </Row>
          </Form.Group>
          <hr/>
                    <li className="published-date">Video</li>
                  <hr/>
                          <Row xs={1} md={2}>
                            <Col  xs={1}><img src={Images.Social.YOUTUBE} className="socialIcon" /></Col>
                            <Col><Form.Control type="text" value={this.state.youtubevideo} name="youtubevideo" onChange={this.get}/></Col>
                          </Row>
                            <hr/>
                            <hr/>
                                      <li className="published-date">Clase Gratis</li>
                                    <hr/>
                                            <Row xs={1} md={2}>
                                              <Col  xs={1}><img src={Images.Social.EDIT} className="socialIcon" /></Col>
                                              <Col><Form.Control type="text" value={this.state.freelesson} name="freelesson" onChange={this.get}/></Col>
                                            </Row>
                                              <hr/>

        </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={this.save}>
          Guardar
        </Button>
        <Button variant="danger" onClick={this.show}>
          Cancelar
        </Button>
        </Modal.Footer>
        </Modal>
    <ul class="sticky">
        <li onClick={()=>this.open(this.state.facebook)}>
            <img src={Images.Social.FACEBBOK} className="socialIcon " />
            <h5  target="_blank" className="gray-text ">Facebook</h5>
        </li>
        <li  onClick={()=>this.open(this.state.instagram)}>
            <img src={Images.Social.INSTAGRAM} className="socialIcon " />
            <h5  target="_blank" className="gray-text ">Instagram</h5>
        </li>

        <li onClick={()=>this.open(this.state.youtube)}>
            <img src={Images.Social.YOUTUBE} className="socialIcon " />
            <h5  target="_blank" className="gray-text ">Youtube</h5>
        </li>
        <li onClick={()=>this.open(this.state.tiktok)}>
            <img src={Images.Social.TIKTOK} className="socialIcon " />
            <h5 target="_blank" className="gray-text ">Tiktok</h5>
        </li>
        {this.props.edit==true?
        <li onClick={this.show}>
            <img src={Images.Social.EDIT} onClick={this.show} className="socialIcon" />
            <h5 target="_blank" className="gray-text " onClick={this.show}>Editar</h5>
        </li>:null}
    </ul>
</div>
</div>
    );
  }
}

export default Socialbars;
