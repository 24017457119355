import React from 'react';
import classes from './title.module.css';



const Title =(props)=>{
  const {title} =props;
  return(
    <div className={classes.root}>
      <span className={classes.title}>{title}</span>
    </div>
  )
}

export default Title;
