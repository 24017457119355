import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Helpers from '../lib/Helper'
import Carousel from './Homepage/Carousel';
import CardVideoHome from './Media/Card_videohome'
import Navbar from './Homepage/Navbar';
import Fade from './MainComponents/fade'
import Footer from './Homepage/Footer'
import People  from './Homepage/people'
import Modal from './Homepage/Modal'
import Title from './Homepage/title'
import Socialbars from './Homepage/SocialBars'
import AboutCards from './Homepage/AboutCards'


class HomePage extends Component {
  constructor(props){
          super(props);
          this.state={
                uid:'8B57J61q4bM51u9oF7tKln340Fm2',
                banner:[],
								edit:false,
								Navbar:null,
								Title:null,
								Cards:null,
								Social:'',
								People:'',
								Footer:''
            };

      }


      			componentDidMount(){
      				Helpers.GetHomepageInfo(this.state.uid,(des)=>{
      		 this.setState({
      		 	Navbar:des.Navbar,
      			Title:des.title.title,
      			Cards:des.cards,
      			Social:des.social,
      			People:des.testimonio,
      			Footer:des.footer

      		 })
      		 })
      				if(localStorage.getItem('userData')){
      					let myItem = localStorage.getItem('userData')
      					const info=JSON.parse(myItem)
      					if(info.type=='MASTER'){
      						//return (<Redirect to={'/admin'}/>)
      						this.setState({edit:true})
      					}
      					if(info.type=='admin'){
      						this.setState({edit:true})
      					}

      				}
      			}

  render(){
    const cards=this.state.Cards
    const navbar=this.state.Navbar
    const social=this.state.Social
    const people=this.state.People
    const footer=this.state.Footer
    const title=this.state.Title
		return(
    <div className="backgroundHomePage overflow-hidden">
    {this.state.Navbar?
        <Navbar edit={this.state.edit} data={navbar}/>:null}
        <Fade>
          <Carousel edit={this.state.edit}/>
        </Fade>
        {title?
          <Fade>
            <Title  edit={this.state.edit} data={title}/></Fade>:null}
              {this.state.Cards?
          <Fade>
          <AboutCards edit={this.state.edit} data={cards}/></Fade>:null}
        {this.state.Social?
<Socialbars edit={this.state.edit} data={social}/>:null}
        {this.state.Social?
              <Fade><CardVideoHome data={social}/></Fade>:null}
              {this.state.People?
                <Fade><People edit={this.state.edit} data={people}/></Fade>:null}
                  {this.state.Social?
								<Modal ruleta={true} data={social} time={"3000"}/>:null}
                {this.state.Footer?
                  <Footer edit={this.state.edit} data={footer}/>:null}

    </div>

			)
    }






}

export default HomePage;
