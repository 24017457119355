import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Helpers from '../../lib/Helper'
import Navbar from './Navbar';
import Post from '../blog/postView'
import Iframe from 'react-iframe'
class Easy extends Component {
  constructor(props){
    super(props)
    this.state={
      uid:'8B57J61q4bM51u9oF7tKln340Fm2',
      show:false,
      title:'',
      createPost:false,
      edit:false,
      Navbar:null,
      posts:true,
      topics:false,
      editpu:false

    }
    this.handleClose=this.handleClose.bind(this)
    this.handleTopics=this.handleTopics.bind(this)
    this.handleEdit=this.handleEdit.bind(this)

  }
  componentDidMount(){
    if(localStorage.getItem('userData')){
      let myItem = localStorage.getItem('userData')
      const info=JSON.parse(myItem)
      if(info.type=='MASTER'){
        //return (<Redirect to={'/admin'}/>)
        this.setState({editpu:true})
      }
      if(info.type=='admin'){
        this.setState({editpu:true})
      }

    }
    Helpers.GetHomepageInfo(this.state.uid,(des)=>{

 this.setState({
  Navbar:des.Navbar
 })
 })
  }

handleClose(){
  this.setState({
    createPost:!this.state.createPost,
    topics:false,
    edit:false,
    posts:true
  })
}
handleEdit(){
  this.setState({
    createPost:!this.state.createPost,
    edit:true,
    posts:false
  })
}
handleTopics(){
  this.setState({
    createPost:!this.state.createPost,
    topics:true,
    posts:false
  })
}
  render() {
    return (
      <div>
      {this.state.Navbar?
            <Navbar edit={false} data={this.state.Navbar}/>:null}
    <Container fluid className="margintop6">
{/* <Iframe url="http://blog.conversationhackers.com/"
        width="100%"
        height="100%"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"/>*/}   
    <Post id={this.props.id}/>
    		</Container>
        </div>
    );
  }
}

export default Easy;
