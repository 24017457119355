import React, { useState} from 'react';
import '../../Styles/App.css';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import {NavLink,Redirect,withRouter,Link} from 'react-router-dom'
import Home from './Home';
import Sucursales from './Sucursales';
import Membership from './Membership';
import Settings from './Settings';
import Reportes from './Reportes';
import Coaches from './Coaches'
import classes from './menu.module.css';
import * as AdminMenu from '../../Constants/Menu'
import logo from '../../Media/Conversation_Hackers_Logo.png'
import home from '../../Media/Home.png'
import place from '../../Media/Place.png'
import membership from '../../Media/Membership.png'
import status from '../../Media/Status.png'
import settings from '../../Media/Settings.png'
import reports from '../../Media/Report.png'



const Menu = () =>{
  const [redirectToReferrer,setRedirectToReferrer]=useState();
    const logOut = () =>{
        localStorage.removeItem('userData');

       alert("saliste");
       setRedirectToReferrer(true);
     }



    if (redirectToReferrer) {
     return (<Redirect to={'/'}/>)
   }

   if(localStorage.getItem('userData')){
     let myItem = localStorage.getItem('userData')
     const info=JSON.parse(myItem)
     if(info.type=='MASTER'){
      // return (<Redirect to={'/admin'}/>)
     }
     if(info.type=='Student'){
       return (<Redirect to={'/Profile'}/>)
     }
     if(info.type=='Coach'){
       return (<Redirect to={'/Coach'}/>)
     }
     if(info.type=='admin'){
       return (<Redirect to={'/admins'}/>)
     }

   }else {
     return (<Redirect to={'/login'}/>)
   }

    return (
        <Navbar collapseOnSelect expand="lg"  className="Menu navbar-blue"  sticky="top">
        <Navbar.Brand > <img src={logo} className="Logo_Img_Sm"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse className={classes.root}>
            <Nav className="mr-auto">
            <NavLink to={'/admin'} activeClassName={classes.activeMenu} className={classes.link}><img src={home} className="CH_SM_Icono"/>{AdminMenu.HOME}</NavLink>
            <NavLink to={'/admin/sucursal'} activeClassName={classes.activeMenu} className={classes.link}><img src={place} className="CH_SM_Icono"/>{AdminMenu.PLACES}</NavLink>
            <NavLink to={'/admin/membership'} activeClassName={classes.activeMenu} className={classes.link}><img src={membership} className="CH_SM_Icono"/>{AdminMenu.MEMBERSHIPS}</NavLink>
            <NavLink to={'/admin/settings'} activeClassName={classes.activeMenu} className={classes.link}><img src={settings} className="CH_SM_Icono"/>{AdminMenu.CONFIG}</NavLink>
            <NavLink to={'/admin/notifications'} activeClassName={classes.activeMenu} className={classes.link}><img src={reports} className="CH_SM_Icono"/>{AdminMenu.NOTIFICATIONS}</NavLink>
            <NavLink to={'/admin/reports'} activeClassName={classes.activeMenu} className={classes.link}><img src={reports} className="CH_SM_Icono"/>{"Reportes"}</NavLink>
            </Nav>
            <Nav>
              <Nav.Link eventKey={2} href="#" onClick={()=>logOut()}>
                <span className="white-text">{AdminMenu.LOGOUT}</span>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

    );

}

export default Menu;
