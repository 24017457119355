import React, { Component } from 'react';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import ModalDialog from 'react-bootstrap/ModalDialog'

class ModalChild extends Component {
  constructor(props){
          super(props);
          this.state={
                uid:'8B57J61q4bM51u9oF7tKln340Fm2',
              setShow:true,
							ended:false,
							talk:false
            };
            this.setShow=this.setShow.bind(this)
      }

      setShow(e){
        this.setState({setShow:e})
      }
      close(){
        this.setState({setShow:false})
        setTimeout(
      function() {
            this.setState({setShow:true})
      }
      .bind(this),
      5000
  )
      }

  render() {
    return (

        <Modal
          show={this.state.setShow}
          backdrop={true}
        backdrop="static"
          onHide={() => this.close()}

        >
        <Modal.Header closeButton>
      <Modal.Title>{this.props.title}
      {this.props.image?<img src={this.props.image} className="img-100a"/>:null}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
    {this.props.children}
  </Modal.Body>


          </Modal>
    );
  }
}

export default ModalChild;
