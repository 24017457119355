import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../Styles/App.css';

class Gray_Template extends Component {



  render(){
    const {children}=this.props;
		return(
        <Container>
  <Row className="Center">
    <Col className="Front">
    {children}


    </Col>
</Row>
</Container>

			)
    }






}

export default Gray_Template;
