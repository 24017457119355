import React from 'react'
import Button from 'react-bootstrap/Button'
import * as Web from '../../../../Constants/Web'
//this.props.fetchAddPost(count,'Update/Update_counterBA.php')

  //this.props.DeletePost(data,'Delete/delete_Week.php')
const CancelClass = (props)=>{
  const {id_class, id_student, update}=props

  const removeClass = ()=>{
    const data={
      id_student:id_student,
      id_agenda_Week:id_class
    }
    var url = Web.wurl+'/Api/conversationHackers/Delete/delete_Week.php';
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.text())
    .catch(error => console.error('Error:', error))
    .then(response => {
      alert(response)
      update()
    })
  }

  return(<Button variant="danger"  onClick={()=>removeClass()}>
          x
          </Button>)
      }


export default CancelClass
