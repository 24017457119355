import React, { Component } from 'react';
import { QRCode } from "react-qr-svg";
import { connect } from 'react-redux';
import Badge from 'react-bootstrap/Badge'
import CHlogo from '../../Media/Conversation_Hackers_Logo.png'
import { Edit } from '../../reducers/postsReducer';
import Agenda from '../Agenda/Agenda'
import Gray_Template from '../MainComponents/Gray_Template'
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card_video from '../Media/Card_video'
import '../../Styles/App.css';
import Menu from './Menu'
import * as Web from '../../Constants/Web'

class App extends Component {

  constructor(props){
          super(props);
          this.state={
              Datos:[],
              url:[],
              url:Web.wurl+'/',
              videos:[]
            };
      }
      componentWillMount(){
        let myItem = localStorage.getItem('userData')
        const info=JSON.parse(myItem)
        //alert(info.Id)
        //alert(JSON.stringify(info))

        const student={
          id:info.Id
        }
        var url = this.state.url+'Api/Get_VideosStudents.php';
        fetch(url, {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(student), // data can be `string` or {object}!
          header: {'Content-Type': 'application/json'}
        }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(response => {this.setState({videos:response.reverse()})} );

        this.setState({
          url:this.state.id
        })
        }

  render() {
    const student=this.state.videos
    let myItem = localStorage.getItem('userData')
    const info=JSON.parse(myItem)
    const data = { "Id":info.Id, "type":"Student" };
    return (
      <Gray_Template>
      <h1>
  English Lab
      </h1>

  <hr />
      {student.map(item => (

        <div className={'profile-info'}>
            <Container key={item}>
          <Row>
            <Col sm >
            <Card_video video={item.url_video} title={item.title_video} desc={item.desc_video} url={item.url_dir_video} date={item.Date_video}/>
            </Col>
          </Row>
          <hr/>
        </Container>
</div>
          ))}
  </Gray_Template>
    );
  }
}

export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({

              edit: state.edit.edit,
    }),
    //mapeo de funciones
    {
        Edit
        }
)(App);
