import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Helpers from '../../lib/Helper'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Images from '../../Constants/images'
import Videos from '../../Constants/videos'
import ButtonFree from './ButtonFreeClass'
import Form from 'react-bootstrap/Form'
import Zoom from 'react-reveal/Fade';
class CardsAbout extends Component {
  constructor(props){
    super(props)
    this.state={
      uid:'8B57J61q4bM51u9oF7tKln340Fm2',
      show:false,
      title:'',
      banks:false,
      cash:false
    }
    this.handleMouseHover=this.handleMouseHover.bind(this)
    this.handleMouseleave=this.handleMouseleave.bind(this)
    this.handleMouseHoverCash=this.handleMouseHoverCash.bind(this)
    this.handleMouseleaveCash=this.handleMouseleaveCash.bind(this)

  }
  handleMouseHover(){
  ///  alert('mostar')
    this.setState({banks:true})
  }

  handleMouseleave(){
  ///  alert('cerrar')
    this.setState({banks:false})
  }
  handleMouseHoverCash(){
  ///  alert('mostar')
    this.setState({cash:true})
  }

  handleMouseleaveCash(){
  ///  alert('cerrar')
    this.setState({cash:false})
  }

  render() {
    return (
      <div className="mainsticky">
      <div className="sticky CH_NR">
        <Zoom left cascade >
        <Col >
        <img src={Images.FreeClass.msg} className="img-100"/>
        <video preload="none" src={Videos.prices.general} muted controls autoPlay className="video-prices"/>
        </Col>
        </Zoom>
      </div>
      <div className="CH_Responsive">
        <Zoom left cascade >
        <Col >
        <img src={Images.FreeClass.msg} className="img-100"/>
        <video preload="none" src={Videos.prices.general} muted controls autoPlay className="video-prices"/>
        </Col>
        </Zoom>
      </div>

      </div>
    );
  }
}

export default CardsAbout;
