import React, { useState, useEffect } from 'react';
import * as URL from '../../../Constants/services'
import axios from 'axios';
import {
  useStripe,
} from '@stripe/react-stripe-js';


export const useOxxo = (  ) =>{
  const [data, setData] = useState();
  const [ confirmPaymentStatus, setConfirmPaymentStatus ] = useState(null);
  const [clientSecret, setClientSecret] =useState();
  const [name ,setName] =useState();
  const [email, setEmail]= useState();
  const [isDisabled, setIsDisabled] = useState(true);

  const createPaymentIntent = ()=>{

      const stripeData = {
        amount:250000,
        typePayment:'oxxo'
      }

      var url = URL.api+"/getPaymentIntentStripe";

          axios.post(url, stripeData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then(({data}) => {
        const key=data.split('{"client_secret":')[1]
        const key2=key.replace("}","")
        setClientSecret(key2.replace(/['"]+/g, ''));

      })


  }

  useEffect(()=>{
    if (name && email ) {
      setIsDisabled(false);
    }else {
      setIsDisabled(true);
    }
  },[name,email])


  return{
    data,
    createPaymentIntent,
    clientSecret,
    isDisabled,
    name,
    email,
    setName,
    setEmail
  }
}
