export const City="Ciudad";
export const Address="Dirección";
export const State="Estado";
export const Zip="CP";
export const Phone="Teléfono";
export const Email="Correo";
export const addPlace="Agregar sucursal";
/////////////////personal PersonalInformation


export const Name="Nombre";
export const LastName="Apellido";
export const Year_Birthdate="Años cumplidos";
/////addplaces
export const placeName="Nombre"
export const placeAddress="Dirección"
export const placeCity="Ciudad"
export const placeState="Estado"
export const placeZip="Código Postal"
export const placePhone="Teléfono"
export const placeEmail="Email"



//actions
export const Edit="Editar";
export const Cancel="Cancelar"
export const Save="Guardar"
export const Empty="Vacío"
export const EmptyMemberships="Primero debes agregar membresías"
export const EmptyLevels="Primero debes agregar niveles"
export const Pay="Pagar"
export const Inactive="Inactivo"
export const Options="Opciones"
export const Update="Actualizar"
export const Settings="Configuración"
export const OnlineSettings="Configuración ONLINE"
export const Questions="preguntas"



///label//
export const Students="Estudiantes"
export const Coach="Coaches"
export const Birthday="Cumpleaños este mes"
export const Agenda="Agenda"
export const GeneralAgenda="Agenda General"
export const Reports="Reportes"
export const Media="Media"
export const Congratulations="Felicidades!"
export const SettingInfo="Aquí podrás realizár modificaciones"
export const Gallery="Galería"
export const Levels="Niveles"
export const States="Estados"
export const Admins="Admins"
export const Classrooms="Salones"
export const HomePage="Pagina Inicio"
export const MaxMin="Horas establecidas"
export const Min="Min"
export const Max="Max"
export const Range="Crea un rango de horarios"
