import React, { Component } from 'react';
import '../../Styles/App.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import * as Web from '../../Constants/Web'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux';
import {Agenda,fetchAddPost,DeletePost,fetchGetPost2,
  fetchGetTimeAgenda,
  fetchGetCoaches
} from '../../reducers/postsReducer';
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {Redirect} from 'react-router-dom';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import GrayTemplate from '../MainComponents/Gray_Template_divide'
import Colors from '../../Constants/Colors'
import ReactAgenda from './src/reactAgenda';
import DaysSelected from './components/Student/showSelectedAdminView'
import {OldClassHistory} from './components/Student'
import classes from './agendaStudentAdmin.module.css'
require('moment/locale/es-us.js'); // this is important for traduction purpose

var now = new Date();
//var items=[];
var coaches='';
const  fecha =  new Date();
var anio = fecha.getFullYear();
var mes = fecha.getMonth()+1;
var dia = fecha.getDate();
var priorityNumber;
class AgendaSelect extends Component {

  constructor(props){
          super(props);
          this.state={
              items:[],
              cellHeight:35,
              rowsPerHour:1,
              numberOfDays:7,
              quantityAgendaNew:'',
              loader:'',
              url:Web.wurl+'/',
              show:false,
              Time:'',
              Coach:'',
              Id:'',
              Info:'',
              Level:'',
              Color:'',
              Day:'',
              DayAgenda:'',
              Classroom:'',
              scheduled:'',
              counter:'',
              full:'',
              student:'',
              agendaselected:'',
              loader:'1',
              Priority:'',
              idStudentSelected:''
            };
      }

      componentDidMount(){
        this.update()
      }
      update(){

        var items=[];
        let myItem = localStorage.getItem('userData')
        //const info=JSON.parse(myItem)
        //alert(JSON.stringify(info))

        this.setState({idplaces:this.props.idPlace})
        const student={
          Id_Student_Week:this.props.idStudent
        }

        const studentinfo={
          id:this.props.idStudent
        }
var url = this.state.url+'Api/Get_Student.php';
        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(studentinfo), // data can be `string` or {object}!
            header: {'Content-Type': 'application/json'}
          }).then(res => res.json())
          .catch(error => console.error('Error:', error))
          .then(response => {
            this.setState({student:response})
              console.log(JSON.stringify(response));
            if(this.state.student){
              var stud = this.state.student[Object.keys(this.state.student)];
              priorityNumber=stud.Level_Priority
              this.setState({
                Priority:stud.Level_Priority,
                idStudentSelected:stud.Id_Student
              })
              if(stud.Sessions=='0'){
                //alert(stud.Sessions)
                this.setState({
                  pay:'1'
                })
              }
            }


          });


          const getagenda={
            id:this.props.idPlace
          }
          var url = this.state.url+'Api/Get_AgendasNamePlace.php';
          fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(getagenda), // data can be `string` or {object}!
            header: {'Content-Type': 'application/json'}
          }).then(res => res.json())
          .catch(error => console.error('Error:', error))
          .then(response => {

            response.forEach((data) => {
            						var myJSON = JSON.stringify(data);
                        //alert(data.Id_Agenda_Names)
                        this.setState({
                          agendaselected:data.Id_Agenda_Names
                        })

                      })

                      const data={
                        id:this.props.idPlace,
                        agendaid:this.state.agendaselected,
                        Priority:priorityNumber,
                        year:anio,
                        studentId:this.props.idStudent
                      }
                      //var url = this.state.url+'Api/Get_AgendaPriority.php';
                    var url = this.state.url+'Api/conversationHackers/Get_Agenda_student.php';

                      fetch(url, {
                        method: 'POST', // or 'PUT'
                        body: JSON.stringify(data), // data can be `string` or {object}!
                        header: {'Content-Type': 'application/json'}
                      }).then(res => res.json())
                      .catch(error => console.error('Error:', error))
                      .then(response => {
                        response.forEach((data) => {
                               var myJSON = JSON.stringify(data);
                               var initial=data.quantityAgenda;
                                      items.push(
                                        {
                                         _id            :data.Id_Agenda,
                                          name          : data.infoAgenda,
                                          startDateTime : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourFrom, data.minutesFrom),
                                          endDateTime   : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourTo, data.minutesTo),
                                          classes       : data.colorAgenda,
                                          coaches:data.id_coachAgenda,
                                          classroom:data.id_classroomAgenda,
                                          quantity:data.quantityAgenda,
                                          level:data.levelAgenda,
                                          backgroundColor:data.colorAgenda,
                                          hourFrom:data.hourFrom,
                                          minutesFrom:data.minutesFrom,
                                          hourTo:data.hourTo,
                                          minutesTo:data.minutesTo,
                                          hexa:data.hexa,
                                          quantityAgendaNew:data.quantityAgendaNew

                                        });


                             })
                             this.setState({
                               items:items,
                               loader:''
                             })


                       });
          //  this.props.fetchGetPost2('Get_Agenda_WeekBA.php',student)///mostrar las clases seleccionada


           });


      }
      updates(){
      //  let myItem = localStorage.getItem('userData')
        //const info=JSON.parse(myItem)
        //alert(JSON.stringify(info))
      /*  const data={
          id:this.props.idPlace
        }*/
        //this.props.idPlace
        //this.props.idStudent
      /*  const student={
          Id_Student_Week:this.props.idStudent
        }
        this.props.fetchGetTimeAgenda('Get_AgendaTime.php',data)*/
        /*this.props.Agenda('Get_Agenda_Students.php',data)///mostrar agenda*/
        /*this.props.fetchGetPost2('Get_Agenda_WeekBA.php',student)///mostrar la clase seleccionada
        this.setState({
        Id:'',
        DayAgenda:'',
        Coach:'',
        Color:'',
        Classroom:'',
        Level:'',
        show:false
      })*/
        /*setTimeout(
    function() {
        this.setState({position: 1});
        this.setState({loader:''})
    }
    .bind(this),
    3000

);*/



      }
      handleClose() {
    this.setState({ show: false });
  }

  handleShow(e) {
this.setState({
  Time:'',
  Id:'',
  Coach:'',
  Info:'',
  Level:'',
  Color:'',
  Day:'',
  DayAgenda:'',
  Classroom:'',
  counter:''})
  var coachname=''
  //alert(JSON.stringify(info))
  //this.setState({idplaces:info.Id})

  const coachesplaces={
    id:this.state.idplaces
  }
  var url = this.state.url+'Api/Get_PlaceCoaches.php';
  fetch(url, {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(coachesplaces), // data can be `string` or {object}!
    header: {'Content-Type': 'application/json'}
  }).then(res => res.json())
  .catch(error => console.error('Error:', error))
  .then(response => {
    response.forEach((data) => {
                if(data.Id==e.coaches){
                   coachname=data.Coach_Name+' '+data.Last_Name+' '+data.Second_Lastname
                   this.setState({ show: true,
                     Time:e.hourFrom+":0"+e.minutesFrom+"-"+e.hourTo+":0"+e.minutesTo,
                     Id:e._id,
                     Coach:coachname,
                     Info:e.name,
    //                 Level:e.level,
                     Color:e.hexa,
                     Day:e.day_agenda,
                    // DayAgenda:e[7],
                     Classroom:e.classroom,
                     counter:e.quantity,
                     quantityAgendaNew:e.quantityAgendaNew
                    });
                }});

   } );
   const levelsname={
     id:this.state.idplaces
   }
   var url = this.state.url+'Api/Get_ActiveLevels.php';
   fetch(url, {
     method: 'POST', // or 'PUT'
     body: JSON.stringify(levelsname), // data can be `string` or {object}!
     header: {'Content-Type': 'application/json'}
   }).then(res => res.json())
   .catch(error => console.error('Error:', error))
   .then(response => {
     response.forEach((data) => {
                 if(data.Id_Level==e.level){

                    const levelname=data.Name
                    this.setState({
                      Level:levelname

                     });
                 }});

    } );

  }

  Agenda(){
    this.setState({loader:'1'})
    const idClass={
      id:this.state.Id
    }
    var url = this.state.url+'Api/Get_CounterClass.php';
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(idClass), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => {
      response.forEach((data) => {

                  if(parseInt(data.quantityAgenda)>=parseInt(data.quantityAgendaNew)){


                            /*-----------------------------------------------------------------------------*/
                            const idClassStudentsCounter={
                              id:this.state.Id
                            }
                            var url = this.state.url+'Api/Get_CounterStudentClass.php';
                            fetch(url, {
                              method: 'POST', // or 'PUT'
                              body: JSON.stringify(idClassStudentsCounter), // data can be `string` or {object}!
                              header: {'Content-Type': 'application/json'}
                            }).then(res => res.json())
                            .catch(error => console.error('Error:', error))
                            .then(response => {
                            ///alert(response[0])
                            if (parseInt(response[0], 10)>=parseInt(data.quantityAgenda,10)) {
                              alert('CLASE LLENA');
                              this.update()
                            }else {
                              /*se puede agendar*/
                              //alert(parseInt(response[0], 10)+'aaa'+parseInt(data.quantityAgenda,10))
                              var integer = parseInt(this.state.quantityAgendaNew, 10)
                              var session_new=integer+1
                              const count={
                                Day_Agenda:this.state.DayAgenda,
                                Id_Agenda_Week:this.state.Id,
                                counter:session_new
                              }


                              this.props.fetchAddPost(count,'Update/Update_counterBA.php')

                              let myItem = localStorage.getItem('userData')
                              const place=this.props.idPlace
                          const id=this.props.idStudent
                          //this.props.idPlace
                          //this.props.idStudent
                          const data={
                            Id_Student_Week:id,
                            Id_Place_Week:place,
                            Id_Agenda_Week:this.state.Id,
                            //Day_Agenda:this.state.DayAgenda,
                            Id_Coach:this.state.Coach,
                            Color:this.state.Color,
                            Classroom:this.state.Classroom,
                            Level_Name:this.state.Level
                          }
                          this.props.fetchAddPost(data,'Create/Create_Week.php')
                              alert("Agendado correctamente")
                              this.setState({loader:false, show:false})
                              this.update()

                            }
                            } );

                                    /*-------------------------------------------------------------------------*/

                  }else {
                    alert('clase llena_')
                    this.setState({loader:false, show:false})
                    this.update()
                  }});

      } );


  }


nextWeek(){
  var items=[];
this.setState({loader:'1'})
  let myItem = localStorage.getItem('userData')

  //this.props.idPlace
  //this.props.idStudent

  const student={
    Id_Student_Week:this.props.idStudent
  }

  const studentinfo={
    id:this.props.idStudent
  }
var url = this.state.url+'Api/Get_Student.php';
  fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(studentinfo), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => {
      this.setState({student:response})

      if(this.state.student){
        var stud = this.state.student[Object.keys(this.state.student)];
        if(stud.Sessions=='0'){
          //alert(stud.Sessions)
          this.setState({
            pay:'1'
          })
        }
      }


    });


    const getagenda={
      id:this.props.idPlace
    }
    var url = this.state.url+'Api/Get_AgendasNamePlace2.php';
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(getagenda), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => {

      response.forEach((data) => {
                  var myJSON = JSON.stringify(data);
                  //alert(data.Id_Agenda_Names)
                  this.setState({
                    agendaselected:data.Id_Agenda_Names
                  })

                })

                const data={
                  id:this.props.idPlace,
                  agendaid:this.state.agendaselected
                }
                var url = this.state.url+'Api/Get_Agenda.php';
                fetch(url, {
                  method: 'POST', // or 'PUT'
                  body: JSON.stringify(data), // data can be `string` or {object}!
                  header: {'Content-Type': 'application/json'}
                }).then(res => res.json())
                .catch(error => console.error('Error:', error))
                .then(response => {


                  response.forEach((data) => {
                         var myJSON = JSON.stringify(data);
                         var initial=data.quantityAgenda;
                         var final=data.quantityAgendaNew;
                           //alert(data.quantityAgendaNew+" "+data.quantityAgenda)
                              //var myJSON = JSON.stringify(data);
                              //alert(now.getMonth())
                              //alert(data.quantityAgendaNew+data.quantityAgenda)
                              if(final==initial){
                                items.push(
                                  {
                                    _id            :'null',
                                     name          :'lleno',
                                     classes       : 'gray',

                                  });
                              }else {
                                items.push(
                                  {
                                   _id            :data.Id_Agenda,
                                    name          : data.infoAgenda,
                                    startDateTime : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourFrom, data.minutesFrom),
                                    endDateTime   : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourTo, data.minutesTo),
                                    classes       : data.colorAgenda,
                                    coaches:data.id_coachAgenda,
                                    classroom:data.id_classroomAgenda,
                                    quantity:data.quantityAgenda,
                                    level:data.levelAgenda,
                                    backgroundColor:data.colorAgenda,
                                    hourFrom:data.hourFrom,
                                    minutesFrom:data.minutesFrom,
                                    hourTo:data.hourTo,
                                    minutesTo:data.minutesTo,
                                    hexa:data.hexa,
                                    quantityAgendaNew:data.quantityAgendaNew

                                  });
                              }

                       })
                       this.setState({
                         items:items,
                         loader:''
                       })

                 });
       this.props.fetchGetPost2('Get_Agenda_WeekBA.php',student)///mostrar la clase seleccionada

this.setState({loader:''})

     });

}
oldWeek(){
  var items=[];
this.setState({loader:'1'})
  let myItem = localStorage.getItem('userData')

  //this.props.idPlace
  //this.props.idStudent
  const student={
    Id_Student_Week:this.props.idStudent
  }

  const studentinfo={
    id:this.props.idStudent
  }
var url = this.state.url+'Api/Get_Student.php';
  fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(studentinfo), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => {
      this.setState({student:response})

      if(this.state.student){
        var stud = this.state.student[Object.keys(this.state.student)];
        if(stud.Sessions=='0'){
          //alert(stud.Sessions)
          this.setState({
            pay:'1'
          })
        }
      }


    });


    const getagenda={
      id:this.props.idPlace
    }
    var url = this.state.url+'Api/Get_AgendasNamePlace.php';
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(getagenda), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => {

      response.forEach((data) => {
                  var myJSON = JSON.stringify(data);
                  //alert(data.Id_Agenda_Names)
                  this.setState({
                    agendaselected:data.Id_Agenda_Names
                  })

                })

                const data={
                  id:this.props.idPlace,
                  agendaid:this.state.agendaselected
                }

                var url = this.state.url+'Api/Get_Agenda.php';
                fetch(url, {
                  method: 'POST', // or 'PUT'
                  body: JSON.stringify(data), // data can be `string` or {object}!
                  header: {'Content-Type': 'application/json'}
                }).then(res => res.json())
                .catch(error => console.error('Error:', error))
                .then(response => {
                //  alert('si')
                  response.forEach((data) => {
                         var myJSON = JSON.stringify(data);
                         var initial=data.quantityAgenda;
                         var final=data.quantityAgendaNew;
                           //alert(data.quantityAgendaNew+" "+data.quantityAgenda)
                              //var myJSON = JSON.stringify(data);
                              //alert(now.getMonth())
                              //alert(data.quantityAgendaNew+data.quantityAgenda)
                              if(final==initial){
                                items.push(
                                  {
                                    _id            :'null',
                                     name          :'lleno',
                                     classes       : 'gray',

                                  });
                              }else {
                                items.push(
                                  {
                                   _id            :data.Id_Agenda,
                                    name          : data.infoAgenda,
                                    startDateTime : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourFrom, data.minutesFrom),
                                    endDateTime   : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourTo, data.minutesTo),
                                    classes       : data.colorAgenda,
                                    coaches:data.id_coachAgenda,
                                    classroom:data.id_classroomAgenda,
                                    quantity:data.quantityAgenda,
                                    level:data.levelAgenda,
                                    backgroundColor:data.colorAgenda,
                                    hourFrom:data.hourFrom,
                                    minutesFrom:data.minutesFrom,
                                    hourTo:data.hourTo,
                                    minutesTo:data.minutesTo,
                                    hexa:data.hexa,
                                    quantityAgendaNew:data.quantityAgendaNew

                                  }
                                  /*{
                                  _id            :data.Id_Agenda,
                                   name          : data.infoAgenda,
                                   startDateTime : new Date(data.year_agenda, data.month_agenda, data.day_agenda, data.hourFrom, data.minutesFrom),
                                     endDateTime   : new Date(data.year_agenda, data.month_agenda, data.day_agenda, data.hourTo, data.minutesTo),
                                     classes       : 'color-2'
                                }*/);
                              }

                       })
                       this.setState({
                         items:items,
                         loader:''
                       })

                 });
       this.props.fetchGetPost2('Get_Agenda_WeekBA.php',student)///mostrar la clase seleccionada
this.setState({loader:''})


     });

}
  render() {
    /*if(this.state.student){
      var stud = this.state.student[Object.keys(this.state.student)];
      if(stud.Sessions=='0'){
        this.setState({
          pay:'1'
        })
      }
    }*/

    const time=this.props.time///agenda
    const ready=this.props.posts//ready
    const agendatime=this.props.TimeAgenda


    coaches=this.props.placecoaches
    //alert(JSON.stringify(monday))

    return (
      <GrayTemplate>
      {this.state.loader?
        <div className="center2">
        <Loader
             type="Puff"
             color="#00BFFF"
             height="100"
             width="100"
          />
        </div>:
    <Row className={classes.root}>
    <Col className="Front_divide" md={3}>
    <h4>Agendado</h4>
      <span>Clases son mosotradas con el horario de el alumno</span>
    <hr/>

    <DaysSelected
    id={this.props.idStudent}/>



    </Col>
    <Col className="Front_divide" xs={8} md={8}>
    {this.state.pay=='1'?<h4>Necesitas renovar tu membresía</h4>:
    <div>
      <Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered className={"white-text"} >
        <Modal.Header closeButton style={{ backgroundColor:this.state.Color}} className={"white-text"}>
          <Modal.Title id="contained-modal-title-vcenter" ><h6>{this.state.Level}  {this.state.Time}</h6></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor:this.state.Color}}>
        <h6>Coach:{this.state.Coach}</h6>
        <p>
          {this.state.Info}
        </p>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor:this.state.Color}}>
          <Button variant="secondary" onClick={this.handleClose.bind(this)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={this.Agenda.bind(this)}>
            Agendar
          </Button>
        </Modal.Footer>
      </Modal>

  <hr/>
  <div className={classes.agenda}>

    <span>Agenda es mosotrada con el horario de el alumno</span>
  <ReactAgenda
      minDate={new Date(now.getFullYear(), now.getMonth()-2, now.getDate()-5)}
      maxDate={new Date(now.getFullYear(), now.getMonth()+2)}
      disablePrevButton={true}
      startDate={this.state.startDate}
      cellHeight={this.state.cellHeight}
      locale={this.state.locale}
      items={this.state.items}
      numberOfDays={this.state.numberOfDays}
      rowsPerHour={this.state.rowsPerHour}
      itemColors={Colors.Agenda}
      autoScale={true}
      fixedHeader={true}
      onItemEdit={this.handleShow.bind(this)}/>
      </div>



</div>}
   </Col>
  </Row>}
  <OldClassHistory student_id={this.props.idStudent}/>
</GrayTemplate>

    );
  }
}
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        time: state.posts.time,
        posts:state.posts.dato,
        TimeAgenda:state.posts.TimeAgenda,
        placecoaches:state.placecoaches.placecoaches,
    }),
    //mapeo de funciones
    {
      Agenda,fetchAddPost,fetchGetPost2,DeletePost,fetchGetTimeAgenda,
    }
)(AgendaSelect);
