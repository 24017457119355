import { createLayoutPlugin } from '@react-page/create-plugin-materialui';
import React from 'react';
import { defaultSlate, customizedSlate } from './slate';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default () =>
  createLayoutPlugin<{
    backgroundColor: string;
  }>({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Renderer: ({ children, state }: any) => (
      <div
        style={{
          border: '1px solid black',
          backgroundColor: state.backgroundColor,
        }}
      >
        {children}
      </div>
    ),
    createInitialChildren: () => {
      return [
        [
          {
            content: {
              plugin: customizedSlate,
              state: customizedSlate.createInitialSlateState(({ plugins }) => ({
                children: [
                  {
                    plugin: plugins.headings.h2,
                    children: ['Hello world'],
                  }
                ],
              })),
            },
          },
        ],
      ];
    },

    name: 'custom-layout-plugin-initial-state',
    text: 'Custom layout plugin with initial text',
    description: 'Some custom layout plugin with initial text',
    version: '0.0.1',
    schema: {
      required: ['backgroundColor'],
      properties: {
        backgroundColor: { type: 'string' },
      },
    },
  })();
