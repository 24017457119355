import React from 'react';
import * as Web from '../../Constants/Web'
import Table from 'react-bootstrap/Table'
import Flag from 'react-world-flags'
 var from
 var to
 var daynumber
  let month
  let year
  var daymonth
  var hourClass
  var hourMinutes
  var hour_end
  var hour_endMinutes
export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url:Web.wurl+'/',
      countryName:'',
      countryCode:'',
      Time_zone:'',
      myTime_zoneHour:'',
      myDayTime_zone:'',
      mytimeAMPM:'',
      hora:'',
      timezones:[]
    };

  }

  componentDidMount(){
    var item=[]
     from =new Date(this.props.dateFrom);
     to =new Date(this.props.dateTo);
     daynumber = from.getDay();
      month = from.getMonth() + 1;
      year = from.getFullYear();
      daymonth = from.getDate();
      hourClass = from.getHours();
      hourMinutes = from.getMinutes();
      hour_end = to.getHours();
      hour_endMinutes = to.getMinutes()
      var url = 'https://ipapi.co/json/';
              fetch(url)
            .then(response => response.json())
          .then((data)=>{
            var myCountry=data.timezone
            var aestTime = new Date().toLocaleString("en-US", {timeZone:myCountry});
            var naestTime = new Date(aestTime);
             var myhour=naestTime.getHours();
            this.setState({
            myTime_zoneHour:naestTime.getHours(),
              myDayTime_zone:naestTime.getDate(),
            })
            //myTime_zoneHour:naestTime.getHours(),
            //mytimeAMPM: myhour >= 12 ? 'pm' : 'am'
            this.setState({
              countryName: data.country_name,
    					countryCode: data.country_code,
    					Time_zone:data.timezone,
              hora:aestTime
            })
              /*-----------------------------------------------FETCHING TIMEZONE FROM THE STUDENTS */
              const times={
                id:this.props.id,
              }
              var url = this.state.url+'Api/Get_Timezones.php';
              fetch(url, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(times), // data can be `string` or {object}!
                header: {'Content-Type': 'application/json'}
              }).then(res => res.json())
              .catch(error => console.error('Error:', error))
              .then(response => {
                response.forEach((data) => {
                       var myJSON = JSON.stringify(data);

                       if(data.Time_zone){
                         var comp = new Date().toLocaleString("en-US", {timeZone:data.Time_zone});
                         var ncomp = new Date(comp);
                         //alert(data.Time_zone)
                        var hour=ncomp.getHours();
                        var daymonth=ncomp.getDate();
                        //var ampm = hour >= 12 ? 'pm' : 'am';
                        var dif=0
                        if(this.state.myDayTime_zone==daymonth){///si siguen siendo el mismo dia
                          if(hour==this.state.myTime_zoneHour){
                            item.push({
                              countryName: data.Country,
                    					countryCode: data.Country_Code,
                    					Time_zone:data.timezone,
                              hour:hourClass,
                              daynumber:daynumber,
                              month:month,
                               year:year,
                              sign:'+',
                              diff:0
                            })
                          //  alert('es la misma hora')
                        }
                          //var myhour=13//for testing
                        //  alert(hour)
                          if(hour>this.state.myTime_zoneHour){
                          //alert(hour-myhour) diferencia de horas +
                          var to=hour-this.state.myTime_zoneHour
                          var horaclase=to+hourClass
                          item.push({
                            countryName: data.Country,
                            countryCode: data.Country_Code,
                            Time_zone:data.timezone,
                            hour:to+hourClass,
                            sign:'+',
                            diff:hour-this.state.myTime_zoneHour
                          })
                          /*if(horaclase>24&horaclase>0){
                            alert('es mayor')
                          }else {
                            alert('es menor')
                          }*/
                          }
                          if(hour<this.state.myTime_zoneHour){
                        //  alert(myhour-hour) //diferencia de horas -
                        var to=this.state.myTime_zoneHour-hour
                        item.push({
                          countryName: data.Country,
                          countryCode: data.Country_Code,
                          Time_zone:data.timezone,
                          hour:hourClass-to,
                          sign:'-',
                          diff:this.state.myTime_zoneHour-hour
                        })
                          }
                        }else {////si ya no es el mismo dia

                        }
                        this.setState({
                        timezones:item
                        })
                       }

                     })
                     this.setState({
                       loader:''
                     })
               });







                /*--------------------------------------------------------------------------------------------END*/
          }).catch((error)=>{
            alert(JSON.stringify(error))
          });
  }
  render() {
/*
countryName: data.Country,
countryCode: data.Country_Code,
Time_zone:data.timezone,
hour:hourClass,
sign:'-',
diff:myhour-hour*/
    return (
      <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>País</th>
            <th>Hora de clase</th>
          </tr>
        </thead>
        <tbody>
        {this.state.timezones.map(item => (
              <tr  value={item}  key={item}>
                <td>{item.countryCode?<Flag code={item.countryCode} height="16" />:null}{item.countryName}</td>
                <td>{item.hour}:00</td>
              </tr>
            ))}
        </tbody>
      </Table>

      </div>
    );
  }
}
