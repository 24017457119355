import React,{useState} from 'react';
import classes from './generalReport.module.css'
import GrayTemplate from '../../MainComponents/Gray_Template'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import NewStudents from './NewStudents'
import Memberships from './Memberships'
import MembershipMostSell from './MembershipMostSell'
import FutureClass from './FutureClass'
import {usePlaces} from '../hooks/usePlaces';
import SelectedOnThisTime from './SelectedOnThisTime'
import Checkbox from './Checkbox';
import PerClub from './StudentsPerClub';
const General  = (props) =>{

  const {updateNewStudentBars, updateMostSelledMembershipsBars,dataMost}=props;

/*
-Agenda coach- (en blanco) *resuelto*
-Eliminar clases previo a 1 dia en agenda de alumnos (mostraba el mensaje "no se puede cancelar") *resuelto*
-reportes futuros *resuelto*
membresias que ya expiraron filtro *resuelto*
poner cantidades de tipos de membresias y un filtro para encontrar alumnos o informacion

exportar en archivo excel *resuelto*
archivos separados por categorias *resuelto*
Mostrar a todos los admins *resuelto*
graficas
1.alumnos nuevos
2.-tipos de membrerias solicitadas a alumnos nuevos
3.-agendados (ya tienen clase o ya pasaron clases)
4.- no agendados
5.-stand by (cantidad de standby) *resuelto*
saber a cuantos se pasaron a standby durante ese periodo *resuelto*
hacer comparaciones de graficas con N periodos (cambiar barras de colores)
https://meet.google.com/bgp-wzgj-ocv
resolver cantidades de agenda, no es BD es logica de backEnd
no deja subir archivos en coach, tania y tere. paso recientemente.

*/

    const [showNewStudents, setShowNewStudents] = useState(false);
    const [showSelectedOnThisTime, setShowSelectedOnThisTime]=useState(false);
    const [showMembershipsExpiration, setShowMembershipExpiration] = useState(false);
    const [showFutureClass, setShowFutureClass]=useState(false);
    const [showSellers, setShowSellers]=useState(false);
    const [showClubs, setShowClubs]=useState(false);


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [place, setPlace]=useState(22);
    const {places}=usePlaces();



    const onChange = (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    };

    const placesMap = places.map((item)=>{
      return<option value={item.id}>{item.name}</option>
    })
  return(
    <GrayTemplate>

      <div className={classes.root}>
      <h2>Reportes: sucursal{place}</h2>
          <DatePicker
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          withPortal
        />
        {"   "}
        <select onChange={(e)=>setPlace(e.target.value)}>
              <option></option>
        {placesMap}

        </select>


        <div className={classes.containerCheckbox}>
        <span>Categorías</span>
          <Checkbox value={showNewStudents} setValue={setShowNewStudents} label={"Nuevos estudiantes"}/>
          <Checkbox value={showSelectedOnThisTime} setValue={setShowSelectedOnThisTime} label={"Historial"}/>
          <Checkbox value={showMembershipsExpiration} setValue={setShowMembershipExpiration} label={"Membresías a expirar"}/>
          <Checkbox value={showFutureClass} setValue={setShowFutureClass} label={"Clases futuras"}/>
          <Checkbox value={showSellers} setValue={setShowSellers} label={"Membresías más vendidas"}/>
          <Checkbox value={showClubs} setValue={setShowClubs} label={"Clubs pasados"}/>
        </div>
      </div>

      <div className={classes.whiteSpace}></div>

    {showNewStudents?
            <NewStudents
              startDate={startDate}
              endDate={endDate}
              place={place}
              show={showNewStudents}
              updateNewStudentBars={updateNewStudentBars}
              />
          :null}

      {showSelectedOnThisTime?
      <SelectedOnThisTime
        startDate={startDate}
        endDate={endDate}
        place={place}
      />
      :null}

        {/*membresias a expirar*/}
        {showMembershipsExpiration?
        <Memberships
        startDate={startDate}
        endDate={endDate}
        place={place}
        />
        :null}
        {/*membresias mas vendidas*/}
        {showSellers?
        <MembershipMostSell
        dataMost={dataMost}
        updateMostSelledMembershipsBars={updateMostSelledMembershipsBars}
        startDate={startDate}
        endDate={endDate}
        place={place}
        />:null}
        {showFutureClass?
        <FutureClass
        startDate={startDate}
        endDate={endDate}
        place={place}
        />:null}
        {showClubs?
        <PerClub
        startDate={startDate}
        endDate={endDate}
        place={place}
        show={showClubs}
        />:null}

      <div>



      </div>
    </GrayTemplate>);
}

export default General;
