import React, {useEffect, useState} from 'react'
import * as URL from '../../../../Constants/services';
import SearchBarInput from '../SearchBarInput';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import TableContainer from '../TableContainer';
import Title from '../Titles';




const FutureClass = (props) =>{
  const {startDate, endDate, place}= props;
  const [data, setData] = useState([]);
  const [searchTerm,setSearchTerm] = useState('')


useEffect(()=>{
  var url = URL.api+"/futureClass";
  const inf={
    from:startDate,
    to:endDate,
    place:place
  }
  fetch(url, {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(inf), // data can be `string` or {object}!
    header: {'Content-Type': 'application/json'}
  }).then(res => res.json())
  .catch(error => console.error('Error:', error))
  .then(response => {
    setData(response)
  })
},[startDate, endDate])
if (!startDate || !endDate || !data) {
  return'';
}
const list =data.filter((val)=>{
        if(searchTerm == ""){
          return val
        }
        if (!searchTerm) {
          return val;
        }
        else if(val.name.toLowerCase().includes(searchTerm.toLowerCase())){
          return val;
        }
      }).map((val,key)=>{
        return <tr>
        <td>{val.id}</td>
        <td> {val.name}</td>
        <td>{val.lastName+" "+val.secondLastName}</td>
        <td>{val.email}</td>
        <td>{val.phone}</td>
        <td>{val.created}</td>
        </tr>
      })


      const headersFutureClass = [
        { label: "id", key: "id" },
        { label: "name", key: "name" },
        { label: "apellido", key: "lastName" },
        { label: "apellido", key: "secondLastName" },
        { label: "email", key: "email" },
        { label: "ingreso", key: "created" }
      ];
return(
  <TableContainer>
    <Title title={"Clases futuras / agendados"} />
      <SearchBarInput linkToExport={""} fileName={"clases_futuras"} data={data} headers={headersFutureClass} setSearchTerm={setSearchTerm}/>
      <Table>
      <Thead>
        <Tr>
          <Th>ID</Th>
          <Th>Nombre</Th>
          <Th>Apellido</Th>
          <Th>Ingreso</Th>
        </Tr>
      </Thead>
      <Tbody>
        {list}
      </Tbody>
    </Table>
</TableContainer>)

}

export default FutureClass;
