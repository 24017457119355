import React,{useState} from 'react'
import Add from './addFiles'
import {updateFilesCoach} from './Actions/coachActions'

import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import classes from './createday.module.css'
/*
  <Add day={dateTo} callbackFiles={files}/>*/

const CoachActions =(props)=>{


const {filesToShow,dateTo, link, isActiveCV,id}=props
const files=(e)=>{
    setFiles(e)
}
  const [filesJson,setFiles]=useState(filesToShow);

  const [clasevirtual,setLink]=useState(link);
  const [isActive,setActive]=useState(isActiveCV==1?true:false);

  const update=()=>{
    updateFilesCoach(id,clasevirtual,filesJson)
  }
  return(<div>
    <Col className={classes.buttonContainer}>
    {isActive?<h5>Link Zoom</h5>:null}
  {isActive?<input type={"text"} onChange={(e)=>setLink(e.target.value)} value={clasevirtual}/>:null}
  </Col>

<Add  day={dateTo} callbackFiles={files} filesToShow={filesJson}/>
    <Col className={classes.buttonContainer}>
<Button variant="success" type="submit" onClick={update}>guardar</Button>
</Col>
<hr/>
<h5>Lista</h5>
    </div>)


}

export default CoachActions;
