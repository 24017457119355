import React, { Component } from 'react';
import '../../../Styles/App.css';
import Stat from '../../../Media/States.png'
import Card from 'react-bootstrap/Card'
import dateimg from '../../../Media/Calendar.png'
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { connect } from 'react-redux';
import { UpdateActive,fetchGetPost,DeletePost} from '../../../reducers/postsReducer';
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import States from '../Forms/Create_State'
import SearchInput, {createFilter} from 'react-search-input';
import ListGroup from 'react-bootstrap/ListGroup'
import Table from 'react-bootstrap/Table'
import Add from '../../../Media/Add.png';
import * as Web from '../../../Constants/Web'
const KEYS_TO_FILTERS = ['Name','Last_Name','Second_Last_Name','yearto','monthto'];
class ShowStates extends Component {

  constructor(props){
          super(props);
          this.state={
              url:Web.wurl+'/',
              datos:[],
            searchTerm: '',
            Id:'',
            edit:false,
            history:[],
            name:'',
            price:'',
            year:'',
            day:'',
            month:'',
            daylet:'',
            daynum:'',
            hour:'',
            member:''
            };
            this.onChange=this.onChange.bind(this);

      }


    async  componentDidMount(){
      var newDate = new Date()
      var date = newDate.getDate();
      var month = newDate.getMonth() + 1;
      var year = newDate.getFullYear();
      var numday= newDate.getDate();
      this.setState({
        date:numday+"/"+month+"/"+year
      })
      const data={
        id:this.props.id,
        day:numday,
        month:month,
        year:year
      }
      var url = this.state.url+'Api/Get_InactiveStudentsdate.php';
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        header: {'Content-Type': 'application/json'}
      }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {this.setState({history:response})} );
}

     onChange(e){
       this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre

     }



     searchUpdated (term) {
      this.setState({searchTerm: term})
  }
  onChange(e){
    this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
  }

  active(e){

      const data = {
            Id: e[0],
            Active:'1',
            Table:'students',
            IdTable:'id_Student'
          }
          //alert(JSON.stringify(data))
          this.props.UpdateActive(data,'Update/Update_ActiveStudent.php');
          const datainactive={
            id:e[0]
          }

           this.props.DeletePost(datainactive,'Delete/delete_Inactive.php')
      alert("activado")

  }

  showAll(){
    var newDate = new Date()
    var date = newDate.getDate();
    var month = newDate.getMonth() + 1;
    var year = newDate.getFullYear();
    var numday= newDate.getDate();
    this.setState({
      date:numday+"/"+month+"/"+year
    })
    const data={
      id:this.props.id,
      day:numday,
      month:month,
      year:year
    }
    var url = this.state.url+'Api/Get_InactiveStudents.php';
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => {this.setState({history:response})} );
  }

  render(){
    const post = this.state.history;
		const filteredInfo = post.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
	        if (post.length === 0 ) {
	        	return(
	            <div>  <div className="center2">
                <h2 ><img className="CH_SM_Icono" src={dateimg}/>{this.state.date}</h2>

  	            <h5>no hay datos</h5>
                <p onClick={this.componentDidMount.bind(this)} className="pointer hoover-black">ver Hoy</p>
                <p onClick={this.showAll.bind(this)} className="pointer hoover-black">ver todo</p>

                              </div>
                              <label>Puedes realizar busquedas con los siguientes datos:</label><br/>
                              <label>1.-Presiona HOY para ver el día actual</label><br/>
                              <label>2.-Presiona VER TODO para ver todos los inactivos</label><br/>
                              <label>3.-ingresa Nombre,Apellido,Teléfono,año,día o mes para realizar busquedas</label>

	            </div>
	            )} else{
		return(
				<div>

                <div className="center2">
                <h2 ><img className="CH_SM_Icono" src={dateimg}/>{this.state.date}</h2>
                <h5>Alumnos  próximos a activar</h5>
                <p onClick={this.componentDidMount.bind(this)} className="pointer hoover-black">ver Hoy</p>
                <p onClick={this.showAll.bind(this)} className="pointer hoover-black">ver todo</p>
                </div>
        <label>Puedes realizar busquedas con los siguientes datos:</label><br/>
        <label>1.-Presiona HOY para ver el día actual</label><br/>
        <label>2.-Presiona VER TODO para ver todos los inactivos</label><br/>
        <label>3.-ingresa Nombre,Apellido,año o mes para realizar busquedas</label>
	        <SearchInput placeholder="Nombre,apellido,mes,año" className="search-input form-group my-input text-center"  onChange={this.searchUpdated.bind(this)} />
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>
                <th className="table-width">Nombre</th>
              <th className="table-width">Fecha inactivo</th>
            <th className="table-width">Fecha regreso</th>
              <th className="table-width">Teléfono</th>
              <th className="table-width">Razón</th>
              <th className="table-width">Activar</th>
              </tr>
            </thead>
            <tbody>
				  {filteredInfo.map((dato, key) => {
	  return(
      <tr key={key}>
      <td ><label className="ch-dark-text">{dato.Name} {dato.Last_Name} {dato.Second_Last_Name}</label></td>
      <td ><label className="ch-dark-text">{dato.dayfrom}/{dato.monthfrom}/{dato.yearfrom}</label></td>
      <td ><label className="ch-dark-text">{dato.dayto}/{dato.monthto}/{dato.yearto}</label></td>
      <td ><label className="ch-dark-text">{dato.CellPhone} </label></td>
      <td ><label className="ch-dark-text">{dato.Info} </label></td>
      <td><Button variant="success" onClick={() => this.active([dato.Id_Student])}>Activar</Button></td>
        </tr>

	                        )
	                    })
	                }</tbody>
                </Table>





				</div>

			)}
		}





}export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        UpdateActive,fetchGetPost,DeletePost
    }
)(ShowStates);
