import React, { Component } from 'react';
import '../../../Styles/App.css';
import { connect } from 'react-redux';
import {  fetchAddPost,Update } from '../../../reducers/postsReducer';
import Container from 'react-bootstrap/Container'
import * as Web from '../../../Constants/Web'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import * as DATA from '../../../Constants/Dictionary/ES'
import Badge from 'react-bootstrap/Badge'
import Gray_Template from '../../MainComponents/Gray_Template'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import * as Data from '../../../Constants/Dictionary/ES'
import classes from './online.module.css'
class Admins extends Component {
  constructor(props){
          super(props);
          this.state={
                  url2:Web.wurl+'/',
                  url:'Update/Update_Timezone.php',
                  urlsave:'Api/Create/Create_Timerange.php',
                  min:'',
                  max:''
            };
            this.save=this.save.bind(this)
            this.update=this.update.bind(this)
            this.onChange=this.onChange.bind(this);

      }

componentDidMount(){

}
onChange(e){
  this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
}
update(){
  const time={
    Min:this.state.min,
    Max:this.state.max
  }
  this.props.Update(time,this.state.url);
}
save(){
  const time={
      Min:this.state.min,
      Max:this.state.max
  }
  var url = this.state.url2+this.state.urlsave;
  //var url = 'https://conversation-test.000webhostapp.com/Api/Create/Create_State.php';

  fetch(url, {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(time), // data can be `string` or {object}!
    header: {'Content-Type': 'application/json'}
  }).then(res => res.json())
  .catch(error => console.error('Error:', error))
  .then(response =>  {
    alert('listo')
  });
}

restoreTime(){
  const data={
    id:1
  }
  var url = Web.wurl+'/Api/Update/Update_timeMSG.php';
  fetch(url, {
  method: 'POST', // or 'PUT'
  body: JSON.stringify(data), // data can be `string` or {object}!
  header: {'Content-Type': 'application/json'}
}).then(res => res.text())
  .catch(error => console.error('Error:', error))
  .then(response => {
    alert(response)
    });
}
  render() {
    return (
  <Gray_Template>
  <h1>{Data.Settings}</h1>
  <br/>
  <hr/>
  <Col className={classes.timeContainer}>
  <span>Restablecer mensaje para preguntar hora a alumnos ONLINE</span>
  <br/>
  <button onClick={this.restoreTime.bind(this)}>Restablecer</button><br/>
  <span className={classes.grayText}>Esta opción te permite mostrar el mensaje de inicio a los alumnos ONLINE para volver a seleccionar el horario de su país
  puedes utilizar este botón en caso de un cambio de horario
  </span>
  </Col>
  <hr/>
    </Gray_Template>

    );

  }
}
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        placesall: state.posts.places,
    }),
    //mapeo de funciones
    {
       fetchAddPost,Update
    }
)(Admins);
