import React, { Component } from 'react';
import '../../../Styles/App.css';
import { connect } from 'react-redux';
import { fetchGetPlaces, fetchAddPost, fetchUpdatePost, fetchDeletePost } from '../../../reducers/postsReducer';
import Container from 'react-bootstrap/Container'
import * as Web from '../../../Constants/Web'
import Card from 'react-bootstrap/Card'

import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Modal from 'react-bootstrap/Modal'
import * as Settings from '../../../Constants/Menu'
import Add from '../../MainComponents/Add'
import ShowLevels from '../Tables/Show_Levels'
import * as Data from '../../../Constants/Dictionary/ES'
import TopLabel from './../../MainComponents/Close'
class Admins extends Component {
  constructor(props){
          super(props);
          this.state={
                  url2:Web.wurl+'/',
                  show:false,
                  show2:false,
                  name:'',
                  lastname:'',
                  lastname2:'',
                  email:'',
                  password:'',
                  id:'',
                  adminid:'',
                  Admins:[],

            };

            this.onChange=this.onChange.bind(this);
            this.save=this.save.bind(this);
      }

  componentWillMount() {
   this.props.fetchGetPlaces('Get_Places.php');
   fetch(this.state.url2+"Api/Get_Admins.php")
   .then((response)=>response.json())
   .then((responseJson)=>
   {
   this.setState({Admins:responseJson});
   const vare=JSON.stringify(this.state.Admins)

})
    }
    update() {
     this.props.fetchGetPlaces('Get_Places.php');
     fetch(this.state.url2+"Api/Get_Admins.php")
     .then((response)=>response.json())
     .then((responseJson)=>
     {
     this.setState({Admins:responseJson});
     const vare=JSON.stringify(this.state.Admins)

  })
      }

    handleClose() {
    this.setState({ show: false });
    }
    handleClose2() {
    this.setState({ show2: false });
    }

    handleShow() {
    this.setState({ show: true });
    }
    updatedata(){
      const data = {
          idadmin:this.state.adminid,
            password:this.state.password,
            email:this.state.email,
            id:this.state.id
          }
//alert(this.state.id)
//alert(JSON.stringify(data))
      this.props.fetchAddPost(data,'Update/Update_Admin.php');
    alert('correcto')
    this.update();
    this.setState({show2:false})
    }
    save(){
      const data = {
            Name: this.state.name,
            lastname: this.state.lastname,
            lastname2: this.state.lastname2,
            password:this.state.password,
            email:this.state.email,
            id:this.state.id
          }
//alert(this.state.id)
      this.props.fetchAddPost(data,'Create/Create_Admin.php');
    alert('correcto')
    this.update();
    this.setState({show:false})
    }
    onChange(e){
      this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre

    }
    create(){
      return(<div>
        <Form>
    <Form.Row>
      <Form.Group as={Col} controlId="formGridEmail">
        <Form.Label>Correo</Form.Label>
        <Form.Control onChange={this.onChange} name="email" type="email" placeholder="Enter email" />
      </Form.Group>

      <Form.Group as={Col} controlId="formGridPassword">
        <Form.Label>Clave</Form.Label>
        <Form.Control  onChange={this.onChange} name="password" type="password" placeholder="Password" />
      </Form.Group>
    </Form.Row>



    </Form>


        </div>
      )
    }
updateadmin(){

  return(
    <div>
      <Form>
    <Form.Row>
    <Form.Group as={Col} controlId="formGridEmail">
      <Form.Label>Correo</Form.Label>
      <Form.Control value={this.state.email} onChange={this.onChange} name="email" type="email" placeholder="Enter email" />
    </Form.Group>

    <Form.Group as={Col} controlId="formGridPassword">
      <Form.Label>Clave</Form.Label>
      <Form.Control value={this.state.password} onChange={this.onChange} name="password" type="password" placeholder="Password" />
    </Form.Group>
    </Form.Row>



    </Form>


      </div>

  )

}
    edit(e){
      this.setState({
        adminid:e[0],
        id:e[1],
        email:e[2],
        password:e[3],
        show2:true

      })
    }

  render() {
    const posts = this.props.placesall;
    const adminsall=this.state.Admins

    return (
    <Container>
    <Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{Data.Admins}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div>
      {this.create()}
<Form>
      <Form.Group as={Col}  controlId="formGridCity">
    <Form.Label>Sucursal</Form.Label>
    <Form.Control as="select" onChange={this.onChange} name="id">
        <option >Selecciona una sucursal</option>
    {posts.map(item => (
      <option value={item.Id_Place}  key={item}>{item.Id_Place}</option>
    ))}
    </Form.Control>
    </Form.Group>
      </Form>
      </div>
      </Modal.Body>
      <Modal.Footer>

      <ButtonToolbar>
<Button variant="primary" onClick={this.save.bind(this)} >Agregar</Button>
</ButtonToolbar>
        <Button variant="secondary" onClick={this.handleClose.bind(this)}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>


    {/**************************************************************/}
    <Modal show={this.state.show2} onHide={this.handleClose2.bind(this)} centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{Data.Admins}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div>
      {this.updateadmin()}
  <Form>
      <Form.Group as={Col}  controlId="formGridCity">
    <Form.Label>Sucursal</Form.Label>
    <Form.Control as="select" onChange={this.onChange} name="id">

      <option value={this.state.id}>{this.state.id}</option>
    {posts.map(item => (
      <option value={item.Id_Place}  key={item}>{item.Id_Place}</option>
    ))}
    </Form.Control>
    </Form.Group>
      </Form>
      </div>
      </Modal.Body>
      <Modal.Footer>

      <ButtonToolbar>
  <Button variant="primary" onClick={this.updatedata.bind(this)} >Actualizár</Button>
  </ButtonToolbar>
        <Button variant="secondary" onClick={this.handleClose2.bind(this)}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>




    {/**************************************************************/}
  <TopLabel label={Data.Admins}press={this.props.close} />

  <ButtonToolbar>
  <Button variant="primary" onClick={this.handleShow.bind(this)}>Agregar</Button>
  </ButtonToolbar>




  <Table striped bordered hover size="sm">
    <thead>
      <tr>
        <th>Sucursal</th>
        <th>Correo</th>
      </tr>
    </thead>
    <tbody>
    {adminsall.map(item => (
      <tr>
      <td className="CH_Text3 pointer" onClick={()=>this.edit([item.Admin_Id,item.Place_Admin,item.Email,item.Password])}>{item.Place_Admin}</td>
            <td className="CH_Text3">{item.Email}</td>
            </tr>
        ))}
          </tbody>
        </Table>



    </Container>

    );

  }
}
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        placesall: state.posts.places,
    }),
    //mapeo de funciones
    {
        fetchGetPlaces, fetchAddPost, fetchUpdatePost, fetchDeletePost
    }
)(Admins);
