import React, { useState,useMemo,useEffect } from 'react';
import TimePicker from 'react-time-picker';
import countryList from 'react-select-country-list'
import Select from 'react-select'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import classes from './clock.module.css'
import {diff} from './solverTimeDiff'
import * as Web from '../../../Constants/Web'
function MyApp(data) {
const {ask,studentId, notModal}=data
const isShowing=ask==1?false:true;
  const [show, setShow] = useState(true);
  const [hourDiff,setHourDiff]=useState(0)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [country, setValue] = useState('')
   const options = useMemo(() => countryList().getData(), [])

   const changeHandler = country => {
     setValue(country)
   }

  const timer = new Date();
  const [value, onChange] = useState(timer);

   var todayMX = new Date().toLocaleString("en-US", {
      timeZone: "America/Mexico_City"
    }, {hour: 'numeric', minute: 'numeric', hour12: false });
    todayMX = new Date(todayMX);


    const [hourCompare,setHourToCompare]=useState(timer.getHours())
   const upload =() =>{
     /*const hourMX= await todayMX.getHours()
     const hourtToCompare = await hourCompare*/

     const hourMX= todayMX.getHours()+":"+todayMX.getMinutes()
     const hourtToCompare= hourCompare+":"+todayMX.getMinutes()

    const timeDiff=  diff(hourMX,hourtToCompare)


  /*  const dat=new Date(value)
    alert(dat.getHours())*/
    const sign =timeDiff.includes('-')
    const timeDiffNew=sign?Math.abs(timeDiff):timeDiff
    const signSelected=sign?'-':"+"
    const data={
      country:country.label,
      countryCode:country.value,
      hourDiff:timeDiffNew,
      studentId:studentId,
      sign:signSelected
        }
        var url = Web.wurl+'/Api/Update/Update_timezone.php';
        fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        header: {'Content-Type': 'application/json'}
      }).then(res => res.text())
        .catch(error => console.error('Error:', error))
        .then(response => {
          if (response=="actualizado") {
            setShow(false)
            window.location.reload();
          }
          alert(response)
        });

  }
  const change = (e) =>{
    const time = e.split(':')
    const hour=time[0]
    setHourToCompare(hour)
  }

  return (
    <div className={classes.width}>
    {notModal?
      <div className={classes.width}>

      <h4>Selecciona tu país</h4>

      <Select options={options} value={country} onChange={changeHandler} />
      <br/>

      <h4>¿Es esta tu hora actual?</h4>
        <TimePicker
          onChange={(e)=>change(e)}
          value={value}
        />
        <br/>
        <br/>

      {country?
        <Button variant="primary" onClick={()=>upload()}>
          Guardar
        </Button>:null}
      </div>:
    <Modal show={show} onHide={handleClose}
        backdrop="static">
        <Modal.Header >
          <Modal.Title>Configuración</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modal}>
        <h4>Selecciona tu país</h4>

        <Select options={options} value={country} onChange={changeHandler} />
        <br/>

        <h4>¿Es esta tu hora actual?</h4>
          <TimePicker
            onChange={(e)=>change(e)}
            value={value}
          />
          <br/>
          <br/>
          <span>Puedes modificar esta hora en caso de no ser correcta</span>
        </Modal.Body>
        <Modal.Footer>
        {country?
          <Button variant="primary" onClick={()=>upload()}>
            Guardar
          </Button>:null}
        </Modal.Footer>
      </Modal>}

    </div>
  );
}

export default MyApp
