import React, {useEffect, useState} from 'react';
import * as URL from '../../../../Constants/services';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import SearchBarInput from '../SearchBarInput';
import TableContainer from '../TableContainer';
import Title from '../Titles';




const Memberships = (props) =>{
    const {startDate, endDate, place}= props;
    const [data, setData] = useState([]);
    const [searchTerm,setSearchTerm] = useState('')

  useEffect(()=>{
    var url = URL.api+"/membershipsOver";
    const inf={
      from:startDate,
      to:endDate,
      place:place
    }
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(inf), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => {
      setData(response)
    })
  },[startDate, endDate || !data])
  if (!startDate || !endDate) {
    return'';
  }

  const list =data.filter((val)=>{
          if(searchTerm == ""){
            return val
          }
          if (!searchTerm) {
            return val;
          }
          else if(val.name.toLowerCase().includes(searchTerm.toLowerCase())){
            return val;
          }
        }).map((item,i)=>{
    return(<tr>
          <td>{item.id}</td>
          <td> {item.name}</td>
          <td>{item.lastName+" "+item.secondLastName}</td>
          <td>{item.email}</td>
          <td>{item.phone}</td>
          <td>{item.expirationDay}</td>
          </tr>)
  })
  const headersExpirations = [
    { label: "id", key: "id" },
    { label: "nombre", key: "name" },
    { label: "apellido", key: "lastName" },
    { label: "apellido", key: "secondLastName" },
    { label: "email", key: "email" },
    { label: "telf", key: "phone" },
    { label: "fecha de expi", key: "expirationDay" },
  ];
return(<TableContainer>
  <Title title={"Membresías a expirar"} />

  <SearchBarInput linkToExport={""} fileName={"membresias_expirar"} data={data} headers={headersExpirations}etSearchTerm={setSearchTerm}/>
  <Table>
  <Thead>
    <Tr>
      <Th>ID</Th>
      <Th>Nombre</Th>
      <Th>Apellido</Th>
      <Th>Correo</Th>
      <Th>Fecha de Expi.</Th>
    </Tr>
  </Thead>
  <Tbody>
    {list}
  </Tbody>
  </Table>
</TableContainer>)


}
export default Memberships;
