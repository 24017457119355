import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { connect } from 'react-redux';
import {NavLink,Redirect,withRouter,Link} from 'react-router-dom'
import { GetLevels,Agenda,fetchGetPost,fetchGetPost2,fetchGetCoaches,fetchGetBirthdays,CounterStudents,CounterCouches,CounterBirthday,fetchAddPost, fetchUpdatePost, fetchDeletePost,Update } from '../../../reducers/postsReducer';
import AddStudent from '../../Admin/Forms/Create_Student'
import LeftSide from './leftSide'
import Placeinfo from '../../Admin/Data/Placeinfo'
import GeneralAgenda from '../../Agenda/AgendaCoachGeneral'
import * as DATAINFO from '../../../Constants/Dictionary/ES'
import ShowStudents from '../../Admin/Tables/ShowStudentsAdmin'
import Coaches from '../../Admin/Tables/PlaceCoaches'
import Birthdays from '../../Admin/Tables/PlaceBirthdays'
import Calendar from '../../Agenda/CreateAgendabig'
import Report from '../../Admin/ReportesLower'
	    var date = new Date().getDate();
      var month = new Date().getMonth() + 1;
      var year = new Date().getFullYear();

class PlaceData extends Component {
  constructor(props){
    super(props);

    this.state={
          id:'',
          Add:'',
          Main:'1',
          Student:'',
          Coach:'',
          Birthday:'',
          Calendar:'',
          Notes:'',
          one:'list-box',
          two:'list-box',
          three:'list-box',
          four:'list-box',
          fourone:'list-box',
          five:'list-box',
          six:'list-box-active',
          NameStudent:'',
          LastName:'',
          LastName2:'',
          Day_Birthdate:'',
          Month_Birthdate:'',
          Year_Birthdate:'',
          CellPhone:'',
          Phone:'',
          Email:'',
          Password:'',
          PasswordVerification:'',
          Street:'',
          Col:'',
          Number:'',
          InsideNumber:'',
          Zip:'',
          City:'',
          State:'',
          Picture:'',
          Level:'',
          Membership:'',
          Expiration_Day:'',
          Expiration_Month:'',
          Expiration_Year:'',
          img:'',
          Qr:'',
          passwordmsg:'',
          length:'',
					quantity:'',
          url:'Create/Create_Student.php',
					redirectToReferrer:false,

					GeneralAgenda:''
    };
  }
  componentDidMount() {
 if(localStorage.getItem('userData')){
			let myItem = localStorage.getItem('userData')
			const info=JSON.parse(myItem)
    //this.setState({id:info.Place})
	//	alert(info.Place)
	this.setState({id:info.Place})
    const data = {
          id:info.Place,
          month:month
        }
      this.props.fetchGetPost('Get_Students.php',data);
      this.props.fetchGetPost2('Get_Place.php',data);
      this.props.fetchGetCoaches('Get_PlaceCoaches.php',data);
      this.props.fetchGetBirthdays('Get_PlaceBirthdays.php',data);
      this.props.CounterStudents('CounterStudents.php',data);
      this.props.CounterCouches('CounterCouches.php',data);
      this.props.CounterBirthday('CounterBirthdays.php',data);
			this.props.GetLevels('Get_ActiveLevels.php');
		}else {
			this.setState({
	      redirectToReferrer:true
	    })
		}
      }


 update=()=>{
        const data = {
              id: this.state.id,
              month:month
            }
      this.props.fetchGetPost('Get_Students.php',data);
      this.props.fetchGetPost2('Get_Place.php',data);
      this.props.CounterStudents('CounterStudents.php',data);
      this.props.CounterCouches('CounterCouches.php',data);
      this.props.CounterBirthday('CounterBirthdays.php',data);
      this.props.fetchGetCoaches('Get_PlaceCoaches.php',data);
      this.props.fetchGetBirthdays('Get_PlaceBirthdays.php',data);
			//this.props.Agenda('Get_Agenda.php',data);
			this.props.GetLevels('Get_ActiveLevels.php');
      }

			one(){this.setState({Main:'',Student:'1',Coach:'',Birthday:'',Calendar:'',GeneralAgenda:'',Report:'',dataPlace:'',Media:'',one:'list-box-active',two:'list-box',three:'list-box',four:'list-box',fourone:'list-box',five:'list-box',six:'list-box',seven:'list-box'})}
			two(){this.setState({Main:'',Student:'',Coach:'1',Birthday:'',Calendar:'',GeneralAgenda:'',Report:'',dataPlace:'',Media:'',one:'list-box',two:'list-box-active',three:'list-box',four:'list-box',fourone:'list-box',five:'list-box',six:'list-box',seven:'list-box'})}
			three(){this.setState({Main:'',Student:'',Coach:'',Birthday:'1',Calendar:'',GeneralAgenda:'',Report:'',dataPlace:'',Media:'',one:'list-box',two:'list-box',three:'list-box-active',four:'list-box',fourone:'list-box',five:'list-box',six:'list-box',seven:'list-box'})}
			four(){this.setState({Main:'',Student:'',Coach:'',Birthday:'',Calendar:'1',GeneralAgenda:'',Report:'',dataPlace:'',Media:'',one:'list-box',two:'list-box',three:'list-box',four:'list-box-active',fourone:'list-box',five:'list-box',six:'list-box',seven:'list-box'})}
			fourone(){this.setState({Main:'',Student:'',Coach:'',Birthday:'',Calendar:'',GeneralAgenda:'1',Report:'',dataPlace:'',Media:'',one:'list-box',two:'list-box',three:'list-box',four:'list-box',fourone:'list-box-active',five:'list-box',six:'list-box',seven:'list-box'})}
			five(){this.setState({Main:'',Student:'',Coach:'',Birthday:'',Calendar:'',GeneralAgenda:'',Report:'1',dataPlace:'',Media:'',one:'list-box',two:'list-box',three:'list-box',four:'list-box',fourone:'list-box',five:'list-box-active',six:'list-box',seven:'list-box'})}
			six(){this.setState({Main:'',Student:'',Coach:'',Birthday:'',Calendar:'',GeneralAgenda:'',Report:'',dataPlace:'1',Media:'',one:'list-box',two:'list-box',three:'list-box',four:'list-box',fourone:'list-box',five:'list-box',six:'list-box-active',seven:'list-box'})}
			seven(){this.setState({Main:'',Student:'',Coach:'',Birthday:'',Calendar:'',GeneralAgenda:'',Report:'',dataPlace:'',Media:'1',one:'list-box',two:'list-box',three:'list-box',four:'list-box',fourone:'list-box',five:'list-box',six:'list-box-active',seven:'list-box-active'})}

handleChangeValue(e){
  this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
}

_handleImageChange(e) {
   e.preventDefault();

   let reader = new FileReader();
   let file = e.target.files[0];

   reader.onloadend = () => {
     this.setState({
       file: file,
       img: reader.result
     });
   }

   reader.readAsDataURL(file)
 }

save(){
	this.update()
  if(this.state.Password==this.state.PasswordVerification){
    //alert("bien")
    this.setState({
      passwordmsg:'',
    })

if(this.state.Password.length>=8){
  //alert('correcto')
  this.setState({
    length:''
  })
	this.update()
const data={
  Name:this.state.NameStudent,
  Last_Name:this.state.LastName,
  Second_Last_Name:this.state.LastName2,
  Day_Birthdate:this.state.Day_Birthdate,
  Month_Birthdate:this.state.Month_Birthdate,
  Year_Birthdate:this.state.Year_Birthdate,
  CellPhone:this.state.CellPhone,
  Phone:this.state.Phone,
  Email:this.state.Email,
  Password:this.state.Password,
  Street:this.state.Street,
  Col:this.state.Col,
  Number:this.state.Number,
  Inside_Number:this.state.InsideNumber,
  Zip:this.state.Zip,
  City:this.state.City,
  State:this.state.State,
  Picture:this.state.Picture,
  Level:this.state.Level,
  Membership:this.state.Membership,
  Place:this.state.id,
  Expiration_Date_Membership_Day:this.state.Expiration_Day,
  Expiration_Date_Membership_Month:this.state.Expiration_Month,
  Expiration_Date_Membership_Year:this.state.Expiration_Year,
	Quantity:this.state.quantity
}
//alert(JSON.stringify(data))
this.props.fetchAddPost(data,this.state.url);
this.update()
alert('Correcto')

this.setState({
	Add:''
})
this.update()

}else {
	this.update()
  this.setState({
    length:'1',
      Password:'',
      PasswordVerification:'',
  })

}


  }else {
		this.update()
    //alert('las contrasenas no concuerdan')
    this.setState({
      Password:'',
      PasswordVerification:'',
      passwordmsg:'1',
    })
  }
}
closeagenda(){
	this.setState({Calendar:'',GeneralAgenda:''})
}
  render() {
		if (this.state.redirectToReferrer) {
     return (<Redirect to={'/'}/>)
   }
		if(localStorage.getItem('userData')){
			let myItem = localStorage.getItem('userData')
			const info=JSON.parse(myItem)
			if(info.type=='MASTER'){
				return (<Redirect to={'/admin'}/>)
			}
			if(info.type=='Student'){
				return (<Redirect to={'/Profile'}/>)
			}
			if(info.type=='Coach'){
				return (<Redirect to={'/Coach'}/>)
			}
			if(info.type=='Admin'){
			 //return (<Redirect to={'/conversationplace'}/>)
			}

		}else {
			return (<Redirect to={'/'}/>)
		}
    const PlaceData=this.props.place
    const PlaceCoaches=this.props.placecoaches
    const PlaceBirthdays=this.props.placebirthdays
		//alert(JSON.stringify(PlaceBirthdays))
    const CounterStudents=this.props.counterstudents
    const CounterCoach=this.props.countercoaches
    const CounterBirthday=this.props.counterbirthdays
		const activelevels=this.props.activelevels
    //alert(JSON.stringify(activelevels))
    return (
    <div >
		{this.state.Calendar?<div>
			<Calendar id={this.state.id} close={this.closeagenda.bind(this)}/></div>:null}

{this.state.GeneralAgenda?<GeneralAgenda id={this.state.id} close={this.closeagenda.bind(this)}/>:null}
		{this.state.Calendar||this.state.GeneralAgenda?null:
			<Container className="mainbox container2">

	<Row>
    <Col xs={12} md={12} className="mainbox-header">
    <span className="header-text">{this.state.id}</span>
    </Col>

    <LeftSide
		update={this.update.bind(this)}
    PlaceData={PlaceData}
    CounterCoaches={CounterCoach}
    StudentCounter={CounterStudents}
    BirthdayCounter={CounterBirthday}
    uid={this.state.id}
    one={this.one.bind(this)}
    two={this.two.bind(this)}
    three={this.three.bind(this)}
    four={this.four.bind(this)}

			    foourone={this.state.fourone}
			    fourone={this.fourone.bind(this)}
    five={this.five.bind(this)}
    six={this.six.bind(this)}
    on={this.state.one}
    tw={this.state.two}
    th={this.state.three}
    fo={this.state.four}
    fiv={this.state.five}
    si={this.state.six}
    />
    {/*rightside---------------------------------------*/}
    <Col xs={12} md={8} className="columnright">
    {this.state.Main?<Placeinfo PlaceData={PlaceData} id={this.state.id}/>:null}
    {this.state.Student?<div>{this.Students()}</div>:null}
    {this.state.Coach?<Coaches Placecoaches={PlaceCoaches} id={this.state.id}/>:null}
		{this.state.Birthday?<Birthdays PlaceBirthday={PlaceBirthdays} />:null}
		{this.state.Report?<Report id={this.state.id}/>:null}





    </Col>

    {/*rightside---------------------------------------*/}
    </Row>
	  </Container>}
    </div>
    );
  }

  leftSide(){
    return(
      <LeftSide
      id={this.state.id}
      one={this.one.bind(this)}
      two={this.two.bind(this)}
      three={this.three.bind(this)}
      on={this.state.one}
      tw={this.state.two}
      th={this.state.three}
      />
    )}


add(){
  if(this.state.Add=='1'){
    this.setState({
      Add:''
    })
  }else {
    this.setState({
      Add:'1'
    })
  }
}

quantity(e){
  ///to save qunatity

  this.setState({
    quantity:e[0]
  })
}
  Students(){
    return(
      <div>
      <label className="ch-black-bgtext">Estudiantes</label>
      <hr/>
      {this.state.Add?<div>
        <button className="btn btn-success" type="submit" onClick={this.add.bind(this)}>Cancelar</button>

			<AddStudent
      id={this.state.id}
        onChangeValue={this.handleChangeValue.bind(this)}
        onHandleImageChange={this._handleImageChange.bind(this)}
        Password={this.state.Password}
        PasswordVerification={this.state.PasswordVerification}
        img={this.state.img}
				quantity={this.quantity.bind(this)}
      />
      {this.state.passwordmsg?<label className="ch-wrong-text">las claves no concuerdan</label>:null}
      <br/>{this.state.emailmsg?<label className="ch-wrong-text">este correo ya existe</label>:null}
      <br/>{this.state.length?<label className="ch-wrong-text">la contrasena debe ser mayo a 8 caracteres</label>:null}
      <br/><button variant="primary" type="submit" onClick={this.save.bind(this)}>
        Guardar
      </button>
      </div>:
      <div>
      <button className="btn btn-success" type="submit" onClick={this.add.bind(this)}>Agregar</button>

        <ShowStudents
				id={this.state.id}
        />
  </div>
}
  </div>
    )}}

export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
        place:state.datas.dato,
        placecoaches:state.placecoaches.placecoaches,
				placebirthdays:state.placebirthdays.placebirthdays,
        counterstudents:state.counterstudents.counterstudents,
        countercoaches:state.countercoaches.countercoaches,
        counterbirthdays:state.counterbirthdays.counterbirthdays,
				activelevels:state.activelevels.activelevels

    }),
    //mapeo de funciones
    {
        GetLevels,Agenda,fetchGetPost,fetchGetPost2,fetchGetCoaches,fetchGetBirthdays,CounterStudents,CounterCouches,CounterBirthday, fetchAddPost, fetchUpdatePost, fetchDeletePost
    }
)(PlaceData);
