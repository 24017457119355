import React, { Component } from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import AddComponent from './addComponent'
import classes from './addFiles.module.css'
//php version 7.3.2

class Add extends Component {
  constructor(props){
          super(props);
      }


  render() {
    return (
      <Container className={classes.root}>
      <Col>
        <AddComponent  day={this.props.day} filesToShow={this.props.filesToShow}callbackFiles={this.props.callbackFiles} deleteFile={this.props.deleteFile}/>
        </Col>
    </Container>
    );
  }
}

export default Add;
