import {
    apiGetPost,
    apiGetPost2,
    apiGetPlaceCoaches,
    apiGetPlaceBirthdays,
    apiAddPost,
    apiDeletePost,
    apiUpdatePost,
    apiGetStudentCounter,
    apiGetCoachCounter,
    apiGetBirthdayCounter,
    apiUpdateActive,
    apiUpdate,
    apiEdit,
    apiGetAgenda,
    apiGetActiveLevles,
    apiGetClassroom,
    apiGetTimeAgenda,
    apiGetMonday,
    apiGetTuesday,
    apiGetWednesday,
    apiGetThursday,
    apiGetFriday,
    apiGetSaturday,
    apiGetSunday,
    apiGetPlaces
} from '../lib/api';

const initialState = {
    posts:[],
    dato:[],////this is the second property of the state
    //(state) => ({
      //  data: state.datas.[dato]<------this,
  //  }),
  counterstudents:[],
  countercoaches:[],
  counterbirthdays:[],
  placecoaches:[],
  placebirthdays:[],
  edit:[],
  time:[],
  classroom:[],
  TimeAgenda:[],
  Monday:[],
  Tuesday:[],
  Wednesday:[],
  Thursday:[],
  Friday:[],
  Saturday:[],
  Sunday:[],
  places:[]

};

///agenda
const GET_AGENDATIME = 'GET_AGENDATIME';
const GET_MONDAY = 'GET_MONDAY';
const GET_TUESDAY = 'GET_TUESDAY';
const GET_WEDNESDAY = 'GET_WEDNESDAY';
const GET_THURSDAY = 'GET_THURSDAY';
const GET_FRIDAY = 'GET_FRIDAY';
const GET_SATURDAY = 'GET_SATURDAY';
const GET_SUNDAY = 'GET_SUNDAY';
//////endagenda

const GET_EDIT = 'GET_EDIT';
const GET_POSTS = 'GET_POSTS';
const GET_CLASSROOM = 'GET_CLASSROOM';
const Get_ACTIVELEVELS = 'Get_ACTIVELEVELS';
const GET_PLACECOACHES = 'GET_PLACECOACHES';
const GET_PLACEBIRTHDAYS = 'GET_PLACEBIRTHDAYS';
const GET_DATA = 'GET_DATA';////,<-this is the name for the switch and we use the array
const GET_COCOUNTER = 'GET_COCOUNTER';
const GET_STUDENTCOUNTER = 'GET_STUDENTCOUNTER';
const GET_BIRTHDAYCOUNTER = 'GET_BIRTHDAYCOUNTER';
const GET_AGENDA = 'GET_AGENDA';
const ADD_POST = 'ADD_POST';
const UPDATE_POST = 'UPDATE_POST';
const DELETE_POST = 'DELETE_POST';
const GET_PLACES = 'GET_PLACES';



const getPlaces = (posts) => ({ type: GET_PLACES, payload: posts });
const getAgendaTime = (posts) => ({ type: GET_AGENDATIME, payload: posts });
const getMonday = (posts) => ({ type: GET_MONDAY, payload: posts });
const getTuesday = (posts) => ({ type: GET_TUESDAY, payload: posts });
const getWednesday = (posts) => ({ type: GET_WEDNESDAY, payload: posts });
const getThursday = (posts) => ({ type: GET_THURSDAY, payload: posts });
const getFriday = (posts) => ({ type: GET_FRIDAY, payload: posts });
const getSaturday = (posts) => ({ type: GET_SATURDAY, payload: posts });
const getSunday = (posts) => ({ type: GET_SUNDAY, payload: posts });
//actions
const Editu = (post) => ({ type: GET_EDIT, payload: post });
const getClassroom = (posts) => ({ type: GET_CLASSROOM, payload: posts });
const getPosts = (posts) => ({ type: GET_POSTS, payload: posts });
const getactivelevels = (posts) => ({ type: Get_ACTIVELEVELS, payload: posts });
const getplacecoaches = (dat) => ({ type: GET_PLACECOACHES, payload: dat });
const getplacebirthdays = (dat) => ({ type: GET_PLACEBIRTHDAYS, payload: dat });
const getData = (datas) => ({ type: GET_DATA, payload: datas });//get the data and put inside the payload
const addPost = (post) => ({ type: ADD_POST, payload: post });
const updatePost = (post) => ({ type: UPDATE_POST, payload: post });
const deletePost = (id) => ({ type: DELETE_POST, payload: id });

/////COUNTERS/////
const getStudentCounter = (counter) => ({ type: GET_STUDENTCOUNTER, payload: counter });
const getCoachCounter = (counter) => ({ type: GET_COCOUNTER, payload: counter });
const getBirthdayCounter = (counter) => ({ type: GET_BIRTHDAYCOUNTER, payload: counter });
/////COUNTERS/////

///agenda///
const getAgenda = (dat) => ({ type: GET_AGENDA, payload: dat });



//counters///////
export const CounterStudents = (type,id) => {
    return (dispatch) => {
        apiGetStudentCounter(type,id)
            .then(res => {
                dispatch(getStudentCounter(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};
export const CounterCouches= (type,id) => {
    return (dispatch) => {
        apiGetCoachCounter(type,id)
            .then(res => {
                dispatch(getCoachCounter(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};
export const CounterBirthday = (type,id) => {
    return (dispatch) => {
        apiGetBirthdayCounter(type,id)
            .then(res => {
                dispatch(getBirthdayCounter(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};



/////end counters////





/////agendas
export const fetchGetTimeAgenda = (type,id) => {
    return (dispatch) => {
        apiGetTimeAgenda(type,id)
            .then(res => {
                dispatch(getAgendaTime(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};
export const fetchGetMonday = (type,id) => {
    return (dispatch) => {
        apiGetMonday(type,id)
            .then(res => {
                dispatch(getMonday(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};

export const fetchGetTuesday = (type,id) => {
    return (dispatch) => {
        apiGetTuesday(type,id)
            .then(res => {
                dispatch(getTuesday(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};

export const fetchGetWednesday = (type,id) => {
    return (dispatch) => {
        apiGetWednesday(type,id)
            .then(res => {
                dispatch(getWednesday(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};
export const fetchGetThursday = (type,id) => {
    return (dispatch) => {
        apiGetThursday(type,id)
            .then(res => {
                dispatch(getThursday(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};
export const fetchGetFriday = (type,id) => {
    return (dispatch) => {
        apiGetFriday(type,id)
            .then(res => {
                dispatch(getFriday(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};
export const fetchGetSaturday = (type,id) => {
    return (dispatch) => {
        apiGetSaturday(type,id)
            .then(res => {
                dispatch(getSaturday(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};
export const fetchGetSunday = (type,id) => {
    return (dispatch) => {
        apiGetSunday(type,id)
            .then(res => {
                dispatch(getSunday(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};


////agendas

////fetches---------------------//

export const GetClassroom = (type,id) => {
    return (dispatch) => {
        apiGetClassroom(type,id)
            .then(res => {
                dispatch(getClassroom(res.reverse()));
            })
            .catch(res => {
                console.log(res);
            })
    }
};
export const fetchGetPost = (type,id) => {
    return (dispatch) => {
        apiGetPost(type,id)
            .then(res => {
                dispatch(getPosts(res.reverse()));
            })
            .catch(res => {
                console.log(res);
            })
    }
};
export const fetchGetPlaces = (type,id) => {
    return (dispatch) => {
        apiGetPlaces(type,id)
            .then(res => {
                dispatch(getPlaces(res.reverse()));
            })
            .catch(res => {
                console.log(res);
            })
    }
};
export const fetchGetPost2 = (type,id) => {
    return (dispatch) => {
        apiGetPost2(type,id)
            .then(res => {
              //alert(JSON.stringify(res))
                dispatch(getData(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};


 export const fetchGetCoaches = (type,id) => {
     return (dispatch) => {
         apiGetPlaceCoaches(type,id)
             .then(res => {
                 dispatch(getplacecoaches(res));
             })
             .catch(res => {
                 console.log(res);
             })
     }
 }
 export const fetchGetBirthdays = (type,id) => {
     return (dispatch) => {
         apiGetPlaceBirthdays(type,id)
             .then(res => {
                 dispatch(getplacebirthdays(res));
             })
             .catch(res => {
                 console.log(res);
             })
     }
 }


 export const GetLevels = (type,id) => {
     return (dispatch) => {
         apiGetActiveLevles(type,id)
             .then(res => {
                 dispatch(getactivelevels(res));
             })
             .catch(res => {
                 console.log(res);
             })
     }
 }

export const Agenda = (type,id) => {
    return (dispatch) => {
        apiGetAgenda(type,id)
            .then(res => {
                dispatch(getAgenda(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
}
export const fetchAddPost = (post,type) => {
    return (dispatch) => {
        apiAddPost(post,type)
            .then(res => {
                dispatch(addPost(res));
                //alert(res)
            })
            .catch(res => {
              //  alert(res)
            })
    }
};



export const UpdateActive = (id, post) => {
    return (dispatch) => {
        apiUpdateActive(id, post)
            .then(res => {
                dispatch(updatePost(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};
export const Update = (id, post) => {
    return (dispatch) => {
        apiUpdate(id, post)
            .then(res => {

                dispatch(updatePost(res));
                //alert('correcto')
            })
            .catch(res => {
                console.log(res);
                //alert('error')
            })
    }
};
export const Edit = (id, post) => {
    return (dispatch) => {
        apiEdit(id, post)
            .then(res => {
                dispatch(Editu(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};

export const fetchUpdatePost = (id, post) => {
    return (dispatch) => {
        apiUpdatePost(id, post)
            .then(res => {
                dispatch(updatePost(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};


export const DeletePost = (id, post) => {
    return (dispatch) => {
        apiDeletePost(id, post)
            .then(res => {
                dispatch(deletePost(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};

export const fetchDeletePost = (id) => {
    return (dispatch) => {
        apiDeletePost(id)
            .then(res => {
                dispatch(deletePost(id));
            })
            .catch(res => {
                console.log(res);
            })
    }
};


export default (state = initialState, action) => {
    switch (action.type) {
        ////COUNTERS//////////////////////////////////

        case GET_COCOUNTER:
                return { ...state, countercoaches: action.payload };

        case GET_STUDENTCOUNTER:
                return { ...state, counterstudents: action.payload };



        case GET_BIRTHDAYCOUNTER:
              return { ...state, counterbirthdays: action.payload };
        ////END COUNTERS/////////////////////

        case GET_EDIT:
                return { ...state, edit: action.payload };
        //en todos los casos regresamos un objeto nuevo en el cual incluimos todos las propiedades del objeto state con ...state
        case GET_POSTS:
            //cambiamos el valor de la propiedad post
            return { ...state, posts: action.payload };

        case GET_PLACECOACHES:
                return { ...state, placecoaches: action.payload };

        case GET_PLACEBIRTHDAYS:
                        return { ...state, placebirthdays: action.payload };

        case GET_AGENDA:
                                return { ...state, time: action.payload };


        case GET_DATA:
                //cambiamos el valor de la propiedad post
                return { ...state, dato: action.payload };


                case GET_PLACES:
                        //cambiamos el valor de la propiedad post
                        return { ...state, places: action.payload };
//////////////////////////////////////agendas

case GET_AGENDATIME:
        //cambiamos el valor de la propiedad post es el segundo post
        return { ...state, TimeAgenda: action.payload };
case GET_MONDAY:
                //cambiamos el valor de la propiedad post es el segundo post
        return { ...state, Monday: action.payload };
case GET_TUESDAY:
                //cambiamos el valor de la propiedad post es el segundo post
          return { ...state, Tuesday: action.payload };
case GET_WEDNESDAY:
                        //cambiamos el valor de la propiedad post es el segundo post
          return { ...state, Wednesday: action.payload };

case GET_THURSDAY:
                  //cambiamos el valor de la propiedad post es el segundo post
      return { ...state, Thursday: action.payload };
case GET_FRIDAY:
                          //cambiamos el valor de la propiedad post es el segundo post
      return { ...state, Friday: action.payload };
case GET_SATURDAY:
                          //cambiamos el valor de la propiedad post es el segundo post
        return { ...state, Saturday: action.payload };
case GET_SUNDAY:
                                  //cambiamos el valor de la propiedad post
        return { ...state, Sunday: action.payload };
/////////////////////////////////////////////agenda

        case GET_CLASSROOM:
                        //cambiamos el valor de la propiedad post
                        return { ...state, classroom: action.payload };

        case Get_ACTIVELEVELS:
                        //cambiamos el valor de la propiedad post
                        return { ...state, activelevels: action.payload };


        case ADD_POST:
            console.log(ADD_POST, action);
            return {
                ...state,
                posts: [action.payload, ...state.posts]
            };
        //concatenamos los posts exitentes con el nuevo post, en este caso se agregan los posts al inicio del arreglo
        //si quisieramos ponerlo al final lo cambiamos de la siguiente manera
        //posts: [...state.post.rows, action.payload]

        case UPDATE_POST:
          return {
                ...state,
                posts: [
                    ...state.posts.map(
                        (post) => post.id === action.payload.id ?
                            action.payload
                            : post
                    )
                ]
            }
        //Con el uso de la funcion map regresamos un nuevo arreglo con el cual reemplazamos el post con las propiedades
        //no actualizadas por el post actualizado

        case DELETE_POST:

            return {
                ...state,
                posts: [...state.posts.filter(post => post.id !== action.payload)]
            }
        //Haciendo uso de la funcion filter, creamos un nuevo arreglo que excluye el post que eliminamos

        default:
            return { ...state };
    }
}
