import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Edit } from '../../reducers/postsReducer';
import '../../Styles/App.css';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal'
import Footer from '../MainComponents/Footer'
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button'
import {Redirect} from 'react-router-dom';
import Inicio from './Inicio'
import Info from './Informacion'
import Payments from './Payments'
import Lab from './Videos'
import Agenda from '../Agenda/Agenda'
import Time_Zone from '../Functions/Functions'
import * as Web from '../../Constants/Web'
import Images from '../../Constants/images'
class App extends Component {

  constructor(props){
          super(props);
          this.state={
              Inicio:'1',
              url:Web.wurl+'/',
	            url2:'Update/Update_StudentCountry.php',
              Agenda:'',
              Info:'',
              lab:'',
              pay:'',
             show:false,
             redirectToReferrer:false,
             Loader:true
            };
      }

      handleClose() {
      this.setState({ show: false });
      }
      handleShow() {
      this.setState({ show: true });
      }
      async   componentWillMount(){
          let myItem = localStorage.getItem('userData')
          if (myItem) {

          const info=JSON.parse(myItem)
          //alert(info.Id)
          //alert(JSON.stringify(info))
          const student={
            id:info.Id
          }
          this.props.Edit('Get_Student.php',student);
          this.setState({
            Loader:false
          })
          }else {
            localStorage.removeItem('userData');
            this.setState({redirectToReferrer:true})
          }

}





      Inicio(){
        this.setState({
          Inicio:'1',
          Agenda:'',
          Info:'',
          pay:'',
          lab:''
        })
      }
      Agenda(){
        this.setState({
          Inicio:'',
          Agenda:'1',
          Info:'',
          pay:'',
          lab:''
        })
      }
      Info(){
        this.setState({
          Inicio:'',
          Agenda:'',
          Info:'1',
          pay:'',
          lab:''
        })
      }
      pay(){
        this.setState({
          Inicio:'',
          Agenda:'',
          Info:'',
          pay:'1',
          lab:''
        })
      }
      lab(){
        window.open('https://conversationhackers.club.hotmart.com/login')
        /*
        this.setState({
          Inicio:'',
          Agenda:'',
          Info:'',
          pay:'',
          lab:'1'
        })*/
      }
logOut(){
  localStorage.removeItem('userData');

this.setState({
  redirectToReferrer:true
})}

  render() {
  const student=this.props.edit
    //alert(JSON.stringify(student))
    if (this.state.redirectToReferrer) {
     return (<Redirect to={'/'}/>)
   }
    return (
    <div>


    <Modal show={this.state.show} onHide={this.handleClose.bind(this)}>
      <Modal.Header closeButton>
        <Modal.Title>Salir</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div>
      <Button variant="primary" className="cv-button" onClick={this.logOut.bind(this)}>
        SI
      </Button>
      <Button variant="primary" className="cv-button" onClick={this.handleClose.bind(this)}>
      NO
      </Button>
      </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.handleClose.bind(this)}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
{this.state.Loader==true?<p>Cargando...</p>:
<div>
    <Navbar collapseOnSelect expand="lg"  className="Menu navbar-blue"  >
    <Navbar.Brand >
     <img src={Images.Logos.Main}  className="Logo_Img_Sm" alt=""/>
  </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">

    <Nav className="mr-auto">

  <Nav.Link >
  {student.map(item => (


        <div key={item} className="white-text">{item.Name} {item.Last_Name.substring(1, 3)}</div>


      ))}</Nav.Link >



    <Nav.Link  href="#" onClick={this.Inicio.bind(this)} className="white-text">  <div  className="white-text">Inicio</div></Nav.Link>

      <Nav.Link  href="#" onClick={this.Agenda.bind(this)}className="white-text"><div  className="white-text">Agendar</div></Nav.Link>

      <Nav.Link  href="#" onClick={this.Info.bind(this)} className="white-text"><div  className="white-text">Información</div></Nav.Link>

            <Nav.Link  href="#" onClick={this.lab.bind(this)} className="white-text"><div  className="white-text">English lab</div></Nav.Link>
      <Nav.Link  href="#" onClick={this.pay.bind(this)} className="white-text"><div  className="white-text">Pagos</div></Nav.Link>


    {student.map(item => (
              <Nav.Link  key={item} >
                  <div  className="red-text ">{item.Name_Membership}:{item.Sessions}</div>
                </Nav.Link  >
        ))}
        </Nav>
        <Nav>

          <Nav.Link eventKey={2} href="#" onClick={this.handleShow.bind(this)} className="white-text">
          <div  className="white-text">
          Salir</div>
          </Nav.Link>
        </Nav>
  </Navbar.Collapse>
</Navbar>
{/*https://codesandbox.io/s/react-usecontext-rydy5?file=/src/context/defaults.js*/}
  {this.state.Inicio?<Inicio/>:null}
  {this.state.Agenda?<Agenda/>:null}
  {this.state.Info?<Info/>:null}
  {this.state.lab?<Lab/>:null}
  {this.state.pay?<Payments/>:null}
</div>}
        </div>
    );
  }
}
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({

              edit: state.edit.edit,
    }),
    //mapeo de funciones
    {
        Edit
        }
)(App);
