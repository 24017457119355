import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Helpers from '../../lib/Helper'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from './Navbar';
import Images from '../../Constants/images'
import GrayTemplate from '../MainComponents/Gray_Template'
import Form from 'react-bootstrap/Form'
import Desc from './Desc'
import ButtonFree from './ButtonFreeClass'
import Scholarship from './Scholarship'
import Socialbars from './SocialBars'
import Head from './freeClass/header'
import Middle from './freeClass/middle'
import Footer from './freeClass/footer'
class Method extends Component {
  constructor(props){
    super(props)
    this.state={
      uid:'8B57J61q4bM51u9oF7tKln340Fm2',
      show:false,
      title:'',
      Navbar:null,
      Social:'',
    }

  }
  componentDidMount(){
    Helpers.GetHomepageInfo(this.state.uid,(des)=>{

 this.setState({
  Navbar:des.Navbar,
  Social:des.social,
 })
 })
  }

  render() {

    const social=this.state.Social
    return (
      <div >

{this.state.Navbar?
      <Navbar edit={false} data={this.state.Navbar}/>:null}
      {this.state.Social?
<Socialbars edit={this.state.edit} data={social}/>:null}
    <Container   className="text-center margin-top90" >
  <Head/>
  <Middle/>
  <Footer/>

</Container>
<Col xs={12} className="fotter-freeclas no-padding mainstickydoctor "><a href={"https://pay.hotmart.com/E41634392F?checkoutMode=2"}>
<img src={Images.FreeClass.Sticky} className="img-100-footer-responsive CH_Responsive"/>
<img src={Images.FreeClass.Sticky} className="img-100-footer CH_NR"/></a></Col>
</div>

    );
  }
}

export default Method;
