import React,{useState} from 'react'
import Button from 'react-bootstrap/Button'
import Loader from '../../../Loader'
import * as Web from '../../../../Constants/Web'

const RegisterClassButton = (props) =>{
  const {id_class, id_student, reload, tempData, classroom}=props
  const [loading, setLoading]=useState(false)


  if (!id_class || !id_student) {
    return<div> no hay valores</div>
  }

  const register = ()=>{
    setLoading(true)
    const data={
      id_student:id_student,
      id_class:id_class,
      classroom:classroom

    }
    var url = Web.wurl+'/Api/conversationHackers/discountClass.php';
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.text())
    .catch(error => console.error('Error:', error))
    .then(response => {
      setLoading(false)
      reload(tempData)
    })
  }
  if (loading) {
    return(<Loader/>)
  }else {
    return (<Button variant="outline-dark" onClick={()=>register()}>Confirmar clase</Button>)
  }
}
export default RegisterClassButton
