import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Helpers from '../../lib/Helper'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Images from '../../Constants/images'
import GrayTemplate from '../MainComponents/Gray_Template'
import Form from 'react-bootstrap/Form'
import ButtonFree from './ButtonFreeClass'
import Zoom from 'react-reveal/Fade';
import Desc from './Desc'
var labels = [
"Acceso ILIMITADO a la plataforma de Conversation Hackers",
"Acceso ILIMITADO plataforma de Gramática Práctica (English Lab)",
"Handbook",
"Preparación para examenes oficiales internacionales (TOEFL, CAMBRIDGE, IELTS)",
"Acceso a clubs de conversación (BASICO,AVANZADO,TEENS,KIDS)",
"Acceso clubs de gramática práctica (INTRO, BASICA, AVANZADA)",
"Acceso a clubs de pronunciación (Accent reduccion)",
"Acceso a clubs de comprensión de imágenes y textos ",
"Acceso a clubs de Tecnicismos y modismos (IDIOMS, SLANGS, PHRASAL VERBS)",
"Acceso a clubs de Negocios ",
"Acceso a Roleplays: Simulaciones conversacionales de situaciones reales ",
"Acceso a Speech clubs (Presentación mensual en Inglés) ",
"Acceso a Coaching Sessions (Evaluación semanal)",
"Acceso a Workcamps y campamentos de verano internacionales",
"Acceso al grupo privado en facebook y whatsapp",
"Acceso a Sesiones de coaching por whatsapp ilimitado con Laura y Betsy",
"Acceso a Master clubs semanales impartidos por Betsy y Laura  ",
"Speaking dude (Conectate con otros para practicar Inglés)",
"Acceso a Video Club",
"Acceso a Reading Club",
"Videollamada mensual con Laura o Betsy"
];
class CardsAbout extends Component {
  constructor(props){
    super(props)
    this.state={
      uid:'8B57J61q4bM51u9oF7tKln340Fm2',
      show:false,
      title:''
    }

  }
  render() {
    return (
      <Zoom left cascade>
      <img src={Images.FreeClass.enjoy} className="img-100"/>
      <div >

          {(() => {
            const options = [];

            for (let i = 0; i <= 20; i++) {
              options.push(
                <div className="grid">
                  <img className="checking-img" src={Images.Settings.Checking}/>
                  <span className="span-scholarship"> {labels[i]} </span><br/>
                </div>
              );
            }

            return options;
          })()}
      </div>
</Zoom>
    );
  }
}

export default CardsAbout;
