import React, { Component } from 'react';
import '../../Styles/App.css';
import Nav from 'react-bootstrap/Nav'
import Add from '../MainComponents/Add'
import Payments from './Reports/Payments'
import Remaining from './Reports/Remaining'
import History from './Reports/History'
import Agenda from './Reports/Agenda'
import Not from './Reports/NotAgenda'
import Nxtpay from './Reports/Nxtpay'
import Inactives from './Reports/Inactives'
import Standby from './Reports/Standby'
import Gray_Template from '../MainComponents/Gray_Template'

class App extends Component {

  constructor(props){
          super(props);
          this.state={
              payments:'1',
              history:'',
              agenda:'',
              not:'',
              nxtpay:'',
              inactives:'',
              standby:'',
              one:'Active_Menu',
              two:'',
              three:'',
              four:'',
              five:'',
              six:'',
              seven:''

            };
      }


payments(){this.setState({  payments:'1',remaining:'',history:'',agenda:'',not:'',nxtpay:'',inactives:'',standby:'',one:'Active_Menu',onefive:'',two:'',three:'',four:'',five:'',six:'',seven:''})}
remaining(){this.setState({  payments:'',remaining:'1',history:'',agenda:'',not:'',nxtpay:'',inactives:'',standby:'',one:'',onefive:'Active_Menu',two:'',three:'',four:'',five:'',six:'',seven:''})}
history(){this.setState({  payments:'',remaining:'',history:'1',agenda:'',not:'',nxtpay:'',inactives:'',standby:'',one:'',onefive:'',two:'Active_Menu',three:'',four:'',five:'',six:'',seven:''})}
agenda(){this.setState({  payments:'',remaining:'',history:'',agenda:'1',not:'',nxtpay:'',inactives:'',standby:'',one:'',onefive:'',two:'',three:'Active_Menu',four:'',five:'',six:'',seven:''})}
notagenda(){this.setState({  payments:'',remaining:'',history:'',agenda:'',not:'1',nxtpay:'',inactives:'',standby:'',one:'',onefive:'',two:'',three:'',four:'Active_Menu',five:'',six:'',seven:''})}
nxtpay(){this.setState({  payments:'',remaining:'',history:'',agenda:'',not:'',nxtpay:'1',inactives:'',standby:'',one:'',onefive:'',two:'',three:'',four:'',five:'Active_Menu',six:'',seven:''})}
inactives(){this.setState({  payments:'',remaining:'',history:'',agenda:'',not:'',nxtpay:'',inactives:'1',standby:'',one:'',onefive:'',two:'',three:'',four:'',five:'',six:'Active_Menu',seven:''})}
standby(){this.setState({  payments:'',remaining:'',history:'',agenda:'',not:'',nxtpay:'',inactives:'',standby:'1',one:'',onefive:'',two:'',three:'',four:'',five:'',six:'',seven:'Active_Menu'})}




  render() {
    return (
        <Gray_Template>
        <label>Reportes</label>
        <Nav>
  <Nav.Item>
    <Nav.Link href="#" onClick={this.payments.bind(this)} className={this.state.one+" hoover-black"}>Pagos</Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link href="#" onClick={this.remaining.bind(this)} className={this.state.onefive+" hoover-black"}>Adeudos</Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link href="#" onClick={this.history.bind(this)} className={this.state.two+" hoover-black"}>Historial de clases</Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link href="#" onClick={this.agenda.bind(this)} className={this.state.three+" hoover-black"}>Agendado</Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link href="#" onClick={this.notagenda.bind(this)} className={this.state.four+" hoover-black"}>No agendado</Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link href="#" onClick={this.nxtpay.bind(this)} className={this.state.five+" hoover-black"}>Proximos a pagar</Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link href="#" onClick={this.inactives.bind(this)} className={this.state.six+" hoover-black"}>Inactivos</Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link href="#" onClick={this.standby.bind(this)} className={this.state.seven+" hoover-black"}>Standby</Nav.Link>
  </Nav.Item>
</Nav>
<hr/>
{this.state.payments?<Payments id={this.props.id} Master={'active'}/>:null}
{this.state.remaining?<Remaining id={this.props.id}/>:null}
{this.state.history?<History id={this.props.id}/>:null}
{this.state.agenda?<Agenda id={this.props.id}/>:null}
{this.state.not?<Not id={this.props.id}/>:null}
{this.state.nxtpay?<Nxtpay id={this.props.id}/>:null}
{this.state.inactives?<Inactives id={this.props.id}/>:null}
{this.state.standby?<Standby id={this.props.id}/>:null}





            </Gray_Template>
    );
  }
}

export default App;
