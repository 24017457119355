//const BASEURL = "http://localhost/conversationhackers/Api/";
const BASEURL = "https://conversationhackers.com/Api/";
//const BASEURL = "https://conversationhackers.com/test/Api/";




export const apiGetPlaces = (type,id) => {
////to pass more values go to postsReducer.js and add every value
const url = BASEURL+type;
if(!id){
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json())
}}












///////////////agendas

export const apiGetTimeAgenda = (type,id) => {
////to pass more values go to postsReducer.js and add every value
const url = BASEURL+type;
if(!id){
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json())
}}

export const apiGetMonday = (type,id) => {
////to pass more values go to postsReducer.js and add every value
const url = BASEURL+type;
if(!id){
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json())
}}

export const apiGetTuesday = (type,id) => {
////to pass more values go to postsReducer.js and add every value
const url = BASEURL+type;
if(!id){
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json())
}}
export const apiGetWednesday = (type,id) => {
////to pass more values go to postsReducer.js and add every value
const url = BASEURL+type;
if(!id){
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json())
}}
export const apiGetThursday = (type,id) => {
////to pass more values go to postsReducer.js and add every value
const url = BASEURL+type;
if(!id){
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json())
}}

export const apiGetFriday = (type,id) => {
////to pass more values go to postsReducer.js and add every value
const url = BASEURL+type;
if(!id){
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json())
}}

export const apiGetSaturday = (type,id) => {
////to pass more values go to postsReducer.js and add every value
const url = BASEURL+type;
if(!id){
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json())
}}

export const apiGetSunday = (type,id) => {
////to pass more values go to postsReducer.js and add every value
const url = BASEURL+type;
if(!id){
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json())
}}







/////////////agenda

////*counters//////////////////////////////////
export const apiGetStudentCounter = (type,id) => {
////to pass more values go to postsReducer.js and add every value
const url = BASEURL+type;
if(!id){
  //alert("empty")
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json())
}}

export const apiGetCoachCounter = (type,id) => {
////to pass more values go to postsReducer.js and add every value
const url = BASEURL+type;
if(!id){
  //alert("empty")
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json())
}}
export const apiGetBirthdayCounter = (type,id) => {
////to pass more values go to postsReducer.js and add every value
const url = BASEURL+type;
if(!id){
  //alert("empty")
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json())
}}
///*end counters//////////////////////////////



export const apiGetClassroom = (type,id) => {
////to pass more values go to postsReducer.js and add every value
const url = BASEURL+type;
if(!id){
  //alert("empty")
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json())
}}

export const apiGetActiveLevles = (type,id) => {
////to pass more values go to postsReducer.js and add every value
const url = BASEURL+type;
if(!id){
  //alert("empty")
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json())
}}
export const apiGetPost = (type,id) => {
////to pass more values go to postsReducer.js and add every value
const url = BASEURL+type;
if(!id){
  //alert("empty")
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json())


}}
export const apiEdit = (type,id) => {
////to pass more values go to postsReducer.js and add every value
const url = BASEURL+type;
if(!id){
  //alert("empty")
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json())


}}
export const apiGetPost2 = (type,id) => {
////to pass more values go to postsReducer.js and add every value
const url = BASEURL+type;
if(!id){
  //alert("empty")
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json())


}}

export const apiGetPlaceCoaches = (type,id) => {
const url = BASEURL+type;
if(!id){
  //alert("empty")
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json()
        .catch(res => {
          //alert(res)
            console.log(res);
        })
      )


}}


export const apiGetPlaceBirthdays = (type,id) => {
const url = BASEURL+type;
if(!id){
  //alert("empty")
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json()
        .catch(res => {
          //alert(res)
            console.log(res);
        })
      )


}}

export const apiGetAgenda = (type,id) => {
const url = BASEURL+type;
if(!id){
  //alert("empty")
  return fetch(url)
      .then(response => response.json());
  }else {
    const request = {
        method: 'POST',
        body:JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };
    return fetch(url,request)
        .then(response => response.json()
        .catch(res => {
          //alert(res)
            console.log(res);
        })
      )


}}


export const apiAddPost = (post,type) => {
    const url = BASEURL +type;
    const request = {
        method: 'POST',
        body: JSON.stringify(post),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };

    return fetch(url, request)
        .then(response => response.json())


};
export const apiAddTime = (post,type) => {
    const url = BASEURL +type;
    const request = {
        method: 'POST',
        body: JSON.stringify(post),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };

    return fetch(url, request)
        .then(response => response.json())


};
export const apiUpdateActive = (id, post) => {
    const url = BASEURL+ post;
      //const url=post
    const request = {
        method: 'POST',
        body: JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };

    return fetch(url, request)
        .then(response => response.json())
};


export const apiUpdate = (id, post) => {
    const url = BASEURL+ post;
      //const url=post
    const request = {
        method: 'POST',
        body: JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };

    return fetch(url, request)
        .then(response => response.json())
};


export const apiUpdatePost = (id, post) => {
    const url = BASEURL + "/posts/" + id;

    const request = {
        method: 'PUT',
        body: JSON.stringify(post),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };

    return fetch(url, request)
        .then(response => response.json())
};

export const apiDeletePost = (id,post) => {
    const url = BASEURL + post;
    const request = {
        method: 'DELETE',
        body: JSON.stringify(id),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    };

    return fetch(url, request)
        .then(response => response.json())
};
