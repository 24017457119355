import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import {Link} from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import '../../Styles/App.css';
import * as LOGIN from '../../Constants/Menu'
import logo from '../../Media/Conversation_Hackers_Logo.png'
import {Redirect} from 'react-router-dom';
import * as Web from '../../Constants/Web'

//php version 7.3.2

class LoginAdmin extends Component {

  constructor(props){
          super(props);
          this.state={
              username:'',
              password:'',
              redirectToReferrer: false,
              User:'',
              url:Web.wurl+'/',
            };
            this.login=this.login.bind(this)
            this.onChange=this.onChange.bind(this)
      }



      login() {
        const data = {
              Email: this.state.username,
              Password:this.state.password,
              Table:'students'
            }
        const res=[]
            var url = this.state.url+'Api/Login.php';
            //var url = 'https://conversation-test.000webhostapp.com/Api/Create/Create_State.php';

            fetch(url, {
              method: 'POST', // or 'PUT'
              body: JSON.stringify(data), // data can be `string` or {object}!
              header: {'Content-Type': 'application/json'}
            }).then(res => res.json())
            .catch(error => console.error('Error:', error))
            .then(response => this.Set(response) );




      }



Set(e) {
   e  = e.filter((item)=>{
    if(item.Error){
      alert('error comprueba tus datos')
    }if(item.User){
      //return this.setState({User:item.User})
      const sesionD=item.User;
      const PlaceD=item.Place;
      const data = { "Id":sesionD,"type":"Student","Place":PlaceD};
            localStorage.setItem('userData',JSON.stringify(data));
            if (localStorage.length > 0) {
              this.setState({redirectToReferrer: true});
              //let myItem = localStorage.getItem('userData')
              //const info=JSON.parse(myItem)
              //alert(JSON.stringify(info))
      } else {
        alert("error")
        // No items
      }

    }})
}
      onChange(e){
        this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre

      }

      iniciar(){
        alert("hola admin")
      }


  render() {
    if (this.state.redirectToReferrer) {
     return (<Redirect to={'/Profile'}/>)
   }

   if(localStorage.getItem('userData')){
     let myItem = localStorage.getItem('userData')
     const info=JSON.parse(myItem)
     if(info.type=='MASTER'){
       return (<Redirect to={'/admin'}/>)
     }
     if(info.type=='Student'){
       return (<Redirect to={'/Profile'}/>)
     }
     if(info.type=='Coach'){
       return (<Redirect to={'/Coach'}/>)
     }
     if(info.type=='Admin'){
       return (<Redirect to={'/admins'}/>)
     }

   }
    return (
      <div className="Login-Card" >
      <div className="Login-Card2">
      <img src={logo} className="Logo_Img"/>
      <br/>
      <p htmlFor="basic-url" className="CH_Text2">{LOGIN.STUDENT}</p>
      <label htmlFor="basic-url" className="CH_Text">{LOGIN.EMAIL}</label><br/>
        <InputGroup className="mb-3" >
          <FormControl
          onChange={this.onChange}
          name="username"
            placeholder="conversationhacker@email.com"
            type="email"
            aria-label="Correo"
            aria-describedby="basic-addon1"
          />
        </InputGroup>

        <label htmlFor="basic-url" className="CH_Text">{LOGIN.PASSWORD}</label>
        <InputGroup className="mb-3">
        <FormControl
        onChange={this.onChange}
        name="password"
          placeholder="******"
          type="password"
          />
        </InputGroup>
    <Button variant="dark" className="CH-buttons" onClick={this.login}>
      <label className="CH_Text">{LOGIN.LOGIN}</label>
    </Button>
  </div>
        </div>
    );
  }
}

export default LoginAdmin;
