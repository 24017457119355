import { connect } from 'react-redux';
import { fetchGetPlaces, fetchAddPost, fetchUpdatePost, fetchDeletePost } from '../../../reducers/postsReducer';
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import {Link} from 'react-router-dom'
import SearchInput, {createFilter} from 'react-search-input';
import Table from 'react-bootstrap/Table'
import TestCards from '../cards'
import Form from 'react-bootstrap/Form'
import * as Web from '../../../Constants/Web'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import * as Place from '../../../Constants/Menu'
import * as Forms from '../../../Constants/Forms'
import Images from '../../../Constants/images'
const KEYS_TO_FILTERS = ['Id_Place','Name','State','City','Phone','State','Address','Email'];

var url="/admin/sucursal/"
class ShowPlaces extends Component {

  constructor(props){
          super(props);
          this.state={
                url2:Web.wurl+'/',
                show:false,
            searchTerm: '',
                Name:'',
                States:'',
                State:'',
                City:'',
                Phone:'',
                Address:'',
                Zip:'',
                Email:'',
                url:'Create/Create_Place.php',
            };
            this.onChange=this.onChange.bind(this);
            this.save=this.save.bind(this);
            this.update=this.update.bind(this)
      }


      componentDidMount() {
          this.props.fetchGetPlaces('Get_Places.php');
          fetch(this.state.url2+"Api/Get_States.php")
          .then((response)=>response.json())
          .then((responseJson)=>
          {
          this.setState({States:responseJson});
          const vare=JSON.stringify(this.state.States)
       })
      }
      handleClose() {
      this.setState({ show: false });
      }

      handleShow() {
      this.setState({ show: true });
      }
      update(){
        alert("Correcto");
        this.props.fetchGetPlaces('Get_Places.php');
        this.handleClose();

      }
      onChange(e){
        this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre

      }
      save(event){
         event.preventDefault();
        const data = {
              Name: this.state.Name,
              Address:this.state.Address,
              City:this.state.City,
              State:this.state.State,
              Zip:this.state.Zip,
              Phone:this.state.Phone,
              Email:this.state.Email,
            }

                this.props.fetchAddPost(data,this.state.url);
                this.update()
      }
     searchUpdated (term) {
      this.setState({searchTerm: term})
  }


addPlace(){
  return(
    <Form
    onSubmit={this.save.bind(this)}>
  <Form.Row>
    <Form.Group as={Col} controlId="formGridEmail">
      <Form.Label>{Forms.placeName}</Form.Label>
      <Form.Control onChange={this.onChange} name="Name"  placeholder="Nombre" />
    </Form.Group>

  </Form.Row>
<Form.Row>
  <Form.Group as={Col}  controlId="formGridAddress1">
    <Form.Label>{Forms.placeAddress}</Form.Label>
    <Form.Control onChange={this.onChange} name="Address" placeholder="Domicilio" />
  </Form.Group>
    <Form.Group as={Col}  controlId="formGridCity">
      <Form.Label>{Forms.placeCity}</Form.Label>
      <Form.Control  onChange={this.onChange} name="City" placeholder="Ciudad"/>
    </Form.Group>
    </Form.Row>

    <Form.Row>
    {this.state.States?
    <Form.Group as={Col} controlId="formGridState">
      <Form.Label>{Forms.placeState}</Form.Label>

      <Form.Control as="select" onChange={this.onChange} name="State" placeholder="Estado">
      <option>none</option>
      {this.state.States.map(item => (
            <option key={item}>{item.Name}</option>
          ))}
      </Form.Control >
    </Form.Group>:null}

    <Form.Group as={Col} controlId="formGridZip" >
      <Form.Label>{Forms.placeZip}</Form.Label>
      <Form.Control onChange={this.onChange} name="Zip" placeholder="CP"/>
    </Form.Group>
    </Form.Row>
        <Form.Row>

  <Form.Group as={Col} controlId="formGridZip" >
    <Form.Label>{Forms.placePhone}</Form.Label>
    <Form.Control onChange={this.onChange} name="Phone" type="tel" placeholder="Telefono"/>
  </Form.Group>
  <Form.Group as={Col} controlId="formGridZip" >
    <Form.Label>{Forms.placeEmail}</Form.Label>
    <Form.Control onChange={this.onChange} name="Email" type="email" placeholder="Correo"/>
  </Form.Group>
</Form.Row>
{this.state.Name&&this.state.Address&&this.state.City&&this.state.State&&this.state.Zip&&this.state.Phone&&this.state.Email?<button className="btn btn-success" type="submit">Agregar</button>:null}

</Form>
  )
}

  render(){
    const posts = this.props.placesall;
      const {children}=this.props
		const filteredInfo = this.props.placesall.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
	        if (this.props.placesall.length === 0 ) {
	        	return(
	            <div>
	            <h1>{Forms.Empty}</h1>
              <div >
              <a onClick={this.handleShow.bind(this)} href="javascript:;" className="CV_Button">
              <img src={Images.Admin.Add} className="CV_Icons"/><br/>
              <p>{Place.addPlace}</p>
                </a>
                  </div>
              <Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered>
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">{Place.addPlace}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
                {this.addPlace()}
                </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose.bind(this)}>
                    {Forms.Cancel}
                  </Button>
                </Modal.Footer>
              </Modal>
	            </div>
	            )} else{
		return(
				<div>
        <Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{Place.addPlace}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
          {this.addPlace()}
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose.bind(this)}>
              {Forms.Cancel}
            </Button>
          </Modal.Footer>
        </Modal>
	        <SearchInput placeholder="Buscar" className="search-input form-group my-input text-center"  onChange={this.searchUpdated.bind(this)} />
          <div className="CH_NR text-center ">

          <div >
          <a onClick={this.handleShow.bind(this)} href="javascript:;" className="CV_Button">
          <img src={Images.Admin.Add} className="CV_Icons"/><br/>
          <p>{Place.addPlace}</p>
            </a>
              </div>
				  {filteredInfo.map((dato, key) => {
	  return(
      <Link  to={`${url}${dato.Id_Place}`} key={key} >
      <div className="card-places card-place text-center">
       <div className="additional-home text-center">
       {/*<div class="more-info text-center">*/}
         <div class="text-center">
           <h3 className="CH-Text-white"></h3>
           <div class="coords">
           <h2>{Forms.School} {dato.Name}</h2>
           <p>{dato.Address}</p>
           <p>{dato.Phone}</p>
           </div>
           <div class="coords">
             <span></span>
           </div>
           <div class="stats">

           </div>
         </div>
       </div>
       <div >
        <img src={Images.Logos.Main} className="CH_bxl_Icono"/>
        <h2>{Forms.School} {dato.Name}</h2>
        <p>{dato.Address}</p>
        <p>{dato.Phone}</p>
       </div>
      </div>

    </Link>
	                        )
	                    })
	                }
                  </div>
                  {/*comienza el area de responsivo */}

                      <div className="Show_Data_P">

                      <ul className="Show_Data">
                      <div className="CV_Square_Gray">
                      <a onClick={this.handleShow.bind(this)} href="javascript:;" className="CV_Button">
                      <img src={Images.Admin.Add} className="CV_Icons"/><br/>
                      <p>name</p>
                        </a>
                          </div>
                  {filteredInfo.map((dato, key) => {
            return(
              <Link  to={`${url}${dato.Id_Place}`} key={key}>
        <div key={key}className="CV_Square_Gray2">

        <img src={Images.Admin.Place} className="CH_BG_Icono"/>
        <h6>{dato.Id_Place}</h6>
        <label>{dato.Name}</label>
        <hr/>
        <label>{dato.City}</label>
        <label>{dato.State}</label>
            </div>
            </Link>
                                  )
                              })
                          }
                          </ul>
                      </div>

				</div>

			)}
		}





}

export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        placesall: state.posts.places,
    }),
    //mapeo de funciones
    {
        fetchGetPlaces, fetchAddPost, fetchUpdatePost, fetchDeletePost
    }
)(ShowPlaces);
