import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Helpers from '../../lib/Helper'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Images from '../../Constants/images'
import WhiteTemplate from '../MainComponents/White_Template'
import Form from 'react-bootstrap/Form'
import Navbar from './Navbar';
import Desc from './Desc'
import Socialbars from './SocialBars'
import Scholarship from './Scholarship'
import ModalHome from './ModalPrices'
import Questions from './prices/questions'
import Cardpost from '../blog/cardPost'
import videos from '../../Constants/videos'
class Price extends Component {
  constructor(props){
    super(props)
    this.state={
      uid:'8B57J61q4bM51u9oF7tKln340Fm2',
      show:false,
      title:'',
      Social:'',
      SocialBars:'',
      Navbar:null,
      edit:false,
      video:''
    }
    this.close=this.close.bind(this);
  }
  Modal(e){
    if (e=='person1') {
      this.setState({video:videos.prices.person1})
    }
    if (e=='person2') {
      this.setState({video:videos.prices.person2})
    }
    if (e=='person3') {
      this.setState({video:videos.prices.person3})
    }
this.setState({
  show:true
})
  }
  componentDidMount(){
    Helpers.GetHomepageInfo(this.state.uid,(des)=>{
 this.setState({
  Social:des.social.free,
   SocialBars:des.social,
   Navbar:des.Navbar

 })

})
if(localStorage.getItem('userData')){
  let myItem = localStorage.getItem('userData')
  const info=JSON.parse(myItem)
  if(info.type=='MASTER'){
    //return (<Redirect to={'/admin'}/>)
    this.setState({edit:true})
  }
  if(info.type=='admin'){
    this.setState({edit:true})
  }

}
}
close(){
  this.setState({show:false})
}
  render() {
    const social=this.state.Social
    const socialBars=this.state.SocialBars
    return (
      <div>
      <ModalHome video={true}
      closeBlack={this.close}
      videoUrl={this.state.video}
      ruleta={false}
      setShow={this.state.show}
      talk={Images.FreeClass.talk}
      talkTime={5000} />
{this.state.Navbar?
      <Navbar edit={false} data={this.state.Navbar}/>:null}
      {this.state.Social?
<Socialbars edit={false} data={socialBars}/>:null}
      <WhiteTemplate className="margintop10">
      <Row className={'prices_class'} >

      <Col xs={2} md={2}>
      </Col>
  <Col  md={2} className=" pointer text-center" >
      <img src={Images.prices.person1} className="img-100-price pointer" onClick={()=>this.Modal('person1')} />
  </Col>
  <Col  md={2} className="pointer text-center">
      <img src={Images.prices.person2} className="img-100-price pointer" onClick={()=>this.Modal('person2')} />
  </Col>
  <Col  md={2} className="pointer text-center">
      <img src={Images.prices.person3} className="img-100-price pointer" onClick={()=>this.Modal('person3')} />
  </Col>
  <Col xs={2} md={2}>
  </Col>


      </Row>


    <Row className="Animation_FadeDown Center margintop15 ">
    <Col  md={5} ><Desc link={this.state.Social}/>
    </Col>
      <Col  md={7}>
        <Scholarship/>

      </Col>
      <img src={Images.FreeClass.question} className="img-100-ask"/>
      <Questions/>
      </Row>
</WhiteTemplate>

<Col xs={12} className="fotter-freeclas no-padding mainstickydoctor "><a href={"https://pay.hotmart.com/E41634392F?checkoutMode=2"}>
<img src={Images.FreeClass.Sticky} className="img-100-footer-responsive CH_Responsive"/>
<img src={Images.FreeClass.Sticky} className="img-100-footer CH_NR"/></a></Col>
</div>
    );
  }
}

export default Price;
