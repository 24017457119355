import React, { Component } from 'react';
import firebase from 'firebase'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Helper from '../../../lib/Helper'
import '../../../Styles/App.css';

var fecha;
var archivo=[];
var dataimages=[]
class Media extends Component {
constructor(props){
  super(props)
  this.state={
    uid:'8B57J61q4bM51u9oF7tKln340Fm2',
   uploadValue:'',
   subir:'',
   banner:[],
   bannerhome:[],
   modalhome:false,

  }
}

componentWillMount(){
Helper.GetBannersHome(this.state.uid,(des)=>{
this.setState({
  bannerhome:des
})

})
}

  handleClose() {
  this.setState({ modalhome: false,modalbanner:false,gallerymodal:false });
  }
  modalhome(){
    this.setState({ modalhome: true})
  }
  homepicture(event){
     archivo=event.target.files[0]
    this.setState({
      home:event.target.files[0]/*guarda el archivo en un estado para despues subirlo cuando se le de click*/
    })
  }
  /*upload*/home(){
    fecha = new Date();
     var segundos = fecha.getSeconds();
     var minutos = fecha.getMinutes();
     var horas = fecha.getHours();
     var anio = fecha.getFullYear();
     var mes = fecha.getMonth();
     var dia = fecha.getDate();
     var fet=anio+'a'+mes+'a'+dia+'a'+horas+'a'+minutos+'a'+segundos
    this.setState({subir:'subiendo'})
    const file = this.state.home
    const storageRef = firebase.storage().ref("Gallery/Home/"+fet+".jpg")
    const task = storageRef.put(file)

    task.on('state_changed', (snapshot) => {
      let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      this.setState({
        uploadValue: percentage
      })
    }, (error) => {
      alert("hubo un error, vuelve a intentarlo.")
      this.setState({
        uploadValue:'',
        subir:''

      })
    }, () =>{
      task.snapshot.ref.getDownloadURL().then((downloadURL)=> {


        const gallery={
          Url:downloadURL,
          id:fet
        }
        Helper.AddPictureHome(this.state.uid,fet,(gallery)).then(()=>{/*informacion de archivo*/
          window.location.reload(true);
        }).catch(function(error){
        let MS=error.message
        alert(MS)
        });
        this.setState({
          uploadValue:'',
          subir:''
        })
  })
        });
  }
  DeleteHomePicture(e){
//alert(JSON.stringify(e[1]))

    let userNamePath = "Gallery/Home/Picture/"+e[1]
  firebase.database().ref(userNamePath).remove()

  var desertRef = firebase.storage().ref('Gallery/Home/'+e[1]+'.jpg');

// Delete the file
desertRef.delete().then(function() {
window.location.reload(true);

}).catch(function(error) {
  // Uh-oh, an error occurred!
});

  }


  render() {

      return (
      <div>
      <Button onClick={this.modalhome.bind(this)}>Editar</Button>
      <Modal show={this.state.modalhome} onHide={this.handleClose.bind(this)}>
      <Modal.Header closeButton>
      <Modal.Title>Home</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div>
      <p>Home</p>
      <input className="CargarArchivoBoton" type="file" onChange={this.homepicture.bind(this)} label="Cargar Archivo" s={12} />
      {this.state.home?<Button label="subir" onClick={this.home.bind(this)} className="botonCA">Subir</Button>:null}

      {this.state.bannerhome.map(pic => (
        <div>
        <br/>
          <img
            className="Logo_Img_Sm"
            src={pic.Url}
          />
          <Button label="subir" onClick={()=>this.DeleteHomePicture([pic.Url,pic.id])} className="botonCA">Borrar</Button>
          </div>
        ))}


      {this.state.subir?
        <div>
        <label>Subiendo.....</label>
        <br/>
      <ProgressBar>
      <ProgressBar striped variant="success" now={this.state.uploadValue} key={1} />
      </ProgressBar></div>:null}
      </div>
      </Modal.Body>
      <Modal.Footer>

      <Button variant="secondary" onClick={this.handleClose.bind(this)}>
        Close
      </Button>
      </Modal.Footer>
      </Modal>
          </div>
      );

  }
}

export default Media;
