import React, { Component } from 'react';
import '../../../Styles/App.css';
import Stat from '../../../Media/States.png'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import { connect } from 'react-redux';
import { UpdateActive,fetchGetPost,} from '../../../reducers/postsReducer';
import Button from 'react-bootstrap/Button'
import States from '../Forms/Create_State'
import SearchInput, {createFilter} from 'react-search-input';
import Table from 'react-bootstrap/Table'
import Add from '../../../Media/Add.png';
const KEYS_TO_FILTERS = ['Name'];
class ShowStates extends Component {

  constructor(props){
          super(props);
          this.state={
              datos:[],
            searchTerm: '',
            Name:'',
            Id:'',
            edit:false
            };
      }


    async  componentDidMount(){
       this.props.fetchGetPost('Get_States.php');
     }
     Change(e){
       this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre

     }
     update(){
      this.props.fetchGetPost('Get_States.php');
     }


     searchUpdated (term) {
      this.setState({searchTerm: term})
  }
states(){
  return(
<States/>
  )
}

  handleClose() {
  this.setState({ show: false,edit:false });
  }
  editShow(e){
  this.setState({ edit:true,
  Id:e[0],
  Name:e[1]})

  }
  handleShow() {
  this.setState({ show: true });
  }
  saveEditing(){
    //alert('hola')
    const data = {
          //Table:'Membership',
          Id:this.state.Id,
          Name: this.state.Name,
        }
        this.props.UpdateActive(data,'Update/Update_State.php');
        alert('Correcto')
        this.update()
  }
  active(e){
    {this.update()}
    if(e[0]==1){
      {this.update()}
      const data = {
          Id: e[1],
          Active:'0',
          Table:'states',
          IdTable:'Id_State'
        }
        this.props.UpdateActive(data,'Update/Update_Active.php');
      //alert("desactivado")
      {this.update()}
    }
    {this.update()}
    if(e[0]==0){
      {this.update()}
      const data = {
            Id: e[1],
            Active:'1',
            Table:'states',
            IdTable:'Id_State'
          }
          this.props.UpdateActive(data,'Update/Update_Active.php');
      //alert("activado")
      {this.update()}
    }
{this.update()}
  }

  Edit(){
    return(
      <div>
      <Form>
    <Form.Row>
      <Form.Group as={Col} controlId="formGridEmail">
        <Form.Label>Nombre</Form.Label>
        <Form.Control onChange={this.Change.bind(this)} name="Name" value={this.state.Name} placeholder="Nombre" />
      </Form.Group>

      </Form.Row>
  </Form>
          </div>
    )

  }

  render(){
    const post = this.props.posts;
    const {children}=this.props
		const filteredInfo = this.props.posts.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
	        if (this.props.posts.length === 0 ) {
	        	return(
	            <div>
              {/*------------------edit---------------------*/}
              <Modal show={this.state.edit} onHide={this.handleClose.bind(this)}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
                {this.Edit()}
                </div>
                </Modal.Body>
                <Modal.Footer>
                {this.state.Name?<Button className="btn btn-success" onClick={this.saveEditing.bind(this)}>Guardar</Button>:null}

                  <Button variant="secondary" onClick={this.handleClose.bind(this)}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              {/*--------------------------------------------------------*/}

              <Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered>
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
                {this.states()}
                </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose.bind(this)}>
                    Cancelar
                  </Button>
                </Modal.Footer>
              </Modal>
	            <h1>Empty</h1>


	            </div>
	            )} else{
		return(
				<div><br/>
        {/*------------------edit---------------------*/}
        <Modal show={this.state.edit} onHide={this.handleClose.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
          {this.Edit()}
          </div>
          </Modal.Body>
          <Modal.Footer>
          {this.state.Name?<Button className="btn btn-success" onClick={this.saveEditing.bind(this)}>Guardar</Button>:null}

            <Button variant="secondary" onClick={this.handleClose.bind(this)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/*--------------------------------------------------------*/}

        <Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
          {this.states()}
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose.bind(this)}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
	        <SearchInput placeholder="Buscar" className="search-input form-group my-input text-center"  onChange={this.searchUpdated.bind(this)} />
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>
                <th className="center-ico" >
                <img src={Add} className="CH_SM_Icono" onClick={this.handleShow.bind(this)}/><br/>
                  </th>
                <th className="table-width">Nombre</th>
              <th className="table-width">Editar</th>
              </tr>
            </thead>
            <tbody>
				  {filteredInfo.map((dato, key) => {
	  return(
      <tr key={key}>
      <td className="CH_SM_Icono" onClick={() => this.editShow([dato.Id_State,dato.Name])}><img src={Stat} className="CH_SM_Icono"/></td>
      <td onClick={() => this.editShow([dato.Id_State,dato.Name])}><label className="ch-dark-text">{dato.Name}</label></td>
      <td >
      {dato.Active==1?
  <Button variant="success" onClick={() => this.active([dato.Active,dato.Id_State])}>Active</Button>:null}
  {dato.Active==0?
<Button variant="danger" onClick={() => this.active([dato.Active,dato.Id_State])}>Desactivado</Button>:null}
</td>
    </tr>

	                        )
	                    })
	                }</tbody>
                </Table>





				</div>

			)}
		}





}export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        UpdateActive,fetchGetPost,
    }
)(ShowStates);
