import React, {Component} from 'react';
import * as Web from '../../Constants/Web'
import { UpdateActive,fetchGetPost, fetchAddPost, fetchUpdatePost, fetchDeletePost,DeletePost } from '../../reducers/postsReducer';
import { connect } from 'react-redux';
import SearchInput, {createFilter} from 'react-search-input';
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'
import Video from '../../Media/Video.png'
import Delete from '../../Media/clear.png'
import CardvideoEdit from './Video_edit'
import Button from 'react-bootstrap/Button'
const KEYS_TO_FILTERS = ['title_video'];
 class EditVideo extends Component {
	constructor(props) {
		super(props);
		this.state = {
				searchTerm: '',
			page: 'static',
			url:Web.wurl+'/',
			videos:[]
		};

		this.editShow=this.editShow.bind(this);
	}
	componentWillMount() {
		var url = this.state.url+'Api/Get_Videos.php';
			fetch(url)
			.then((response)=>response.json())
			.then((responseJson)=>
			{
			this.setState({videos:responseJson});
	 })
	}
	searchUpdated (term) {
	 this.setState({searchTerm: term})
}
update(){
	var url = this.state.url+'Api/Get_Videos.php';
		fetch(url)
		.then((response)=>response.json())
		.then((responseJson)=>
		{
		this.setState({videos:responseJson});
 })
}
	handleClose() {

	this.setState({ show: false,edit:false });
  this.update()
	}

	editShow(e){
    //alert(JSON.stringify(e))
    //["9","BLx6fnxQw_k","test","test","9/9/2019 a las 13:56","null"
		//Id_video_conversation,
    //dato.url_video,
    ///dato.title_video,
    //dato.desc_video,
    //dato.Date_video,
    //dato.url_dir_video
		this.setState({ edit:true,
	Id:e[0],
	url_video:e[1],
	title_video:e[2],
	desc_video:e[3],
	Date_video:e[4],
	url_dir_video:e[5],
 });
	}
editDelete(e)
{
	const data={
		Id_video_conversation:e[0]
	}
	//alert(JSON.stringify(data))
		this.props.DeletePost(data,'Delete/delete_video.php')
		alert('correcto')
		{this.update()}
}
active(e){
		if(e[0]==1){
		const data = {
        Id: e[1],
        Active:'0',
        Table:'videos_conversation',
        IdTable:'Id_video_conversation'
      }
      this.props.UpdateActive(data,'Update/Update_Active.php');
			alert('Desactivado')
			{this.update()}
		}
		if(e[0]==0){
		const data = {
        Id: e[1],
        Active:'1',
        Table:'videos_conversation',
        IdTable:'Id_video_conversation'
      }
      this.props.UpdateActive(data,'Update/Update_Active.php');
			alert('Activado')
			{this.update()}
		}
	}
	render(){
    const post = this.state.videos;
		const filteredInfo = this.state.videos.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
	        if (this.state.videos.length === 0 ) {
	        	return(
	            <div>
	            <h1>no hay datos</h1>
	            </div>
	            )} else{
		return(
				<div>
        {/*------------------edit---------------------*/}
        <Modal

      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
        show={this.state.edit} onHide={this.handleClose.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Editar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
					<CardvideoEdit
					id={this.state.Id}
					video={this.state.url_video}
					title={this.state.title_video}
					 Descripci={this.state.desc_video}
					  url={this.state.url_dir_video}
						date={this.state.Date_video}
            />

          </div>
          </Modal.Body>
          <Modal.Footer>
          {this.state.Name&&this.state.Price&&this.state.Info&&this.state.quantity?<Button className="btn btn-success" onClick={this.saveEditing.bind(this)}>Guardar</Button>:null}

            <Button variant="secondary" onClick={this.handleClose.bind(this)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/*--------------------------------------------------------*/}
	        <SearchInput placeholder="Buscar" className="input-cv search-input form-group my-input text-center"  onChange={this.searchUpdated.bind(this)} />


              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr>
                    <th className="table-width">Video</th>
                    <th className="table-width">Nombre</th>
                    <th className="table-width">Borrar</th>
                    <th className="table-width">Act/Des</th>
                  </tr>
                </thead>
                <tbody>



				  {filteredInfo.map((dato, key) => {
	  return(
      <tr key={key}>
      <td className="CH_SM_Icono"><img src={Video} onClick={() => this.editShow([dato.Id_video_conversation,dato.url_video,dato.title_video,dato.desc_video,dato.Date_video,dato.url_dir_video])} className="CH_SM_Icono"/></td>
			<td onClick={() => this.editShow([dato.Id_video_conversation,dato.url_video,dato.title_video,dato.desc_video,dato.Date_video,dato.url_dir_video])}><label className="ch-dark-text" >{dato.title_video}</label></td>
			<td ><label className="ch-dark-text" ><img src={Delete} onClick={() => this.editDelete([dato.Id_video_conversation])} className="CH_SM_Icono"/></label></td>
      <td>
     {dato.Active==1?
 <Button variant="success" onClick={() => this.active([dato.Active,dato.Id_video_conversation])}>Active </Button>:null}
 {dato.Active==0?
<Button variant="danger" onClick={() => this.active([dato.Active,dato.Id_video_conversation])}>Desactivado </Button>:null}
</td>
    </tr>
	                        )
	                    })
	                }

                  </tbody>
                </Table>


				</div>

			)}
		}
}


export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        DeletePost,UpdateActive,fetchGetPost, fetchAddPost, fetchUpdatePost, fetchDeletePost
    }
)(EditVideo);
