import React, { Component } from 'react';
import '../../Styles/App.css';
import Add from '../../Media/Add.png'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Create_State from '../Admin/Forms/Create_State'
import * as Forms from '../../Constants/Menu'
var mod;

class AddButtonClass extends Component {

  constructor(props){
          super(props);
          this.state={
              show:false,
            };
      }


      handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  render() {
    const {link, name}=this.props;
    return (
      <div>
      {/*begins Modal-----------------------------------------------------------------*/}
      <Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>
        {this.props.modal==[Forms.addStateModal]?
        <Create_State/>:null}
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose.bind(this)}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
  {/*End Modal-----------------------------------------------------------------*/}


    <div className="CV_Square_Gray">
    <a onClick={this.handleShow.bind(this)} href="javascript:;" className="CV_Button">
    <img src={Add} className="CV_Icons"/><br/>
    <p>{name}</p>
      </a>
        </div>
        </div>
    );
  }
}

export default AddButtonClass;
