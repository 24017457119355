import React, { Component } from 'react';
import '../../../Styles/App.css';
import Add from '../../../Media/Add.png'
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal'
import { UpdateActive,fetchGetPost, fetchAddPost, fetchUpdatePost, fetchDeletePost } from '../../../reducers/postsReducer';
import SearchInput, {createFilter} from 'react-search-input';
import Table from 'react-bootstrap/Table'
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Memberships from '../../../Media/Memberships.png'
const KEYS_TO_FILTERS = ['name','email','date','phone'];
class ShowMembership extends Component {

  constructor(props){
          super(props);
          this.state={
            searchTerm: '',
                show:false,
                edit:false,
                Name:'',
                Price:'',
                Info:'',
                id:'',
                url:'Create/Create_Membership.php',
                quantity:''
            };
            this.update=this.update.bind(this);

      }

      componentWillMount() {
          this.props.fetchGetPost('Get_WorldStudents.php');
      }


     searchUpdated (term) {
      this.setState({searchTerm: term})
  }
  update(){
    this.props.fetchGetPost('Get_WorldStudents.php');
  }

  render(){
    const post = this.props.posts;
    const {children}=this.props
		const filteredInfo = this.props.posts.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
	        if (this.props.posts.length === 0 ) {
	        	return(
	            <div>
	            <h1>Empty</h1>
	            </div>
	            )} else{
		return(
				<div>

	        <SearchInput placeholder="Buscar" className="input-cv search-input form-group my-input text-center"  onChange={this.searchUpdated.bind(this)} />

          {/*<div className="CV_Square_Gray">
          <a onClick={this.handleShow.bind(this)} href="javascript:;" className="CV_Button">
          <img src={Add} className="CV_Icons"/><br/>
          <p>name</p>
            </a>
              </div>*/}

              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr>
                    <th className="table-width">Nombre</th>
                    <th className="table-width">Correo</th>
                    <th className="table-width">Telf</th>
                    <th className="table-width">Fecha</th>
                  </tr>
                </thead>
                <tbody>



				  {filteredInfo.map((dato, key) => {
	  return(
      <tr key={key}>
      <td className="pointer"><label className="ch-dark-text pointer" >{dato.name}</label></td>
     <td className="pointer" ><label className="ch-dark-text pointer" >{dato.email}</label></td>
    <td className="pointer"><label className="ch-dark-text pointer" >{dato.phone}</label></td>
   <td className="pointer"><label className="ch-dark-text pointer" >{dato.date}</label></td>

    </tr>
	                        )
	                    })
	                }

                  </tbody>
                </Table>


				</div>

			)}
		}





}

export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        UpdateActive,fetchGetPost, fetchAddPost, fetchUpdatePost, fetchDeletePost
    }
)(ShowMembership);
