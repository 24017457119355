import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Route, Link,NavLink,Switch,Redirect,BrowserRouter} from 'react-router-dom'
import PlaceData from './Data/PlaceData'
import Place from './Sucursales'
import Membership from './Membership'
import Settings from './Settings'
import Home from './Home'
import Header from './Menu'
import Agenda from '../Agenda/CreateAgendabig'
import HomePage from '../Home'
import OnlineSettings from './Settings/Online'
import Questions from './Settings/questions'
import Notifications from './Notifications/notifications'
import Reports from './Reports'


class Index extends Component {
  render() {
		if(localStorage.getItem('userData')){
			let myItem = localStorage.getItem('userData')
			const info=JSON.parse(myItem)
			if(info.type=='MASTER'){
				//return (<Redirect to={'/admin'}/>)
			}
			if(info.type=='Student'){
				return (<Redirect to={'/Profile'}/>)
			}
			if(info.type=='Coach'){
				return (<Redirect to={'/Coach'}/>)
			}
			if(info.type=='admin'){
			 return (<Redirect to={'/conversationplace'}/>)
			}

		}else {
			return (<Redirect to={'/'}/>)
		}
    if(!localStorage.getItem('userData')){
      return (<Redirect to={'/'}/>)
    }
    return (
    <div>
    <Header/>
      <Switch>
        <Route path="/admin"                      exact component={Home} />
        <Route path="/admin/sucursal"             exact component={Place}/>
        <Route path="/admin/sucursal/:id"         exact render={props=><PlaceData id={props.match.params.id}/>}/>
        <Route path="/admin/sucursal/:id/Agenda"  exact render={props=><Agenda id={props.match.params.id}/>}/>
        <Route path="/admin/membership"           exact component={Membership}/>
        <Route path="/admin/settings"             exact component={Settings}/>
        <Route path="/admin/settings/questions"   exact component={Questions}/>
        <Route path="/admin/settings/homepage"    exact component={HomePage}/>
        <Route path="/admin/settings/online"      exact component={OnlineSettings}/>
        <Route path="/admin/notifications"        exact component={Notifications}/>
        <Route path="/admin/reports"              exact component={Reports}/>
      </Switch>
    </div>
)}}

export default Index;
