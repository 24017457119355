import React,{useState} from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {CirclePicker} from 'react-color';
import Button from 'react-bootstrap/Button'
import VirtualClass from './virtualClass'
import {updateDay} from './Actions/updateDay'
import Add from './addFiles'
import classes from './createday.module.css'
const EditDay=(props)=>{


  const {coaches,
        classrooms,
        nums,
        backgroundColor,
        handleChangeComplete,
        openDisponibilitie,
        dateFrom,
        dateTo,
        day,
        idAgenda,
        id,
        timezone,
        backgroundhexa,
        coachname,
        coachId,
        levelSelected,
        classroomname,
        quantity,
        infoSelected,
        clasevirtualLink,
        isActiveCV,
        filesToShow,
        deleteClass,
        hourFromold,
        minutesFromold,
        hourToold,
        minutesToold,
        hours,
        minutes,
        id_agendaGeneral,
        classroomnID
      }=props;
      const [info,setInfo]=useState(infoSelected);
      const [isActive,setActive]=useState(isActiveCV==1?true:false);
      const [hourFrom, setHourFrom]=useState(hourFromold);
      const [hourTo, setHourTo]=useState(hourToold);
      const [minutesFrom, setMinutesFrom]=useState(minutesFromold);
      const [minutesTo, setMinutesTo]=useState(minutesToold);
      const [coach,setCoach]=useState(coachId);
      const [clasevirtual,setLink]=useState(clasevirtualLink);
      const [level,setLevel]=useState(levelSelected);
        const [classroom,setClassroom]=useState(classroomnID);
        const [limit,setLimit]=useState(quantity);
        //const filesdecode= JSON.parse(filesToShow)
        const [filesJson,setFiles]=useState(filesToShow);
      const handleUpdateDay=()=>{
        updateDay(id,
          idAgenda,
          dateFrom,
          dateTo,
          coach,
          level,
          limit,
          day,
          classroom,
          info,
        timezone,
        backgroundColor,
      backgroundhexa,
    clasevirtual,
    isActive,
    filesJson,
  id_agendaGeneral,
hourFrom, minutesFrom, hourTo,minutesTo)
      }


const files=(e)=>{
    setFiles('')
    setFiles(e)
    //alert(JSON.stringify(e))
}
const deleteFile=(e,idx)=>{
const rem=e.filter((s, sidx) => idx !== sidx)
setFiles(rem)
//alert(JSON.stringify(filesJson))
}
  return(
    <Form.Row>
    <Form.Group as={Col} controlId="formGridEmail">
      <Form.Label>Coach</Form.Label>
    {coaches?    <Form.Control as="select" onChange={(e)=>setCoach(e.target.value)} name="coach">

        <option value={[coachId,coachname]}>{coachname}</option>
        {coaches.map(item => (
          <option value={item.Id}  key={item}>{item.Coach_Name} {item.Last_Name} {item.Second_Lastname}</option>
        ))}
      </Form.Control>:<p>No tienes coaches registrados</p>}
      <Form.Label>Nivel</Form.Label>
      <Form.Control as="select" onChange={(e)=>setLevel(e.target.value)} name="level">
      {levelSelected==-10?  <option  value={-10} >Kids extra básico (SELECCIONADO)</option>:null}
      {levelSelected==10?  <option  value={10} >Kids extra básico (SELECCIONADO)</option>:null}
      {levelSelected==-5?  <option  value={-5} >Teens extra básico (SELECCIONADO)</option>:null}
      {levelSelected==5?  <option  value={5} >Teens extra básico (SELECCIONADO)</option>:null}
      {levelSelected==0?  <option  value={0} >Básico (SELECCIONADO)</option>:null}
      {levelSelected==49?  <option  value={49} >Básico intermedio  (SELECCIONADO)</option>:null}
      {levelSelected==50?  <option  value={50} >Avanzado intermedio  (SELECCIONADO)</option>:null}
      {levelSelected==100?  <option  value={100} >Avanzado (SELECCIONADO)</option>:null}
      <option value={-10}  >[-10]-Kids extra básico </option>
      <option value={-5}  >[-5]-Teens extra básico </option>
      <option value={0}  >[0]-Básico </option>
      <option value={49}  >[49]-Básico intermedio  </option>
      <option value={50}  >[50]-Avanzado intermedio  </option>
      <option value={100}  >[100]-Avanzado </option>
      {/*activelevels.map(item => (
        <option value={item.Id_Level}  key={item}>[{item.Level_Priority}]-{item.Name}   </option>
      ))*/}

    </Form.Control>

      <Form.Label>Salon</Form.Label>
      <Form.Control as="select" onChange={(e)=>setClassroom(e.target.value)} name="classroom">

      <option value={classroomnID}>{classroomname}</option>
      {classrooms.map(item => (
        <option value={item.Id_Classroom}  key={item}>{item.Name}</option>
      ))}
    </Form.Control>


    <Form.Label>Limite</Form.Label>
    <Form.Control as="select" onChange={(e)=>setLimit(e.target.value)} name="quantity">

    <option value={quantity} >{quantity}</option>
    {nums.map(item => (
      <option value={item}  key={item}>{item}</option>
    ))}
    </Form.Control>


    <Form.Label>info</Form.Label>
    <Form.Control onChange={(e)=>setInfo(e.target.value)} value={info} name="info" as="textarea" rows="3" />
    <Col className={classes.link}>
    <Form.Label>Color</Form.Label>
    <CirclePicker
        color={ backgroundColor }
        onChangeComplete={ handleChangeComplete }
    /><br/><br/>
    </Col>

      <Col className={classes.link}>
      <h5>Horario:</h5>
    <Form.Group as={Col} controlId="formGridEmail">
    <Form.Label>Inicio</Form.Label>
    <Form.Control as="select" onChange={(e)=>setHourFrom(e.target.value)} name="hourFrom">
    <option value={hourFrom}>{hourFrom}</option>
    {hours.map(item => (
      <option value={item}  key={item}>{item}</option>
    ))}
    </Form.Control>
    <Form.Control as="select" onChange={(e)=>setMinutesFrom(e.target.value)} name="minutesFrom">
    <option value={minutesFrom}>{minutesFrom}</option>
    {minutes.map(item => (
      <option value={item}  key={item}>{item}</option>
    ))}
    </Form.Control>

     <Form.Label>Fin</Form.Label>
     <Form.Control as="select" onChange={(e)=>setHourTo(e.target.value)} name="hourTo">
     <option value={hourTo}>{hourTo}</option>
     {hours.map(item => (
       <option value={item}  key={item}>{item}</option>
     ))}
     </Form.Control>
     <Form.Control as="select" onChange={(e)=>setMinutesTo(e.target.value)} name="minutesTo">
     <option value={minutesTo}>{minutesTo}</option>
     {minutes.map(item => (
       <option value={item}  key={item}>{item}</option>
     ))}
     </Form.Control>
     </Form.Group>
     </Col>




    <Col className={classes.link}>
    <Form.Group controlId="formBasicCheckbox" onChange={()=>setActive(!isActive)} >
      <Form.Check type="checkbox" label="Activar enlace a clase virtual" checked={isActive}/>
    </Form.Group>

    {isActive?<input type={"text"} onChange={(e)=>setLink(e.target.value)} value={clasevirtual}/>:null}
    </Col>
    <Add day={dateTo} callbackFiles={files} filesToShow={filesJson} deleteFile={deleteFile}/>
    <Col className={classes.buttonContainer}>
    <Button variant="success" type="submit" onClick={handleUpdateDay} className={classes.button}>
      Actualizar
    </Button>
    <Button variant="danger" className={"cv-button"} type="submit" onClick={deleteClass}>
      Borrar
    </Button>
    </Col>
  </Form.Group>



    </Form.Row>

  )
}

export default EditDay;
