import React, { Component } from 'react';
import '../../../Styles/App.css';
import Form from 'react-bootstrap/Form'
import { connect } from 'react-redux';
import { UpdateActive,GetClassroom} from '../../../reducers/postsReducer';
import Button from 'react-bootstrap/Form'
import * as Web from '../../../Constants/Web'
import Col from 'react-bootstrap/Col'



class CreateLevels extends Component {

  constructor(props){
          super(props);
          this.state={
              Name:'',
            url:Web.wurl+'/',

            };
            this.Save=this.Save.bind(this);
            this.onChange=this.onChange.bind(this);
            this.Successfully=this.Successfully.bind(this)
      }




onChange(e){
  this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
}

Successfully(){
  this.props.GetClassroom('Get_Classrooms.php');
  alert("se agrego correctamente")
  this.setState({
    Name:''
  })
  this.props.GetClassroom('Get_Classrooms.php');
{this.update()}
}
update(){
  this.props.GetClassroom('Get_Classrooms.php');
}
    async Save(){
      const data = {
            Name: this.state.Name,
            Info:this.state.Info
          }
          var url = this.state.url+'Api/Create/Create_Classroom.php';
          //var url = 'https://conversation-test.000webhostapp.com/Api/Create/Create_State.php';

          fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            header: {'Content-Type': 'application/json'}
          }).then(res => res.json())
          .catch(error => console.error('Error:', error))
          .then(response =>   this.Successfully());

}



  render() {
    return (
    <div>
    <Form>
  <Form.Row>
    <Form.Group as={Col} controlId="formGridEmail">
      <Form.Label>Nombre</Form.Label>
      <Form.Control onChange={this.onChange} name="Name" value={this.state.Name} placeholder="Nombre" />
  </Form.Group>
    </Form.Row>
  <Button variant="primary" type="submit" onClick={this.Save}>
    Guardar
  </Button>
</Form>
        </div>
    );
  }
}
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
          classroom: state.classroom.classroom,
    }),
    //mapeo de funciones
    {
        UpdateActive,GetClassroom
    }
)(CreateLevels);
