import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Form'
import { connect } from 'react-redux';
import { GetLevels,Agenda,fetchGetPost,fetchGetPost2,fetchGetCoaches,fetchGetBirthdays,CounterStudents,CounterCouches,CounterBirthday,fetchAddPost, fetchUpdatePost, fetchDeletePost,Update } from '../../../reducers/postsReducer';
import AddStudent from '../Forms/Create_Student'
import LeftSide from './leftSide'
import Loader from 'react-loader-spinner'
import Placeinfo from './Placeinfo'
import * as Web from '../../../Constants/Web'
import * as DATAINFO from '../../../Constants/Dictionary/ES'
import ShowStudents from '../Tables/ShowStudents'
import Coaches from '../Tables/PlaceCoaches'
import Birthdays from '../Tables/PlaceBirthdays'
import Calendar from '../../Agenda/CreateAgendabig'
import GeneralAgenda from '../../Agenda/AgendaCoachGeneral'
import Report from '../Reportes'
import Media from '../../Media/Video'
import Version from '../../ui/Version'
	    var date = new Date().getDate();
      var month = new Date().getMonth() + 1;
      var year = new Date().getFullYear();

class PlaceData extends Component {
  constructor(props){
    super(props);

    this.state={
					url:Web.wurl+'/',
					Memberships:'',
          id:'',
          Add:'',
          Main:'1',
          Student:'',
          Coach:'',
          Birthday:'',
          Calendar:'',
          Notes:'',
          one:'list-box',
          two:'list-box',
          three:'list-box',
          four:'list-box',
          fourone:'list-box',
          five:'list-box',
          six:'list-box-active',
					seven:'list-box',
          Qr:'',
          passwordmsg:'',
          length:'',
					loader:'',
					listName:'',
					GeneralAgenda:''
    };
  }

  componentDidMount() {
		fetch(this.state.url+"Api/Get_ActiveMemberships.php")
	  .then((response)=>response.json())
	  .then((responseJson)=>
	  {
	  this.setState({Memberships:responseJson});
	})

    this.setState({id:this.props.id})
    const data = {
          id: this.props.id,
          month:month
        }
      this.props.fetchGetPost('Get_Students.php',data);
      this.props.fetchGetPost2('Get_Place.php',data);
      this.props.fetchGetCoaches('Get_PlaceCoaches.php',data);
      this.props.fetchGetBirthdays('Get_PlaceBirthdays.php',data);
      this.props.CounterStudents('CounterStudents.php',data);
      this.props.CounterCouches('CounterCouches.php',data);
      this.props.CounterBirthday('CounterBirthdays.php',data);
			this.props.GetLevels('Get_ActiveLevels.php');
      }


 update=()=>{
        const data = {
              id: this.props.id,
              month:month
            }
      this.props.fetchGetPost('Get_Students.php',data);
      this.props.fetchGetPost2('Get_Place.php',data);
      this.props.CounterStudents('CounterStudents.php',data);
      this.props.CounterCouches('CounterCouches.php',data);
      this.props.CounterBirthday('CounterBirthdays.php',data);
      this.props.fetchGetCoaches('Get_PlaceCoaches.php',data);
      this.props.fetchGetBirthdays('Get_PlaceBirthdays.php',data);
			//this.props.Agenda('Get_Agenda.php',data);
			this.props.GetLevels('Get_ActiveLevels.php');
      }

one(){this.setState({Main:'',Student:'1',Coach:'',Birthday:'',Calendar:'',GeneralAgenda:'',Report:'',dataPlace:'',Media:'',one:'list-box-active',two:'list-box',three:'list-box',four:'list-box',fourone:'list-box',five:'list-box',six:'list-box',seven:'list-box'})}
two(){this.setState({Main:'',Student:'',Coach:'1',Birthday:'',Calendar:'',GeneralAgenda:'',Report:'',dataPlace:'',Media:'',one:'list-box',two:'list-box-active',three:'list-box',four:'list-box',fourone:'list-box',five:'list-box',six:'list-box',seven:'list-box'})}
three(){this.setState({Main:'',Student:'',Coach:'',Birthday:'1',Calendar:'',GeneralAgenda:'',Report:'',dataPlace:'',Media:'',one:'list-box',two:'list-box',three:'list-box-active',four:'list-box',fourone:'list-box',five:'list-box',six:'list-box',seven:'list-box'})}
four(){this.setState({Main:'',Student:'',Coach:'',Birthday:'',Calendar:'1',GeneralAgenda:'',Report:'',dataPlace:'',Media:'',one:'list-box',two:'list-box',three:'list-box',four:'list-box-active',fourone:'list-box',five:'list-box',six:'list-box',seven:'list-box'})}
fourone(){this.setState({Main:'',Student:'',Coach:'',Birthday:'',Calendar:'',GeneralAgenda:'1',Report:'',dataPlace:'',Media:'',one:'list-box',two:'list-box',three:'list-box',four:'list-box',fourone:'list-box-active',five:'list-box',six:'list-box',seven:'list-box'})}
five(){this.setState({Main:'',Student:'',Coach:'',Birthday:'',Calendar:'',GeneralAgenda:'',Report:'1',dataPlace:'',Media:'',one:'list-box',two:'list-box',three:'list-box',four:'list-box',fourone:'list-box',five:'list-box-active',six:'list-box',seven:'list-box'})}
six(){this.setState({Main:'',Student:'',Coach:'',Birthday:'',Calendar:'',GeneralAgenda:'',Report:'',dataPlace:'1',Media:'',one:'list-box',two:'list-box',three:'list-box',four:'list-box',fourone:'list-box',five:'list-box',six:'list-box-active',seven:'list-box'})}
seven(){this.setState({Main:'',Student:'',Coach:'',Birthday:'',Calendar:'',GeneralAgenda:'',Report:'',dataPlace:'',Media:'1',one:'list-box',two:'list-box',three:'list-box',four:'list-box',fourone:'list-box',five:'list-box',six:'list-box-active',seven:'list-box-active'})}

handleChangeValue(e){
  this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
}

handleClose() {
this.setState({ show: false });
}

handleShow() {
	this.setState({ show: true });
	}
_handleImageChange(e) {
   e.preventDefault();

   let reader = new FileReader();
   let file = e.target.files[0];

   reader.onloadend = () => {
     this.setState({
       file: file,
       img: reader.result
     });
   }

   reader.readAsDataURL(file)
 }


closeagenda(){
	this.setState({Calendar:'',GeneralAgenda:''})
}
  render() {
    const PlaceData=this.props.place
    const PlaceCoaches=this.props.placecoaches
    const PlaceBirthdays=this.props.placebirthdays
		//alert(JSON.stringify(PlaceBirthdays))
    const CounterStudents=this.props.counterstudents
    const CounterCoach=this.props.countercoaches
    const CounterBirthday=this.props.counterbirthdays
		const activelevels=this.props.activelevels
    //alert(JSON.stringify(activelevels))
    return (
			<div>
			{this.state.Calendar?<div>
				<Calendar id={this.state.id} close={this.closeagenda.bind(this)}/></div>:null}

{this.state.GeneralAgenda?<GeneralAgenda id={this.props.id} close={this.closeagenda.bind(this)}/>:null}
			{this.state.Calendar||this.state.GeneralAgenda?null:<Container className="mainbox container2">
		<Row>
    <Col xs={12} md={12} className="mainbox-header">
    <span className="header-text">{this.props.id}</span>
    </Col>

    <LeftSide
		update={this.update.bind(this)}
    PlaceData={PlaceData}
    CounterCoaches={CounterCoach}
    StudentCounter={CounterStudents}
    BirthdayCounter={CounterBirthday}
    uid={this.props.id}
    one={this.one.bind(this)}
    two={this.two.bind(this)}
    three={this.three.bind(this)}
    four={this.four.bind(this)}
    fourone={this.fourone.bind(this)}
    five={this.five.bind(this)}
    six={this.six.bind(this)}
		seven={this.seven.bind(this)}
    on={this.state.one}
    tw={this.state.two}
    th={this.state.three}
    fo={this.state.four}
    foourone={this.state.fourone}
    fiv={this.state.five}
    si={this.state.six}
		sev={this.state.seven}
    />
    {/*rightside---------------------------------------*/}
    <Col xs={12} md={8} className="columnright">
			<Version/>
    {this.state.Main?<Placeinfo PlaceData={PlaceData} id={this.props.id}/>:null}
    {this.state.Student?<div>{this.Students()}</div>:null}
    {this.state.Coach?<Coaches Placecoaches={PlaceCoaches} id={this.props.id}/>:null}
		{this.state.Birthday?<Birthdays PlaceBirthday={PlaceBirthdays} />:null}
		{this.state.Report?<Report id={this.props.id}/>:null}
		{/*this.state.Media?<Media id={this.props.id}/>:null*/}




    </Col>

    {/*rightside---------------------------------------*/}
    </Row>
    </Container>}
		</div>
    );
  }

  leftSide(){
    return(
      <LeftSide
      id={this.props.id}
      one={this.one.bind(this)}
      two={this.two.bind(this)}
      three={this.three.bind(this)}
      on={this.state.one}
      tw={this.state.two}
      th={this.state.three}
      />
    )}


add(){
  if(this.state.Add=='1'){
    this.setState({
      Add:''
    })
  }else {
    this.setState({
      Add:'1'
    })
  }
}

  Students(){

		if(this.state.Memberships.length === 0){
			return(
				<div>
				<h1> no hay datos</h1>
				</div>
			)
}else {
const membership = this.state.Memberships;

    return(
      <div>
			<Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">Opciones</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				<div>
				<a className="btn btn-success mt-btn" href={this.state.url+"Api/conversationhackers.php?sucursal="+this.props.id} rel="noopener noreferrer" target="_blank" >Descargar lista de alumnos completa</a>
				{membership?
				<Form.Group as={Col} controlId="formGridZip" >
					<Form.Label>Membresía</Form.Label>
					<Form.Control as="select" onChange={this.handleChangeValue.bind(this)} name="listMem" placeholder="Descargar por...">
					<option >Descargar por membresía...</option>
					{membership.map(item => (
								<option value={this.state.url+"Api/conversationhackers.php?sucursal="+this.props.id+"&"+"mem="+item.Id_Membership}  key={item}> {item.Name} </option>
							))}
					</Form.Control >
							</Form.Group>:null}
							{this.state.listMem?	<a className="btn btn-info mt-btn" href={this.state.listMem} rel="noopener noreferrer" target="_blank" >Descargar</a>
								:null}
				</div>
				</Modal.Body>
				<Modal.Footer>
					<Button className="btn btn-danger mt-btn" onClick={this.handleClose.bind(this)}>
						Cerrar
					</Button>
				</Modal.Footer>
			</Modal>

			{this.state.loader?<div className="center2">
	    <Loader
	         type="Puff"
	         color="#00BFFF"
	         height="100"
	         width="100"
	      />
	    </div>:<div>
      <label className="ch-black-bgtext">Estudiantes</label>
      <hr/>
      {this.state.Add?<div>
        <button className="btn btn-danger" type="submit" onClick={this.add.bind(this)}>Cancelar</button>

			<AddStudent
      id={this.props.id}
      />

      </div>:
      <div>
      <Button className="btn btn-success mt-btn" type="submit" onClick={this.add.bind(this)}>Agregar</Button>
			<Button className="btn btn-success mt-btn" type="submit" onClick={this.handleShow.bind(this)}>Opciones</Button>

        <ShowStudents
				id={this.props.id}
        />
  </div>
}
</div>}
  </div>
    )
}

	}



}

export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
        place:state.datas.dato,
        placecoaches:state.placecoaches.placecoaches,
				placebirthdays:state.placebirthdays.placebirthdays,
        counterstudents:state.counterstudents.counterstudents,
        countercoaches:state.countercoaches.countercoaches,
        counterbirthdays:state.counterbirthdays.counterbirthdays,
				activelevels:state.activelevels.activelevels

    }),
    //mapeo de funciones
    {
        GetLevels,Agenda,fetchGetPost,fetchGetPost2,fetchGetCoaches,fetchGetBirthdays,CounterStudents,CounterCouches,CounterBirthday, fetchAddPost, fetchUpdatePost, fetchDeletePost
    }
)(PlaceData);
