import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Helpers from '../../lib/Helper'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from './Navbar';
import Images from '../../Constants/images'
import GrayTemplate from '../MainComponents/Gray_Template'
import Form from 'react-bootstrap/Form'
import Desc from './Desc'
import ButtonFree from './ButtonFreeClass'
import Scholarship from './Scholarship'
class Method extends Component {
  constructor(props){
    super(props)
    this.state={
      uid:'8B57J61q4bM51u9oF7tKln340Fm2',
      show:false,
      social:'',
      title:'',
      Navbar:null
    }

  }
  componentDidMount(){
    Helpers.GetHomepageInfo(this.state.uid,(des)=>{

 this.setState({
  Navbar:des.Navbar,
  Social:des.social,
 })
 })
  }

  render() {

    const social=this.state.Social
    return (
      <div>
{this.state.Navbar?
      <Navbar edit={false} data={this.state.Navbar}/>:null}
    <Container fluid >
      <br/>
      <Row className="justify-content-md-center margintop6">
      <Col md="8">
        <img src={Images.Home.Method} alt="ConversationHackers_metodo" className="img-100 margin-top-34"/>
        {this.state.Social?<ButtonFree image={Images.Home.Free} url={social.free}/>:null}
        </Col>
</Row>



</Container>
</div>

    );
  }
}

export default Method;
