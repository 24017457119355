import React, { Component } from 'react';
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux';
import { UpdateActive,fetchGetPost,} from '../../../reducers/postsReducer';
import Table from 'react-bootstrap/Table'
import SearchInput, {createFilter} from 'react-search-input';
import Alert from 'react-bootstrap/Alert'
const KEYS_TO_FILTERS = ['Name'];
var priority=[]
class ShowLevels extends Component {

  constructor(props){
          super(props);
          this.state={
            searchTerm: '',
            edit:false,
            Id:'',
            Name:'',
            Info:'',
            Priority:'',
            done:false
            };
      }


     async  componentDidMount(){
        this.props.fetchGetPost('Get_Questions.php');
      }

      update(){
       this.props.fetchGetPost('Get_Questions.php');
      }

      searchUpdated (term) {
       this.setState({searchTerm: term})
      }
  render(){

     this.props.fetchGetPost('Get_Questions.php');
    const {children}=this.props
    const filteredInfo = this.props.posts.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))

		return(
				<div className="width-100">


				  {filteredInfo.map((dato, key) => {
	  return(
      <Alert show={true} key={key}key={key} variant="success" className="questions">
        <Alert.Heading>{dato.question}</Alert.Heading>
        <p>
        {dato.answer}
        </p>
        <hr />
      </Alert>
	                        )
	                    })
	                }

				</div>

			)
		}





}
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        UpdateActive,fetchGetPost,
    }
)(ShowLevels);
