import React,{ useState, useEffect,useMemo } from 'react';
import countryList from 'react-select-country-list'
import Select from 'react-select'

const Clock = (props) => {
  const {hourDiff, signDiff,small} =props
  const [value, setValue] = useState('')
   const options = useMemo(() => countryList().getData(), [])
   var todayMX = new Date().toLocaleString("en-US", {
      timeZone: "America/Mexico_City"
    }, {hour: 'numeric', minute: 'numeric', hour12: false });
    todayMX = new Date(todayMX);
   const changeHandler = value => {
     setValue(value)
   }
   //Here we reference our custom hook
  // const timer = useNewTimer(new Date());
      var studentTime
      var hour=0;
      if (signDiff=="+") {
         hour=parseInt(todayMX.getHours())+parseInt(hourDiff);
        var studentTime = new Date(2021, 9, 1,hour,todayMX.getMinutes(), todayMX.getSeconds());
      }
      if (signDiff=="-") {
         hour=parseInt(todayMX.getHours())-parseInt(hourDiff);
      var studentTime = new Date(2021, 9, 1, hour,todayMX.getMinutes(), todayMX.getSeconds());
      }
      const timer = useNewTimer(new Date());
  return (
     <div>
      <span>{studentTime.toLocaleTimeString() }</span>
     </div>
   );


}


function useNewTimer(currentDate) {
    const [date, setDate] = React.useState(currentDate);

    React.useEffect(() => {
      var timerID = setInterval( () => tick(), 1000 );
      return function cleanup() {
          clearInterval(timerID);
        };
     });

    function tick() {
      setDate(new Date());
     }

    return date;
  }
  export default Clock
