import React,{useEffect,useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import classes from './showLink.module.css'
import * as Web from '../../../../Constants/Web'
const  fechanew =  new Date();
 var anio = fechanew.getFullYear();
 var mes = fechanew.getMonth()+1;
 var dia = fechanew.getDate();

const ShowLink = (props) =>{
  const {data, show, close}=props
  const [files, setFiles]=useState([])
  const [url,setUrl]=useState()


  useEffect(()=>{
    var url = Web.wurl+'/Api/conversationHackers/getInformationClass.php';
    if (!data.Id_Agenda) {
      return
    }
    const inf={
      id:data.Id_Agenda
    }
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(inf), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => {
      setUrl(response[0].clase_virtual)
      setFiles(response[0].files?JSON.parse(response[0].files):[])
   })
 },[show])

return(
  <Modal show={show}  onHide={()=>close()}
    backdrop="static"
  size="lg"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
  <Modal.Header closeButton>
      <Modal.Title>Información</Modal.Title>
    </Modal.Header>
    <Modal.Body  >
    <Row>
    {data.isActiveCV==1?
    <Col> Clase virtual

                  {data.isActiveCV==1? <div className={classes.join}>
                   <div><Button
                   variant="outline-dark"
                   href={url}
                   target="blank">Unirse</Button>
                   <br/>
                   <a href={url} target="_blank"className={classes.sub}>{url}</a>
                   <br/>
                   <a href="#" className={classes.sub} onClick={() => {navigator.clipboard.writeText(url)}}>copiar link</a>

                   </div>
                   </div>
                  :null}


    </Col>:null}

    {files?<Col> Material descargable
      <br/>
    {files.map(item => (
      <>
      <a href={item.fileUrl} target="_blank">Descargar</a>
      <br/>
      </>
    ))}


    </Col>:null}



    </Row>

    </Modal.Body>

  <Modal.Footer>

          <Button variant="danger" onClick={()=>close()}>
            Cerrar
          </Button>
    </Modal.Footer>
  </Modal>
)

}

export default ShowLink
