import React, { Component } from 'react';
import '../../Styles/App.css';

import Badge from 'react-bootstrap/Badge'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import close from '../../Media/close.png'
class Close extends Component {



  render() {
    return (
      <Row>
      <Col xs={11}><h1><Badge variant="secondary">{this.props.label}</Badge></h1>
      </Col>
      <Col>
      <img className="close-icon" src={close} onClick={this.props.press} />
      </Col>
</Row>
    );
  }
}

export default Close;
