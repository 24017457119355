import React from 'react';
import * as Web from '../../Constants/Web'
import firebase from 'firebase'
import { connect } from 'react-redux';
import { fetchGetPlaces, fetchAddPost, fetchUpdatePost, fetchDeletePost } from '../../reducers/postsReducer';
import Helper from '../../lib/Helper'
const MyTime_Zone=function(){
  var url = 'https://ipapi.co/json/';
  					fetch(url)
  				.then(response => response.json())
  			.then((data)=>{
          var comp = new Date().toLocaleString("en-US", {timeZone:data.Time_zone});
          var ncomp = new Date(comp);
         var hour=ncomp.getHours();
         var daymonth=ncomp.getDate();
  				const myTime={
  					countryName: data.country_name,
  					countryCode: data.country_code,
  					Time_zone:data.timezone,
            hour:hour
  				}
          return(myTime)
  			}).catch((error)=>{
  				alert('error')
  			});
}
const Time_Zone=async(student)=>{
  const times={
    id:student,
  }
var polo
var url = Web.wurl+'/'+'Api/Get_TimezoneStudent.php';

const response= await fetch(url,
  {
   method: 'POST', // or 'PUT'
   body: JSON.stringify(times), // data can be `string` or {object}!
   header: {'Content-Type': 'application/json'}})
   .then(result=>result.json())
  .then(async(data)=>{
      return data;
  })
        return response;

}
const ChangeTime= function(timezoneplace,timezonestudent,hourf,hourt){
           var newfrom
           var newto
           var temp
           var hourfrom=parseInt(hourf)
           var hourto=parseInt(hourt)
           if(timezonestudent){
             /*mytime current student*/
             var StudentTime = new Date().toLocaleString("en-US", {timeZone:timezonestudent});
             var studentime = new Date(StudentTime);
            var hourstudentime=studentime.getHours();
            var daymonthstudentime=studentime.getDate();
            /*end mytimecurrentStudent*/
            /*timezone place where agenda */
            var PlaceTime = new Date().toLocaleString("en-US", {timeZone:timezoneplace});
            var placetime = new Date(PlaceTime);
           var hourplace=placetime.getHours();
           var daymonthplace=placetime.getDate();
           /* end timezone place where agenda*/
           ////////////////////////////////////////
           /*ya que tenemos las 2 horas hacemos la comparacion*/
           ////////////////////////////////////////
            //var ampm = hour >= 12 ? 'pm' : 'am';

            if(daymonthstudentime==daymonthplace){///si siguen siendo el mismo dia
              //alert(hourplace+"+"+hourstudentime)



              if(hourplace==hourstudentime){
              //  alert('la misma hora')
               const timess={
                  from: hourfrom,
                  to: hourto,
                 diff:0,
                 sign:'+'
                }
                //alert(JSON.stringify(timess))
                return timess;

              //  alert('es la misma hora')
            }
              //var myhour=13//for testing
            //  alert(hour)
              if(hourplace>hourstudentime){
              //  alert('la sucursal es mayor')

               temp=parseInt(hourplace-hourstudentime)
                 const timess= {
                  from: hourfrom-temp,
                  to: hourto-temp,
                  diff:temp,
                  sign:'-'
                }
              //  alert(JSON.stringify(timess))
                return timess;

              //alert(hour-myhour) diferencia de horas +
              //var to=hour-this.state.myTime_zoneHour
              //var horaclase=to+hourClass

              /*if(horaclase>24&horaclase>0){
                alert('es mayor')
              }else {
                alert('es menor')
              }*/
              }
              if(hourplace<hourstudentime){
              temp=parseInt(hourstudentime-hourplace)
                   const timess={
                  from: hourfrom+temp,
                  to: hourto+temp,
                  diff:temp,
                  sign:'+'
                }
                return timess;
            //  alert(myhour-hour) //diferencia de horas -
          //  var to=this.state.myTime_zoneHour-hour

              }
            }
            else {
              if(daymonthplace>daymonthstudentime){
                temp=parseInt(24-hourstudentime+hourplace)
                const timess= {
                 from: hourfrom-temp,
                 to: hourto-temp,
                 diff:temp,
                 sign:'-'
               }

                //alert('aqui es mas tarde')
            }
            if(daymonthplace<daymonthstudentime){
              temp=parseInt(24-hourplace+hourstudentime)
              const timess= {
               from: hourfrom+temp,
               to: hourto+temp,
               diff:temp,
               sign:'+'
             }

            //  alert('aqui es mas temprano'+JSON.stringify(timess))
             return timess;
          }
}
           }
}
const CoachChecker=function(data,coachId){
  var items
  if(data.id_coachAgenda==coachId) {
     items=
      {
       _id            :data.Id_Agenda,
        name          : data.infoAgenda,
        startDateTime : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourFrom, data.minutesFrom),
        endDateTime   : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourTo, data.minutesTo),
        classes       : data.colorAgenda,
        coaches:data.id_coachAgenda,
        classroom:data.id_classroomAgenda,
        quantity:data.quantityAgenda,
        level:data.levelAgenda,
        backgroundColor:data.colorAgenda,
        hourFrom:data.hourFrom,
        minutesFrom:data.minutesFrom,
        hourTo:data.hourTo,
        minutesTo:data.minutesTo,
        hexa:data.hexa,
        quantityAgendaNew:data.quantityAgendaNew

      }
  }else {

  }

return (items)
}
const UploadLogo=function(picture,uid){
     var fet='logo2'
    const file = picture
    const storageRef = firebase.storage().ref("Home/navbar/"+fet+".jpg")
    const task = storageRef.put(file)
    task.on('state_changed', (snapshot) => {
      let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    }, (error) => {
      alert("hubo un error, vuelve a intentarlo.")
    },() =>{
      task.snapshot.ref.getDownloadURL().then((downloadURL)=> {
        const gallery={
          Url:downloadURL
        }
        Helper.AddPictureLogo(uid,fet,(gallery)).then(()=>{/*informacion de archivo*/
          alert("el archivo se ha subido correctamente")
          window.location.reload(true);
        }).catch(function(error){
        let MS=error.message
        alert(MS)
        });

  })
        });

}
const UploadPodcast=async(podcast,uid,title,des)=>{





}
export default {Time_Zone,MyTime_Zone,ChangeTime,CoachChecker,UploadLogo,UploadPodcast};
