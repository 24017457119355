import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Gray_Template from '../../MainComponents/Gray_Template'
import ShowNotifications from '../Tables/Show_Notifications'

class Membership extends Component {



  render() {
    return (

      <div className="center-text">
      <Container className="mainbox container2 " >

      <hr/>
      <ShowNotifications/>
</Container>
</div>
    );
  }
}

export default Membership;
