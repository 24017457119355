import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

//app-reducers
import postsReducer from './postsReducer';


const mainReducer = combineReducers({
    edit:postsReducer,
    posts: postsReducer,
    datas:postsReducer,
    counterstudents:postsReducer,
    countercoaches:postsReducer,
    counterbirthdays:postsReducer,
    placecoaches:postsReducer,
    places:postsReducer,
    placebirthdays:postsReducer,
    time:postsReducer,
    activelevels:postsReducer,
    classroom:postsReducer,
    TimeAgenda:postsReducer,
    Monday:postsReducer,
    Tuesday:postsReducer,
    Wednesday:postsReducer,
    Thursday:postsReducer,
    Friday:postsReducer,
    Saturday:postsReducer,
    Sunday:postsReducer,
    //(state) => ({
      //  data: state.[datas]<------this.dato
  //  }),
});


export default createStore(
    mainReducer,
    applyMiddleware(thunk)
);
