import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Helpers from '../../lib/Helper'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Images from '../../Constants/images'
import Form from 'react-bootstrap/Form'
import Bounce from 'react-reveal/Bounce'

class CardsAbout extends Component {
  constructor(props){
    super(props)
    this.state={
      uid:'8B57J61q4bM51u9oF7tKln340Fm2',
      show:false,
      title:'',
      className:' imgSpin image-spinner',
      shake:true,
      win:false
    }
this.spin=this.spin.bind(this)
  }
  setShow(e){
    this.setState({shake:e})
    this.shake();
  }
  shake(){
    setTimeout(
function() {
    this.setShow(!this.state.shake);
}
.bind(this),
2000
);
  }
  componentDidMount(){
    this.shake();
  }
  componentWillMount(){

}
spin(){
this.setState({
  className:'Spin imgSpin image-spinner',
  win:false
})
setTimeout(
function() {
this.setState({className:'Spin-slow-3 imgSpin image-spinner'})

}
.bind(this),
2000
);
setTimeout(
function() {
this.setState({className:'Spin-slow-2 imgSpin image-spinner'})

}
.bind(this),
4000
);
setTimeout(
function() {
this.setState({className:'Spin-slow-1 imgSpin image-spinner',
  win:true
})

}
.bind(this),
6000
);

}
  render() {
    return (

  <Container className="modal-blue">
  {this.state.win==true? <div> <Bounce left cascade>
      	<p className="h1-congrats ">Congratulations!</p>
        </Bounce></div>:<Bounce left cascade><p className="h1-congrats ">GANA AQUI</p></Bounce>}
    {/*<Col><img src={Images.Animations.RouletCircle} className={this.state.className}/></Col>*/}
    <br/>
    <br/>
    <br/><br/>
    <Row>
    <Col>

    <div className="container-imgb ">
    <img src={Images.Animations.RouletCircle} alt="cv_testimonio" className={this.state.className} />
    <div className="centered-img2 black-text  ">
    <img  src={Images.Animations.Selector} className="selector-ornament" />
    </div>
    </div>
    <div className=" center-text">
    {this.state.win==true?  <Button variant="success" href={this.props.social.free} className="shake-slow  shake-constant congrats-button">obtener 50% de descuento</Button>:
<div>
    {this.state.shake==true?
    <Button variant="danger" onClick={this.spin} className="shake-slow  shake-constant spin-button ">
      GIRAR
    </Button>:<Button  onClick={this.spin} className="spin-button">
      GIRAR
    </Button>}
    </div>}
    <Button  onClick={this.props.close} >
      NO, GRACIAS
    </Button>
    </div>
    </Col>
    </Row>
</Container>

    );
  }
}

export default CardsAbout;
