import React, { Component } from 'react';
import '../../../Styles/App.css';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import moment from 'moment';
import Helmet from 'react-helmet';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { connect } from 'react-redux';
import { UpdateActive,fetchGetPost,fetchAddPost} from '../../../reducers/postsReducer';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Badge from 'react-bootstrap/Badge'

const numbers = [1, 2, 3, 4, 5];


class CreateLevels extends Component {

  constructor(props){
          super(props);
          this.state={
              Name:'',
              Info:'',

            };
   this.onChange=this.onChange.bind(this);
      }

      onChange(e){
        this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
      }
      save(){
        const data={
          Id_Student:this.props.id,
          info:this.state.Info
        }
        const inactive={
          Id:this.props.id,
          Active:'0',
          Table:'students',
          IdTable:'id_Student'
        }
        this.props.fetchAddPost(data,'Create/Create_Standby.php');
        this.props.UpdateActive(inactive,'Update/Update_Active.php');
                alert('Correcto')
                const update={
                  id:this.props.idPlace
                }
        this.props.fetchGetPost('Get_Students.php',update);

      }
        render() {

          return (
            <div className="InputFromTo">

              <Form>
              <h1>Comentario</h1>
              <Row>
                <Col>
                  <Form.Control placeholder="Razon" onChange={this.onChange.bind(this)} name="Info"/>
                </Col>
              </Row>
            </Form>
            <br/>
            {this.state.Info?
  <Button variant="success" onClick={this.save.bind(this)}>Guardar</Button>:null}
            </div>
          );
        }
      }
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
        counterstudents:state.counterstudents.counterstudents,
    }),
    //mapeo de funciones
    {
        UpdateActive,fetchGetPost,fetchAddPost
    }
)(CreateLevels);
