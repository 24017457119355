import React, { Component } from 'react';
import '../../../Styles/App.css';
import Stat from '../../../Media/States.png'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { connect } from 'react-redux';
import { UpdateActive,fetchGetPost,DeletePost} from '../../../reducers/postsReducer';
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import States from '../Forms/Create_State'
import SearchInput, {createFilter} from 'react-search-input';
import ListGroup from 'react-bootstrap/ListGroup'
import Table from 'react-bootstrap/Table'
import Add from '../../../Media/Add.png';
import * as Web from '../../../Constants/Web'
const KEYS_TO_FILTERS = ['Name','Last_Name','Second_Last_Name','id_Student','Coach'];
class ShowStates extends Component {

  constructor(props){
          super(props);
          this.state={
              url:Web.wurl+'/',
              datos:[],
            searchTerm: '',
            Id:'',
            edit:false,
            history:[],
            name:'',
            price:'',
            year:'',
            day:'',
            month:'',
            daylet:'',
            daynum:'',
            hour:'',
            member:''
            };
            this.onChange=this.onChange.bind(this);

      }


    async  componentDidMount(){

            const data={
              id:this.props.id
            }
      var url = this.state.url+'Api/Get_StandbyStudents.php';
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        header: {'Content-Type': 'application/json'}
      }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {this.setState({history:response})} );
}

     onChange(e){
       this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre

     }



     searchUpdated (term) {
      this.setState({searchTerm: term})
  }
  onChange(e){
    this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
  }

  active(e){

      const data = {
            Id: e[0],
            Active:'1',
            Table:'students',
            IdTable:'id_Student'
          }
          this.props.UpdateActive(data,'Update/Update_Active.php');
          const datainactive={
            id:e[0]
          }

            this.props.DeletePost(datainactive,'Delete/delete_Standby.php')
      alert("activado")

  }



  render(){
    const post = this.state.history;
		const filteredInfo = post.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
	        if (post.length === 0 ) {
	        	return(
	            <div>
	            <h1>No hay datos</h1>
	            </div>
	            )} else{
		return(
				<div>
	        <SearchInput placeholder="Nombre apellido,,id,día" className="search-input form-group my-input text-center"  onChange={this.searchUpdated.bind(this)} />
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>
                <th className="table-width">Nombre</th>
              <th className="table-width">Teléfono</th>
              <th className="table-width">Razón</th>
              <th className="table-width">Activar</th>
              </tr>
            </thead>
            <tbody>
				  {filteredInfo.map((dato, key) => {
	  return(
      <tr key={key}>
      <td ><label className="ch-dark-text">{dato.Name} {dato.Last_Name} {dato.Second_Last_Name}</label></td>
      <td ><label className="ch-dark-text">{dato.CellPhone} </label></td>
      <td ><label className="ch-dark-text">{dato.Info_Standby} </label></td>
      <td><Button variant="success" onClick={() => this.active([dato.Id_Student])}>Activar</Button></td>
        </tr>

	                        )
	                    })
	                }</tbody>
                </Table>





				</div>

			)}
		}





}export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        UpdateActive,fetchGetPost,DeletePost
    }
)(ShowStates);
