import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import logo from '../../Media/Conversation_Hackers_Logo.png'

class Footer extends Component {



  render(){
		return(

    <div class="container footer">
      <div class="item">
      <img src={logo} className="Logo_Img_Sm2"/>
        <h3 className={'footer-text'}>Conversation Hackers</h3>
        <h1>Conversation Hackers</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ornare erat eu semper tincidunt. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum blandit viverra leo in tempor.</p>

      </div>
    </div>

			)
    }






}

export default Footer;
