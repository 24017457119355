import React, { Component } from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import About from '../../Media/carousel/about.jpg'
import Helper from '../../lib/Helper'
import Button from 'react-bootstrap/Button'
import firebase from 'firebase'
import Col from 'react-bootstrap/Col'
import logo from '../../Media/Conversation_Hackers_Logo.png'
var d
var utc
var nd
class Footer extends Component {

  constructor(props){
          super(props);
          this.state={
                uid:'8B57J61q4bM51u9oF7tKln340Fm2',
                banner:[],
								countryName:'',
								countryCode:'',
                card1image:'',
                card1imageurl:'',
                uploading:false,
                uploadvalue:'',
            };
            this.selectedpicture=this.selectedpicture.bind(this);
            this.upload=this.upload.bind(this);

      }
      componentWillMount(){
        this.setState({
          card1imageurl:this.props.data.Url
        })
var aestTime = new Date().toLocaleString("en-US", {timeZone: "America/Santiago"});
aestTime = new Date(aestTime);
/*alert(aestTime)*/
console.log('AEST time: '+aestTime.toLocaleString())

var asiaTime = new Date().toLocaleString("en-US", {timeZone: "Asia/Shanghai"});
asiaTime = new Date(asiaTime);
console.log('Asia time: '+asiaTime.toLocaleString())

var usaTime = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
usaTime = new Date(usaTime);
console.log('USA time: '+usaTime.toLocaleString())

var indiaTime = new Date().toLocaleString("en-US", {timeZone: "Asia/Kolkata"});
indiaTime = new Date(indiaTime);
console.log('India time: '+indiaTime.toLocaleString())
        var url = 'https://ipapi.co/json/';
				        fetch(url)
							.then(response => response.json())
			      .then((data)=>{

							this.setState({
            countryName: data.country_name,
            countryCode: data.country_calling_code
							})
						}).catch((error)=>{
							alert(JSON.stringify(error))
						});
      }


      selectedpicture(event){
        this.setState({
          [event.target.name]:event.target.files[0],/*guarda el archivo en un estado para despues subirlo cuando se le de click*/
          [event.target.name+'url']: URL.createObjectURL(event.target.files[0])
        })
      }
  render() {
    return (<div>
      {this.props.edit==true?
        <div>
        <input  type="file" onChange={this.selectedpicture} name={'card1image'} label="Cargar Archivo" s={3} />
        {this.state.card1image?<Button onClick={this.upload}>Guardar</Button>:null}
        </div>:null}
        {this.state.uploading==true?<p>Subiendo...</p>:
        <div className="FooterImage" style={{backgroundImage: `url(${this.state.card1imageurl})`}}>
  <MDBFooter  className="FooterHome ">
   <MDBContainer fluid className="text-center text-md-left">
     <MDBRow>
       <MDBCol md="3">
         <h4 className="text-uppercase">Contáctanos</h4>
         <p className="small mt-3">
         Conversation Hackers {this.state.countryName}<br/>
         </p>
       </MDBCol>
       <MDBCol md="2">
         <h4 className="text-uppercase">Información</h4>
         <p className="small mt-3">
         Bolsa de trabajo
         </p>
       </MDBCol>
     </MDBRow>
   </MDBContainer>
   <div className="footer-copyright text-center  transparent margintop4">
     <MDBContainer fluid>
<a href="https://www.conversationhackers.com">    <p className="small"> &copy;Conversationhackers.com     {new Date().getFullYear()} </p> </a>
     </MDBContainer>
   </div>
  </MDBFooter>
  </div>}
</div>

    );
  }
  upload(){
    this.setState({
      uploading:true})
    const file = this.state.card1image
    const storageRef = firebase.storage().ref("Gallery/Home/Footer.jpg")
    const task = storageRef.put(file)

    task.on('state_changed', (snapshot) => {
      let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      this.setState({
        uploadvalue: percentage
      })
    }, (error) => {
      alert("hubo un error, vuelve a intentarlo.")
      this.setState({
        uploadvalue:'',
        uploading:false

      })
    }, () =>{
      task.snapshot.ref.getDownloadURL().then((downloadURL)=> {
        const gallery={
          Url:downloadURL
        }
        Helper.AddPictureHomeFooter(this.state.uid,(gallery)).then(()=>{/*informacion de archivo*/
          alert('Correcto')
        }).catch(function(error){
        let MS=error.message
        alert(MS)
        });
        this.setState({
          uploadvalue:'',
          uploading:false
        })
  })
        });
  }
}

export default Footer ;
