import React from 'react';


const Checkbox = (props) =>{
  const {label, setValue, value} =props;
  return (
    <label>
    <input type="checkbox" checked={value} onChange={()=>setValue(!value)} />
    {label}
  </label>
)

}
export default Checkbox;
