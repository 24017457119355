import React, { Component } from 'react';
import '../../Styles/App.css';
import Images from '../../Constants/images'
import * as Web from '../../Constants/Web'
import logo from '../../Media/Conversation_Hackers_Logo.png'
import Labels from '../../Constants/Dictionary/Gral'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Gray_Template from '../MainComponents/Gray_Template'

class Home extends Component {

  constructor(props){
          super(props);
          this.state={
              url:Web.wurl+'/',
              db:[],
              students:[],
              levels:[],
              coaches:[],
              places:[],
              memberships:[]
            };
      }


      componentWillMount(){
        if(localStorage.getItem('userData')){
          //let myItem = localStorage.getItem('userData')
          //const info=JSON.parse(myItem)
          //const data = {
          //      id: '1'
          //    }
              fetch(this.state.url+"Api/CounterStudentsAll.php")
              .then((response)=>response.json())
              .then((responseJson)=>
              {
              this.setState({students:responseJson});
           })

           fetch(this.state.url+"Api/Get_ActiveLevelsAll.php")
           .then((response)=>response.json())
           .then((responseJson)=>
           {
           this.setState({levels:responseJson});
        })
        fetch(this.state.url+"Api/CounterCouchesAll.php")
        .then((response)=>response.json())
        .then((responseJson)=>
        {
        this.setState({coaches:responseJson});
     })

     fetch(this.state.url+"Api/CounterPlaces.php")
     .then((response)=>response.json())
     .then((responseJson)=>
     {
     this.setState({places:responseJson});
  })
  fetch(this.state.url+"Api/CounterMembersAll.php")
  .then((response)=>response.json())
  .then((responseJson)=>
  {
  this.setState({memberships:responseJson});
})

      }

      }
  render() {
    //const CounterStudents=this.props.counterstudents
    //const CounterCoach=this.props.countercoaches
		//const Activelevels=this.props.activelevels
    //const Places=this.props.counterbirthdays

    return (
      <div>
      <Container className="mainbox container2 "  style = {{ backgroundImage: 'url(' + logo + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
              }}>
  <Row>
    <Col md={4}>
    <div className="reports text-center">
    <img src={Images.Admin.Place} className="Img_Animation_FadeDown margintop4"/>
    <span className="reports-text">{Labels.Admin.Places}</span><br/>
    <span className="reports-numbers black-text ">{this.state.places[0]}</span>
    <hr/>
    </div>
    </Col>
    <Col md={{ span: 4, offset: 4 }}>
          <div className="reports text-center ">
          <img src={Images.Admin.Students} className="Img_Animation_FadeDown margintop4"/>
          <span className="reports-text">{Labels.Admin.Students}</span><br/>
          <span className="reports-numbers black-text ">{this.state.students[0]}</span>
          <hr/>
          </div>
    </Col>
  </Row>
  <Row>
    <Col md={4}>
    <div className="reports text-center ">
    <img src={Images.Admin.Coaches} className="Img_Animation_FadeDown margintop4"/>
    <span className="reports-text">{Labels.Admin.Coaches}</span><br/><br/><br/>
    <span className="reports-numbers black-text ">{this.state.coaches[0]}</span>
    <hr/>
    </div>
    </Col>
    <Col md={{ span: 4, offset: 4 }}>
    <div className="reports text-center ">
    <img src={Images.Admin.Membership} className="Img_Animation_FadeDown margintop4"/>
    <span className="reports-text">{Labels.Admin.Membership}</span><br/>
    <span className="reports-numbers black-text ">{this.state.memberships[0]}</span>
    <hr/>
    </div>
    </Col>
  </Row>

  <Row>
    <Col md={4}>
    <div className="reports text-center ">
    <img src={Images.Admin.Levels} className="Img_Animation_FadeDown margintop4"/>
    <span className="reports-text">{Labels.Admin.Levels}</span><br/>
    <span className="reports-numbers black-text ">{this.state.levels[0]}</span>
    <hr/>
    </div>
    </Col>
  </Row>
</Container>
          </div>
    );
  }
}

export default Home;
