import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchGetPost,fetchGetPost2, fetchAddPost, fetchUpdatePost, fetchDeletePost } from '../../../reducers/postsReducer';
import '../../../Styles/App.css';

import * as Data from '../../../Constants/Dictionary/ES'
import Cake from '../../../Media/Cakes.png'
import SearchInput, {createFilter} from 'react-search-input';
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'

var year = new Date().getFullYear();
const KEYS_TO_FILTERS = ['Name','Membership','Id_Student','Last_Name','Second_Last_Name','Day_Birthdate'];
class ShowStudents extends Component {

  constructor(props){
          super(props);
          this.state={
            searchTerm: '',
            };
      }


     searchUpdated (term) {
      this.setState({searchTerm: term})
  }




  render(){
    const info =this.props.PlaceBirthday
		const filteredInfo = info.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
	        if (info.length === 0 ) {
	        	return(
	            <div>
	            <h1>{Data.Empty}</h1>
	            </div>
	            )} else{
		return(
				<div>
        <SearchInput placeholder="Buscar" className="search-input form-group my-input text-center"  onChange={this.searchUpdated.bind(this)} />
        <br/>
          <Alert dismissible variant="info">
            <Alert.Heading>{Data.Congratulations}<img src={Cake} className="CH_SM_Icono"/></Alert.Heading>
          </Alert>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>{Data.Name}</th>
                <th>{Data.LastName}</th>
                <th>Dia</th>
                <th>{Data.Year_Birthdate}</th>
              </tr>
            </thead>
            <tbody>

				  {filteredInfo.map((dato, key) => {
            var edad=year-dato.Year_Birthdate
	  return(
	      <tr key={key}>
       <td>{dato.Name}</td>
       <td>{dato.Last_Name} {dato.Second_Last_Name}</td>
        <td>{dato.Day_Birthdate}</td>
       <td>{edad}</td>
     </tr>
	                        )
	                    })
	                }
                  </tbody>
                </Table>
<div className="center"><img src={Cake} className="Cake"/></div>

				</div>

			)}
		}





}
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        fetchGetPost,fetchGetPost2, fetchAddPost, fetchUpdatePost, fetchDeletePost
    }
)(ShowStudents);
