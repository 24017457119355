import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchGetPost,fetchGetPost2, fetchAddPost, fetchUpdatePost,fetchGetCoaches, fetchDeletePost,UpdateActive,DeletePost } from '../../../reducers/postsReducer';
import '../../../Styles/App.css';
import SearchInput, {createFilter} from 'react-search-input';
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Col from 'react-bootstrap/Col'
const KEYS_TO_FILTERS = ['Id','Id_Place_Coach','Coach_Name','Last_Name','Second_Last_Name'];
class ShowStudents extends Component {

  constructor(props){
          super(props);
          this.state={
            searchTerm: '',
                  show:false,
                  show2:false,
                  name:'',
                  lastname:'',
                  lastname2:'',
                  email:'',
                  password:'',
                  id:''
            };

            this.onChange=this.onChange.bind(this);
            this.save=this.save.bind(this);
            this.data=this.data.bind(this);
      }


     searchUpdated (term) {
      this.setState({searchTerm: term})
  }
  onChange(e){
    this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre

  }
  handleClose() {
  this.setState({ show: false });
  }
  handleClose2() {
  this.setState({ show2: false });
  }

  handleShow() {
  this.setState({ show: true });
  }
  save(){
    const data = {
          Name: this.state.name,
          lastname: this.state.lastname,
          lastname2: this.state.lastname2,
          password:this.state.password,
          email:this.state.email,
          id:this.props.id
        }

            this.props.fetchAddPost(data,'Create/Create_Coach.php');
alert('correcto')
  }
update(){
  const data = {
        id: this.props.id
      }
  this.props.fetchGetCoaches('Get_PlaceCoaches.php',data);
}
  data(e){
    this.setState({
      name:e[0],
      lastname:e[1],
      lastname2:e[2],
      email:e[3],
      password:e[4],
      id:e[5],
      show2:true
    })
  }
  saveedit(){
    const data = {
          Name: this.state.name,
          lastname: this.state.lastname,
          lastname2: this.state.lastname2,
          password:this.state.password,
          email:this.state.email,
          id:this.state.id
        }
        this.props.UpdateActive(data,'Update/Update_PlaceCoach.php');
        alert('correcto')
        this.update()
  }

edit(){
  return(<div>
    <Form>

    <Form.Row>
      <Form.Group  as={Col} controlId="formGridEmail">
        <Form.Label>Nombre</Form.Label>
        <Form.Control onChange={this.onChange} value={this.state.name} name="name" type="text" placeholder="Nombre" />
      </Form.Group>

      <Form.Group  as={Col} controlId="formGridPassword">
        <Form.Label>Apellido Paterno</Form.Label>
        <Form.Control onChange={this.onChange} value={this.state.lastname} name="lastname" type="text" placeholder="Apellido" />
      </Form.Group>
      <Form.Group as={Col} controlId="formGridPassword">
        <Form.Label>Apellido Materno</Form.Label>
        <Form.Control onChange={this.onChange} value={this.state.lastname2} name="lastname2" type="text" placeholder="Apellido" />
      </Form.Group>
    </Form.Row>
<Form.Row>
  <Form.Group as={Col} controlId="formGridEmail">
    <Form.Label>Correo</Form.Label>
    <Form.Control onChange={this.onChange} value={this.state.email}  name="email" type="email" placeholder="Enter email" />
  </Form.Group>

  <Form.Group as={Col} controlId="formGridPassword">
    <Form.Label>Clave</Form.Label>
    <Form.Control  onChange={this.onChange} value={this.state.password}  name="password" type="password" placeholder="Password" />
  </Form.Group>
</Form.Row>

</Form>


    </div>
  )
}
delete(e){
  const data={
    Id:e[0]
  }
    this.props.DeletePost(data,'Delete/delete_PlaceCoach.php')
    this.update()
}
  create(){
    return(<div>
      <Form>

      <Form.Row>
        <Form.Group  as={Col} controlId="formGridEmail">
          <Form.Label>Nombre</Form.Label>
          <Form.Control onChange={this.onChange} name="name" type="text" placeholder="Nombre" />
        </Form.Group>

        <Form.Group  as={Col} controlId="formGridPassword">
          <Form.Label>Apellido Paterno</Form.Label>
          <Form.Control onChange={this.onChange} name="lastname" type="text" placeholder="Apellido" />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridPassword">
          <Form.Label>Apellido Materno</Form.Label>
          <Form.Control onChange={this.onChange} name="lastname2" type="text" placeholder="Apellido" />
        </Form.Group>
      </Form.Row>
  <Form.Row>
    <Form.Group as={Col} controlId="formGridEmail">
      <Form.Label>Correo</Form.Label>
      <Form.Control onChange={this.onChange} name="email" type="email" placeholder="Enter email" />
    </Form.Group>

    <Form.Group as={Col} controlId="formGridPassword">
      <Form.Label>Clave</Form.Label>
      <Form.Control  onChange={this.onChange} name="password" type="password" placeholder="Password" />
    </Form.Group>
  </Form.Row>

</Form>


      </div>
    )
  }
  render(){
    const info =this.props.Placecoaches
		const filteredInfo = info.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
	        if (info.length === 0 ) {
	        	return(
	            <div>

              <label className="ch-black-bgtext">Coaches</label>
              <hr/>
	            <h1>No hay datos</h1>
              <Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered>
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">Coach</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
                {this.create()}
                </div>
                </Modal.Body>
                <Modal.Footer>

                <ButtonToolbar>
      <Button variant="success" onClick={this.save.bind(this)} >Agregar</Button>
      </ButtonToolbar>
                  <Button variant="secondary" onClick={this.handleClose.bind(this)}>
                    Cancelar
                  </Button>
                </Modal.Footer>
              </Modal>
              <ButtonToolbar>
  <Button variant="success" onClick={this.handleShow.bind(this)} >Agregar</Button>
</ButtonToolbar>
	            </div>
	            )} else{
		return(
				<div>
        <label className="ch-black-bgtext">Coaches</label>
        <hr/>
        <br/>
        <ButtonToolbar>
<Button variant="success" onClick={this.handleShow.bind(this)}>Agregar</Button>
</ButtonToolbar>
        <Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Coach</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
          {this.create()}
          </div>
          </Modal.Body>
          <Modal.Footer>
          <ButtonToolbar>
<Button variant="primary" onClick={this.save.bind(this)} >Agregar</Button>
</ButtonToolbar>
            <Button variant="secondary" onClick={this.handleClose.bind(this)}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>

{/*editar////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
        <Modal show={this.state.show2} onHide={this.handleClose2.bind(this)} centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Editar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
          {this.edit()}
          </div>
          </Modal.Body>
          <Modal.Footer>
          <ButtonToolbar>
<Button variant="primary" onClick={this.saveedit.bind(this)} >Guardar</Button>
</ButtonToolbar>
            <Button variant="secondary" onClick={this.handleClose2.bind(this)}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>

{/*editar////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
	        <SearchInput placeholder="Buscar" className="search-input form-group my-input text-center"  onChange={this.searchUpdated.bind(this)} />

          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Id</th>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Editar</th>
              </tr>
            </thead>
            <tbody>

				  {filteredInfo.map((dato, key) => {
	  return(
	      <tr key={key}>
       <td className="pointer" onClick={()=>this.data([dato.Coach_Name,dato.Last_Name,dato.Second_Lastname,dato.Email,dato.Password,dato.Id])}>{dato.Id}</td>
       <td className="pointer" onClick={()=>this.data([dato.Coach_Name,dato.Last_Name,dato.Second_Lastname,dato.Email,dato.Password,dato.Id])}>{dato.Coach_Name}</td>
       <td className="pointer" onClick={()=>this.data([dato.Coach_Name,dato.Last_Name,dato.Second_Lastname,dato.Email,dato.Password,dato.Id])}>{dato.Last_Name} {dato.Second_Lastname}</td>
       <td><a className="pointer" onClick={()=>this.delete([dato.Id])}>Eliminar</a></td>
     </tr>
	                        )
	                    })
	                }
                  </tbody>
                </Table>


				</div>

			)}
		}





}
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        posts: state.posts.posts,
    }),
    //mapeo de funciones
    {
        fetchGetPost,fetchGetPost2,fetchGetCoaches, fetchAddPost, fetchUpdatePost, fetchDeletePost,UpdateActive,DeletePost
    }
)(ShowStudents);
