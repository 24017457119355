import React,{useState} from 'react';
import GeneralReports from './generalReport';
import NewStudents from './Bars/NewStudents'
import MostSold from './Bars/MostSold';
import GrayTemplate from '../../MainComponents/Gray_Template';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import classes from './generalReport.module.css';

const Reports = () =>{
  const [count, setCount] = useState(0);
  const [dates, setDates] = useState([])
  const [newStudents, setNewStudents]= useState([
  ['Fecha y sucursal', 'Cantidad']
  ])
  const [mostSoldMemberships, setMostSoldMemberships]= useState([['Nombre', 'Total']])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const updateNewStudentBars = (count,inf)=>{
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const dateFrom = new Date(inf.from);
    const dateTo = new Date(inf.to);
    const data =["Sucur:"+inf.place+"_Desde:"+dateFrom.toLocaleDateString("es-MX")+"__Hasta:"+dateTo.toLocaleDateString("es-MX") , count]
    setNewStudents([...newStudents,data])
  }
  const updateMostSelledMembershipsBars = (infMost)=>{
      setMostSoldMemberships(infMost);

  /*  infMost.map((item)=>{
      const dat=["Nombre:"+item.Name_Membership, "Total:"+item.total]
      alert(item.Name_Membership)
      setMostSoldMemberships([...mostSoldMemberships,dat]);
    })*/

    /*inf.map((item)=>{
      console.log(item.Name_Membership);
      const data=["Nombre:"+item.Name_Membership, "Total:"+item.total]
      setMostSoldMemberships([...mostSoldMemberships,data])
      console.log(JSON.stringify(mostSoldMemberships));
    })*/
  }
//console.log(new Date("Fri Jan 20 2012 11:51:36 GMT+600").toUTCString())
  var rows  = [];
  for (var i = 0; i < count; i++) {
      rows.push(
        <Carousel.Item>
        <h1>Fecha:{i+1}</h1>
        <GeneralReports updateNewStudentBars={updateNewStudentBars} updateMostSelledMembershipsBars={updateMostSelledMembershipsBars} dataMost={mostSoldMemberships}/>
       </Carousel.Item>);
  }
  return (
    <div>
    <div className={classes.btnContainer}>
      <Button variant="warning" onClick={() => setCount(count + 1)}>
          Generar fecha
      </Button>
      <Button variant="success" onClick={handleShow}>
       Gráficas
     </Button>

    </div>


     <Modal
       show={show}
       onHide={handleClose}
       backdrop="static"
       keyboard={false}
       fullscreen={'xxl-down'}
       size={'xl'}
     >
       <Modal.Header closeButton>
         <Modal.Title>Gráficas</Modal.Title>
       </Modal.Header>
       <Modal.Body>
        <NewStudents newStudents={newStudents}/>
        <MostSold mostSoldMemberships={mostSoldMemberships} />
       </Modal.Body>
       <Modal.Footer>
         <Button variant="secondary" onClick={handleClose}>
           Cerrar
         </Button>
       </Modal.Footer>
     </Modal>


     <Carousel variant="dark">
      {rows}
     </Carousel>
      {rows.length}
    </div>);
}

export default Reports;
