import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Loadable from 'react-loadable';
import '../../Styles/App.css';
import Student from './Login_Student'
import Coach from './Login_Coach'
import * as MenuLogin from '../../Constants/Menu'
import logo from '../../Media/Conversation_Hackers_Logo.png'

class App extends Component {

  constructor(props){
          super(props);
          this.state={
              Student:'1',
              Coach:'',
              Master:'',
              one:'Active_Menu',
              two:'',
              three:''
            };}

      Student(){
        this.setState({
          Student:'1',
          Coach:'',
          Master:'',
          one:'Active_Menu',
          two:'',
          three:''
        })}

      Coach(){
        this.setState({
          Student:'',
          Coach:'1',
          Master:'',
          one:'',
          two:'Active_Menu',
          three:''
        })}
      Master(){
        this.setState({
          Student:'',
          Coach:'',
          Master:'1',
          one:'',
          two:'',
          three:'Active_Menu'
        })}



  render() {
    return (
      <div>
      <div className="Login-Card3">
      <Navbar collapseOnSelect  className="Menu navbar-blue" expand="lg" sticky="top">
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Brand ><img src={logo} className="Logo_Img_Sm"/></Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="mr-auto">
      <Nav.Link href="#" className={this.state.one}  onClick={this.Student.bind(this)}><label className="CH_Text white-text">{MenuLogin.STUDENT}</label></Nav.Link>
        <Nav.Link  href="#" className={this.state.two} onClick={this.Coach.bind(this)}><label className="CH_Text white-text">{MenuLogin.TEACHER}</label></Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
      {this.state.Student?<Student/>:null}
      {this.state.Coach?<Coach/>:null}
      </div>
      </div>


    );
  }
}

export default App;
