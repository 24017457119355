import {isWebpSupported} from 'react-image-webp/dist/utils';

let Social;
let prices;
let Settings;
let Agenda;
let People;
let Payments;
let Logos;
let Animations;
let Countries;
let PeopleWorld;
let Admin;
let Blog;
let Home;
let FreeClass;

if (isWebpSupported()) {
  //alert('no')
   Social={
    FACEBBOK: require('../Media/social/Facebook_3.png'),
    FACEBBOKBLOG: require('../Media/social/FacebookBlog.png'),
    YOUTUBE: require('../Media/social/Youtube_3.png'),
    TIKTOK: require('../Media/social/TikTok_3.png'),
    INSTAGRAM: require('../Media/social/Instagram_3.png'),
    EDIT: require('../Media/social/edit.png'),
    WHATSAPP: require('../Media/social/WhatsApp_3.png'),
    WHATSAPPBLOG: require('../Media/social/WhatsAppBlog.png'),
    TWITERBLOG: require('../Media/social/TwiterBlog.png'),
    EMAILBLOG: require('../Media/social/EmailBlog.png'),
    SHARE: require('../Media/social/Share.png'),

  }
   prices={
    person1:require('../Media/prices/1.png'),
    person2:require('../Media/prices/2.png'),
    person3:require('../Media/prices/3.png'),
  }
   Settings={
    Gallery:require('../Media/picture.png'),
    States:require('../Media/States.png'),
    Levels:require('../Media/Levels.png'),
    Admin:require('../Media/Admin.png'),
    Desing:require('../Media/Design.png'),
    Classroomicon:require('../Media/Classroom.png'),
    Home:require('../Media/Home.png'),
    Checking:require('../Media/checking.png')
  }
   Agenda={
      Dateimg:require('../Media/Calendar.png'),
      Delete:require('../Media/Delete.png'),
      Reload:require('../Media/reload.png'),
      Watch:require('../Media/Watch.png'),
      Successfully:require('../Media/Successfully.png')
  }
   People={
      T1: require('../Media/people/cv_testimonio1.png'),
      T2: require('../Media/people/cv_testimonio2.png'),
      T3: require('../Media/people/cv_testimonio3.png')

  }
   Payments={
        Paypal: require('../Media/payment/paypal.png'),
        Bank: require('../Media/payment/electronic.png'),
        Cash: require('../Media/payment/cash.png'),
        Bancolombia: require('../Media/payment/ConversationHackers-bancolombia.png'),
        Bancomer: require('../Media/payment/ConversationHackers-Bancomer.png'),
        Oxoo: require('../Media/payment/ConversationHackers-oxxo.png'),
        Intermex: require('../Media/payment/ConversationHYackers-Intermex.png')
  }
   Logos={
          Main: require('../Media/Conversation_Hackers_Logo.png')
  }
   Animations={
    RouletCircle: require('../Media/Roulete/ruleta.png'),
    Selector: require('../Media/Roulete/selector.png'),
    Maps: require('../Media/Maps.png')
  }
   Countries={
    Cloud: require('../Media/Countries/Cloud.png'),
    Us: require('../Media/Countries/USA.png'),
    Mx_People: require('../Media/Countries/MEXICO_People.png'),
    Mx: require('../Media/Countries/MEXICO.png'),
    Gu: require('../Media/Countries/GUATEMALA.png'),
    Salv: require('../Media/Countries/SALVADOR.png'),
    Hon: require('../Media/Countries/HONDURAS.png'),
    Col: require('../Media/Countries/COLOMBIA.png'),
    Per: require('../Media/Countries/PERU.png'),
    Boli: require('../Media/Countries/BOLIVIA.png'),
    Arg: require('../Media/Countries/ARG.png'),
    Ch: require('../Media/Countries/CHILE.png'),
    Maps: require('../Media/Countries/Maps-noIcons.png'),
    Around: require('../Media/Countries/around.png'),
    Pres: require('../Media/Countries/pres.png'),
    PresMX: require('../Media/Countries/presmx.png'),
    Prescol: require('../Media/Countries/prescol.png'),
    PresMorelos: require('../Media/Countries/morelos.png'),
    PresBogota: require('../Media/Countries/bogota.png'),
  }
   PeopleWorld={
      Mexico: require('../Media/places/mexico1.jpg'),
      Mexico2: require('../Media/places/mexico2.jpg'),
      Guatemala: require('../Media/places/guatemala.jpg'),
      Chile: require('../Media/places/chile.jpg'),
      Argentina: require('../Media/places/argentina.jpg'),
      Colombia: require('../Media/places/colombia.jpg'),
      default: require('../Media/places/default.png')
  }
   Admin={
        Place: require('../Media/Place.png'),
        Students:require('../Media/Student.png'),
        Coaches:require('../Media/Teacher.png'),
        Levels:require('../Media/Levels.png'),
        Membership:require('../Media/Membership.png'),
        Prices:require('../Media/prices.png'),
        Add:require('../Media/Add.png')
  }
   Blog={
          Podcast:  require('../Media/blog/podcasts.png'),
          Thumbnail:require('../Media/blog/picture.png'),
          Play:require('../Media/blog/play.png'),
          Text:require('../Media/blog/text.png'),
          Click:require('../Media/blog/click-here.png'),



  }
   Home={
            Method:  require('../Media/Method.png'),
            Free:  require('../Media/ConversationHackers-clase-gratis.png'),
  }
   FreeClass={
              thumbn:  require('../Media/freeClass/clase.png'),
              Popup1:  require('../Media/freeClass/pop-up1.png'),
              Asesor:  require('../Media/freeClass/hablar-asesor.png'),
              Head:  require('../Media/freeClass/head.png'),
              Enjoy:  require('../Media/freeClass/enjoy.png'),
              Leftside:  require('../Media/freeClass/left-side.png'),
              Lograr:  require('../Media/freeClass/lograras.png'),
              Rightside:  require('../Media/freeClass/right-side1.png'),
              Rightside2:  require('../Media/freeClass/right-side2.png'),
              MonthGray: require('../Media/freeClass/free-month.png'),
              MonthBlue: require('../Media/freeClass/free-month-blue.png'),
              Platform:  require('../Media/freeClass/Platform.png'),
              Coach:  require('../Media/freeClass/Coach.png'),
              Accent:  require('../Media/freeClass/Accent.png'),
              Conversation:  require('../Media/freeClass/Conversation.png'),
              Business:  require('../Media/freeClass/Business.png'),
              Role:  require('../Media/freeClass/Role.png'),
              Speach:  require('../Media/freeClass/Speach.png'),
              Camps:  require('../Media/freeClass/Camps.png'),
              Freebtn:  require('../Media/freeClass/free-btn.png'),
              Freetext:  require('../Media/freeClass/free-txt.png'),
              Free50:  require('../Media/freeClass/50-off.png'),
              Wehack:  require('../Media/freeClass/we-hack.png'),
              Cards1:  require('../Media/freeClass/Cards1.png'),
              Cards2:  require('../Media/freeClass/Cards2.png'),
              Cards4:  require('../Media/freeClass/Cards4.png'),
              Impossible:  require('../Media/freeClass/impossible.png'),
              Sticky:  require('../Media/freeClass/sticky.png'),
              StickyMobile:  require('../Media/freeClass/sticky-mobile.png'),
              Head:  require('../Media/freeClass/head.png'),
              freeWeek:  require('../Media/freeClass/free-week.png'),
              freeMonth:  require('../Media/freeClass/free-month2.png'),
              changeLife:  require('../Media/freeClass/change-life.png'),
              talk:  require('../Media/freeClass/talk.png'),
              enjoy:require('../Media/prices/disfrutar.png'),
              msg:require('../Media/prices/msg.png'),
              question:require('../Media/prices/questions.png'),
              sound:require('../Media/sound.png')
  }
}else {
  //alert('si')
  Social={
   FACEBBOK: require('../Mediawebp/social/Facebook_3.webp'),
   FACEBBOKBLOG: require('../Mediawebp/social/FacebookBlog.webp'),
   YOUTUBE: require('../Mediawebp/social/Youtube_3.webp'),
   TIKTOK: require('../Mediawebp/social/TikTok_3.webp'),
   INSTAGRAM: require('../Mediawebp/social/Instagram_3.webp'),
   EDIT: require('../Mediawebp/social/edit.webp'),
   WHATSAPP: require('../Mediawebp/social/WhatsApp_3.webp'),
   WHATSAPPBLOG: require('../Mediawebp/social/WhatsAppBlog.webp'),
   TWITERBLOG: require('../Mediawebp/social/TwiterBlog.webp'),
   EMAILBLOG: require('../Mediawebp/social/EmailBlog.webp'),
   SHARE: require('../Mediawebp/social/Share.webp'),

 }
  prices={
   person1:require('../Mediawebp/prices/1.webp'),
   person2:require('../Mediawebp/prices/2.webp'),
   person3:require('../Mediawebp/prices/3.webp'),
 }
  Settings={
   Gallery:require('../Mediawebp/picture.webp'),
   States:require('../Mediawebp/States.webp'),
   Levels:require('../Mediawebp/Levels.webp'),
   Admin:require('../Mediawebp/Admin.webp'),
   Desing:require('../Mediawebp/Design.webp'),
   Classroomicon:require('../Mediawebp/Classroom.webp'),
   Home:require('../Mediawebp/Home.webp'),
   Checking:require('../Mediawebp/checking.webp')
 }
  Agenda={
     Dateimg:require('../Mediawebp/Calendar.webp'),
     Delete:require('../Mediawebp/Delete.webp'),
     Reload:require('../Mediawebp/reload.webp'),
     Watch:require('../Mediawebp/Watch.webp'),
     Successfully:require('../Mediawebp/Successfully.webp')
 }
  People={
     T1: require('../Mediawebp/people/cv_testimonio1.webp'),
     T2: require('../Mediawebp/people/cv_testimonio2.webp'),
     T3: require('../Mediawebp/people/cv_testimonio3.webp')

 }
  Payments={
       Paypal: require('../Mediawebp/payment/paypal.webp'),
       Bank: require('../Mediawebp/payment/electronic.webp'),
       Cash: require('../Mediawebp/payment/cash.webp'),
       Bancolombia: require('../Mediawebp/payment/ConversationHackers-bancolombia.webp'),
       Bancomer: require('../Mediawebp/payment/ConversationHackers-Bancomer.webp'),
       Oxoo: require('../Mediawebp/payment/ConversationHackers-oxxo.webp'),
       Intermex: require('../Mediawebp/payment/ConversationHYackers-Intermex.webp')
 }
  Logos={
         Main: require('../Mediawebp/Conversation_Hackers_Logo.webp')
 }
  Animations={
   RouletCircle: require('../Mediawebp/Roulete/ruleta.webp'),
   Selector: require('../Mediawebp/Roulete/selector.webp'),
   Maps: require('../Mediawebp/Maps.webp')
 }
  Countries={
   Cloud: require('../Mediawebp/Countries/Cloud.webp'),
   Us: require('../Mediawebp/Countries/USA.webp'),
   Mx_People: require('../Mediawebp/Countries/MEXICO_People.webp'),
   Mx: require('../Mediawebp/Countries/MEXICO.webp'),
   Gu: require('../Mediawebp/Countries/GUATEMALA.webp'),
   Salv: require('../Mediawebp/Countries/SALVADOR.webp'),
   Hon: require('../Mediawebp/Countries/HONDURAS.webp'),
   Col: require('../Mediawebp/Countries/COLOMBIA.webp'),
   Per: require('../Mediawebp/Countries/PERU.webp'),
   Boli: require('../Mediawebp/Countries/BOLIVIA.webp'),
   Arg: require('../Mediawebp/Countries/ARG.webp'),
   Ch: require('../Mediawebp/Countries/CHILE.webp'),
   Maps: require('../Mediawebp/Countries/Maps-noIcons.webp'),
   Around: require('../Mediawebp/Countries/around.webp'),
   Pres: require('../Mediawebp/Countries/pres.webp'),
   PresMX: require('../Mediawebp/Countries/presmx.webp'),
   Prescol: require('../Mediawebp/Countries/prescol.webp'),
   PresMorelos: require('../Mediawebp/Countries/morelos.webp'),
   PresBogota: require('../Mediawebp/Countries/bogota.webp'),
 }
  PeopleWorld={
     Mexico: require('../Mediawebp/places/mexico1.webp'),
     Mexico2: require('../Mediawebp/places/mexico2.webp'),
     Guatemala: require('../Mediawebp/places/guatemala.webp'),
     Colombia: require('../Mediawebp/places/colombia.webp'),
     Chile: require('../Mediawebp/places/chile.webp'),
     Argentina: require('../Mediawebp/places/argentina.webp'),
     default: require('../Mediawebp/places/default.webp')
 }
  Admin={
       Place: require('../Mediawebp/Place.webp'),
       Students:require('../Mediawebp/Student.webp'),
       Coaches:require('../Mediawebp/Teacher.webp'),
       Levels:require('../Mediawebp/Levels.webp'),
       Membership:require('../Mediawebp/Membership.webp'),
       Prices:require('../Mediawebp/prices.webp'),
       Add:require('../Mediawebp/Add.webp')
 }
  Blog={
         Podcast:  require('../Mediawebp/blog/podcasts.webp'),
         Thumbnail:require('../Mediawebp/blog/picture.webp'),
         Play:require('../Mediawebp/blog/play.webp'),
         Text:require('../Mediawebp/blog/text.webp'),
         Click:require('../Mediawebp/blog/click-here.webp'),



 }
  Home={
           Method:  require('../Mediawebp/Method.webp'),
           Free:  require('../Mediawebp/ConversationHackers-clase-gratis.webp'),
 }
  FreeClass={
             thumbn:  require('../Mediawebp/freeClass/clase.webp'),
             Popup1:  require('../Mediawebp/freeClass/pop-up1.webp'),
             Asesor:  require('../Mediawebp/freeClass/hablar-asesor.webp'),
             Head:  require('../Mediawebp/freeClass/head.webp'),
             Enjoy:  require('../Mediawebp/freeClass/enjoy.webp'),
             Leftside:  require('../Mediawebp/freeClass/left-side.webp'),
             Lograr:  require('../Mediawebp/freeClass/lograras.webp'),
             Rightside:  require('../Mediawebp/freeClass/right-side1.webp'),
             Rightside2:  require('../Mediawebp/freeClass/right-side2.webp'),
             MonthGray: require('../Mediawebp/freeClass/free-month.webp'),
             MonthBlue: require('../Mediawebp/freeClass/free-month-blue.webp'),
             Platform:  require('../Mediawebp/freeClass/Platform.webp'),
             Coach:  require('../Mediawebp/freeClass/Coach.webp'),
             Accent:  require('../Mediawebp/freeClass/Accent.webp'),
             Conversation:  require('../Mediawebp/freeClass/Conversation.webp'),
             Business:  require('../Mediawebp/freeClass/Business.webp'),
             Role:  require('../Mediawebp/freeClass/Role.webp'),
             Speach:  require('../Mediawebp/freeClass/Speach.webp'),
             Camps:  require('../Mediawebp/freeClass/Camps.webp'),
             Freebtn:  require('../Mediawebp/freeClass/free-btn.webp'),
             Freetext:  require('../Mediawebp/freeClass/free-txt.webp'),
             Free50:  require('../Mediawebp/freeClass/50-off.webp'),
             Wehack:  require('../Mediawebp/freeClass/we-hack.webp'),
             Cards1:  require('../Mediawebp/freeClass/Cards1.webp'),
             Cards2:  require('../Mediawebp/freeClass/Cards2.webp'),
             Cards4:  require('../Mediawebp/freeClass/Cards4.webp'),
             Impossible:  require('../Mediawebp/freeClass/impossible.webp'),
             Sticky:  require('../Mediawebp/freeClass/sticky.webp'),
             StickyMobile:  require('../Mediawebp/freeClass/sticky-mobile.webp'),
             Head:  require('../Mediawebp/freeClass/head.webp'),
             freeWeek:  require('../Mediawebp/freeClass/free-week.webp'),
             freeMonth:  require('../Mediawebp/freeClass/free-month2.webp'),
             changeLife:  require('../Mediawebp/freeClass/change-life.webp'),
             talk:  require('../Mediawebp/freeClass/talk.webp'),
             enjoy:require('../Mediawebp/prices/disfrutar.webp'),
             msg:require('../Mediawebp/prices/msg.webp'),
             question:require('../Mediawebp/prices/questions.webp'),
             sound:require('../Mediawebp/sound.webp')
 }
}


export default{
  Social,PeopleWorld,Settings,prices,Agenda,People,Payments,Logos,Animations,Countries, Admin,Blog,Home,FreeClass
}
