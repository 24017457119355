import React, { Component } from 'react';
import * as Web from '../../Constants/Web'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux';
import {fetchGetPost2,GetClassroom,fetchGetCoaches
} from '../../reducers/postsReducer';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {Redirect} from 'react-router-dom';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import GrayTemplate from '../MainComponents/Gray_Template_divide'
import Colors from '../../Constants/Colors'
import ReactAgenda from './src/reactAgenda';
import RegisterClassButton from './components/Actions/registerClassButton'
import classes from './agendaCoachGeneral.module.css'
require('moment/locale/es-us.js'); // this is important for traduction purpose

var ready;
var now = new Date();
var coaches='';
var classroomname;
var coachname;


class AgendaSelect extends Component {

  constructor(props){
          super(props);
          this.state={
            studentsAgendas:[],
              items:[],
              quantityAgendaNew:'',
              loader:'',
              url:Web.wurl+'/',
              show:false,
              cellHeight:25,
              rowsPerHour:1,
              numberOfDays:7,
              Time:'',
              Coach:'',
              Id:'',
              Info:'',
              Level:'',
              Color:'',
              Day:'',
              DayAgenda:'',
              Classroom:'',
              scheduled:'',
              counter:'',
              full:'',
              student:'',
              agendaselected:'',
              loader:'1',
              classroomname:'',
              levelAgenda:'',
              tempData:''
            };
      }



      componentDidMount(){

          this.props.GetClassroom('Get_Classrooms.php');
          const data = {
                id: this.props.id
              }
          this.props.fetchGetCoaches('Get_PlaceCoaches.php',data);
  var items=[];

          const getagenda={
            id:this.props.id
          }
          var url = this.state.url+'Api/Get_AgendasNamePlace.php';
          fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(getagenda), // data can be `string` or {object}!
            header: {'Content-Type': 'application/json'}
          }).then(res => res.json())
          .catch(error => console.error('Error:', error))
          .then(response => {

            response.forEach((data) => {
                        var myJSON = JSON.stringify(data);
                        //alert(data.Id_Agenda_Names)
                        this.setState({
                          agendaselected:data.Id_Agenda_Names
                        })

                      })

                      const data={
                        id:this.props.id,
                        agendaid:this.state.agendaselected
                      }

                      var url = this.state.url+'Api/Get_AgendaGeneral.php';
                      fetch(url, {
                        method: 'POST', // or 'PUT'
                        body: JSON.stringify(data), // data can be `string` or {object}!
                        header: {'Content-Type': 'application/json'}
                      }).then(res => res.json())
                      .catch(error => console.error('Error:', error))
                      .then(response => {
                      //  alert('si')
                        response.forEach((data) => {
                               var myJSON = JSON.stringify(data);
                               var initial=data.quantityAgenda;
                               var final=data.quantityAgendaNew;
                                 //alert(data.quantityAgendaNew+" "+data.quantityAgenda)
                                    //var myJSON = JSON.stringify(data);
                                    //alert(now.getMonth())
                                    //alert(data.quantityAgendaNew+data.quantityAgenda)

                                      items.push(
                                        {
                                         _id            :data.Id_Agenda,
                                          name          : data.infoAgenda,
                                          startDateTime : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourFrom, data.minutesFrom),
                                          endDateTime   : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourTo, data.minutesTo),
                                          classes       : data.colorAgenda,
                                          coaches:data.id_coachAgenda,
                                          classroom:data.id_classroomAgenda,
                                          quantity:data.quantityAgenda,
                                          level:data.levelAgenda,
                                          levelName:data.levelAgendaName,
                                          backgroundColor:data.colorAgenda,
                                          hourFrom:data.hourFrom,
                                          minutesFrom:data.minutesFrom,
                                          hourTo:data.hourTo,
                                          minutesTo:data.minutesTo,
                                          hexa:data.hexa,
                                          quantityAgendaNew:data.quantityAgendaNew

                                        }

                                        /*{
                                        _id            :data.Id_Agenda,
                                         name          : data.infoAgenda,
                                         startDateTime : new Date(data.year_agenda, data.month_agenda, data.day_agenda, data.hourFrom, data.minutesFrom),
                                           endDateTime   : new Date(data.year_agenda, data.month_agenda, data.day_agenda, data.hourTo, data.minutesTo),
                                           classes       : 'color-2'
                                      }*/);




                             })

                             this.setState({
                               loader:'',
                               items:items
                             })
                       });



           });

      }
      handleClose() {
    this.setState({ show: false,studentsAgendas:[] });
  }

  handleShow(e) {
    this.setState({
      tempData:e
    })
    const classrooms = this.props.classroom;
    const PlaceCoaches=this.props.placecoaches;

    this.setState({
      levelAgenda:e.levelName
    })
    PlaceCoaches.forEach((data) => {
                var myJSON = JSON.stringify(data);
                if(data.Id==e.coaches){
                  //alert(data.Coach_Name)
                  coachname=data.Coach_Name+" "+data.Last_Name+" "+data.Second_Lastname
                  }});
    classrooms.forEach((data) => {
                var myJSON = JSON.stringify(data);
                if(data.Id_Classroom==e.classroom){
                  //alert(data.Coach_Name)
                  classroomname=data.Name
                  }});
this.setState({
  show: true})
  const coachesplaces={
    id:e._id
  }

  var url = this.state.url+'Api/Get_Agenda_StudentSelected2.php';
  fetch(url, {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(coachesplaces), // data can be `string` or {object}!
    header: {'Content-Type': 'application/json'}
  }).then(res => res.json())
  .catch(error => console.error('Error:', error))
  .then(response => {
this.setState({studentsAgendas:response})

   } );
  }

  render() {

    return (
      <GrayTemplate>
      <div className="center2">
      <label class="pointer" onClick={this.props.close}>Cerrar</label>
      </div>
      {this.state.loader?
        <div className="center2">
        <Loader
             type="Puff"
             color="#00BFFF"
             height="100"
             width="100"
          />
          <h1>Cargando...</h1>
        </div>:
    <Row>
    <Col className="Front_divide" >
    <Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered  >
      <Modal.Header closeButton  >
        <Modal.Title id="contained-modal-title-vcenter" >
        <h5>Coach: {coachname}</h5>
        <h5>Salón: {classroomname} </h5>
        <h5>Nivel: {this.state.levelAgenda} </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
      {this.state.studentsAgendas.map(item => (
            <div  value={item}  key={item.Id_Student_Week} className={classes.rootModal}>
            <div>  <p className="CH_Text_list">{item.order}.- {item.key}{item.Name_Student} {item.Last_Name} {item.Second_Last_Name}  - {item.Level_Priority}
            </p></div>
            <div><RegisterClassButton classroom={item.Name_Classroom}id_class={item.Id_Week_Agenda} id_student={item.Id_Student_Week} reload={this.handleShow.bind(this)} tempData={this.state.tempData}/></div>
            </div>
          ))}
      </Modal.Body>
      <Modal.Footer >
        <Button variant="primary" onClick={this.handleClose.bind(this)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>

  <hr/>
  {this.state.items?
  <ReactAgenda
      minDate={new Date(now.getFullYear(), now.getMonth()-2, now.getDate()-5)}
      maxDate={new Date(now.getFullYear(), now.getMonth()+2)}
      disablePrevButton={false}
      startDate={this.state.startDate}
      cellHeight={this.state.cellHeight}
      locale={this.state.locale}
      items={this.state.items}
      numberOfDays={this.state.numberOfDays}
      rowsPerHour={this.state.rowsPerHour}
      itemColors={Colors.Agenda}
      autoScale={false}
      fixedHeader={false}
      onItemEdit={this.handleShow.bind(this)}/>:null}

   </Col>
  </Row>}
</GrayTemplate>

    );
  }
}
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        classroom: state.classroom.classroom,
        placecoaches:state.placecoaches.placecoaches,
    }),
    //mapeo de funciones
    {
        GetClassroom,fetchGetCoaches
    }
)(AgendaSelect);
