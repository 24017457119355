import React, {useEffect, useState} from 'react'
import * as URL from '../../../../Constants/services';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import SearchBarInput from '../SearchBarInput';
import TableContainer from '../TableContainer';
import Title from '../Titles';




const MostSell = (props) =>{
    const {startDate, endDate, place, updateMostSelledMembershipsBars,dataMost}= props;
    const [data, setData] = useState([]);
    const [searchTerm,setSearchTerm] = useState('');
    const [dat,setDat]=useState(dataMost)

  useEffect(()=>{
    var url = URL.api+"/membershipsMostSell";
    const inf={
      from:startDate,
      to:endDate,
      place:place
    }
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(inf), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => {
      setData(response);
      var array=[['Nombre', 'Total']];
      Object.entries(dataMost).forEach(([key, value]) => {
        if (key>=1) {
              array.push(value)
        }
});
      response.map((item)=>{
          array.push([item.Name_Membership, item.total])
        })
          updateMostSelledMembershipsBars(array);

    })
  },[startDate, endDate])
  if (!startDate || !endDate || !data) {
    return'';
  }
  const list =data.filter((val)=>{
          if(searchTerm == ""){
            return val
          }
          if (!searchTerm) {
            return val;
          }
          else if(val.Name_Membership.toLowerCase().includes(searchTerm.toLowerCase())){
            return val;
          }
        }).map((item,key)=>{
          return<tr>
                <td>{item.Name_Membership}</td>
                <td> {item.total}</td>
                </tr>
        })

        const headersMostSelled = [
          { label: "nombre", key: "Name_Membership" },
          { label: "total", key: "total" }
        ];
return(
  <TableContainer>
  <Title title={"Membresías más solicitadas por los alumnos nuevos"} />
  <SearchBarInput linkToExport={""}fileName={"membresias_mas_vendidas"} data={data} headers={headersMostSelled}etSearchTerm={setSearchTerm}/>
  <Table>
  <Thead>
    <Tr>
      <Th>Nombre</Th>
      <Th>Total</Th>
    </Tr>
  </Thead>
  <Tbody>
    {list}
  </Tbody>
  </Table>

</TableContainer>)


}
export default MostSell;
