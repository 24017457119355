import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import ListGroup from 'react-bootstrap/ListGroup'
import { connect } from 'react-redux';
import { UpdateActive} from '../../../reducers/postsReducer';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import * as Place from '../../../Constants/Dictionary/ES'
import logo from '../../../Media/Conversation_Hackers_Logo.png'



class  Placeinfo extends Component {
  constructor(props){
    super(props);
    this.state={
      Name:'',
      State:'',
      City:'',
      Zip:'',
      Phone:'',
      Email:'',
      Address:'',
      show:false,

    }

    this.onChange=this.onChange.bind(this);
  }
  handleClose() {
  this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
    }
save(){
  const data = {
        Name: this.state.Name,
        Address:this.state.Address,
        City:this.state.City,
        State:this.state.State,
        Zip:this.state.Zip,
        Phone:this.state.Phone,
        Email:this.state.Email,
        Id:this.props.id
      }
      this.props.UpdateActive(data,'Update/Update_Place.php');
      this.setState({show:false})


}
onChange(e){
  this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
}
edit(e){
  this.setState({
    Name:e[0],
    State:e[1],
    City:e[2],
    Zip:e[3],
    Phone:e[4],
    Email:e[5],
    Address:e[6],
    show:true
  })
}
EditPlace(){
  return(
    <div>
    <Form>
  <Form.Row>
    <Form.Group as={Col} controlId="formGridEmail">
      <Form.Label>{Place.placeName}</Form.Label>
      <Form.Control onChange={this.onChange} value={this.state.Name} name="Name"  placeholder="Nombre" />
    </Form.Group>

  </Form.Row>
<Form.Row>
  <Form.Group as={Col}  controlId="formGridAddress1">
    <Form.Label>{Place.placeAddress}</Form.Label>
    <Form.Control onChange={this.onChange} value={this.state.Address} name="Address" placeholder="Domicilio" />
  </Form.Group>
    <Form.Group as={Col}  controlId="formGridCity">
      <Form.Label>{Place.placeCity}</Form.Label>
      <Form.Control  onChange={this.onChange} value={this.state.City} name="City" placeholder="Ciudad"/>
    </Form.Group>
    </Form.Row>

    <Form.Row>
    {this.state.States?
    <Form.Group as={Col} controlId="formGridState">
      <Form.Label>{Place.placeState}</Form.Label>

      <Form.Control as="select" onChange={this.onChange}  name="State" placeholder="Estado">
      <option value={this.state.State}>{this.state.State}</option>
      {this.state.States.map(item => (
            <option key={item}>{item.Name}</option>
          ))}
      </Form.Control >
    </Form.Group>:null}

    <Form.Group as={Col} controlId="formGridZip" >
      <Form.Label>{Place.placeZip}</Form.Label>
      <Form.Control onChange={this.onChange} value={this.state.Zip} name="Zip" placeholder="CP"/>
    </Form.Group>
    </Form.Row>
        <Form.Row>

  <Form.Group as={Col} controlId="formGridZip" >
    <Form.Label>{Place.placePhone}</Form.Label>
    <Form.Control onChange={this.onChange} value={this.state.Phone} name="Phone" type="tel" placeholder="Telefono"/>
  </Form.Group>
  <Form.Group as={Col} controlId="formGridZip" >
    <Form.Label>{Place.placeEmail}</Form.Label>
    <Form.Control onChange={this.onChange} value={this.state.Email} name="Email" type="email" placeholder="Correo"/>
  </Form.Group>
</Form.Row>
</Form>
{this.state.Name&&this.state.Address&&this.state.City&&this.state.State&&this.state.Zip&&this.state.Phone&&this.state.Email?<button onClick={this.save.bind(this)} className="btn btn-success" type="submit">Agregar</button>:null}

</div>
  )
}
  render() {
const placeData = this.props.PlaceData;
    return (
  <div className="center-data">
  <Modal show={this.state.show} onHide={this.handleClose.bind(this)} centered>
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">{Place.Edit}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div>
    {this.EditPlace()}
    </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={this.handleClose.bind(this)}>
        {Place.Cancel}
      </Button>
    </Modal.Footer>
  </Modal>
  <img src={logo} className="Logo_Img"/><br/>
  {
      placeData.map(post =><div className={this.props.si} onClick={this.props.six}>
          <span className="ch-black-text">{post.Name}</span><br/>
          <span className="ch-black-text">{post.State}</span><br/>
          <h1 className="ch-black-text">{Place.Address}</h1>
          <label>{Place.City}:</label>  <span className="ch-black-text">{post.City}</span><br/>
          <label>{Place.State}</label>  <span className="ch-black-text">{post.State}</span><br/>
          <label>{Place.Zip}</label>      <span className="ch-black-text">{post.Zip}</span><br/>
          <h1 className="ch-black-text">{Place.Phone}-{Place.Email}</h1>
          <span className="ch-black-text">{post.Phone}</span><br/>
          <span className="ch-black-text">{post.Email}</span><br/>

          <hr/>

          <p className={"pointer hoover"} onClick={()=>this.edit([post.Name,post.State,post.City,post.Zip,post.Phone,post.Email,post.Address])}>{Place.Edit}</p>
          </div>
      )}
  </div>

    );
  }
}


export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
    }),
    //mapeo de funciones
    {
        UpdateActive
    }
)(Placeinfo);
