import React, { Component } from 'react';
import '../../Styles/App.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import * as Web from '../../Constants/Web'
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux';
import {hourChecker} from './hourChecker'
import HourCheckerNotInternational from './hourCheckerNotInternational'
import {Agenda,fetchAddPost,DeletePost,fetchGetPost2,
  fetchGetTimeAgenda,
  fetchGetCoaches
} from '../../reducers/postsReducer';
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {Redirect} from 'react-router-dom';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import GrayTemplate from '../MainComponents/Gray_Template_divide'
import Colors from '../../Constants/Colors'
import Time_Zone from '../Functions/Functions'
import ReactAgenda from './src/reactAgenda';
import DaysSelected from './components/Student/showSelected'
import Flag from 'react-world-flags'
import Clock from './Clock'
import ClockNow from './Clock/clock_'
import classes from './agenda.module.css'
import {cancelClass} from './components/Student/cancelClass'
import {clearCache} from '../Functions/version'
import ShowLink from './components/Student/showLink'
import Version from '../ui/Version'
require('moment/locale/es-us.js'); // this is important for traduction purpose

var now = new Date();
var coaches='';
const  fecha =  new Date();
var anio = fecha.getFullYear();
var mes = fecha.getMonth()+1;
var dia = fecha.getDate();
class AgendaSelect extends Component {

  constructor(props){
          super(props);
          this.state={
              items:[],
              idClassSelected:'',
              modalClassSelected:false,
              quantityAgendaNew:'',
              loader:'',
              url:Web.wurl+'/',
              show:false,
              info:false,
              cellHeight:25,
              rowsPerHour:1,
              numberOfDays:7,
              Time:'',
              studentId:'',
              isActiveCV:'',
              year_agenda:'',
              month_agenda:'',
              day_agenda:'',
              clase_virtual:'',
              files:[],
              Coach:'',
              Id:'',
              Info:'',
              Level:'',
              Color:'',
              Day:'',
              DayAgenda:'',
              Classroom:'',
              scheduled:'',
              counter:'',
              full:'',
              student:'',
              agendaselected:'',
              loader:'',
              Time_zoneStudent:'',
              country:'',
              Country_Code:'',
              Time_Diff:'',
              SignDiff:'',
              Priority:'',
              ask:'',
              diffPrev:'',
              signPrev:'',
              isInternational:''/*prioridad nivel del estudiante dependiendo del nivel se mostraran con este numero*/
            };
      }


      componentDidMount(){
        clearCache()
        this.update()
      }
      async update(){

            this.setState({loader:'1'})
            var items=[]
            let myItem = localStorage.getItem('userData')

            if (localStorage.getItem('userData')) {
              const info=JSON.parse(myItem)
              this.setState({idplaces:info.Place,
                studentId:info.Id
              })
              const student={
                Id_Student_Week:info.Id
              }
              const studentinfo={
                id:info.Id
              }

                  var url = this.state.url+'Api/Get_Student.php';
              fetch(url, {
                  method: 'POST', // or 'PUT'
                  body: JSON.stringify(studentinfo), // data can be `string` or {object}!
                  header: {'Content-Type': 'application/json'}
                }).then(res => res.json())
                .catch(error => console.error('Error:', error))
                .then(response => {
                  this.setState({student:response})

                  if(this.state.student){
                    var stud = this.state.student[Object.keys(this.state.student)];
                    /*nivel del estudiante*/

                    this.setState({
                      Priority:stud.Level_Priority,
                      ask:stud.ask,
                      diffPrev:stud.diff,
                      signPrev:stud.signDiffSelected,
                      country:stud.Country,
                      Country_Code:stud.Country_Code

                    })
                    if(stud.Sessions=='0'){
                      //alert(stud.Sessions)
                      this.setState({
                        pay:'1'
                      })
                    }



                    const getagenda={
                      id:info.Place
                    }
                    var urlGetAgenda = this.state.url+'Api/Get_AgendasNamePlace.php';
                    fetch(urlGetAgenda, {
                      method: 'POST', // or 'PUT'
                      body: JSON.stringify(getagenda), // data can be `string` or {object}!
                      header: {'Content-Type': 'application/json'}
                    }).then(res => res.json())
                    .catch(error => console.error('Error:', error))
                    .then(response => {

                      response.forEach((agendaNames) => {
                                  var myJSON = JSON.stringify(agendaNames);
                                  const isInternational=agendaNames.international
                                  this.setState({
                                    agendaselected:agendaNames.Id_Agenda_Names
                                  })


                                const data={
                                  id:info.Place,
                                  agendaid:this.state.agendaselected,
                                  Priority:stud.Level_Priority,
                                  year:anio,
                                  studentId:this.state.studentId
                                }
                                ///borrar el archivo Get_AgendaPriorityTest.php
                                var url = this.state.url+'Api/conversationHackers/Get_Agenda_student.php';
                                ///var url = this.state.url+'Api/Get_Agenda.php';
                                fetch(url, {
                                  method: 'POST', // or 'PUT'
                                  body: JSON.stringify(data), // data can be `string` or {object}!
                                  header: {'Content-Type': 'application/json'}
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                  response.forEach((data) => {
                                           items.push(HourCheckerNotInternational(data))
                                            this.setState({
                                             items:items,/*return value from that function converted to time_zone*/
                                             Time_Diff:'',
                                             SignDiff:'',
                                             isInternational:isInternational
                                           })



                                       })
                                       this.setState({
                                         loader:''
                                       })
                                 });
                                 })
                     });
                  }

                });


            }else {
              localStorage.removeItem('userData');
            }



          }
      handleClose() {
    this.setState({ show: false,info:false });
  }

  handleShow(e) {
this.setState({
  Time:'',
  Id:'',
  Coach:'',
  Info:'',
  Level:'',
  Color:'',
  Day:'',
  DayAgenda:'',
  Classroom:'',
  counter:''})
  var coachname=''
  //alert(JSON.stringify(info))
  //this.setState({idplaces:info.Id})

  const coachesplaces={
    id:this.state.idplaces
  }
  var url = this.state.url+'Api/Get_PlaceCoaches.php';
  fetch(url, {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(coachesplaces), // data can be `string` or {object}!
    header: {'Content-Type': 'application/json'}
  }).then(res => res.json())
  .catch(error => console.error('Error:', error))
  .then(response => {
    response.forEach((data) => {
                if(data.Id==e.coaches){
                   coachname=data.Coach_Name+' '+data.Last_Name+' '+data.Second_Lastname
                   this.setState({ show: true,
                     Time:e.hourFrom+":0"+e.minutesFrom+"-"+e.hourTo+":0"+e.minutesTo,
                     Id:e._id,
                     Coach:coachname,
                     Info:e.name,
    //                 Level:e.level,
                     Color:e.hexa,
                     Day:e.day_agenda,
                    // DayAgenda:e[7],
                     Classroom:e.classroom,
                     counter:e.quantity,
                     quantityAgendaNew:e.quantityAgendaNew


                    });
                }});

   } );
   const levelsname={
     id:this.state.idplaces
   }
   var url = this.state.url+'Api/Get_ActiveLevels.php';
   fetch(url, {
     method: 'POST', // or 'PUT'
     body: JSON.stringify(levelsname), // data can be `string` or {object}!
     header: {'Content-Type': 'application/json'}
   }).then(res => res.json())
   .catch(error => console.error('Error:', error))
   .then(response => {
     response.forEach((data) => {
                 if(data.Id_Level==e.level){

                    const levelname=data.Name
                    this.setState({
                      Level:levelname

                     });
                 }});

    } );

  }

  Agenda(){
  this.setState({loader:'1'})
    const idClass={
      id:this.state.Id
    }

    var url = this.state.url+'Api/Get_CounterClass.php';
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(idClass), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => {
      response.forEach((data) => {

       if(data.quantityAgenda>=data.quantityAgendaNew){

       }else {
           alert('Clase llena')
           this.update()
         }
/*-----------------------------------------------------------------------------*/
const idClassStudentsCounter={
  id:this.state.Id
}
var url = this.state.url+'Api/Get_CounterStudentClass.php';
fetch(url, {
  method: 'POST', // or 'PUT'
  body: JSON.stringify(idClassStudentsCounter), // data can be `string` or {object}!
  header: {'Content-Type': 'application/json'}
}).then(res => res.json())
.catch(error => console.error('Error:', error))
.then(response => {
  //alert(response[0])
  if (parseInt(response[0], 10)>=parseInt(data.quantityAgenda,10)) {
    alert('CLASE LLENA');
    this.update()
  }else {
    /*se puede agendar*/

  var integer = parseInt(this.state.quantityAgendaNew, 10)
  var session_new=integer+1
  const count={
    Day_Agenda:this.state.DayAgenda,
    Id_Agenda_Week:this.state.Id,
    counter:session_new
  }


  this.props.fetchAddPost(count,'Update/Update_counterBA.php')

  let myItem = localStorage.getItem('userData')
  const info=JSON.parse(myItem)
  const place=info.Place
const id=info.Id

const data={
Id_Student_Week:id,
Id_Place_Week:place,
Id_Agenda_Week:this.state.Id,
//Day_Agenda:this.state.DayAgenda,
Id_Coach:this.state.Coach,
Color:this.state.Color,
Classroom:this.state.Classroom,
Level_Name:this.state.Level
}
//alert(JSON.stringify(data))
this.props.fetchAddPost(data,'Create/Create_Week.php')

//alert(JSON.stringify(data))
  this.setState({
    show:false,
    scheduled:'1'
  })
 alert("Agendado correctamente")
  this.update()
} });

                });

      } );

  }



openClock(){
  this.setState({ask:0})
}

  openInfo(e){
      this.setState({idClassSelected:e,modalClassSelected:true})
  }

handleCloseModal(){
  this.setState({modalClassSelected:false})
}
  render() {
    const ready=this.props.posts//ready
    //alert(JSON.stringify(monday))
    if(localStorage.getItem('userData')){
      let myItem = localStorage.getItem('userData')
      const info=JSON.parse(myItem)
      if(info.type=='MASTER'){
        return (<Redirect to={'/admin'}/>)
      }
      if(info.type=='Student'){
        //return (<Redirect to={'/Profile'}/>)
      }
      if(info.type=='Coach'){
        return (<Redirect to={'/Coach'}/>)
      }
      if(info.type=='Admin'){
        return (<Redirect to={'/admins'}/>)
      }

    }else {
      return (<Redirect to={'/'}/>)
    }


    return (
      <GrayTemplate>
      <Version/>
      {this.state.loader?
        <div className="center2">
        <Loader
             type="Puff"
             color="#00BFFF"
             height="100"
             width="100"
          />
        </div>:
    <div className={classes.root}>
    {this.state.ask==1?null:
    <Clock ask={this.state.ask} studentId={this.state.studentId}/>}
    <div className={classes.Front_divide_classes} >
    <h4>Agendado</h4>
    <hr/>
    <ShowLink
     data={this.state.idClassSelected}
     show={this.state.modalClassSelected}
     close={this.handleCloseModal.bind(this)}
     />
    <DaysSelected
      isInternational={this.state.isInternational}
      open={this.openInfo.bind(this)}/>
    </div>
    <div className={classes.Front_divide} >
    {this.state.pay=='1'?<h4>Necesitas renovar tu membresía</h4>:
    <div>
      <Modal show={this.state.show}  onHide={this.handleClose.bind(this)} centered className="Modal-border">
          <Modal.Header closeButton>
            <Modal.Title>{this.state.Level}  {this.state.Time}</Modal.Title>
          </Modal.Header>
        <Modal.Body  >

        {/*  <Modal.Body style={{ backgroundColor:this.state.Color}}>*/}
        <div className={classes.container}><span>Coach:</span> <span className={classes.subText}>{'  '}  {this.state.Coach}</span></div>
        <div className={classes.container}><span>Clase:</span> <span className={classes.subText}>{'  '}  {this.state.Info}</span></div>


        </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose.bind(this)} >
              Cerrar
            </Button>
            <Button variant="primary" onClick={this.Agenda.bind(this)}>
              Agendar
            </Button>
           </Modal.Footer>
      </Modal>




    <div className={classes.watchContainer}>
  <span className="center-text">{this.state.country}-
  {this.state.Country_Code?<Flag code={this.state.Country_Code} height="16" />:null}
  <p onClick={this.openClock.bind(this)} className={classes.editBtn}>Editar </p>
  </span>  <span className={classes.small}>si esta información es incorrecta presiona "EDITAR" para modificarla</span>
  {this.state.diffPrev && this.state.signPrev?
<ClockNow hourDiff={this.state.diffPrev} signDiff={this.state.signPrev}/>:
null}
</div>
    <hr/>
  {this.state.diffPrev?
  <ReactAgenda
      minDate={new Date(now.getFullYear(), now.getMonth()-2, now.getDate()-5)}
      maxDate={new Date(now.getFullYear(), now.getMonth()+2)}
      disablePrevButton={false}
      startDate={this.state.startDate}
      cellHeight={this.state.cellHeight}
      locale={this.state.locale}
      items={this.state.items}
      numberOfDays={this.state.numberOfDays}
      rowsPerHour={this.state.rowsPerHour}
      itemColors={Colors.Agenda}
      autoScale={false}
      fixedHeader={false}
      onItemEdit={this.handleShow.bind(this)}/>:<p>cargando...</p>}



</div>}
   </div>
  </div>}
</GrayTemplate>

    );
  }
}
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        time: state.posts.time,
        posts:state.posts.dato,
        TimeAgenda:state.posts.TimeAgenda,
        placecoaches:state.placecoaches.placecoaches
    }),
    //mapeo de funciones
    {
      Agenda,fetchAddPost,fetchGetPost2,DeletePost,fetchGetTimeAgenda
    }
)(AgendaSelect);
