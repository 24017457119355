import React from 'react';
import classes from './tableContainer.module.css';




const TableContainer = (props) =>{
  const {children} =props;

  return(<div className={classes.root}>{children}</div>)
}
export default TableContainer;
