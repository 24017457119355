import React, { Component } from 'react';
import firebase from 'firebase'
import '../../Styles/Home.css';
import '../../Styles/App.css';
import Carousel from 'react-bootstrap/Carousel'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav';
import {NavLink,Redirect,withRouter,Link} from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar';
import Helpers from '../../lib/Helper'
import Modal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import Button from 'react-bootstrap/Button'
import {TwitterPicker,BlockPicker,SketchPicker} from 'react-color';
import Functions from '../Functions/Functions'
import logo from '../../Media/Conversation_Hackers_Logo.png'
import Radium from 'radium';

// You can create your style objects dynamically or share them for
// every instance of the component.
var styles;

class Navbars extends Component {

  constructor(props){
          super(props);

          this.state={
                uid:'8B57J61q4bM51u9oF7tKln340Fm2',
                banner:[],
								showEdit:false,
								background:'',
								textcolor:'',
								hovercolor:'',
								data:[],
								image:''
            };
						this.setShow=this.setShow.bind(this)


      }
			componentWillMount(){
				this.setState({
					background:this.props.data.background,
					textcolor:this.props.data.textcolor,
					hovercolor:this.props.data.hovercolor,
					image:this.props.data.Url,
				})

			}

			cancel(){
				if(this.props.data){
          this.setState({
						background:this.props.data.background,
						textcolor:this.props.data.textcolor,
						hovercolor:this.props.data.hovercolor
					})
				}else {
          this.setState({
            background:'#1d386d',
            textcolor:'#ffffff',
            hovercolor:'#FF2228',
          })

				}
			}
			setShow(){
				this.setState({
					showEdit:!this.state.showEdit
				})
			}
			handleChangeComplete = (color) => {
    this.setState({ background: color.hex });
  };
		handleChangeCompleteText = (color) => {
			this.setState({ textcolor: color.hex });
		};
		handleChangeCompleteHover = (color) => {
			this.setState({ hovercolor: color.hex });
		};
		handleChange=(e)=> {
			alert(e)
  this.setState({textcolor:e})
  }
	save(){
		const data={
			background:this.state.background,
			textcolor:this.state.textcolor,
			hovercolor:this.state.hovercolor
		}
		Helpers.UpdateHomepageInfo(this.state.uid,data)
	}
	pred(){
		const data={
			background:'#1d386d',
			textcolor:'#ffffff',
			hovercolor:'#FF2228'
		}
		Helpers.AddHomepageInfo(this.state.uid,data)
    this.setState({
      background:'#1d386d',
      textcolor:'#ffffff',
      hovercolor:'#FF2228',
      image:''
    })
	}
	UploadLogo(event){
		Functions.UploadLogo(event.target.files[0],this.state.uid)

	}
			edit(){
				return(
					<Modal
        show={this.state.showEdit}
        onHide={() => this.setShow()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton className="text-center whiteModal">
          <Modal.Title id="example-custom-modal-styling-title ">
					<h3 className="black-text">Navbar</h3>
          </Modal.Title>

        </Modal.Header>
        <Modal.Body className="text-center whiteModal">
				<Container>
						<Row>
						<input className="CargarArchivoBoton" type="file" onChange={this.UploadLogo.bind(this)} label="Cargar Archivo" s={12} />
								<Col><h4 className="black-text">LOGO</h4></Col>
						</Row><br/>
						<Row>
								<Col><h4 className="black-text initial-text">BackgroundColor</h4>
								<TwitterPicker
		                color={ this.state.background }
		                onChangeComplete={ this.handleChangeComplete }
		            />
								</Col>
						</Row><br/>
						<Row>
    					<Col><h4 className="black-text initial-text">Color texto</h4>
							<TwitterPicker
							width={'150px'}
									color={ this.state.textcolor }
									onChangeComplete={ this.handleChangeCompleteText}
							/>
							</Col>
    					<Col><h4 className="black-text initial-text">Color Hover </h4>
							<TwitterPicker
							width={'150px'}
									color={ this.state.hovercolor }
									onChangeComplete={ this.handleChangeCompleteHover }

							/>
							</Col>
  						</Row>
							<br/>
							<Col>
								<Row style={{backgroundColor:this.state.background,marginTop:30,marginBottom:30}} className="text-center">
                    <ul className="navbar-nav ml-auto">
        			      		<li className="nav-itemDouble">
        			        		<a  style={[styles.base]} key="key15" className="nav-link"  data-hover="EJEMPLO" href="#">EJEMPLO</a>
        			      		</li>
                        </ul>
								</Row>

									<Row style={{marginTop:30,marginBottom:50}} className="text-center">
										{this.state.background&&this.state.textcolor&&this.state.hovercolor?	<Button onClick={this.save.bind(this)}>Guardar</Button>:null}
										<Button onClick={this.pred.bind(this)}>Valores pred.</Button>
										<Button variant="danger" onClick={this.cancel.bind(this)}>Cancelar</Button>
									</Row>
							</Col>

							<SketchPicker
							width={420}
							/>
			</Container>
        </Modal.Body>
      </Modal>
				)
			}

  render() {
    styles = {
      base: {
        color:this.state.textcolor,
        // Adding interactive state couldn't be easier! Add a special key to your
        // style object (:hover, :focus, :active, or @media) with the additional rules.
        ':hover': {
              color: this.state.hovercolor
            }
      },
    };
    return (
      <div>
    	  	<div id="Welcome"  >
    	    	<nav
						style={{backgroundColor:this.state.background}}
						className="CH_NR navbar navbar-expand-lg navbar fixed-top "
						>

    			  	<div className="collapse navbar-collapse" id="navbarText">
							{this.state.image?<img src={this.state.image}  className="Logo_Img_Sm" alt=""/>:  <img src={logo}  className="Logo_Img_Sm" alt=""/>}

								{this.props.edit==true?<Button onClick={this.setShow.bind(this)}>Editar</Button>:null}
								{this.props.edit==true?this.edit():null}
    			    	<ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={'/'}>
                  <a  style={[styles.base]} key="key7" className="nav-link"  data-hover="INICIO" >INICIO</a></Link>
                </li>
                <Link to={'/clase-gratis'}>
    			      		<li className="nav-itemDouble">
                    	<a  style={[styles.base]} key="key1" className="nav-link"  data-hover="CLASE GRATIS" >CLASE <br/> GRATIS</a>
    			      		</li>
                      </Link>
                      <Link to={'/inglesfacil'}>
    					    <li className="nav-itemDouble">
    					    	<a style={[styles.base]} key="key2"  className="nav-link" data-hover="inglés FACIL" >inglés<br/>FACIL</a>
    					    </li>
                  </Link>

                  <Link to={'/metodo'}>
									<li className="nav-item">
									 <a  style={[styles.base]} key="key3"  className="nav-link" data-hover="Método" >Método</a>
								 </li>
                 </Link>
									{/*onClick={this.toggleGallery}*/}
                  <Link to={'/precios'}>
                  <li className="nav-item">
    					    	<a   style={[styles.base]} key="key4"  className="nav-link" data-hover="Precios" >Precios</a>
    					    </li>
                  </Link>
                  <Link to={'/sucursal'}>
    					    <li className="nav-item">
    					    	<a style={[styles.base]} key="key5"  className="nav-link" data-hover="sucursal" >sucursal</a>
    					    </li>
                  </Link>
                  <Link to={'/login'}>
                  <li className="nav-item">
    					    	<a style={[styles.base]} key="key6"  className="nav-link" data-hover="LOGIN" >LOGIN</a>
    					    </li>
                  </Link>
    				    </ul>
    				</div>
    			</nav>
          <div className="CH_Responsive">
          <Navbar collapseOnSelect expand="lg"  className="Menu navbar-blue"  sticky="top">
          <Navbar.Brand >{this.state.image?<img src={this.state.image}  className="Logo_Img_Sm" alt=""/>:  <img src={logo}  className="Logo_Img_Sm" alt=""/>}
</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
          <Nav.Link >
          <Link to={'/'}>
           <li className="nav-item">
              <a  style={[styles.base]} key="key8" className="nav-link"  data-hover="INICIO" href="/">INICIO</a>
            </li>
            </Link>
            </Nav.Link>

            <Nav.Link >
              <Link to={'/clase-gratis'}>
            <li className="nav-itemDouble">
              <a  style={[styles.base]} href={'/precios'} key="key9" className="nav-link"  data-hover="CLASE GRATIS" href="#Welcome">CLASE  GRATIS</a>
            </li>
            </Link>
            </Nav.Link>


            <Nav.Link >
            <Link to={'/inglesfacil'}>
            <li className="nav-itemDouble">
              <a style={[styles.base]} key="key10"  className="nav-link" data-hover="inglés FACIL" >inglés FACIL</a>
            </li>
            </Link>
            </Nav.Link>


            <Nav.Link >
            <Link to={'/metodo'}>
            	<li className="nav-item">
               <a  style={[styles.base]} key="key11"  className="nav-link" data-hover="Método" >Método</a>
             </li>
             </Link>
             </Nav.Link>

             <Link to={'/precios'}>
         <li className="nav-item">
           <a  style={[styles.base]} key="key12"  className="nav-link" data-hover="Precios"  href="/precios">Precios</a>
         </li>
         </Link>

         <Nav.Link >
         <Link to={'/sucursal'}>
         <li className="nav-item">
           <a style={[styles.base]} key="key13"  className="nav-link" data-hover="sucursal">sucursal</a>
         </li>
         </Link>
         </Nav.Link>


         <Nav.Link >
         <Link to={'/login'}>
         <li className="nav-item">
           <a style={[styles.base]} key="key14"  className="nav-link" data-hover="LOGIN" >LOGIN</a>
         </li>
         </Link>
         </Nav.Link>

          </Nav>

              <Nav>

              </Nav>
          </Navbar.Collapse>
          </Navbar>
          </div>
					{/*------------------------------------------------*/}

					{/*---------------------------------------------------*/}

    		</div>

</div>
    );
  }
}

Navbars  = Radium(Navbars );

export default Navbars;
