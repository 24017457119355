import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CardPost from '../../blog/cardPost'
import Images from '../../../Constants/images'
import AboutCards from '../AboutCards'
import Helpers from '../../../lib/Helper'

class Middle extends Component {
  constructor(props){
    super(props)
    this.state={
      uid:'8B57J61q4bM51u9oF7tKln340Fm2',
      show:false,
      title:'',
      Navbar:null,
      Social:'',
      cards:''
    }

  }
  componentDidMount(){
    Helpers.GetHomepageInfo(this.state.uid,(des)=>{
  this.setState({
  Navbar:des.Navbar,
  Title:des.title.title,
  Cards:des.cards,
  Social:des.social,
  People:des.testimonio,
  Footer:des.footer

  })
  })

  }
  render() {
    /*only if you need to show some cards by default show all cards*/
/*var toShow = {1:true, 2:true, 4:true};

 toShow={toShow}*/
      const cards=this.state.Cards
    return (
      <div>
      <Row>
      <Col>
        <img src={Images.FreeClass.Wehack} className="img-100"/>
      </Col>
      </Row>
      {this.state.Cards?
      <AboutCards
       data={cards}/>:null}
      {/*
      <Row>
        <Col md="4"><div ><img src={Images.FreeClass.Cards1} className="img-96"/></div></Col>
        <Col md="4"><div ><img src={Images.FreeClass.Cards2} className="img-96"/></div></Col>
        <Col md="4"><div ><img src={Images.FreeClass.Cards4} className="img-96"/></div></Col>
      </Row>*/}
      {/*  <Row>
          <Col md={3}><img src={Images.FreeClass.Platform} className="img-75"/></Col>
          <Col md={3}><img src={Images.FreeClass.Coach} className="img-75"/></Col>
          <Col md={3}><img src={Images.FreeClass.Accent} className="img-75"/></Col>
          <Col md={3}><img src={Images.FreeClass.Conversation} className="img-75"/></Col>
        </Row>
        <br/><br/>
        <Row>
          <Col md={3}><img src={Images.FreeClass.Business} className="img-75"/></Col>
          <Col md={3}><img src={Images.FreeClass.Role} className="img-75"/></Col>
          <Col md={3}><img src={Images.FreeClass.Speach} className="img-75"/></Col>
          <Col md={3}><img src={Images.FreeClass.Camps} className="img-75"/></Col>
        </Row>
        <Col>

        </Col>*/}
        <Col>
          <img src={Images.FreeClass.Impossible} className="img-100"/>
        </Col>
      </div>
    );
  }
}

export default Middle;
