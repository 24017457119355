import React, { Component } from 'react';
import firebase from 'firebase'
import Button from 'react-bootstrap/Button'
import Helpers from '../../lib/Helper'
import Images from '../../Constants/images'

class ButtonFreeClass extends Component {

  constructor(props){
          super(props);
          this.state={
                uid:'8B57J61q4bM51u9oF7tKln340Fm2',
								Social:'',
            };

      }



			componentDidMount(){
				Helpers.GetHomepageInfo(this.state.uid,(des)=>{
		 this.setState({
			Social:des.social,

		 })
		 })
			}

  render() {

    return (
      <div>
      {this.props.sticky==true?  <Button href={this.state.Social.free} className="transparent" target="_blank">
        {this.props.image?<img src={this.props.image}  alt="ConversationHackers_clase-gratis" className=" img-100 pointer"/>:null}
        </Button>:
			<div className=" center-text">
      {this.props.image?
  <Button href={this.props.url} target="_blank" className="transparent">
  {this.props.image?<img src={this.props.image}   alt="ConversationHackers_clase-gratis" className=" img-50 pointer"/>:null}
  </Button>:<Button href={this.state.Social.free} target="_blank">
  {this.props.text}</Button>}
      </div>}
      </div>
    );
  }
}

export default ButtonFreeClass;
