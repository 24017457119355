import React from 'react';
import CardPayment from './CardPayment';
import OxxoPayment from './OxxoPayment';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import classes from './main.module.css';


const Main = ( props ) =>{
  return (
    <div className={classes.root}>
    <div>
    <img src={"https://conversationhackers.com/static/media/Conversation_Hackers_Logo.7995508f.png"} className={classes.logo}/>
    <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
    <Tab eventKey="home" title="Pago con tarjeta">
      <CardPayment/>
    </Tab>
    <Tab eventKey="profile" title="Pago en oxxo">
      <OxxoPayment/>
    </Tab>
  </Tabs>
</div>

    </div>)

}

export default Main;
