import React,{ useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import * as URL from '../../../../Constants/services';



const DeletePayment =(props) =>{
const { idPayment, update } = props;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const deletePay =() =>{
    var url = URL.api+"/deletePayment";
    const inf={
    idPayment:idPayment
    }
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(inf), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.text())
    .catch(error =>{
      alert(error)
      handleClose()
      update()
    })
    .then(response => {
      alert(response)
      handleClose()
      update()
    })
  }

return( <>
      <Button variant="danger" onClick={handleShow}>
        Eliminar #{idPayment}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar pago N.{idPayment}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Estas a punto de eliminar este pago.<br/>
          ¿Estás seguro?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=>deletePay()}>
            Eliminar
          </Button>
            <Button variant="primary" onClick={handleClose}>
              No
            </Button>
        </Modal.Footer>
      </Modal>
    </>)


}

export default DeletePayment;
