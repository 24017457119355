import { connect } from 'react-redux';
import {fetchAddPost,DeletePost,fetchGetCoaches,GetLevels,GetClassroom,UpdateActive} from '../../reducers/postsReducer';
import React, { Component } from 'react';
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Loader from 'react-loader-spinner'
import {CirclePicker} from 'react-color';
import {NavLink,Redirect,withRouter,Link} from 'react-router-dom'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ReactAgenda from './src/reactAgenda';
import * as Web from '../../Constants/Web'
import Disponib from './Dispo'
import Images from '../../Constants/images'
import Colors from '../../Constants/Colors'
import VirtualClass from './components/virtualClass'
import CreateDay from './components/createDay'
import EditDay from './components/editDay'
import Add from './components/addFiles'
import classes from './components/createday.module.css'
require('moment/locale/es-us.js'); // this is important for traduction purpose

var now = new Date();

var now = new Date();
const nums=[];
for (var i = 0; i < 100; i++) {
  nums[i]=i
}
const hours=[];
for (var i = 0; i < 24; i++) {
  hours[i]=i
}
const minutes=[];
for (var i = 0; i < 60; i++) {
  minutes[i]=i
}
class CreateAgenda extends Component {


  constructor(props){
          super(props);
          this.state={
            items:[],
            backgroundhexa:'',
            loader:'1',
            selected:[],
            cellHeight:20,
            showModal:false,
            locale:"fr",
            rowsPerHour:1,
            numberOfDays:7,
            date:'',
            startDate: new Date(),
              selectedDay: null,
              url:Web.wurl+'/',
              show:false,
              scheduled:'',
              Title:'',
              Title2:'',
              hourFrom:'',
              minutesFrom:'',
              hourTo:'',
              minutesTo:'',
              Time:'',
              background:'',
              info:'',
              coach:'',
              level:'',
              classroom:'',
              edit:false,
              delete:false,
              config:false,
              data:[],
              quantity:'',
              id_agenda:'',
              deleteurl:'',
              edithour:false,
              coachname:'',
              levelnamne:'',
              Priority:'',
              createagenda:false,
              nameagenda:'',
              names:[],
              idagenda:'',
              agendaselected:[],
              agendaselected2:[],
              link:'',
              isActive:false,
              ampm:'',
              showDay:false,
              dateFrom:'',//this state save the complete date 'FROM'
              dateTo:'',///this state save the complete date from TO
              Time_zone:null,
              openDisponibilitie:false,
              setFiles:'',
              clasevirtual:''

            };

            this.handleShow=this.handleShow.bind(this)
            this.editopen=this.editopen.bind(this)
            this.handleClose=this.handleClose.bind(this)
            this.onChange=this.onChange.bind(this)
            this.onChangeLink=this.onChangeLink.bind(this)
            this.onChangeCoach=this.onChangeCoach.bind(this)
            this.onChangelevel=this.onChangelevel.bind(this)
            this.onChangeHour=this.onChangeHour.bind(this)
            this.handleopencreate=this.handleopencreate.bind(this)
            this.handleclosecreate=this.handleclosecreate.bind(this)
            this.saveagenda=this.saveagenda.bind(this)
            this.lookagenda=this.lookagenda.bind(this)
            this.openDisponibilitie=this.openDisponibilitie.bind(this)
            this.setFiles=this.setFiles.bind(this)
      }
  componentWillMount(){
    var url = 'https://ipapi.co/json/';
  					fetch(url)
  				.then(response => response.json())
  			.then((data)=>{
          this.setState({
            Time_zone:data.timezone
          })
          var items=[];
          var newDate = new Date()
          var date = newDate.getDate();
          var month = newDate.getMonth() + 1;
          var year = newDate.getFullYear();
          var numday= newDate.getDate();
          this.setState({
            date:numday+"/"+month+"/"+year
          })
          if(this.props.id){

          const names={
            id:this.props.id
          }
          var url = this.state.url+'Api/Get_AgendasNames.php';
          fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(names), // data can be `string` or {object}!
            header: {'Content-Type': 'application/json'}
          }).then(res => res.json())
          .catch(error => console.error('Error:', error))
          .then(response => { {this.setState({names:response})} });


          const getagenda={
            id:this.props.id
          }
          var url = this.state.url+'Api/Get_AgendasNamePlace.php';
          fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(getagenda), // data can be `string` or {object}!
            header: {'Content-Type': 'application/json'}
          }).then(res => res.json())
          .catch(error => console.error('Error:', error))
          .then(response => {  this.setState({agendaselected:response})} );

          var url = this.state.url+'Api/Get_AgendasNamePlace2.php';
          fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(getagenda), // data can be `string` or {object}!
            header: {'Content-Type': 'application/json'}
          }).then(res => res.json())
          .catch(error => console.error('Error:', error))
          .then(response => {
             this.setState({agendaselected2:response,loader:''})} );
              const data={
                id:this.props.id,
              }
              //this.props.Agenda('Get_Agenda.php',data)
              this.props.fetchGetCoaches('Get_PlaceCoaches.php',data);
              this.props.GetLevels('Get_ActiveLevels.php');
                this.props.GetClassroom('Get_Classrooms.php');

              }else{
                return (<Redirect to={'/admins'}/>)
              }
  			}).catch((error)=>{
  				alert('No pudimos cargar tu zona horaria intenta nuevamente')
  			});

      }



  lookagenda(e){
    var items=[];
    this.setState({
      items:items,
      loader:'1'
    })
    this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
    const data={
      id:this.props.id,
      agendaid:e.target.value
    }
    //alert(this.state.nameagenda)
  //  this.props.Agenda('Get_Agenda.php',data)
    var url = this.state.url+'Api/Get_AgendaGeneral.php';
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => {
    //  alert('si')
    //alert(JSON.stringify(response))
      response.forEach((data) => {
             var myJSON = JSON.stringify(data);

             //alert(now.getMonth())
             //var colores=data.colorAgenda
             //var colores1=data.colorAgenda+" "+data.colorAgenda
             //colors.colors = data.colorAgenda;
             //cart.push(colors);
              /*colors={
               colores:data.colorAgenda,
               colores:data.colorAgenda
             }*/

             items.push(
               {
                _id            :data.Id_Agenda,
                 name          : data.infoAgenda,
                 startDateTime : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourFrom, data.minutesFrom),
                 endDateTime   : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourTo, data.minutesTo),
                 classes       : data.colorAgenda,
                 coaches:data.id_coachAgenda,
                 classroom:data.id_classroomAgenda,
                 quantity:data.quantityAgenda,
                 level:data.levelAgenda,
                 backgroundColor:data.colorAgenda,
                 hourFrom:data.hourFrom,
                 minutesFrom:data.minutesFrom,
                 hourTo:data.hourTo,
                 minutesTo:data.minutesTo,
                 hexa:data.hexa,
                files:data.files,
                clase_virtual:data.clase_virtual,
                isActiveCV:data.isActiveCV


               },
               /*{
               _id            :data.Id_Agenda,
                name          : data.infoAgenda,
                startDateTime : new Date(data.year_agenda, data.month_agenda, data.day_agenda, data.hourFrom, data.minutesFrom),
                  endDateTime   : new Date(data.year_agenda, data.month_agenda, data.day_agenda, data.hourTo, data.minutesTo),
                  classes       : 'color-2'
             }*/);

           })
           //alert(JSON.stringify(colors))
           this.setState({
             items:items,
             loader:''
           })


     });

  }
  openDisponibilitie(){
        this.setState({openDisponibilitie:!this.state.openDisponibilitie})
      }
  handleopencreate(){
        this.setState({createagenda:true})
      }
  handleclosecreate(){
          this.setState({createagenda:false})

        }
        setFiles(e){
          this.setState({setFiles:e})
        }
  update(){
  var items=[];
    this.setState({
      //items:items,
      loader:'1'
    })
    const getagenda={
      id:this.props.id
    }
    var url = this.state.url+'Api/Get_AgendasNamePlace.php';
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(getagenda), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => {  this.setState({agendaselected:response})} );
    var url = this.state.url+'Api/Get_AgendasNamePlace2.php';
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(getagenda), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => {  this.setState({agendaselected2:response})} );
    const names={
      id:this.props.id

    }
    var url = this.state.url+'Api/Get_AgendasNames.php';
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(names), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
    }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => {this.setState({names:response})} );

        const data={
          id:this.props.id,
          agendaid:this.state.idagenda
        }

      //  this.props.Agenda('Get_Agenda.php',data)
      var url = this.state.url+'Api/Get_AgendaGeneral.php';
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        header: {'Content-Type': 'application/json'}
      }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {
        response.forEach((data) => {
               var myJSON = JSON.stringify(data);

                 items.push(
                   {
                    _id            :data.Id_Agenda,
                     name          : data.infoAgenda,
                     startDateTime : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourFrom, data.minutesFrom),
                     endDateTime   : new Date(data.year_agenda, data.month_agenda-1, data.day_agenda, data.hourTo, data.minutesTo),
                     classes       : data.colorAgenda,
                     coaches:data.id_coachAgenda,
                     classroom:data.id_classroomAgenda,
                     quantity:data.quantityAgenda,
                     level:data.levelAgenda,
                     backgroundColor:data.colorAgenda,
                     hourFrom:data.hourFrom,
                     minutesFrom:data.minutesFrom,
                     hourTo:data.hourTo,
                     minutesTo:data.minutesTo,
                     hexa:data.hexa,
                    files:data.files,
                    clase_virtual:data.clase_virtual,
                    isActiveCV:data.isActiveCV


                   },
                 /*{
                 _id            :data.Id_Agenda,
                  name          : data.infoAgenda,
                  startDateTime : new Date(data.year_agenda, data.month_agenda, data.day_agenda, data.hourFrom, data.minutesFrom),
                    endDateTime   : new Date(data.year_agenda, data.month_agenda, data.day_agenda, data.hourTo, data.minutesTo),
                    classes       : 'color-2'
               }*/);

             })
             this.setState({
               items:items,
               loader:''
             })
       });
        this.setState({
          show:false,
          edit:false,
          delete:false,
          edithour:false,
          createagenda:false,
        })
      }
  handleItemEdit(e){
        this.setState({edit:true,
          Id_Agenda:e._id,
          info:e.name,
          coach:e.coaches,
          classroom:e.classroom,
          quantity:e.quantity,
          level:e.level,
          data:e,
          background:e.backgroundColor,
          backgroundhexa:e.hexa,
          hourFrom:e.hourFrom,
          minutesFrom:e.minutesFrom,
          hourTo:e.hourTo,
          minutesTo:e.minutesTo
        })
      }
      onChangeCoach(e){
          this.setState({coach:''})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
              this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
          }
          onChangelevel(e){
              this.setState({level:''})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
                  this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
              }




              onChangeLink(){
                  this.setState({link:'',isActive:!this.state.isActive})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
                  }
  editopen(e,coaches,activelevels,classrooms){

    //startDateTime
    //endDateTime
var coachname='';//e[3]
var levelname='';//e[5]
var classroomname='';//e[4]
        const nums=[];
        for (var i = 0; i < 100; i++) {
          nums[i]=i
        }
        const dateTo=e.endDateTime
        const filesToShow=e.files;


        /*
    Id_Agenda:e._id,
    info:e.name,
    coach:e.coaches,
    classroom:e.classroom,
    quantity:e.quantity,
    level:e.level,
    data:e,*/
    coaches.forEach((data) => {
                var myJSON = JSON.stringify(data);

                if(data.Id==e.coaches){
                   coachname=data.Coach_Name+' '+data.Last_Name+' '+data.Second_Lastname
                }});
    activelevels.forEach((data) => {
                var myJSON = JSON.stringify(data);
                if(data.Id_Level==e.level){
                  //alert(data.Coach_Name)
                  levelname=data.Name
                  }});
    classrooms.forEach((data) => {
                var myJSON = JSON.stringify(data);
                if(data.Id_Classroom==e.classroom){
                  //alert(data.Coach_Name)
                  classroomname=data.Name
                  }});

        return(
          <div>
          <EditDay
          id_agendaGeneral={e._id}
          hours={hours}
          minutes={minutes}
          hourFromold={this.state.hourFrom}
          minutesFromold={this.state.minutesFrom}
          hourToold={this.state.hourTo}
          minutesToold={this.state.minutesTo}
          filesToShow={filesToShow}
          coachname={coachname}
          coachId={this.state.coach}
          levelSelected={this.state.level}
          classroomname={classroomname}
          classroomnID={e.classroom}
          quantity={e.quantity}
          isActiveCV={e.isActiveCV}
          infoSelected={e.name}
          clasevirtualLink={e.clase_virtual}
          backgroundhexa={this.state.backgroundhexa}
          timezone={this.state.Time_zone}
          dateFrom={this.state.dateFrom}
          dateTo={e.endDateTime}
          day={this.state.day}
          id={this.props.id}
          idAgenda={this.state.idagenda}
          openDisponibilitie={this.openDisponibilitie}
          handleChangeComplete={this.handleChangeComplete}
          coaches={coaches}
          nums={nums}
          classrooms={classrooms}
          backgroundColor={this.state.background}
          deleteClass={this.delete.bind(this)}
          />


          </div>
        )
      }

  saveDayUpdate(){
    var coachId = this.state.coach.split(",", 2)
    var levelId = this.state.level.split(",", 2);
    var levelPriority=this.state.level.replace(',','')
    /*alert(this.state.level)
    Level:levelId[0],*/
            if(this.state.idagenda){
                if(this.state.quantity&&this.state.coach&&this.state.level&&this.state.classroom&&this.state.background&&this.state.info){
                  const data={
                    color:this.state.background,
                    Level:levelPriority,
                    LevelName:levelId[1],
                    Id_Coach:coachId[0],
                    Id_CoachName:coachId[1],
                    Id_Classroom:this.state.classroom,
                    info:this.state.info,
                    Day:this.state.day,
                    quantity:this.state.quantity,
                    Id_Agenda:this.state.Id_Agenda,
                    /*date*/
                    hourFrom:this.state.hourFrom,
                    minutesFrom:this.state.minutesFrom,
                    hourTo:this.state.hourTo,
                    minutesTo:this.state.minutesTo,
                    hexa:this.state.backgroundhexa,
                    Time_Zone:this.state.Time_zone
                  }

                  /*alert(JSON.stringify(data))*/
                  //alert(this.state.coach)
                  this.props.UpdateActive(data,'Update/Update_AgendaBig.php');
                  this.props.UpdateActive(data,'Update/Update_Week.php');
                  //alert(hourMinutes)
                  this.update();
                  this.handleClose();
                }else {
                  alert("llena todos los datos")
                }
              }else {
                alert('selecciona una agenda')
              }

              }
  handleClose() {
    this.setState({config:false,showDay:false,Hour:'',HourEnd:'',edithour:false,Time:'',Id_Agenda:'',delete:false,edit:false, show: false,Title:'',one:'',two:'',three:'',four:'',five:'',six:'',seven:'',eight:'' });
    this.update();
  }
  handleShow() {
    this.setState({ show: true });
  }
  Agendar(){
    this.setState({
      show:false,
      scheduled:'1'
    })
    alert("Agendado correctamente")
  }
  saveagenda(){
const data={
  Name:this.state.nameagenda,
  Place:this.props.id
}
    this.props.fetchAddPost(data,'Create/Create_AgendaName.php')
    alert('correcto')
    this.update()
  }
  onChange(e){
      this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
  }
  onChangeHour(e){
    this.setState({[e.target.name]:e.target.value})//el nombre seleccionaso lo guardara en el estado con el mismo nombre
  }

  Cancel(){
    alert("Clase Cancelada")
    this.setState({
      scheduled:''
    })
  }
one(){this.setState({show:true,one:'1',two:'',three:'',four:'',five:'',six:'',seven:'',eight:'',Title:'hora'})}
two(e){this.setState({show:true,one:'',two:'1',three:'',four:'',five:'',six:'',seven:'',eight:'',Title:'Lunes',Time:e[0],Id_Agenda:e[1],day:'Monday'})}
three(e){this.setState({show:true,one:'',two:'',three:'1',four:'',five:'',six:'',seven:'',eight:'',Title:'Martes',Time:e[0],Id_Agenda:e[1],day:'Tuesday'})}
four(e){this.setState({show:true,one:'',two:'',three:'',four:'1',five:'',six:'',seven:'',eight:'',Title:'Miercoles',Time:e[0],Id_Agenda:e[1],day:'Wednesday'})}
five(e){this.setState({show:true,one:'',two:'',three:'',four:'',five:'1',six:'',seven:'',eight:'',Title:'Jueves',Time:e[0],Id_Agenda:e[1],day:'Thursday'})}
six(e){this.setState({show:true,one:'',two:'',three:'',four:'',five:'',six:'1',seven:'',eight:'',Title:'Viernes',Time:e[0],Id_Agenda:e[1],day:'Friday'})}
seven(e){this.setState({show:true,one:'',two:'',three:'',four:'',five:'',six:'',seven:'1',eight:'',Title:'Sabado',Time:e[0],Id_Agenda:e[1],day:'Saturday'})}
eight(e){this.setState({show:true,one:'',two:'',three:'',four:'',five:'',six:'',seven:'',eight:'1',Title:'Domingo',Time:e[0],Id_Agenda:e[1],day:'Sunday'})}

delete(){
  const data={
    id:this.state.Id_Agenda
  }
  this.props.DeletePost(data,'Delete/delete_dayBG.php')
    this.update()
    this.handleClose()
}

  createagenda(){
    return(
      <Form>
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Asignár nombre</Form.Label>
    <Form.Control type="text" name="nameagenda" onChange={this.onChange} placeholder="Nombre" />
    {this.state.nameagenda?
    <Button variant="primary" onClick={this.saveagenda}>
      Guardar
    </Button>:<p>Escribe un nombre</p>}
  </Form.Group>

</Form>

    )
  }
  activeagenda(){
    const data={
      idPlace:this.props.id,
      agendaid:this.state.idagenda
    }
    //alert(JSON.stringify(data))

        this.props.fetchAddPost(data,'Create/Create_activeAgenda.php')
        this.update()
        alert('Agenda activada')
        this.update()
  }
  activeagenda2(){
    const data={
      idPlace:this.props.id,
      agendaid:this.state.idagenda
    }
    //alert(JSON.stringify(data))

        this.props.fetchAddPost(data,'Create/Create_activeAgenda2.php')
        this.update()
        alert('Agenda activada')
        this.update()
  }
  updateactiveagenda(){
    const data={
      idPlace:this.props.id,
      agendaid:this.state.idagenda
    }
    //alert(JSON.stringify(data))

        this.props.fetchAddPost(data,'Create/Create_activeAgenda.php')
  }


  handleItemEdity(item){
    var from =new Date(item[2]);
    var to =new Date(item[3]);

   var daynumber = from.getDay();
    let month = from.getMonth() + 1;
    let year = from.getFullYear();
    var daymonth = from.getDate();
    var hour = from.getHours();
    var hourMinutes = from.getMinutes();
    var hour_end = to.getHours();
    var hour_endMinutes = to.getMinutes();
    if(daynumber=='0'){
      daynumber="Domingo"
    }
    if(daynumber=='1'){
      daynumber="Lunes"
    }
    if(daynumber=='2'){
      daynumber="Martes"
    }
    if(daynumber=='3'){
    daynumber="Miercoles"
    }
    if(daynumber=='4'){
      daynumber="Jueves"
    }
    if(daynumber=='5'){
      daynumber="Viernes"
    }
    if(daynumber=='6'){
      daynumber="Sabado"
    }
    this.setState({edit:true,
      Id_Agenda:item[0],
      info:item[1],
      //data:e,
      //Time:e[0],
      Title:daynumber+" "+hour+":"+hourMinutes+"0-"+hour_end+":"+hour_endMinutes+"0           "+daymonth+"/"+month+"/"+year,
      //background:e[7],
      //level:e[5],
      //coach:e[3],
      //classroom:e[4],

      //day:e[2],
      //quantity:e[8]
      //coachname:e[9]
    })
    //alert(JSON.stringify(item))
  }
  handleRangeSelection(item){
    var from =new Date(item[0]);
    var to =new Date(item[1]);
    this.setState({
      dateFrom:from,
      dateTo:to
    })
   var daynumber = from.getDay();
    let month = from.getMonth() + 1;
    let year = from.getFullYear();
    var daymonth = from.getDate();
    var hour = from.getHours();
    var hourMinutes = from.getMinutes();
    var hour_end = to.getHours();
    var hour_endMinutes = to.getMinutes();

    if(daynumber=='0'){
      daynumber="Domingo"
    }
    if(daynumber=='1'){
      daynumber="Lunes"
    }
    if(daynumber=='2'){
      daynumber="Martes"
    }
    if(daynumber=='3'){
    daynumber="Miercoles"
    }
    if(daynumber=='4'){
      daynumber="Jueves"
    }
    if(daynumber=='5'){
      daynumber="Viernes"
    }
    if(daynumber=='6'){
      daynumber="Sabado"
    }
    this.setState({
      day:daynumber,
     showDay:true,
      Title:daynumber+" "+hour+":"+hourMinutes+"0-"+hour_end+":"+hour_endMinutes+"0           "+daymonth+"/"+month+"/"+year,

    });
  }

  AgendaConfig(){
    return(
      <div>
      <Form>
      <Form.Row>
      <Col>
           <Form.Label>altura</Form.Label>
           <Form.Control as="select" onChange={this.onChange} name="cellHeight">
             <option>Choose...</option>
             {nums.map(item => (
               <option value={item}  key={item}>{item}</option>
             ))}
           </Form.Control>
             </Col>
      <Col>
      <Form.Label>filas por hora</Form.Label>
      <Form.Control as="select" onChange={this.onChange} name="rowsPerHour">
       <option>Choose...</option>
       {nums.map(item => (
         <option value={item}  key={item}>{item}</option>
       ))}
      </Form.Control>
       </Col>
        <Col>
      <Form.Label>mostrar dias</Form.Label>
      <Form.Control as="select" onChange={this.onChange} name="numberOfDays">
       <option>Choose...</option>
       {nums.map(item => (
         <option value={item}  key={item}>{item}</option>
       ))}
      </Form.Control>
      </Col>

       </Form.Row>
      </Form>

      </div>
    )
  }

  ///ccreate new day for the agenda//////
  day(coaches,activelevels,classrooms){
    const nums=[];
    for (var i = 0; i < 1000; i++) {
      nums[i]=i
    }
    return(
      <CreateDay
      backgroundhexa={this.state.backgroundhexa}
      timezone={this.state.Time_zone}
      dateFrom={this.state.dateFrom}
      dateTo={this.state.dateTo}
      day={this.state.day}
      id={this.props.id}
      idAgenda={this.state.idagenda}
      openDisponibilitie={this.openDisponibilitie}
      handleChangeComplete={this.handleChangeComplete}
      coaches={coaches}
      nums={nums}
      classrooms={classrooms}
      backgroundColor={this.state.background}/>
    )
  }

  handleChangeComplete = (colorrgba, event) => {
      /*"color-3":"rgba(235, 85, 59, 1)"*/
      this.setState({background:''})

      var color=colorrgba.rgb

    this.setState({ background: 'rgba('+color.r+','+color.g+','+color.b+','+color.a+')' });


this.setState({backgroundhexa:colorrgba.hex})

  };
  createday(coaches,activelevels,classrooms){
    return(
      <Modal show={this.state.showDay} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{this.state.Title}</Modal.Title><br/>
        </Modal.Header>
        <Modal.Body>

{this.day(coaches,activelevels,classrooms)}

{this.state.openDisponibilitie?<Disponib id={this.props.id} dateFrom={this.state.dateFrom} dateTo={this.state.dateTo}/>:null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
Agendaconfigselect(){
  return(
    <Container>
    <Modal show={this.state.createagenda} onHide={this.handleclosecreate}>
      <Modal.Body>
{this.createagenda()}
      </Modal.Body>
      <Modal.Footer>


        <Button variant="secondary" onClick={this.handleclosecreate}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
      <Row className="justify-content-md-center">
        <Col xs lg="8">
        <Button variant="success" onClick={this.handleopencreate}>
          Nueva agenda
        </Button>
        <Button variant="primary" onClick={this.activeagenda.bind(this)}>
          Activar esta agenda
        </Button>

        <Button variant="primary" onClick={this.activeagenda2.bind(this)}>
          Activar como segunda agenda
        </Button>
        </Col>
      </Row>
    </Container>
  )
}
handleopenconfig(){
  this.setState({
    config:true
  })
}
  //////////////------------------------------------------
  selectedAgenda(agendaselected,agendaselected2,names){
    return(
      <div>
      {agendaselected.map(item => (
        <div>{item.id_Place_AgendaName?
              <h4 className="CH_Text3"> Agenda 1: {item.Name_AgendaName}</h4>:<h4>no tienes agenda activada</h4>}
              </div>
          ))}
          {agendaselected2.map(item => (
            <div>{item.id_Place_AgendaName?
                  <h4 className="CH_Text3"> Agenda 2: {item.Name_AgendaName}</h4>:<h4>no tienes agenda activada</h4>}
                  </div>
              ))}
      <Form.Control as="select" onChange={this.lookagenda} name="idagenda">

          <option>selecciona una agenda</option>
          {names.map(item => (
            <option value={item.Id_Agenda_Names}  key={item}>{item.Name_AgendaName}</option>
          ))}
        </Form.Control>
      </div>
    )
  }
  render() {
    var time='';
    var coaches='';
    var activelevels=''
    var classrooms='';
    var names='';
    var agendaselected='';
    var agendaselected2='';
    if(this.props.id){
       time=this.props.time///agenda
       coaches=this.props.placecoaches
  		 activelevels=this.props.activelevels
       classrooms = this.props.classroom;
       names=this.state.names;
       agendaselected=this.state.agendaselected;
       agendaselected2=this.state.agendaselected2;
    }else{
      return (<Redirect to={'/admins'}/>)
    }

    return (
      <div >

      {this.state.loader?
        <div className="center2">
        <Loader
             type="Puff"
             color="#00BFFF"
             height="100"
             width="100"
          />
        </div>:<div style={{display:'block', position:'absolute' , background:'#f0f0f0'}}>
      <div className="center2">
      <label class="pointer" onClick={this.props.close}>Cerrar</label>
      <h2 ><img className="CH_SM_Icono" src={Images.Agenda.Dateimg}/>{this.state.date}</h2>
      {this.state.Time_zone?<h2>{this.state.Time_zone}</h2>:null}
      <Button variant="primary" onClick={this.handleopenconfig.bind(this)}>
        ver configuración
      </Button>
      </div>
      <Modal show={this.state.edit} onHide={this.handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>

        {this.editopen(this.state.data,coaches,activelevels,classrooms)}

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={this.handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={this.state.config} onHide={this.handleClose}>
        <Modal.Header closeButton>
        <Modal.Title>
        {this.selectedAgenda(agendaselected,agendaselected2,names)}

  {this.Agendaconfigselect()}
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {this.AgendaConfig()}


        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={this.handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

<p  className='pointer'onClick={this.update.bind(this)}>
<img src={Images.Agenda.Reload} className="Logo_Img_Sm3"/>Recargar Agenda</p>
      {this.createday(coaches,activelevels,classrooms,agendaselected)}
      {this.state.Time_zone?
      <ReactAgenda
          minDate={new Date(now.getFullYear(), now.getMonth()-2, now.getDate()-5)}
          maxDate={new Date(now.getFullYear(), now.getMonth()+2)}
          disablePrevButton={false}
          startDate={this.state.startDate}
          cellHeight={this.state.cellHeight}
          locale={this.state.locale}
          items={this.state.items}
          numberOfDays={this.state.numberOfDays}
          rowsPerHour={this.state.rowsPerHour}
          itemColors={Colors.Agenda}
          autoScale={true}
          fixedHeader={true}
          onItemEdit={this.handleItemEdit.bind(this)}
          onRangeSelection={this.handleRangeSelection.bind(this)}/>:<h2>no pudimos cargar tu zona horaria, recarga la pagina</h2>}

</div>}
</div>
    );
  }
}
export default connect(
    //función que mapea propiedades del state con propiedades del componente
    (state) => ({
        time: state.posts.time,
        placecoaches:state.placecoaches.placecoaches,
				activelevels:state.activelevels.activelevels,
        classroom: state.classroom.classroom
    }),
    //mapeo de funciones
    {
        DeletePost,fetchAddPost,fetchGetCoaches,GetLevels,GetClassroom,UpdateActive
    }
)(CreateAgenda);
