import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Helpers from '../../lib/Helper'
import Navbar from './Navbar';
import Posts from '../blog/postsAll'
class Easy extends Component {
  constructor(props){
    super(props)
    this.state={
      uid:'8B57J61q4bM51u9oF7tKln340Fm2',
      show:false,
      Navbar:null,
      posts:true,

    }

  }
  componentDidMount(){
    if(localStorage.getItem('userData')){
      let myItem = localStorage.getItem('userData')
      const info=JSON.parse(myItem)
      if(info.type=='MASTER'){
        //return (<Redirect to={'/admin'}/>)
        this.setState({editpu:true})
      }
      if(info.type=='admin'){
        this.setState({editpu:true})
      }

    }
    Helpers.GetHomepageInfo(this.state.uid,(des)=>{

 this.setState({
  Navbar:des.Navbar
 })
 })
  }

  render() {
    return (
      <div>
      {this.state.Navbar?
            <Navbar edit={false} data={this.state.Navbar}/>:null}
    <Container fluid className="margintop6">

      {this.state.posts==true?<Posts/>:<Button className="margintop6" onClick={this.handleClose}>posts</Button>}
    		</Container>
        </div>
    );
  }
}

export default Easy;
