import React, { Component } from 'react';
import { connect } from 'react-redux';
import Agenda from '../Agenda/Agenda'
import firebase from 'firebase'
import {Update} from '../../reducers/postsReducer';
import * as Web from '../../Constants/Web'
import '../../Styles/App.css';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Carousel from 'react-bootstrap/Carousel'
import logo from '../../Media/Conversation_Hackers_Logo.png'
import Time_Zone from '../Functions/Functions'
import classes from './student.module.css'
	const todos = [];

class App extends Component {

  constructor(props){
          super(props);
          this.state={

	            url2:Web.wurl+'/',
	            url:'Update/Update_StudentCountry.php',
              Datos:[],
                uid:'8B57J61q4bM51u9oF7tKln340Fm2',
                banner:'',
								Loader:true
            };
      }

async componentWillMount(){
	let myItem = localStorage.getItem('userData')
	const info=JSON.parse(myItem)
	const student={
		id:info.Id
	}

  const extr= firebase.database().ref().child("Gallery/Banners")
		 extr.on('value', (snapshot) => {

		 			snapshot.forEach((data) => {
		 				todos.push({
		 						Url:data.val().Url,
		 				});

		 this.setState({
		 banner:todos
		 })
		 })
		 });
}
  render() {
    const gallery=todos
    return (
			<div>


      <div className={classes.root}>

		<Carousel fade>

    {gallery.map(pic => (
      <Carousel.Item>
        <img
          className={classes.img}
          src={pic.Url}
        />
      </Carousel.Item>
        ))}
				</Carousel>


			</div>

	<div className="CH_Responsive">
		<Carousel>
			{gallery.map(pic => (
				<Carousel.Item>
						<img
							className="d-block w-100"
							src={pic.Url}
						/>
			</Carousel.Item>

			))}
	</Carousel>
	</div>

</div>
    );
  }
}

export default connect(
	(state) => ({
					edit: state.edit.edit,
					posts: state.posts.posts,
					place:state.datas.dato,
					placecoaches:state.placecoaches.placecoaches,
					placebirthdays:state.placebirthdays.placebirthdays,
					counterstudents:state.counterstudents.counterstudents,
					countercoaches:state.countercoaches.countercoaches,
					counterbirthdays:state.counterbirthdays.counterbirthdays
	}),
    //mapeo de funciones
    {Update}
)(App);
