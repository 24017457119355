import React, { useState, useEffect} from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
const InstallmentsModal = (props) =>{
  const { confirmPayment, installments, planIsAvailable } =props;
  const [openModal, setOpenModal ] = useState(false);

useEffect(()=>{
  setOpenModal(planIsAvailable);
},[planIsAvailable])

  const _confirm = (count) =>{
    confirmPayment(count);
    setOpenModal(false);
  }

  const showInstallments = planIsAvailable ? installments.map((per)=>{
      return<ListGroup.Item action variant="light" onClick={()=>_confirm(per.count)}>{per.count} Meses</ListGroup.Item>
  }) : null ;

  return(<div>
    <Modal show={openModal} >
      <Modal.Header closeButton>
        <Modal.Title>Selecciona los meses</Modal.Title><br/>
      </Modal.Header>
      <Modal.Body>
      <ListGroup variant="flush" >
      <ListGroup.Item action variant="light" onClick={()=>_confirm(null)}>1 Meses</ListGroup.Item>
      {showInstallments}
      </ListGroup >
      </Modal.Body>
    </Modal>

    </div>

  )
}
export default InstallmentsModal;
